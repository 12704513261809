import React, { useState, useEffect } from 'react';
import { FormHelperText, FormLabel, Radio, TextField } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useStateValue } from '../../../../providers/stateProvider';
import { getallgetbackofficestatus } from '../../../../Models/configapi';

function PostbackofficeTray() {
  const [{ user, backOfficeprecaseInspectionData, backOfficePreCaseData, preInspectionValidation }, dispatch]: any = useStateValue();


  const queryParams = new URLSearchParams(window.location.search);
  const preId: any = queryParams.get('id');
  const readFlag = preId > 0 && user?.BankId != null && user?.BankId != 0

  const [_formData, _setFormData] = useState<any>({
    overAllStatus: '',
    BOoverallRemarks:'',
    OfficeKm: "",
    ResiKm: "",
    CoAppResiKm: "",
    CoAppOfficeKm: "",
    GuaOfficeKm: "",
    GuaResiKm: "",

    AppLocation: 0,
    AppOfficeLocation: 0,
    CoAppLocation: 0,
    CoAppOfficeboLocation: 0,
    GuaLocation: 0,
    GuaOfficeboLocation: 0,

    backOfficeResidentStatus: '',
    backOfficeResidentOffice: '',
    backOfficeCoApplicant: '',
    backOfficeCoAppOffice: '',
    guaBackOfficeStatus: '',
    guaOfficeBackOfficeStatus: '',

    residentRemarks: "",
    resOfficeRemarks: '',
    coAppRemarks: '',
    coAppOfficeRemarks: '',
    guarantorOfficeRemarks: '',
    guarantorRemarks: '',
    AuthorityRemarks: '',

    residentStatus: '',
    statusResidentOffice: '',
    coApplicantStatus: '',
    coOfficeStatus: '',
    guaStatus: '',
    guaOfficeStatus: '',

    ResiCheck: [],
    OfficeCheck: [],
    CoAppResiCheck: [],
    CoAppOfficeCheck: [],
    GuaResiCheck: [],
    GuaOfficeCheck: [],

    ResiBOCheck: [],
    OfficeBOCheck: [],
    CoAppBOResiCheck: [],
    CoAppBOOfficeCheck: [],
    GuaBOResiCheck: [],
    GuaBOOfficeCheck: [],
    InspectionData: {}
  });

  const setFormValue = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
    dispatch({
      type: "backOfficeprecaseInspectionData",
      data: { ..._formData, ...backOfficeprecaseInspectionData, [name]: value },
    });

  };

  const updateItem = (item: any, value: any) => {
    let inspectionData = { ...backOfficeprecaseInspectionData?.InspectionData };
    inspectionData[item?.InspectionID] = {
      ...item,
      Answer: value
    }

    dispatch({
      type: "backOfficeprecaseInspectionData",
      data: { ..._formData, ...backOfficeprecaseInspectionData, InspectionData: { ...inspectionData } },
    });

  }
  const [backOfficeStatus, setbackOfficeStatus] = useState<any>([]);

  const getBackOfficeStatus = () => {
    getallgetbackofficestatus(user?.CorporateDetailsId)
      .then((response) => {
        setbackOfficeStatus(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getBackOfficeStatus();
  }, [])

  useEffect(() => {
    if (Object.entries(backOfficeprecaseInspectionData)) {
      _setFormData({ ..._formData, ...backOfficeprecaseInspectionData });
      console.log(backOfficeprecaseInspectionData)
    }
  }, [backOfficeprecaseInspectionData]);


  return (
    <>
      <div className="bg-white p-3 content-area">
        <div className="text-break">
          {_formData?.ResiBOCheck?.length > 0 && <>
            <div className="text-center fw-bold">Applicant Residence</div>
            {_formData?.ResiBOCheck?.map((item: any, index: any) => <div className='row my-1' key={index}>
              <div className="col-md-6" >
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.ResiCheck[index]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.ResiCheck[index]?.Answer || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6" >
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.InspectionData[item?.InspectionID]?.Answer || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <div className="fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div>
                    <TextField size="small" autoComplete='off'
                      variant="outlined" label=" "
                      value={_formData?.InspectionData[item?.InspectionID]?.Answer}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => updateItem(item, e.target.value)}
                      fullWidth
                      disabled={readFlag}
                    />
                  </div>
                </div>}
            </div>)}
            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.ResiKm || '-'}</div>
                </div>
              </div>
              {readFlag ?
                <div className="col-md-6">
                  <div className="row my-2">
                    <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                    <div className="col-md-6 fs14">{_formData?.ResiBokm || '-'}</div>
                  </div>
                </div>
                : <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Km :</label>
                  <div>
                    <TextField size="small" type="number"
                      variant="outlined" autoComplete='off'
                      label=" "
                      value={_formData?.ResiBokm}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => setFormValue("ResiBokm", e.target.value)}
                      fullWidth
                      disabled={readFlag}
                    />
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.residentLocation === 1 ? 'Local' : _formData?.residentLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div>
              {readFlag ?
                <div className="col-md-6">
                  <div className="row my-2">
                    <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                    <div className="col-md-6 fs14">{_formData?.AppLocation === 1 ? 'Local' : _formData?.AppLocation === 2 ? 'OGL' : '-'}</div>
                  </div>
                </div> : <div className="col-md-6">
                  <FormControl>
                    <div className="fs14 capitalize fw-bold">Location :</div>
                    <RadioGroup row >
                      <FormControlLabel
                        value="local"
                        control={<Radio />}
                        disabled={readFlag}
                        label="Local"
                        onChange={(e) =>
                          setFormValue("AppLocation", 1)
                        }
                        checked={_formData?.AppLocation === 1}
                      />
                      <FormControlLabel
                        value="ogl"
                        control={<Radio />}
                        disabled={readFlag}
                        onChange={(e) =>
                          setFormValue("AppLocation", 2)
                        }
                        checked={_formData?.AppLocation === 2}
                        label="OGL"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.backofficestatusappres)[0]?.TypeName || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.backOfficeResidentStatus)[0]?.TypeName || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Back Office Status :</label>
                  <div>
                    <FormControl size="small" fullWidth error={preInspectionValidation?.backOfficeResidentStatus?.error}>
                      <Select
                        value={_formData?.backOfficeResidentStatus}
                        onChange={(e) =>
                          setFormValue("backOfficeResidentStatus", e.target.value)
                        }
                        disabled={readFlag}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                      >
                        {backOfficeStatus.map((item: any, index: any) => (
                          <MenuItem key={item?.TypeName} value={item.Id}>
                            {item.TypeName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{preInspectionValidation?.backOfficeResidentStatus?.message}</FormHelperText>
                    </FormControl>
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.remarksappres || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.residentRemarks || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Remarks :</label>
                  <div>
                    <TextField size="small" fullWidth multiline autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                      label=" "
                      value={_formData?.residentRemarks}
                      onChange={(e) => setFormValue("residentRemarks", e.target.value)}
                      disabled={readFlag}
                      error={preInspectionValidation?.residentRemarks?.error} helperText={preInspectionValidation?.residentRemarks?.message}
                    />
                  </div>
                </div>}
            </div>
            <hr />
          </>
          }

          {_formData?.OfficeBOCheck?.length > 0 && <>
            <div className="text-center fw-bold">Applicant Office</div>
            {_formData?.OfficeBOCheck?.map((item: any, index: any) => <div className='row my-1' key={index}>
              <div className="col-md-6" >
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.OfficeCheck[index]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.OfficeCheck[index]?.Answer || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6" >
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.InspectionData[item?.InspectionID]?.Answer || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <div className="fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div>
                    <TextField size="small" autoComplete='off'
                      variant="outlined" label=" "
                      value={_formData?.InspectionData[item?.InspectionID]?.Answer}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => updateItem(item, e.target.value)}
                      disabled={readFlag}
                      fullWidth
                    />
                  </div>
                </div>}
            </div>)}
            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.OfficeKm || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.OfficeBokm || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Km :</label>
                  <div>
                    <TextField size="small" type="number"
                      variant="outlined" autoComplete='off'
                      label=" "
                      value={_formData?.OfficeBokm}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => setFormValue("OfficeBokm", e.target.value)}
                      fullWidth
                      disabled={readFlag}
                    />
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.residentOfficeLocation === 1 ? 'Local' : _formData?.residentOfficeLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.AppOfficeLocation === 1 ? 'Local' : _formData?.AppOfficeLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <FormControl>
                    <div className="fs14 capitalize fw-bold">Location :</div>
                    <RadioGroup row>
                      <FormControlLabel
                        value="local"
                        control={<Radio />}
                        label="Local"
                        onChange={(e) =>
                          setFormValue("AppOfficeLocation", 1)
                        }
                        checked={_formData?.AppOfficeLocation === 1}
                        disabled={readFlag}
                      />
                      <FormControlLabel
                        value="ogl"
                        control={<Radio />}
                        onChange={(e) =>
                          setFormValue("AppOfficeLocation", 2)
                        }
                        checked={_formData?.AppOfficeLocation === 2}
                        disabled={readFlag}
                        label="OGL"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.backofficestatusappoff)[0]?.TypeName || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.backOfficeResidentOffice)[0]?.TypeName || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Back Office Status :</label>
                  <div>
                    <FormControl size="small" fullWidth error={preInspectionValidation?.backOfficeResidentOffice?.error}>
                      <Select
                        value={_formData?.backOfficeResidentOffice}
                        onChange={(e) =>
                          setFormValue("backOfficeResidentOffice", e.target.value)
                        }
                        disabled={readFlag}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                      >
                        {backOfficeStatus.map((item: any, index: any) => (
                          <MenuItem key={item?.TypeName} value={item.Id}>
                            {item.TypeName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{preInspectionValidation?.backOfficeResidentOffice?.message}</FormHelperText>
                    </FormControl>
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.remarksappoff || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.resOfficeRemarks || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Remarks :</label>
                  <div>
                    <TextField size="small" fullWidth multiline
                      InputLabelProps={{ shrink: false }} autoComplete='off'
                      label=" "
                      value={_formData?.resOfficeRemarks}
                      onChange={(e) => setFormValue("resOfficeRemarks", e.target.value)}
                      disabled={readFlag}
                      error={preInspectionValidation?.resOfficeRemarks?.error} helperText={preInspectionValidation?.resOfficeRemarks?.message}
                    />
                  </div>
                </div>}
            </div>
            <hr />
          </>
          }

          {_formData?.CoAppBOResiCheck?.length > 0 && <>
            <div className="text-center fw-bold">Co Applicant Residence</div>
            {_formData?.CoAppBOResiCheck?.map((item: any, index: any) => <div className='row my-1' key={index}>
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.CoAppBOResiCheck[index]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.CoAppBOResiCheck[index]?.Answer || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.InspectionData[item?.InspectionID]?.Answer || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <div className="fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div>
                    <TextField size="small"
                      variant="outlined" label=" " autoComplete='off'
                      value={_formData?.InspectionData[item?.InspectionID]?.Answer}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => updateItem(item, e.target.value)}
                      fullWidth
                      disabled={readFlag}
                    />
                  </div>
                </div>}
            </div>)}
            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.CoAppResiKm || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.CoAppBokm || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className=" fs14 capitalize fw-bold">Km :</label>
                  <div>
                    <TextField size="small" type="number"
                      variant="outlined" autoComplete='off'
                      label=" "
                      value={_formData?.CoAppBokm}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => setFormValue("CoAppBokm", e.target.value)}
                      fullWidth
                      disabled={readFlag}
                    />
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.coAppLocation === 1 ? 'Local' : _formData?.coAppLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.CoAppLocation === 1 ? 'Local' : _formData?.CoAppLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <FormControl>
                    <div className="fs14 capitalize fw-bold">Location :</div>
                    <RadioGroup row>
                      <FormControlLabel
                        value="local"
                        control={<Radio />}
                        label="Local"
                        onChange={(e) =>
                          setFormValue("CoAppLocation", 1)
                        }
                        checked={_formData?.CoAppLocation === 1}
                        disabled={readFlag}
                      />
                      <FormControlLabel
                        value="ogl"
                        control={<Radio />}
                        onChange={(e) =>
                          setFormValue("CoAppLocation", 2)
                        }
                        checked={_formData?.CoAppLocation === 2}
                        label="OGL"
                        disabled={readFlag}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.backofficestatuscoappres)[0]?.TypeName || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.backOfficeCoApplicant)[0]?.TypeName || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className=" fs14 capitalize fw-bold">Back Office Status :</label>
                  <div>
                    <FormControl size="small" fullWidth error={preInspectionValidation?.backOfficeCoApplicant?.error}>
                      <Select
                        value={_formData?.backOfficeCoApplicant}
                        onChange={(e) =>
                          setFormValue("backOfficeCoApplicant", e.target.value)
                        }
                        disabled={readFlag}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                      >
                        {backOfficeStatus.map((item: any, index: any) => (
                          <MenuItem key={item?.TypeName} value={item.Id}>
                            {item.TypeName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{preInspectionValidation?.backOfficeCoApplicant?.message}</FormHelperText>
                    </FormControl>
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.remarkscoappres || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.coAppRemarks || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className=" fs14 capitalize fw-bold">Remarks :</label>
                  <div>
                    <TextField size="small" fullWidth multiline
                      InputLabelProps={{ shrink: false }} autoComplete='off'
                      label=" "
                      value={_formData?.coAppRemarks}
                      onChange={(e) => setFormValue("coAppRemarks", e.target.value)}
                      disabled={readFlag}
                      error={preInspectionValidation?.coAppRemarks?.error} helperText={preInspectionValidation?.coAppRemarks?.message}
                    />
                  </div>
                </div>}
            </div>
            <hr />
          </>}

          {_formData?.CoAppBOOfficeCheck?.length > 0 && <>
            <div className="text-center fw-bold">Co Applicant Office</div>
            {_formData?.CoAppBOOfficeCheck?.map((item: any, index: any) => <div className='row my-1' key={index}>
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.CoAppOfficeCheck[index]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.CoAppOfficeCheck[index]?.Answer || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.InspectionData[item?.InspectionID]?.Answer || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <div className=" fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div>
                    <TextField size="small"
                      variant="outlined" label=" " autoComplete='off'
                      value={_formData?.InspectionData[item?.InspectionID]?.Answer}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => updateItem(item, e.target.value)}
                      fullWidth
                      disabled={readFlag}
                    />
                  </div>
                </div>}
            </div>)}
            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.CoAppOfficeKm || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.CoAppOfficeBokm || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className=" fs14 capitalize fw-bold">Km :</label>
                  <div>
                    <TextField size="small" autoComplete='off' type="number" variant="outlined" label=" " value={_formData?.CoAppOfficeBokm}
                      InputLabelProps={{ shrink: false }} onChange={(e) => setFormValue("CoAppOfficeBokm", e.target.value)}
                      fullWidth disabled={readFlag} />
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.coAppOfficeLocation === 1 ? 'Local' : _formData?.coAppOfficeLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.CoAppOfficeboLocation === 1 ? 'Local' : _formData?.CoAppOfficeboLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div> : <div className="col-md-6">
                <FormControl>
                  <div className="fs14 capitalize fw-bold">Location :</div>
                  <RadioGroup row>
                    <FormControlLabel
                      value="local"
                      control={<Radio />}
                      label="Local"
                      onChange={(e) =>
                        setFormValue("CoAppOfficeboLocation", 1)
                      }
                      checked={_formData?.CoAppOfficeboLocation === 1}
                      disabled={readFlag}
                    />
                    <FormControlLabel
                      value="ogl"
                      control={<Radio />}
                      onChange={(e) =>
                        setFormValue("CoAppOfficeboLocation", 2)
                      }
                      disabled={readFlag}
                      checked={_formData?.CoAppOfficeboLocation === 2}
                      label="OGL"
                    />
                  </RadioGroup>
                </FormControl>
              </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.backofficestatuscoappoff)[0]?.TypeName || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.backOfficeCoAppOffice)[0]?.TypeName || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className=" fs14 capitalize fw-bold">Back Office Status :</label>
                  <div>
                    <FormControl size="small" fullWidth error={preInspectionValidation?.backOfficeCoAppOffice?.error}>
                      <Select
                        value={_formData?.backOfficeCoAppOffice}
                        onChange={(e) =>
                          setFormValue("backOfficeCoAppOffice", e.target.value)
                        }
                        disabled={readFlag}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                      >
                        {backOfficeStatus.map((item: any, index: any) => (
                          <MenuItem key={item?.TypeName} value={item.Id}>
                            {item.TypeName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{preInspectionValidation?.backOfficeCoAppOffice?.message}</FormHelperText>
                    </FormControl>
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.remarkscoappoff || '-'}</div>
                </div>
              </div>

              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.coAppOfficeRemarks || '-'}</div>
                </div>
              </div> : <div className="col-md-6">
                <label className=" fs14 capitalize fw-bold">Remarks :</label>
                <div>
                  <TextField size="small" autoComplete='off' fullWidth multiline
                    InputLabelProps={{ shrink: false }}
                    label=" "
                    value={_formData?.coAppOfficeRemarks}
                    onChange={(e) => setFormValue("coAppOfficeRemarks", e.target.value)}
                    disabled={readFlag}
                    error={preInspectionValidation?.coAppOfficeRemarks?.error} helperText={preInspectionValidation?.coAppOfficeRemarks?.message}

                  />
                </div>
              </div>}
            </div>
            <hr />
          </>
          }

          {_formData?.GuaBOResiCheck?.length > 0 && <>
            <div className="text-center fw-bold">Guarantor Residence</div>
            {_formData?.GuaBOResiCheck?.map((item: any, index: any) => <div className='row my-1' key={index}>
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.GuaResiCheck[index]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.GuaResiCheck[index]?.Answer || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.InspectionData[item?.InspectionID]?.Answer || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <div className="fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div>
                    <TextField size="small" autoComplete='off'
                      variant="outlined" label=" "
                      value={_formData?.InspectionData[item?.InspectionID]?.Answer}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => updateItem(item, e.target.value)}
                      disabled={readFlag}
                      fullWidth
                    />
                  </div>
                </div>}
            </div>)}
            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.GuaResiKm || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.GuaBokm || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Km :</label>
                  <div>
                    <TextField size="small" type="number"
                      variant="outlined" autoComplete='off'
                      label=" "
                      value={_formData?.GuaBokm}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => setFormValue("GuaBokm", e.target.value)}
                      fullWidth
                      disabled={readFlag}
                    />
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.guarantorLocation === 1 ? 'Local' : _formData?.guarantorLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.guarantorLocation === 1 ? 'Local' : _formData?.guarantorLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <FormControl>
                    <div className="fs14 capitalize fw-bold">Location :</div>
                    <RadioGroup row>
                      <FormControlLabel
                        value="local"
                        control={<Radio />}
                        label="Local"
                        onChange={(e) =>
                          setFormValue("GuaLocation", 1)
                        }
                        checked={_formData?.GuaLocation === 1}
                        disabled={readFlag}
                      />
                      <FormControlLabel
                        value="ogl"
                        control={<Radio />}
                        onChange={(e) =>
                          setFormValue("GuaLocation", 2)
                        }
                        checked={_formData?.GuaLocation === 2}
                        label="OGL"
                        disabled={readFlag}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.backofficestatusguares)[0]?.TypeName || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.guaBackOfficeStatus)[0]?.TypeName || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Back Office Status :</label>
                  <div>
                    <FormControl size="small" fullWidth error={preInspectionValidation?.guaBackOfficeStatus?.error}>
                      <Select
                        value={_formData?.guaBackOfficeStatus}
                        onChange={(e) =>
                          setFormValue("guaBackOfficeStatus", e.target.value)
                        }
                        disabled={readFlag}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                      >
                        {backOfficeStatus.map((item: any, index: any) => (
                          <MenuItem key={item?.TypeName} value={item.Id}>
                            {item.TypeName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{preInspectionValidation?.guaBackOfficeStatus?.message}</FormHelperText>
                    </FormControl>
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.remarksguares || '-'}</div>
                </div>
              </div>

              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.guarantorRemarks || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Remarks :</label>
                  <div>
                    <TextField size="small" fullWidth multiline
                      InputLabelProps={{ shrink: false }} autoComplete='off'
                      label=" "
                      value={_formData?.guarantorRemarks}
                      onChange={(e) => setFormValue("guarantorRemarks", e.target.value)}
                      disabled={readFlag}
                      error={preInspectionValidation?.guarantorRemarks?.error} helperText={preInspectionValidation?.guarantorRemarks?.message}
                    />
                  </div>
                </div>}
            </div>
            <hr />
          </>
          }

          {_formData?.GuaBOOfficeCheck?.length > 0 && <>
            <div className="text-center fw-bold">Guarantor Office</div>
            {_formData?.GuaBOOfficeCheck?.map((item: any, index: any) => <div className='row my-1' key={index}>
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.GuaOfficeCheck[index]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.GuaOfficeCheck[index]?.Answer || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div className="col-md-6 fs14">{_formData?.InspectionData[item?.InspectionID]?.Answer || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <div className="fs14 capitalize fw-bold">{(_formData?.InspectionData[item?.InspectionID]?.QuestionName).toLowerCase()} :</div>
                  <div>
                    <TextField size="small" autoComplete='off'
                      variant="outlined" label=" "
                      value={_formData?.InspectionData[item?.InspectionID]?.Answer}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => updateItem(item, e.target.value)}
                      fullWidth
                      disabled={readFlag}
                    />
                  </div>
                </div>}
            </div>)}
            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.GuaOfficeKm || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Km :</div>
                  <div className="col-md-6 fs14">{_formData?.GuaOfficeBokm || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Km :</label>
                  <div>
                    <TextField size="small" type="number" autoComplete='off'
                      variant="outlined"
                      label=" "
                      value={_formData?.GuaOfficeBokm}
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => setFormValue("GuaOfficeBokm", e.target.value)}
                      fullWidth
                      disabled={readFlag}
                    />
                  </div>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.guarantorOfficeLocation === 1 ? 'Local' : _formData?.guarantorOfficeLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Location :</div>
                  <div className="col-md-6 fs14">{_formData?.GuaOfficeboLocation === 1 ? 'Local' : _formData?.GuaOfficeboLocation === 2 ? 'OGL' : '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <FormControl>
                    <div className="fs14 capitalize fw-bold">Location :</div>
                    <RadioGroup row>
                      <FormControlLabel
                        value="local"
                        control={<Radio />}
                        label="Local"
                        onChange={(e) =>
                          setFormValue("GuaOfficeboLocation", 1)
                        }
                        checked={_formData?.GuaOfficeboLocation === 1}
                        disabled={readFlag}
                      />
                      <FormControlLabel
                        value="ogl"
                        control={<Radio />}
                        onChange={(e) =>
                          setFormValue("GuaOfficeboLocation", 2)
                        }
                        checked={_formData?.GuaOfficeboLocation === 2}
                        disabled={readFlag}
                        label="OGL"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.backofficestatusguaoff)[0]?.TypeName || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Back Office Status :</div>
                  <div className="col-md-6 fs14">{backOfficeStatus?.filter((item: any) => item?.Id === _formData?.guaOfficeBackOfficeStatus)[0]?.TypeName || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Back Office Status :</label>
                  <div>
                    <FormControl size="small" fullWidth error={preInspectionValidation?.guaOfficeBackOfficeStatus?.error}>
                      <Select
                        value={_formData?.guaOfficeBackOfficeStatus}
                        onChange={(e) =>
                          setFormValue("guaOfficeBackOfficeStatus", e.target.value)
                        }
                        disabled={readFlag}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                      >
                        {backOfficeStatus.map((item: any, index: any) => (
                          <MenuItem key={item?.TypeName} value={item.Id}>
                            {item.TypeName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{preInspectionValidation?.guaOfficeBackOfficeStatus?.message}</FormHelperText>
                    </FormControl>
                  </div>
                </div>}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.remarksguaoff || '-'}</div>
                </div>
              </div>
              {readFlag ? <div className="col-md-6">
                <div className="row my-2">
                  <div className="col-md-6 fs14 capitalize fw-bold">Remarks :</div>
                  <div className="col-md-6 fs14">{_formData?.guarantorOfficeRemarks || '-'}</div>
                </div>
              </div> :
                <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Remarks :</label>
                  <div>
                    <TextField size="small" fullWidth multiline
                      InputLabelProps={{ shrink: false }}
                      label=" " autoComplete='off'
                      value={_formData?.guarantorOfficeRemarks}
                      onChange={(e) => setFormValue("guarantorOfficeRemarks", e.target.value)}
                      disabled={readFlag}
                      error={preInspectionValidation?.guarantorOfficeRemarks?.error} helperText={preInspectionValidation?.guarantorOfficeRemarks?.message}
                    />
                  </div>
                </div>}
            </div>


            <hr />
          </>}

          <div className='row'>
            <div className="col-md-6"></div>
            {readFlag ? <div className="col-md-6">
              <div className="row my-2">
                <div className="col-md-6 fs14 capitalize fw-bold">BackOffice OverAll Status :</div>
                <div className="col-md-6 fs14">{_formData?.overAllStatus || '-'}</div>
              </div>
            </div> : <div className="col-md-6">
              <label className="fs14 capitalize fw-bold">BackOffice OverAll Status :</label>
              <div>
                <FormControl size="small" fullWidth error={preInspectionValidation?.overAllStatus?.error}>
                  <Select
                    value={_formData?.overAllStatus}
                    disabled={readFlag}
                    onChange={(e) => setFormValue("overAllStatus", e.target.value)}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    <MenuItem value={'Positive'}>Positive</MenuItem>
                    <MenuItem value={'Negative'}>Negative</MenuItem>
                    <MenuItem value={'Failed'}>Failed</MenuItem>
                  </Select>
                  <FormHelperText>{preInspectionValidation?.overAllStatus?.message}</FormHelperText>
                </FormControl>
              </div>
            </div>}
          </div>

          <div className='row'>
            <div className="col-md-6"></div>
            {readFlag ? <div className="col-md-6">
              <div className="row my-2">
                <div className="col-md-6 fs14 capitalize fw-bold">BackOffice OverAll Remarks :</div>
                <div className="col-md-6 fs14">{_formData?.BOoverallRemarks || '-'}</div>
              </div>
            </div> : <div className="col-md-6">
              <label className="fs14 capitalize fw-bold">BackOffice OverAll Remarks :</label>
              <div>
                {/* <FormControl size="small" fullWidth error={preInspectionValidation?.BOoverallRemarks?.error}> */}
                  
                <TextField size="small" fullWidth multiline
                      InputLabelProps={{ shrink: false }}
                      label=" " autoComplete='off'
                      value={_formData?.BOoverallRemarks}
                      onChange={(e) => setFormValue("BOoverallRemarks", e.target.value)}
                      disabled={readFlag}
                      error={preInspectionValidation?.BOoverallRemarks?.error} helperText={preInspectionValidation?.BOoverallRemarks?.message}
                    />
                  
                  {/* <Select
                    value={_formData?.BOoverallRemarks}
                    disabled={readFlag}
                    onChange={(e) => setFormValue("BOoverallRemarks", e.target.value)}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    <MenuItem value={'Positive'}>Positive</MenuItem>
                    <MenuItem value={'Negative'}>Negative</MenuItem>
                    <MenuItem value={'Failed'}>Failed</MenuItem>
                  </Select>
                  <FormHelperText>{preInspectionValidation?.BOoverallRemarks?.message}</FormHelperText>
                </FormControl> */}
              </div>
            </div>}
          </div>

          {(backOfficePreCaseData?.applicantAuthority === 1 || backOfficePreCaseData?.officeAuthority === 1 ||
            backOfficePreCaseData?.coAppOfficeAuthority === 1 || backOfficePreCaseData?.coAppAuthority === 1
            || backOfficePreCaseData?.guarantorAuthority === 1 || backOfficePreCaseData?.GuarantorOfficeAuthority === 1) && <>
              <div className='row'>
                <div className="col-md-6"></div>
                {readFlag ? <div className="col-md-6">
                  <div className="row my-2">
                    <div className="col-md-6 fs14 capitalize fw-bold">Authority Remarks :</div>
                    <div className="col-md-6 fs14">{_formData?.AuthorityRemarks || '-'}</div>
                  </div>
                </div> : <div className="col-md-6">
                  <label className="fs14 capitalize fw-bold">Authority Remarks :</label>
                  <div>
                    <TextField size="small" fullWidth multiline
                      InputLabelProps={{ shrink: false }}
                      label=" " autoComplete='off'
                      value={_formData?.AuthorityRemarks}
                      onChange={(e) => setFormValue("AuthorityRemarks", e.target.value)}
                      disabled={readFlag}
                      error={preInspectionValidation?.AuthorityRemarks?.error} helperText={preInspectionValidation?.AuthorityRemarks?.message}
                    />
                  </div>
                </div>}
              </div>
            </>
          }

          {/* <div className='row'>
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <label className=" fs14 capitalize fw-bold">BackOffice OverAll Status</label>
              <div>
                <FormControl size="small" fullWidth>
                  <Select id="demo-simple-select-helper" label=" "
                    value={_formData?.overAllStatus}
                    onChange={(e) => setFormValue("overAllStatus", e.target.value)}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    <MenuItem value={'Positive'}>Positive</MenuItem>
                    <MenuItem value={'Negative'}>Negative</MenuItem>
                    <MenuItem value={'Failed'}>Failed</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default PostbackofficeTray;
