
import { LoadingButton, TabContext, TabList } from '@mui/lab';
import { Tab, Button, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, tableCellClasses, TablePagination } from '@mui/material';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import inputdataicon from '../../assets/images/InputDataWhite.png';
import PDF from '../../assets/images/ExportPDF.png';
import Excel from '../../assets/images/ExportExcel.png';
import { useStateValue } from '../../providers/stateProvider';
import { styled } from '@mui/material/styles';
import { getAllPostcaseInspectionData, updateInspectionData, getAllInspectionDataPost, getMISforXcellDownload } from '../../Models/configapi';
import Backofficetray from '../../pages/inputData/backofficetray/postcase';
import PostbackofficeTray from '../../pages/inputData/backofficetray/postcase/inspectionDetails';
import Postcasedocument from '../../pages/inputData/backofficetray/postcase/postCaseDocuments';
import Postimages from '../../pages/inputData/backofficetray/postcase/Posttimages';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { pdfBackOffice } from './pdf';
import moment from 'moment';
import { downloadExcel } from "react-export-table-to-excel";
import swal from 'sweetalert';
import { Close, DoDisturb } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { SkeletonProviderTables } from '../../providers/SkeletonProvider';
import { getAllPostCase } from '../../Models/model';
import { routerPath } from '../../config/constents';
import PostInspectionValidation from './postInspectionValidation';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { sortTableData } from '../../services/helperService';
import { SortableTableHead } from '../helpers/tableSortableHeader';

type Order = 'asc' | 'desc';


function Index() {
  const navigate = useNavigate();
  const { handleValidation } = PostInspectionValidation();
  const [count, setCount] = useState(1);
  const [{ user, backOfficepostcaseInspectionData, postcaseinspectionImages }, dispatch]: any = useStateValue();
  const [_tableData, _setTableData] = useState<any>([]);
  const [_allData, _setAllData] = useState<any>([]);
  const [_tableLoading, _setTableLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [_save, _setSave] = useState(false);
  const [_submit, _setSubmit] = useState(false);
  const [_loading, _setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [_misreportExport, _setmisreportExport] = useState<any>([]);
  const [_order, _setOrder] = useState<Order>('asc');
  const [_orderBy, _setOrderBy] = useState<string>('');
  const [_totalRecordCount, _setTotalRecordCount] = useState(0)

  const queryParams = new URLSearchParams(window.location.search);
  const postId: any = queryParams.get('id');

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSaveClick = (_type: any) => {
    _setLoading(true);
    const tempArr = Object.keys(backOfficepostcaseInspectionData?.InspectionData).map((key) => backOfficepostcaseInspectionData?.InspectionData[key])
    let data = {
      caseid: backOfficepostcaseInspectionData?.postcaseId,
      userid: user.id,
      lCorporateId: user.CorporateDetailsId,
      casetype: "postcase",
      SaveType: _type || '',

      OverallCaseStatus: backOfficepostcaseInspectionData?.overAllStatus || '',

      remarksappres: backOfficepostcaseInspectionData?.residentRemarks || "",
      remarksappoff: backOfficepostcaseInspectionData?.resOfficeRemarks || "",
      remarkscoappres: backOfficepostcaseInspectionData?.coAppRemarks || "",
      remarkscoappoff: backOfficepostcaseInspectionData?.coAppOfficeRemarks || "",
      remarksguaoff: backOfficepostcaseInspectionData?.guarantorOfficeRemarks || "",
      remarksguares: backOfficepostcaseInspectionData?.guarantorRemarks || "",
      AuthorityRemarks: backOfficepostcaseInspectionData?.AuthorityRemarks || "",

      backofficestatusappres: Number(backOfficepostcaseInspectionData?.backOfficeResidentStatus) || 0,
      backofficestatusappoff: Number(backOfficepostcaseInspectionData?.backOfficeResidentOffice) || 0,
      backofficestatuscoappres: Number(backOfficepostcaseInspectionData?.backOfficeCoApplicant) || 0,
      backofficestatuscoappoff: Number(backOfficepostcaseInspectionData?.backOfficeCoAppOffice) || 0,
      backofficestatusguares: Number(backOfficepostcaseInspectionData?.guaBackOfficeStatus) || 0,
      backofficestatusguaoff: Number(backOfficepostcaseInspectionData?.guaOfficeBackOfficeStatus) || 0,

      AppLocation: Number(backOfficepostcaseInspectionData?.AppLocation) || 0,
      AppOfficeLocation: Number(backOfficepostcaseInspectionData?.AppOfficeLocation) || 0,
      CoAppLocation: Number(backOfficepostcaseInspectionData?.CoAppLocation) || 0,
      CoAppOfficeboLocation: Number(backOfficepostcaseInspectionData?.CoAppOfficeboLocation) || 0,
      GuaLocation: Number(backOfficepostcaseInspectionData?.GuaLocation) || 0,
      GuaOfficeboLocation: Number(backOfficepostcaseInspectionData?.GuaOfficeboLocation) || 0,

      resiBoKM: Number(backOfficepostcaseInspectionData?.ResiBokm) || 0,
      OfficeBoKM: Number(backOfficepostcaseInspectionData?.OfficeBokm) || 0,
      coAppBoKM: Number(backOfficepostcaseInspectionData?.CoAppBokm) || 0,
      coAppOfficeBoKM: Number(backOfficepostcaseInspectionData?.CoAppOfficeBokm) || 0,
      GuaBoKM: Number(backOfficepostcaseInspectionData?.GuaBokm) || 0,
      GuaOfficeBoKM: Number(backOfficepostcaseInspectionData?.GuaOfficeBokm) || 0,


      querylist: [...tempArr]
    };

    if (!handleValidation(1)) {
      _setLoading(false)
      return;
    }

    console.log(data)
    updateInspectionData(data).then((response) => {
      if (response.status === 201) {
        getTableData();
        refreshPage();
        setCount(1);
        swal(`PostCase ${_type === 'Save' ? 'Saved' : 'Updated'} Successfully`, { icon: "success" });
        // navigate(routerPath.qcTray.postcase)
      }
    }).catch((error) => {
      swal("Something went Wrong", { icon: "warning" });
    }).finally(() => _setLoading(false));
  }

  const viewData = (item: any) => {
    refreshPage();
    window.scrollTo(0, 0);
    setCount(1);

    if (item?.Status === 'Completed') {

    } else if (item?.Status === 'In Progress') {
      _setSave(true);
      _setSubmit(false);
    } else if (item?.Status === 'B.O Tray Pending') {
      _setSave(false);
      _setSubmit(true);
    }

    getAllInspectionDataPost(item?.postcaseId, user?.CorporateDetailsId)
      .then((response) => {
        //console.log(response.data)
        debugger;
        if (response.data.status === 'Success') {

          // const arr = [...response.data.data.ResiCheck, ...response.data.data.OfficeCheck, ...response.data.data.CoAppResiCheck,
          // ...response.data.data.CoAppOfficeCheck, ...response.data.data.GuaResiCheck, ...response.data.data.GuaOfficeCheck]
          let arr: any = [];

          if (response?.data?.data?.ResiBOCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.ResiBOCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.ResiCheck]
          }

          if (response?.data?.data?.OfficeBOCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.OfficeBOCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.OfficeCheck]
          }

          if (response?.data?.data?.CoAppBOResiCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.CoAppBOResiCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.CoAppResiCheck]
          }

          if (response?.data?.data?.CoAppBOOfficeCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.CoAppBOOfficeCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.CoAppOfficeCheck]
          }

          if (response?.data?.data?.GuaBOResiCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.GuaBOResiCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.GuaResiCheck]
          }

          if (response?.data?.data?.GuaBOOfficeCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.GuaBOOfficeCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.GuaOfficeCheck]
          }

          let obj: any = {}
          arr.forEach((temp: any) => {
            obj[temp?.InspectionID] = temp;
          })


          // debugger;
          dispatch({
            type: "backOfficePostCaseData", data: { ...item, _view: true, }
          });
          dispatch({
            type: "backOfficepostcaseInspectionData", data: {
              ...item,
              backOfficeResidentStatus: item?.BoresiStatus || 0,
              backOfficeResidentOffice: item?.BoofficeStatus || 0,
              backOfficeCoApplicant: item?.BocoAppResiStatus || 0,
              backOfficeCoAppOffice: item?.BocoAppOfficeStatus || 0,
              guaBackOfficeStatus: item?.BoguaResiStatus || 0,
              guaOfficeBackOfficeStatus: item?.BoguaOfficeStatus || 0,

              residentLocation: item?.ResiLocation || 0,
              residentOfficeLocation: item?.OfficeLocation || 0,
              coAppLocation: item?.CoAppResiLocation || 0,
              coAppOfficeLocation: item?.CoAppOfficeLocation || 0,
              guarantorLocation: item?.GuaResiLocation || 0,
              guarantorOfficeLocation: item?.GuaOfficeboLocation || 0,

              AppLocation: item?.AppBoLocation || 0,
              AppOfficeLocation: item?.OfficeAppBoLocation || 0,
              CoAppLocation: item?.CoappBoLocation || 0,
              CoAppOfficeboLocation: item?.CoappOfficeBoLocation || 0,
              GuaLocation: item?.GuaBoLocation || 0,
              GuaOfficeboLocation: item?.GuaBoOfficeLocation || 0,

              residentRemarks: item?.BoresiRemarks || "",
              resOfficeRemarks: item?.BoofficeRemarks || "",
              coAppRemarks: item?.BocoAppResiRemarks || "",
              coAppOfficeRemarks: item?.BocoAppOfficeRemarks || "",
              guarantorRemarks: item?.BoguaResiRemarks || "",
              guarantorOfficeRemarks: item?.BoguaOfficeRemarks || "",
              AuthorityRemarks: item?.AuthorityRemarks || "",

              remarksappres: item?.BoresiRemarks || "",
              remarksappoff: item?.BoofficeRemarks || "",
              remarkscoappres: item?.BocoAppResiRemarks || "",
              remarkscoappoff: item?.BocoAppOfficeRemarks || "",
              remarksguaoff: item?.BoguaResiRemarks || "",
              remarksguares: item?.BoguaOfficeRemarks || "",

              backofficestatusappres: item?.BoresiStatus || 0,
              backofficestatusappoff: item?.BoofficeStatus || 0,
              backofficestatuscoappres: item?.BocoAppResiStatus || 0,
              backofficestatuscoappoff: item?.BocoAppOfficeStatus || 0,
              backofficestatusguares: item?.BoguaResiStatus || 0,
              backofficestatusguaoff: item?.BoguaOfficeStatus || 0,

              overAllStatus: item?.OverallCaseStatus || '',

              ResiCheck: response.data.data.ResiCheck,
              OfficeCheck: response.data.data.OfficeCheck,
              CoAppResiCheck: response.data.data.CoAppResiCheck,
              CoAppOfficeCheck: response.data.data.CoAppOfficeCheck,
              GuaResiCheck: response.data.data.GuaResiCheck,
              GuaOfficeCheck: response.data.data.GuaOfficeCheck,

              ResiBOCheck: response?.data?.data?.ResiBOCheck?.length > 0 ? response?.data?.data?.ResiBOCheck : response?.data?.data?.ResiCheck,
              OfficeBOCheck: response?.data?.data?.OfficeBOCheck?.length > 0 ? response?.data?.data?.OfficeBOCheck : response?.data?.data?.OfficeCheck,
              CoAppBOResiCheck: response?.data?.data?.CoAppBOResiCheck?.length > 0 ? response?.data?.data?.CoAppBOResiCheck : response?.data?.data?.CoAppResiCheck,
              CoAppBOOfficeCheck: response?.data?.data?.CoAppBOOfficeCheck?.length > 0 ? response?.data?.data?.CoAppBOOfficeCheck : response?.data?.data?.CoAppOfficeCheck,
              GuaBOResiCheck: response?.data?.data?.GuaBOResiCheck?.length > 0 ? response?.data?.data?.GuaBOResiCheck : response?.data?.data?.GuaResiCheck,
              GuaBOOfficeCheck: response?.data?.data?.GuaBOOfficeCheck?.length > 0 ? response?.data?.data?.GuaBOOfficeCheck : response?.data?.data?.GuaOfficeCheck,


              InspectionData: { ...obj }, _view: true,
            }
          });

          const docData = _allData?.filter((aItem: any) => aItem?.CaseDetails?.postcaseId === item?.postcaseId)[0]

          dispatch({
            type: "postcasedocProperties", data: { ...item, documentProperties: docData?.documentProperties, _view: true, }
          })
          dispatch({
            type: "postcaseinspectionImages", data: { ...item, InspectiondocumentProperties: docData?.InspectiondocumentProperties, _view: true, }
          })

        }
      })
      .catch((error) => {
        console.log(error.response);
      });
    // const arr = [...item?.ResiCheck, ...item?.OfficeCheck, ...item?.CoAppResiCheck, 
    //   ...item?.CoAppOfficeCheck, ...item?.GuaResiCheck, ...item?.GuaOfficeCheck]
    // let obj:any = {}
    // arr.forEach((temp:any) => {
    //   obj[temp?.InspectionID] = temp;
    // })

    // dispatch({
    //   type: "backOfficePostCaseData", data: { ...item, ...item?.CaseDetails,  _view: true }
    // });
    // dispatch({
    //   type: "backOfficepostcaseInspectionData", data: { ...item, ...item, InspectionData: {...obj}, _view: true }
    // });
    // dispatch({
    //   type:"postcasedocProperties",data:{...item,...item?.documentProperties, _view:true}
    // })
    // dispatch({
    //   type:"postcaseinspectionImages",data:{...item,...item?.InspectiondocumentProperties, _view:true}
    // })
  };

  const getTableData = () => {
    getAllPostcaseInspectionData(user.id, page, rowsPerPage, user?.CorporateDetailsId)
      .then((response) => {
        console.log(response.data)
        if (response.data.status === 'Success') {
          _setAllData([...response?.data?.data]);
          _setTableData([...response?.data?.data?.map((item: any) => { return { ...item?.CaseDetails } })]);
          _setTotalRecordCount(response.data.data[0].dataCount)

        }
      })
      .catch((error) => {
        console.log(error.response);
      }).finally(() => _setTableLoading(false));;
  }

  const viewDashboardData = (id: any) => {
    getAllPostCase(user.id, user?.CorporateDetailsId)
      .then(response => {
        if (response.status === 200) {
          console.log(response?.data)
          const caseData = response?.data?.Data?.filter((content: any) => content?.postcaseid == id)
          getDashboardData(caseData[0]);
        }
      })
      .catch(error => console.log(error))
  }

  const getDashboardData = (item: any) => {
    getAllInspectionDataPost(item?.postcaseId, user?.CorporateDetailsId)
      .then((response) => {
        //console.log(response.data)
        debugger;
        if (response.data.status === 'Success') {

          // const arr = [...response.data.data.ResiCheck, ...response.data.data.OfficeCheck, ...response.data.data.CoAppResiCheck,
          // ...response.data.data.CoAppOfficeCheck, ...response.data.data.GuaResiCheck, ...response.data.data.GuaOfficeCheck]
          let arr: any = [];

          if (response?.data?.data?.ResiBOCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.ResiBOCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.ResiCheck]
          }

          if (response?.data?.data?.OfficeBOCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.OfficeBOCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.OfficeCheck]
          }

          if (response?.data?.data?.CoAppBOResiCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.CoAppBOResiCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.CoAppResiCheck]
          }

          if (response?.data?.data?.CoAppBOOfficeCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.CoAppBOOfficeCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.CoAppOfficeCheck]
          }

          if (response?.data?.data?.GuaBOResiCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.GuaBOResiCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.GuaResiCheck]
          }

          if (response?.data?.data?.GuaBOOfficeCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.GuaBOOfficeCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.GuaOfficeCheck]
          }

          let obj: any = {}
          arr.forEach((temp: any) => {
            obj[temp?.InspectionID] = temp;
          })


          // debugger;
          dispatch({
            type: "backOfficePostCaseData", data: { ...item, _view: true, }
          });
          dispatch({
            type: "backOfficepostcaseInspectionData", data: {
              ...item,
              backOfficeResidentStatus: item?.BoresiStatus || 0,
              backOfficeResidentOffice: item?.BoofficeStatus || 0,
              backOfficeCoApplicant: item?.BocoAppResiStatus || 0,
              backOfficeCoAppOffice: item?.BocoAppOfficeStatus || 0,
              guaBackOfficeStatus: item?.BoguaResiStatus || 0,
              guaOfficeBackOfficeStatus: item?.BoguaOfficeStatus || 0,

              residentLocation: item?.ResiLocation || 0,
              residentOfficeLocation: item?.OfficeLocation || 0,
              coAppLocation: item?.CoAppResiLocation || 0,
              coAppOfficeLocation: item?.CoAppOfficeLocation || 0,
              guarantorLocation: item?.GuaResiLocation || 0,
              guarantorOfficeLocation: item?.GuaOfficeboLocation || 0,

              AppLocation: item?.AppBoLocation || 0,
              AppOfficeLocation: item?.OfficeAppBoLocation || 0,
              CoAppLocation: item?.CoappBoLocation || 0,
              CoAppOfficeboLocation: item?.CoappOfficeBoLocation || 0,
              GuaLocation: item?.GuaBoLocation || 0,
              GuaOfficeboLocation: item?.GuaBoOfficeLocation || 0,

              residentRemarks: item?.BoresiRemarks || "",
              resOfficeRemarks: item?.BoofficeRemarks || "",
              coAppRemarks: item?.BocoAppResiRemarks || "",
              coAppOfficeRemarks: item?.BocoAppOfficeRemarks || "",
              guarantorRemarks: item?.BoguaResiRemarks || "",
              guarantorOfficeRemarks: item?.BoguaOfficeRemarks || "",
              AuthorityRemarks: item?.AuthorityRemarks || "",

              remarksappres: item?.BoresiRemarks || "",
              remarksappoff: item?.BoofficeRemarks || "",
              remarkscoappres: item?.BocoAppResiRemarks || "",
              remarkscoappoff: item?.BocoAppOfficeRemarks || "",
              remarksguaoff: item?.BoguaResiRemarks || "",
              remarksguares: item?.BoguaOfficeRemarks || "",

              backofficestatusappres: item?.BoresiStatus || 0,
              backofficestatusappoff: item?.BoofficeStatus || 0,
              backofficestatuscoappres: item?.BocoAppResiStatus || 0,
              backofficestatuscoappoff: item?.BocoAppOfficeStatus || 0,
              backofficestatusguares: item?.BoguaResiStatus || 0,
              backofficestatusguaoff: item?.BoguaOfficeStatus || 0,

              overAllStatus: item?.OverallCaseStatus || '',

              ResiCheck: response.data.data.ResiCheck,
              OfficeCheck: response.data.data.OfficeCheck,
              CoAppResiCheck: response.data.data.CoAppResiCheck,
              CoAppOfficeCheck: response.data.data.CoAppOfficeCheck,
              GuaResiCheck: response.data.data.GuaResiCheck,
              GuaOfficeCheck: response.data.data.GuaOfficeCheck,

              ResiBOCheck: response?.data?.data?.ResiBOCheck?.length > 0 ? response?.data?.data?.ResiBOCheck : response?.data?.data?.ResiCheck,
              OfficeBOCheck: response?.data?.data?.OfficeBOCheck?.length > 0 ? response?.data?.data?.OfficeBOCheck : response?.data?.data?.OfficeCheck,
              CoAppBOResiCheck: response?.data?.data?.CoAppBOResiCheck?.length > 0 ? response?.data?.data?.CoAppBOResiCheck : response?.data?.data?.CoAppResiCheck,
              CoAppBOOfficeCheck: response?.data?.data?.CoAppBOOfficeCheck?.length > 0 ? response?.data?.data?.CoAppBOOfficeCheck : response?.data?.data?.CoAppOfficeCheck,
              GuaBOResiCheck: response?.data?.data?.GuaBOResiCheck?.length > 0 ? response?.data?.data?.GuaBOResiCheck : response?.data?.data?.GuaResiCheck,
              GuaBOOfficeCheck: response?.data?.data?.GuaBOOfficeCheck?.length > 0 ? response?.data?.data?.GuaBOOfficeCheck : response?.data?.data?.GuaOfficeCheck,


              InspectionData: { ...obj }, _view: true,
            }
          });
          dispatch({
            type: "postcasedocProperties", data: { ...item, ...item?.documentProperties, _view: true, }
          })
          dispatch({
            type: "postcaseinspectionImages", data: { ...item, InspectiondocumentProperties: response?.data?.data?.InspectiondocumentProperties, _view: true, }
          })

        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  useEffect(() => {
    if (postId && user?.BankId != null && user?.BankId != 0) {
      viewDashboardData(postId)
    }
  }, [postId])

  const refreshPage = () => {
    dispatch({ type: "backOfficePostCaseData", data: {} });
    dispatch({ type: "backOfficepostcaseInspectionData", data: {} });
    dispatch({ type: "postcasedocProperties", data: {} });
    dispatch({ type: "postcaseinspectionImages", data: {} });
    dispatch({ type: "postInspectionValidation", data: {} });

    setCount(1);
    _setSave(false);
    _setSubmit(false);
  }

  useEffect(() => {
    refreshPage();
    getTableData();
    MisDownload();
  }, []);

  useEffect(() => {
    getTableData();
  }, [page, rowsPerPage])

  const exportPDF = () => {
    var curDate = moment()?.format("YYYY-MM-DD")
    const filename = 'BackOffice_PostCase_' + curDate + '.pdf'
    const headers = [
      { key: 'caseNo', display: 'Case No' },
      { key: 'caseDate', display: 'Case Date' },
      { key: 'vendorName', display: 'Vendor Name' },
      { key: 'BankName', display: 'Bank Agency' },
      { key: 'productName', display: 'Product' },
      { key: 'segmentName', display: 'Segment' },
      { key: 'customerName', display: 'Customer Name' },
      { key: 'fieldExecutiveName', display: 'Executive Name' },
    ]
    debugger;
    pdfBackOffice({ data: _tableData, headers, filename })
  }
  const exportEXCEL = () => {
    const header = ["Case No", "Case Date", "Vendor Name", "Bank Agency", "Product", "Segment", "Customer Name", "Executive Name"];
    let body: any = _tableData?.map((e: any) => { return [e.caseNo, e.caseDate, e.vendorName, e.BankName, e.productName, e.segmentName, e.customerName, e.fieldExecutiveName] })
    var curDate = moment()?.format("YYYY-MM-DD")
    var filename = 'BackOffice_PostCase_' + curDate;
    downloadExcel({
      fileName: filename,
      sheet: "BackOffice_PostCase",
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  const MisDownload = () => {
    debugger;
    var PageName = "BackOfficeTray";
    var UserID = user.id;
    var caseType = "postcase";

    getMISforXcellDownload(caseType, UserID, PageName, user?.CorporateDetailsId).then((response) => {
      if (response.data.status === "Success") {
        debugger;
        _setmisreportExport(response.data.data);

      }
    })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const exportMISEXCEL = () => {
    debugger;
    const header = ["CaseNo", "vendorName", "bankName", "branchName", "LoanAmount", "BankEmployeeName", "DsaDesName", "productName", "BankReferenceNo",
      "TypeOfSampling", "CustomerName", "CustomerCity", "CustomerPincode", "FathersName", "DateOfBirth", "EmployeType", "CompanyName", "OfficeCity",
      "OfficePincode", "Designation", "OfficeTrigger", "ResidencyTrigger", "CoAppTrigger", "CoAppOfficeTrigger", "GuarantorTrigger", "GuarantorOfficeTrigger",
      "QcresiRemarks", "QcofficeRemarks", "QccoAppOfficeRemarks", "QcguaOfficeRemarks", "QcguaResiRemarks", "CaseDate", "QcCloseDate",
      "AppFieldStaffStatusName", "AppOfficeFieldStaffStatusName", "CoAppFieldStaffStatusName", "CoAppOfficeFieldStaffStatusName",
      "GuarantorFieldStaffStatusName",
      "ApplicantFieldStaffName", "ApplicantOfficeFieldStaffName", "CoAppFieldStaffName", "CoAppOfficeFieldStaffName", "GuarantorFieldStaffName", "GuarantorOfficeFieldStaffName",

      "AppBoLocation", "OfficeAppBoLocation", "CoappBoLocation", "CoappOfficeBoLocation", "GuaBoLocation", "GuaBoOfficeLocation",
      "Resikm", "Officekm", "CoAppkm", "CoAppOfficekm", "Guakm", "GuaOfficekm", "ResiBokm", "OfficeBokm", "CoAppBokm", "CoAppOfficeBokm", "GuaBokm", "GuaOfficeBokm",];
    let body: any = _misreportExport.map((e: any) => {
      return [e.CaseNo, e.vendorName, e.bankName, e.branchName, e.LoanAmount, e.BankEmployeeName, e.DsaDesName, e.productName, e.BankReferenceNo,
      e.TypeOfSampling, e.CustomerName, e.CustomerCity, e.CustomerPincode, e.FathersName, e.DateOfBirth, e.EmployeType, e.CompanyName, e.OfficeCity,
      e.OfficePincode, e.Designation, e.OfficeTrigger, e.ResidencyTrigger, e.CoAppTrigger, e.CoAppOfficeTrigger, e.GuarantorTrigger, e.GuarantorOfficeTrigger,
      e.QcresiRemarks, e.QcofficeRemarks, e.QccoAppOfficeRemarks, e.QcguaOfficeRemarks, e.QcguaResiRemarks, e.CaseDate, e.QcCloseDate,
      e.AppFieldStaffStatusName, e.AppOfficeFieldStaffStatusName, e.CoAppFieldStaffStatusName, e.CoAppOfficeFieldStaffStatusName,
      e.GuarantorFieldStaffStatusName,
      e.ApplicantFieldStaffName, e.ApplicantOfficeFieldStaffName, e.CoAppFieldStaffName, e.CoAppOfficeFieldStaffName, e.GuarantorFieldStaffName, e.GuarantorOfficeFieldStaffName,

      e.AppBoLocation, e.OfficeAppBoLocation, e.CoappBoLocation, e.CoappOfficeBoLocation, e.GuaBoLocation, e.GuaBoOfficeLocation,
      e.Resikm, e.Officekm, e.CoAppkm, e.CoAppOfficekm, e.Guakm, e.GuaOfficekm, e.ResiBokm, e.OfficeBokm, e.CoAppBokm, e.CoAppOfficeBokm, e.GuaBokm, e.GuaOfficeBokm,]
    })
    var curDate = moment()?.format("YYYY-MM-DD")
    var filename = 'Backoffice_Postcase_MIS_Report_' + curDate;
    downloadExcel({
      fileName: filename,
      sheet: "Product_Master",
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = _orderBy === property && _order === 'asc';
    _setOrder(isAsc ? 'desc' : 'asc');
    _setOrderBy(property);
  };

  useEffect(() => {
    const data = sortTableData(_tableData, _order, _orderBy);
    _setTableData(data)
  }, [_order, _orderBy])


  return (
    <>
      <div className={`d-flex justify-content-between align-items-center bg-primary text-white p-3 ${user?.BankId ? 'roundedTop' : ''}`}>
        <div>
          <h5 className="mb-0">
            <span className="mx-2">
              <img src={inputdataicon} />
            </span>
            Back Office Tray-Post Case
          </h5>
        </div>
      </div>
      <div className="bg-white p-3 content-area">
        <div
          className={`text-white mb-3 mt-3 px-4 d-flex align-items-center bg-primary ${user?.BankId ? 'rounded--1 py-2' : ''}`}
          style={{ borderRadius: "2px" }}
        >
          <TabContext value={""}>
            <TabList aria-label="lab API tabs example">
              <Tab
                label="Post Case Details"
                className={clsx(
                  count === 1 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(1)}
              />
              <Tab
                label="Post Case Documents"
                className={clsx(
                  count === 2 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(2)}
              />
              <Tab
                label="Inspection Details"
                className={clsx(
                  count === 3 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(3)}
              />
              <Tab
                label="Images"
                className={clsx(
                  count === 4 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(4)}
              />
            </TabList>
          </TabContext>
        </div>
        <div>
          {count === 1 && <Backofficetray />}
          {count === 2 && <Postcasedocument />}
          {count === 3 && <PostbackofficeTray />}
          {count === 4 && <Postimages />}

          <div className="row">
            <div className=" col-md-8 text-end pe-3 d-flex justify-content-end">
              {(user?.BankId != null && user?.BankId != 0) && <Button
                className="bg-danger text-white me-2"
                sx={{ width: 140, p: 2 }}
                onClick={() => navigate(routerPath?.dashboard)}
              >Close <Close className="h-15" />
              </Button>}

              {count === 1 ? (
                ""
              ) : (
                <Button
                  className="bg-secondary text-white mx-5"
                  sx={{ width: 170, padding: 2 }}
                  onClick={() => setCount(count - 1)}
                >
                  <ArrowBackIosIcon className="h-15" /> Back
                </Button>
              )}
              <Button
                className={clsx(
                  count === 4 ? "d-none" : "bg-success text-white"
                )}
                sx={{ width: 170, p: 2 }}
                onClick={() => setCount(count + 1)}
              >
                Next <ArrowForwardIosIcon className="h-15" />
              </Button>

              {!(user?.BankId != null && user?.BankId != 0) && count === 3 &&
                <LoadingButton disabled={!_save} className={clsx(`${_save && !_loading ? 'bg-success' : 'bg-light-1 text-muted'} text-white mx-4 px-4`)} loadingPosition="end" endIcon={<></>}
                  loading={_loading} fullWidth sx={{ width: 130, p: 2 }}
                  onClick={() => onSaveClick('Save')}>Save</LoadingButton>
              }


              {!(user?.BankId != null && user?.BankId != 0) && count === 4 && (
                <LoadingButton disabled={!_submit} className={clsx(`${_submit && !_loading ? 'bg-success' : 'bg-light-1 text-muted'} text-white`)} loadingPosition="end" endIcon={<></>}
                  loading={_loading} fullWidth sx={{ width: 170, p: 2 }}
                  onClick={() => onSaveClick('Update')}>Submit</LoadingButton>
              )}
              {!(user?.BankId != null && user?.BankId != 0) && <Button className="bg-danger text-white ms-2" sx={{ width: 170, p: 2 }}
                onClick={refreshPage}>Clear <DoDisturb className="h-15" /></Button>}
            </div>
          </div>
        </div>
        <hr />
        {/* coming soon */}
        {/* <Outlet/> */}
        {!(user?.BankId != null && user?.BankId != 0) && <>
          <div className="bg-primary py-2 mt-5 gx-0 text-white fw-bold ps-3 row d-flex align-items-center justify-content-around">
            <div className="col-md-6 fs-4">Back Office Tray</div>
            <div className="col-md-6 px-2 d-flex justify-content-around">
              <input
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search"
                className="my-2 px-2 rounded-2 w-100 border border-white"
              />
              {/* <Button>
                <img
                  src={PDF}
                  alt=""
                  className="text-end mx-1"
                  role="button"
                  style={{ width: "3rem" }} onClick={exportPDF}
                />
              </Button> */}
              <Button>
                <img
                  src={Excel}
                  onClick={exportEXCEL}
                  className="text-end mx-1"
                  alt=""
                  role="button"
                  style={{ width: "3rem" }}
                />
              </Button>
              <AssignmentReturnedIcon onClick={exportMISEXCEL} className='' style={{ fontSize: "3.5rem" }} />
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <SortableTableHead
                order={_order}
                orderBy={_orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  { id: '', label: 'View', align: 'center' },
                  { id: 'Status', label: 'Status', align: 'center' },
                  // { id: 'caseNo', label: 'Case No', align: 'left' },
                  { id: 'caseDate', label: 'Case Date', align: 'center' },
                  // { id: 'vendorName', label: 'Vendor Name', align: 'center' },
                  { id: 'BankName', label: 'Bank Agency', align: 'center' },
                  { id: 'productName', label: 'Product Name', align: 'center' },
                  { id: '', label: 'Segment', align: 'center' },
                  { id: 'customerName', label: 'Customer Name', align: 'center' },
                  { id: '', label: 'Executive Name', align: 'center' }
                ]}
              />
              <TableBody>
                {_tableData?.length > 0 ?
                  _tableData?.filter((content: any) =>
                    // `${content?.caseNo}`?.toLowerCase().includes(`${query}`?.toLowerCase()) 
                    // || `${content?.vendorName}`?.toLowerCase().includes(`${query}`?.toLowerCase()) 
                    `${content?.caseDate}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.BankName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.productName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.segmentName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.customerName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.fieldExecutiveName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.ApllicantFiledStaffName + ' ' +
                      content?.ApllicantOfficeFiledStaffName + ' ' +
                      content?.coApplicantDetails?.coAppFieldStaffName + ' ' +
                      content?.coApplicantDetails?.coOfficeFieldStaffName + ' ' +
                      content?.gaurantorDetails?.gaurantorFieldStaffName + ' ' +
                      content?.gaurantorDetails?.gaurantorOfficeFieldStaffName}`?.toLowerCase().includes(`${query}`.toLowerCase()))
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item: any, index: any) => (
                      <TableRow
                        key={index + 1}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            className="bg-success"
                            style={{ borderRadius: "10px" }}
                            onClick={() => viewData(item)}
                            type="submit"
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          {/* <Button
                      variant="contained"
                      className="bg-success"
                      style={{ borderRadius: "10px" }}
                      type="submit"
                    >
                      Verification Started
                    </Button> */}
                          {item?.Status === "Completed" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Completed
                            </Button>
                          )}
                          {item?.Status === "Completed-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-info text-white px-4  text-nowrap"
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Completed TAT Breached
                            </Button>
                          )}
                          {item?.Status === "In Progress" && (
                            <Button
                              variant="contained"
                              className="bg-primary px-4  "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              InProgress
                            </Button>
                          )}

                          {item?.Status === "In Progress-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              In Progress-TAT Breached
                            </Button>
                          )}
                          {item?.Status === "Cancelled-Reassign" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Cancelled-Reassign
                            </Button>
                          )}
                          {item?.Status === "Verification Started" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Verification Started
                            </Button>
                          )}
                          {item?.Status === "QC Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              QC Tray Pending
                            </Button>
                          )}
                          {item?.Status === "B.O Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              B.O Tray Pending
                            </Button>
                          )}
                        </TableCell>

                        {/* <TableCell component="th" scope="row">{item?.caseNo}</TableCell> */}
                        <TableCell className="text-nowrap" align="center">{item?.caseDate?.split("T")[0]}</TableCell>
                        {/* <TableCell align="center">{item?.vendorName}</TableCell> */}
                        <TableCell align="center">{item?.BankName}</TableCell>
                        <TableCell align="center">{item?.productName}</TableCell>
                        <TableCell align="center" sx={{ minWidth: 250 }}>
                          {`${item?.ApllicantFiledStaffName?.length > 0 ? 'Resident Details, ' : ''} 
                        ${item?.ApllicantOfficeFiledStaffName?.length > 0 ? 'Resident Office Details, ' : ''}
                        ${item?.coApplicantDetails?.coAppFieldStaffName?.length > 0 ? 'Co-Applicant Details, ' : ''}
                        ${item?.coApplicantDetails?.coOfficeFieldStaffName?.length > 0 ? 'Co-Applicant Office Details, ' : ''}
                        ${item?.gaurantorDetails?.gaurantorFieldStaffName?.length > 0 ? 'Guarantor Details, ' : ''}
                        ${item?.gaurantorDetails?.gaurantorOfficeFieldStaffName?.length > 0 ? 'Guarantor Office Details' : ''}`}
                        </TableCell>
                        <TableCell align="center">{item?.customerName}</TableCell>
                        <TableCell align="center">
                          {`${item?.ApllicantFiledStaffName?.length > 0 ? item?.ApllicantFiledStaffName + ', ' : ''} 
                        ${item?.ApllicantOfficeFiledStaffName?.length > 0 ? item?.ApllicantOfficeFiledStaffName + ', ' : ''}
                        ${item?.coApplicantDetails?.coAppFieldStaffName?.length > 0 ? item?.coApplicantDetails?.coAppFieldStaffName + ', ' : ''}
                        ${item?.coApplicantDetails?.coOfficeFieldStaffName?.length > 0 ? item?.coApplicantDetails?.coOfficeFieldStaffName + ', ' : ''}
                        ${item?.gaurantorDetails?.gaurantorFieldStaffName?.length > 0 ? item?.gaurantorDetails?.gaurantorFieldStaffName + ', ' : ''}
                        ${item?.gaurantorDetails?.gaurantorOfficeFieldStaffName?.length > 0 ? item?.gaurantorDetails?.gaurantorOfficeFieldStaffName + ', ' : ''}`}
                        </TableCell>

                        {/* <TableCell align="center"><div className='d-flex'>
                                            <img src={} style={{height:"",width:""}}}</div></TableCell> */}
                      </TableRow>
                    )) : !_tableData && (
                      <TableRow key={0}>
                        <TableCell align={"center"}>
                          <h3 className="text-muted">Data Not Found</h3>
                        </TableCell>
                      </TableRow>
                    )}
                <SkeletonProviderTables columns={8} visible={_tableLoading} />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={_totalRecordCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>}
      </div>
    </>
  );
}

export default Index;