import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import inputdataicon from '../../assets/images/InputDataWhite.png';
//import { getPreCasetable, downloadExcel, updatePreCase } from '../../Models/model';
import { getPreCaseGridData, getPreCaseGridSearch, getPreCasetable, updatePreCase } from '../../Models/model';

import { getMISforXcellDownload } from '../../Models/configapi';
import swal from 'sweetalert';
import PDF from '../../assets/images/ExportPDF.png';
import Excel from '../../assets/images/ExportExcel.png';
import Customerdetails from '../../pages/inputData/preCase/Customerdetails';
import Otherdetails from '../../pages/inputData/preCase/Otherdetails';
import CaseDetails from '../../pages/inputData/preCase/index';
import Guarantor from '../../pages/inputData/preCase/guarantor';
import CoApplicant from '../../pages/inputData/preCase/coapplicant';
import clsx from 'clsx';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { insertPrecase, editPreCase } from '../../Models/model';
import { useStateValue } from '../../providers/stateProvider';
import { SkeletonProviderTables } from '../../providers/SkeletonProvider';
import PreCaseValidation from './preCaseValidation';
import BulkUpload from '../../pages/inputData/preCase/bulkUpload';
import { pdf } from './pdf';
import moment from 'moment';
import { DoDisturb } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { downloadExcel } from "react-export-table-to-excel";
import { sortTableData } from '../../services/helperService';
import { SortableTableHead } from '../helpers/tableSortableHeader';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 501,
    paddingTop: 24,
    paddingBottom: 24,
  },
}));

type Order = 'asc' | 'desc';

function Index() {
  const { handleValidation } = PreCaseValidation();
  const [count, setCount] = useState(1);
  const [{ user, preCaseData }, dispatch]: any = useStateValue();
  const [tableData, setTableData] = useState<any>([]);
  const [_checked1, _setChecked1] = useState<any>(false);
  const [_checked2, _setChecked2] = useState<any>(false);
  const [_tableLoading, _setTableLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const [_loading, _setLoading] = useState(false);
  const [_misreportExport, _setmisreportExport] = useState<any>([]);
  const [_order, _setOrder] = useState<Order>('asc');
  const [_orderBy, _setOrderBy] = useState<string>('');
  const [_totalRecordCount, _setTotalRecordCount] = useState(0)

  var date = new Date();
  let dateVal = moment(date).format("MM-YYYY-DD");
  let toDate = moment(date).format("MM-YYYY-DD");
  const [_fromDate, _setFromDate] = useState<any>("");
  const [_toDate, _setToDate] = useState<any>("");



  const getTableData = () => {
    debugger;
    getPreCaseGridData(user.id, page, rowsPerPage, _fromDate, _toDate, user?.CorporateDetailsId)
      .then(response => {
        if (response.status === 200) {

          console.log('preCaseTable', response?.data);
          setTableData(response?.data?.result3);
          _setTotalRecordCount(response?.data?.result3?.length)
        }
        else {
          swal(response.data.Data.Message, { icon: 'warning' });
        }
      }).catch(error => {
        swal("Something Went Wrong", { icon: 'error' });
      }).finally(() => _setTableLoading(false));
  }
  const getSearchData = (search: string) => {
    // getPreCaseGridSearch(user.id, search)
    //   .then(response => {
    //     if (response.status === 200) {

    //       console.log('preCaseTable', response?.data);
    //       // setTableData(response?.data?.result3);
    //       // _setTotalRecordCount(response?.data?.result3?.length)
    //     }
    //     else {
    //       swal(response.data.Data.Message, { icon: 'warning' });
    //     }
    //   }).catch(error => {
    //     swal("Something Went Wrong", { icon: 'error' });
    //   }).finally(() => _setTableLoading(false));
  }


  // const downloadExcelFile = () => {
  //   downloadExcel().then(response => {
  //     console.log(response.data);
  //     let a = document.createElement('a');
  //     a.href = response.data.data;
  //     a.download = 'PreCaseData.xlsx';
  //     a.click();
  //   }).catch(error => {
  //     console.log(error.response.data);
  //   })
  // }
  const onCheckBoxChange = (name: any, value: any) => {
    if (name === "coApplicant") {
      dispatch({ type: "preCaseData", data: { ...preCaseData, coApplicantEnable: value } });
      _setChecked1(value);
    }
    if (name === "Guarantor") {
      dispatch({ type: "preCaseData", data: { ...preCaseData, coGaurantorEnable: value } });
      _setChecked2(value);
    }

  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const onSaveClick = () => {
    _setLoading(true);
    const data = {
      Id: preCaseData?.precaseid || 0,
      userLoginId: user?.id,

      vendorId: preCaseData?.vendorId || 0,
      bankid: preCaseData?.bankid || 0,
      branchid: preCaseData?.branchid || 0,
      productId: preCaseData?.productId || 0,
      caseNo: preCaseData?.caseNo || "",
      caseDate: preCaseData?.caseDate || moment()?.format("YYYY-MM-DD"),
      bankReferenceNo: preCaseData?.bankReferenceNo || "",
      verificationAtId: preCaseData?.verificationAtId || 1,
      TypeOfSampling: preCaseData?.TypeOfSampling || '',

      customerName: preCaseData?.customerName || '',
      residentialCustomerAddress1: preCaseData?.residentialCustomerAddress1 || '',
      residentialCustomerAddress2: preCaseData?.residentialCustomerAddress2 || '',
      residentialCity: preCaseData?.residentialCity || '',
      residentialPincode: parseInt(preCaseData?.residentialPincode) || 0,
      residentialState: preCaseData?.residentialState || '',
      residentialMobileNo: preCaseData?.residentialMobileNo || '',
      residentialCountryCode: Number(preCaseData?.residentialCountryCode) || 0,
      residentialEmail: preCaseData?.residentialEmail || '',
      fatherName: preCaseData?.fatherName || '',
      motherName: '',
      panNo: preCaseData?.panNo || '',
      aadhaarNo: preCaseData?.aadhaarNo || '',
      dob: preCaseData?.dob || '',
      customersamplingDetailsId: preCaseData?.customersamplingDetailsId || '',
      ResidencyTrigger: preCaseData?.ResidencyTrigger || '',
      appHubId: preCaseData?.appHubId || 0,
      AppQueryID: preCaseData?.AppQueryID || 0,
      AppOffice_QueryID: preCaseData?.AppOffice_QueryID || 0,
      residentialFieldExecutiveName: preCaseData?.residentialFieldExecutiveName || '',
      employeeTypeId: preCaseData?.employeeTypeId,

      officeName: preCaseData?.officeName || '',
      officeAddress1: preCaseData?.officeAddress1 || '',
      officeAddress2: preCaseData?.officeAddress2 || '',
      officeCity: preCaseData?.officeCity || '',
      officeState: preCaseData?.officeState || '',
      officePincode: Number(preCaseData?.officePincode) || 0,
      officeLandMark: preCaseData?.officeLandMark || '',
      officeCountryCode: 0,
      officeLandLine: preCaseData?.officeLandLine || '',
      officeMobileNumber: preCaseData?.officeMobileNumber || '',
      designation: preCaseData?.designation || '',
      OfficeTrigger: preCaseData?.OfficeTrigger || '',
      officeHubId: preCaseData?.officeHubId || 0,
      officeFieldExecutiveName: preCaseData?.officeFieldExecutiveName || '',
      officeExtensionNumber: parseInt(preCaseData?.officeMobileNumber) || 0,
      othersamplingDetails: preCaseData?.customersamplingDetailsId,

      coApplicantName: preCaseData?.coApplicantName || '',
      coAddress: preCaseData?.coAddress || '',
      coCity: preCaseData?.coCity || '',
      coPincode: Number(preCaseData?.coPincode) || 0,
      coState: preCaseData?.coState || '',
      coMobileNo: Number(preCaseData?.coMobileNo) || 0,
      coEmailAddress: preCaseData?.coEmailAddress || '',
      CoAppTrigger: preCaseData?.CoAppTrigger || '',
      coAppHubId: Number(preCaseData?.coAppHubId) || 0,
      coAppFieldStaffName: preCaseData?.coAppFieldStaffName || '',

      coAddressType: preCaseData?.coAddressType || '',
      coAddress_2: preCaseData?.coAddress_2 || '',

      coofficeName: preCaseData?.coofficeName || '',
      coofficeAddress1: preCaseData?.coofficeAddress1 || '',
      coofficeAddress2: preCaseData?.coofficeAddress2 || '',
      coofficeCity: preCaseData?.coofficeCity || '',
      coofficePincode: preCaseData?.coofficePincode || '',
      coofficeState: preCaseData?.coofficeState || '',
      coofficeLandMark: preCaseData?.coofficeLandMark || '',
      coofficeLandLine: preCaseData?.coofficeLandLine || '',
      coofficeMobileNumber: preCaseData?.coofficeMobileNumber || '',
      codesignation: Number(preCaseData?.codesignation) || '',
      coemployeeTypeId: Number(preCaseData?.coemployeeTypeId) || 0,
      coOfficeFieldStaffName: preCaseData?.coOfficeFieldStaffName || '',

      CoAppOfficeTrigger: preCaseData?.CoAppOfficeTrigger || '',
      coAppOfficeHubId: Number(preCaseData?.coAppOfficeHubId) || 0,
      CoAppQueryID: Number(preCaseData?.CoAppQueryID) || 0,
      CoAppOfficeQueryID: Number(preCaseData?.CoAppOfficeQueryID) || 0,

      gaurantorName: preCaseData?.gaurantorName || '',
      gaurantorAddress_1: preCaseData?.gaurantorAddress_1 || '',
      gaurantorCity: preCaseData?.gaurantorCity || '',
      gaurantorPincode: Number(preCaseData?.gaurantorPincode) || 0,
      gaurantorState: preCaseData?.gaurantorState || '',
      gaurantorMobileNo: Number(preCaseData?.gaurantorMobileNo) || 0,
      gaurantorEmail: preCaseData?.gaurantorEmail || '',
      GuarantorTrigger: preCaseData?.GuarantorTrigger || '',
      gaurantorHubId: Number(preCaseData?.gaurantorHubId) || 0,
      guarantorFieldStaffName: preCaseData?.guarantorFieldStaffName || '',
      GuaQueryID: Number(preCaseData?.GuaQueryID) || 0,
      GuaOfficeQueryID: Number(preCaseData?.GuaOfficeQueryID) || 0,

      guarantorofficeName: preCaseData?.guarantorofficeName || '',
      guarantorofficeAddress1: preCaseData?.guarantorofficeAddress1 || '',
      guarantorofficeAddress2: preCaseData?.guarantorofficeAddress2 || '',
      guarantorofficeCity: preCaseData?.guarantorofficeCity || '',
      guarantorofficePincode: preCaseData?.guarantorofficePincode || '',
      guarantorofficeState: preCaseData?.guarantorofficeState || '',
      guarantorofficeLandMark: preCaseData?.guarantorofficeLandMark || '',
      guarantorofficeLandLine: preCaseData?.guarantorofficeLandLine || '',
      guarantorofficeMobileNumber: preCaseData?.guarantorofficeMobileNumber || '',
      guarantordesignation: Number(preCaseData?.guarantordesignation) || '',
      guarantoremployeeTypeId: Number(preCaseData?.guarantoremployeeTypeId) || 0,
      GuarantorOfficeTrigger: preCaseData?.GuarantorOfficeTrigger || '',
      gaurantorOfficeHubId: Number(preCaseData?.gaurantorOfficeHubId) || 0,
      gaurantorOfficeFieldStaffName: preCaseData?.gaurantorOfficeFieldStaffName || '',


      loanAmount: Number(preCaseData?.loanAmount) || 0,
      bankEmployee: preCaseData?.bankEmployee || '',
      dsaOrDesName: preCaseData?.dsaOrDesName || '',
      reqLoggedBy: preCaseData?.reqLoggedBy || '',
      boStaffName: preCaseData?.boStaffName || '',


      fieldExecutiveName: preCaseData?.fieldExecutiveName || '-',

      coApplicantEnable: _checked1,
      coGaurantorEnable: _checked2,

      documentProperties: [
        ...preCaseData?.documentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
        ...preCaseData?.officeDocumentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
        ...preCaseData?.coDocumentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
        ...preCaseData?.coOfficeDocumentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
        ...preCaseData?.guarantorDocumentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
        ...preCaseData?.guarantorOfficeDocumentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
      ],
      EmployeeDocumnetTypeId: preCaseData?.EmployeeDocumnetTypeId ? 1 : 0,
      coAppEmployeeDocumnetTypeId: preCaseData?.coAppEmployeeDocumnetTypeId ? 1 : 0,
      guarantorEmployeeDocumnetTypeId: preCaseData?.guarantorEmployeeDocumnetTypeId ? 1 : 0,


      applicantDocumentCheck: preCaseData?.applicantDocumentCheck ? 1 : 0,
      applicantProfileCheck: preCaseData?.applicantProfileCheck ? 1 : 0,
      officeDocumentCheck: preCaseData?.officeDocumentCheck ? 1 : 0,
      officeProfileCheck: preCaseData?.officeProfileCheck ? 1 : 0,

      appVerificationTypeID: preCaseData?.verificationTypeField ? 4 : 0,
      appOfficeVerificationTypeID: preCaseData?.officeVerificationTypeField ? 5 : 0,
      coAppVerificationTypeID: preCaseData?.coVerificationField ? 6 : 0,
      coAppOfficeVerificationTypeID: preCaseData?.coOfficeVerificationField ? 7 : 0,
      guarantorAppVerificationTypeID: preCaseData?.guarantorVerificationField ? 9 : 0,
      guarantorAppOfficeVerificationTypeID: preCaseData?.guarantorOfficeVerificationField ? 8 : 0,

      appFieldStaff: preCaseData?.appFieldStaff || preCaseData?.ResidenceFieldStaffID || 0,
      officeFieldStaff: preCaseData?.officeFieldStaff || preCaseData?.OfficeFieldStaffID || 0,
      appAuthority: preCaseData?.verificationTypeAuthority ? 1 : 0,
      officeAuthority: preCaseData?.officeVerificationTypeAuthority ? 1 : 0,

      coAppFieldStaff: preCaseData?.coAppFieldStaff || preCaseData?.coAppFieldStaffId || 0,
      coAppOfficeFieldStaff: preCaseData?.coAppOfficeFieldStaff || preCaseData?.coOfficeFieldStaffId || 0,
      coAppAuthority: preCaseData?.coVerificationAuthority ? 1 : 0,
      coAppOfficeAuthority: preCaseData?.coOfficeVerificationAuthority ? 1 : 0,

      guarantorFieldStaff: preCaseData?.guarantorFieldStaff || preCaseData?.gaurantorFieldStaffId || 0,
      guarantorOfficeFieldStaff: preCaseData?.guarantorOfficeFieldStaff || preCaseData?.gaurantorOfficeFieldStaffId || 0,
      guarantorAuthority: preCaseData?.guarantorVerificationAuthority ? 1 : 0,
      guarantorOfficeAuthority: preCaseData?.guarantorOfficeVerificationAuthority ? 1 : 0,

      coAppProfileCheck: preCaseData?.coAppProfileCheck ? 1 : 0,
      coAppOfficeProfileCheck: preCaseData?.coAppOfficeProfileCheck ? 1 : 0,
      coAppDocumentCheck: preCaseData?.coAppDocumentCheck ? 1 : 0,
      coAppOfficeDocumentCheck: preCaseData?.coAppOfficeDocumentCheck ? 1 : 0,

      guarantorProfileCheck: preCaseData?.guarantorProfileCheck ? 1 : 0,
      guarantorOfficeProfileCheck: preCaseData?.guarantorOfficeProfileCheck ? 1 : 0,
      guarantorDocumentCheck: preCaseData?.guarantorDocumentCheck ? 1 : 0,
      guarantorOfficeDocumentCheck: preCaseData?.guarantorOfficeDocumentCheck ? 1 : 0,
      lCorporateId: user?.CorporateDetailsId
    }

    if (!handleValidation(1)) {
      swal("Check required data is filled or not", { icon: 'warning' });
      setCount(1);
      _setLoading(false)
      return;
    }

    if (!handleValidation(2)) {
      swal("Check required data is filled or not", { icon: 'warning' });
      setCount(2);
      _setLoading(false)
      return;
    }

    if (!handleValidation(3)) {
      swal("Check required data is filled or not", { icon: 'warning' });
      setCount(3);
      _setLoading(false)
      return;
    }

    // if(!handleValidation(4)){
    //   swal("Check required data is filled or not", { icon: 'warning' });
    //   setCount(4);
    //   return;
    // }

    if (_checked1 && !handleValidation(4)) {
      swal("Check Co-Applicant data are filled or not", { icon: 'warning' });
      setCount(4);
      _setLoading(false)
      return;
    }

    if (_checked2 && !handleValidation(5)) {
      swal("Check Guarantor data are filled or not", { icon: 'warning' });
      setCount(5);
      _setLoading(false)
      return;
    }
    debugger;
    console.log(data);
    if (data?.Id) {
      updatePreCase(data)
        .then(response => {
          console.log(response.data)
          if (response.data.status === "Success") {
            swal("Data Updated SuccessFully", { icon: 'success' });
            getTableData();
            refreshPage();
            // dispatch({ type: "preCaseData", data: {} });
            dispatch({ type: "preCaseValidation", data: {} });
            setCount(1);
          }
        }).catch(error => {
          swal(error.response.data, { icon: 'warning' });
          console.log(error.response.status);
        }).finally(() => _setLoading(false))

    } else {
      insertPrecase(data)
        .then(response => {
          // debugger;
          if (response.data.status === "Success") {
            swal("Data Inserted SuccessFully", { icon: 'success' });
            getTableData();
            refreshPage();
            // dispatch({ type: "preCaseData", data: {} });
            dispatch({ type: "preCaseValidation", data: {} });
            setCount(1);
          }
        }).catch(error => {
          // debugger;
          swal(error.response.data, { icon: 'warning' });
          console.log(error.response.status);
        }).finally(() => _setLoading(false))
    }
  }

  const onChangeTab = (item: any) => {
    // let formData = JSON.parse(localStorage.getItem(`form${item}`) || '{}');
    // console.log(formData);
    debugger;
    if (item === 4 && _checked1) {
      setCount(item)
      return;
    }
    if (item === 5 && _checked2) {
      setCount(item)
      return;
    }
    setCount(item);
  };

  const checkValidation = () => {
    if (handleValidation(count)) {
      console.log(count)
      if (count === 2 && _checked1) {
        setCount(4)
        return;
      }
      if (count === 2 && !_checked1 && _checked2) {
        setCount(5)
        return;
      }
      if (count === 4 && _checked2) {
        setCount(count + 1)
        return;
      }
      if (count === 4 && !_checked2 && _checked1) {
        setCount(3)
        return;
      }
      if (count === 5) {
        setCount(count - 2);
        return;
      }

      setCount(count + 1);
    }
  }

  const onGoBack = () => {
    if (count === 3 && _checked2 && _checked1) {
      setCount(5)
      return;
    }
    if ((count === 3 || count === 5) && _checked1) {
      setCount(4)
      return;
    }
    if ((count === 3 || count === 5) && !_checked1 && _checked2) {
      setCount(2)
      return;
    }
    if (count === 4) {
      setCount(2);
      return;
    }
    setCount(count - 1)
  }

  const updateData = (item: any) => {

    window.scrollTo(0, 0);
    debugger;
    if (item.preCaseId != 0) {
      editPreCase(item.Id).then((response: any) => {
        debugger;
        let item1 = response.data.Data[0];
        _setChecked1(item1?.coApplicantEnable ? true : false);
        _setChecked2(item1?.coGaurantorEnable ? true : false);
        setCount(1);
        dispatch({
          type: "preCaseData",
          data: {
            ...item1,
            ...item1?.coApplicantDetails,
            ...item1?.gaurantorDetails,
            boStaffName: item1?.boStaffName,
            caseDate: item1?.caseDate?.slice(0, 10),
            // customer details
            // customerName: item?.dob?.slice(0, 10) || '',
            dob: item1?.dob?.slice(0, 10) || '',
            // residentialCustomerAddress1: '',
            // residentialCustomerAddress2: '',
            // residentialCity: '',
            // residentialPincode: '',
            residentialState: item1?.residentialState || '',
            // residentialMobileNo: '',
            // residentialCountryCode: '',
            // residentialEmail: '',
            // fatherName: '',
            // motherName: '',
            // panNo: '',
            // aadhaarNo: '',
            // customersamplingDetailsId: '',
            // customeraddressTypeId: 0,
            // appFieldStaff: 0,
            ResidencyTrigger: item1?.residentialTrigger || '',
            OfficeTrigger: item1?.officeTrigger || '',
            appHubId: item1?.AppHubID || 0,
            AppQueryID: item1?.residentialQueryID || 0,
            AppOffice_QueryID: item1?.officeQueryID || 0,
            // residentialFieldExecutiveName: '',
            employeeTypeId: item1?.employeeTypeId || 0,

            verificationTypeField: item1?.appVerificationTypeID ? true : false,
            verificationTypeAuthority: item1?.ApplicantAuthority ? true : false,
            officeVerificationTypeField: item1?.appOfficeVerificationTypeID ? true : false,
            officeVerificationTypeAuthority: item1?.OfficeAuthority ? true : false,
            coVerificationField: item1?.coApplicantDetails?.coResiVerificationTypeID ? true : false,
            coVerificationAuthority: item1?.CoAppAuthority ? true : false,
            coOfficeVerificationField: item1?.coApplicantDetails?.coOfficeVerificationTypeID ? true : false,
            coOfficeVerificationAuthority: item1?.CoAppOfficeAuthority ? true : false,
            guarantorVerificationField: item1?.gaurantorDetails?.guarantorResiVerificationTypeID ? true : false,
            guarantorVerificationAuthority: item1?.GuarantorAuthority ? true : false,
            guarantorOfficeVerificationField: item1?.gaurantorDetails?.guarantorOfficeVerificationTypeID ? true : false,
            guarantorOfficeVerificationAuthority: item1?.GuarantorOfficeAuthority ? true : false,

            // officeName: '',
            // officeAddress1: '',
            // officeAddress2: '',
            // officeCity: '',
            // officeState: '',
            // officePincode: '',
            // officeLandMark: '',
            // officeCountryCode: '',
            // officeLandLine: '',
            // officeMobileNumber: '',
            designation: item?.designation || '',
            // officeEmployeeTypeId: 0,
            // EmployeeDocumnetTypeId: 0,
            // officeFieldStaff: 0,
            // officeAuthority: 0,
            // OfficeTrigger: '',
            officeHubId: item1?.OfficeHubID || 0,
            // officeFieldExecutiveName: '',

            // documentProperties: [],
            // officeDocumentProperties: [],

            // verificationType: '',
            // officeVerificationType: '',
            // appVerificationTypeID: true, 
            applicantDocumentCheck: item1?.ApplicantDocumentCheck ? true : false,
            applicantProfileCheck: item1?.ApplicantProfileCheck ? true : false,
            // appOfficeVerificationTypeID: true, 
            officeDocumentCheck: item1?.ApplicantOfficeDocumentCheck ? true : false,
            officeProfileCheck: item1?.ApplicantOfficeProfileCheck ? true : false,


            // co-applicant
            coAddressType: item1?.coApplicantDetails?.coAddressType || '',
            coApplicantName: item1?.coApplicantDetails?.coApplicantName || '',
            coAddress: item1?.coApplicantDetails?.coAddress || '',
            coAddress_2: item1?.coApplicantDetails?.coAddress_2 || '',
            coCity: item1?.coApplicantDetails?.coCity || '',
            coPincode: item1?.coApplicantDetails?.coPincode || '',
            coState: item1?.coApplicantDetails?.coState || '',
            coMobileNo: item1?.coApplicantDetails?.coMobileNo || '',
            coEmailAddress: item1?.coApplicantDetails?.coEmailAddress || '',
            coAppFieldStaff: item1?.coApplicantDetails?.coAppFieldStaffId || 0,

            coofficeName: item1?.coApplicantDetails?.coOfficeName || '',
            coofficeAddress1: item1?.coApplicantDetails?.coOfficeAddress1 || '',
            coofficeAddress2: item1?.coApplicantDetails?.coOfficeAddress2 || '',
            coofficeCity: item1?.coApplicantDetails?.coOfficeCity || '',
            coofficeState: item1?.coApplicantDetails?.coOfficeState || '',
            coofficePincode: item1?.coApplicantDetails?.coOfficePincode || '',
            coofficeLandMark: item1?.coApplicantDetails?.coOfficeLandMark || '',
            coofficeLandLine: item1?.coApplicantDetails?.coOfficeLandline || '',
            coofficeMobileNumber: item1?.coApplicantDetails?.coOfficeMobileNumber || '',
            codesignation: item1?.coApplicantDetails?.coDesignation || '',
            coemployeeTypeId: item1?.coApplicantDetails?.coEmployeetypeID || 0,
            coAppEmployeeDocumnetTypeId: 0,

            CoAppTrigger: item1?.coApplicantDetails?.coAppTrigger || '',
            CoAppOfficeTrigger: item1?.coApplicantDetails?.coOfficeTrigger || '',
            coAppHubId: item1?.coAppHubID || 0,
            coAppOfficeHubId: item1?.coAppOfficeHubID || 0,
            CoAppQueryID: item1?.coApplicantDetails?.coResiQueryID || 0,
            CoAppOfficeQueryID: item1?.coApplicantDetails?.coOfficeQueryID || 0,

            coAppOfficeFieldStaff: 0,


            appVerificationTypeID: item1?.appVerificationTypeID ? true : false,
            appOfficeVerificationTypeID: item1?.appOfficeVerificationTypeID ? true : false,

            guarantorAppVerificationTypeID: item1?.gaurantorDetails?.guarantorResiVerificationTypeID ? true : false,
            guarantorAppOfficeVerificationTypeID: item1?.gaurantorDetails?.guarantorOfficeVerificationTypeID ? true : false,

            coAppVerificationTypeID: item1?.coApplicantDetails?.coResiVerificationTypeID ? true : false,
            coAppOfficeVerificationTypeID: item1?.coApplicantDetails?.coOfficeVerificationTypeID ? true : false,


            coAppDocumentCheck: item1?.CoAppDocumentCheck ? true : false,
            coAppProfileCheck: item1?.CoAppProfileCheck ? true : false,
            coAppOfficeDocumentCheck: item1?.CoAppOfficeDocumentCheck ? true : false,
            coAppOfficeProfileCheck: item1?.CoAppOfficeProfileCheck ? true : false,


            // gaurantorAddressType: item?.'',
            // gaurantorName: '',
            // gaurantorAddress_1: '',
            // gaurantorAddress_2: '',
            // gaurantorCity: '',
            // gaurantorPincode: '',
            // gaurantorState: '',
            // gaurantorMobileNo: '',
            // gaurantorEmail: '',
            GuarantorTrigger: item1?.gaurantorDetails?.gaurantorTrigger || '',
            gaurantorHubId: item1?.guarantorHubID || 0,

            guarantorofficeName: item1?.gaurantorDetails?.gaurantorOfficeName || '',
            guarantorofficeAddress1: item1?.gaurantorDetails?.gaurantorOfficeAddress_1 || '',
            guarantorofficeAddress2: item1?.gaurantorDetails?.gaurantorOfficeAddress_2 || '',
            guarantorofficeCity: item1?.gaurantorDetails?.gaurantorOfficeCity || '',
            guarantorofficeState: item1?.gaurantorDetails?.gaurantorOfficeState || '',
            guarantorofficePincode: item1?.gaurantorDetails?.gaurantorOfficePincode || '',
            guarantorofficeLandMark: item1?.gaurantorDetails?.gaurantorOfficeLandmark || '',
            guarantorofficeLandLine: item1?.gaurantorDetails?.gaurantorOfficeLandLine || '',
            guarantorofficeMobileNumber: item1?.gaurantorDetails?.gaurantorOfficeMobileNumber || '',
            guarantordesignation: item1?.gaurantorDetails?.gaurantorDesignation || '',
            guarantoremployeeTypeId: item1?.gaurantorDetails?.guarantorEmployeeTypeID || 0,

            GuarantorOfficeTrigger: item1?.gaurantorDetails?.gaurantorOfficeTrigger || '',
            gaurantorOfficeHubId: item1?.guarantorOfficeHubID || 0,

            GuaQueryID: item1?.gaurantorDetails?.gaurantorResiQueryID || 0,
            GuaOfficeQueryID: item1?.gaurantorDetails?.gaurantorOfficeQueryID || 0,
            guarantorDocumentCheck: item1?.GuarantorDocumentCheck ? true : false,
            guarantorProfileCheck: item1?.GuarantorProfileCheck ? true : false,
            guarantorOfficeDocumentCheck: item1?.GuarantorOfficeDocumentCheck ? true : false,
            guarantorOfficeProfileCheck: item1?.GuarantorOfficeProfileCheck ? true : false,

            documentProperties: item1?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 1)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],
            officeDocumentProperties: item1?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 2)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],
            coDocumentProperties: item1?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 3)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],
            coOfficeDocumentProperties: item1?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 4)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],
            guarantorDocumentProperties: item1?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 5)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],
            guarantorOfficeDocumentProperties: item1?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 6)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],

            residentialFieldExecutiveName: item1?.ResidenceFieldExecutiveName || '',
            officeFieldExecutiveName: item1?.OfficeFieldExecutiveName || '',
            coAppFieldStaffName: item1?.coApplicantDetails?.coAppFieldStaffName || '',
            coOfficeFieldStaffName: item1?.coApplicantDetails?.coOfficeFieldStaffName || '',
            guarantorFieldStaffName: item1?.gaurantorDetails?.gaurantorFieldStaffName || '',
            gaurantorOfficeFieldStaffName: item1?.gaurantorDetails?.gaurantorOfficeFieldStaffName || '',
          },
        });

      })
    }
    else {
      dispatch({
        type: "preCaseData",
        data: {
          ...item,
          ...item?.coApplicantDetails,
          ...item?.gaurantorDetails,
          boStaffName: item?.boStaffName,
          caseDate: item?.caseDate?.slice(0, 10),
          // customer details
          // customerName: item?.dob?.slice(0, 10) || '',
          dob: item?.dob?.slice(0, 10) || '',
          // residentialCustomerAddress1: '',
          // residentialCustomerAddress2: '',
          // residentialCity: '',
          // residentialPincode: '',
          residentialState: item?.residentialState || '',
          // residentialMobileNo: '',
          // residentialCountryCode: '',
          // residentialEmail: '',
          // fatherName: '',
          // motherName: '',
          // panNo: '',
          // aadhaarNo: '',
          // customersamplingDetailsId: '',
          // customeraddressTypeId: 0,
          // appFieldStaff: 0,
          ResidencyTrigger: item?.residentialTrigger || '',
          OfficeTrigger: item?.officeTrigger || '',
          appHubId: item?.AppHubID || 0,
          AppQueryID: item?.residentialQueryID || 0,
          AppOffice_QueryID: item?.officeQueryID || 0,
          // residentialFieldExecutiveName: '',
          employeeTypeId: item?.employeeTypeId || 0,

          verificationTypeField: item?.appVerificationTypeID ? true : false,
          verificationTypeAuthority: item?.ApplicantAuthority ? true : false,
          officeVerificationTypeField: item?.appOfficeVerificationTypeID ? true : false,
          officeVerificationTypeAuthority: item?.OfficeAuthority ? true : false,
          coVerificationField: item?.coApplicantDetails?.coResiVerificationTypeID ? true : false,
          coVerificationAuthority: item?.CoAppAuthority ? true : false,
          coOfficeVerificationField: item?.coApplicantDetails?.coOfficeVerificationTypeID ? true : false,
          coOfficeVerificationAuthority: item?.CoAppOfficeAuthority ? true : false,
          guarantorVerificationField: item?.gaurantorDetails?.guarantorResiVerificationTypeID ? true : false,
          guarantorVerificationAuthority: item?.GuarantorAuthority ? true : false,
          guarantorOfficeVerificationField: item?.gaurantorDetails?.guarantorOfficeVerificationTypeID ? true : false,
          guarantorOfficeVerificationAuthority: item?.GuarantorOfficeAuthority ? true : false,

          // officeName: '',
          // officeAddress1: '',
          // officeAddress2: '',
          // officeCity: '',
          // officeState: '',
          // officePincode: '',
          // officeLandMark: '',
          // officeCountryCode: '',
          // officeLandLine: '',
          // officeMobileNumber: '',
          designation: item?.designation || '',
          // officeEmployeeTypeId: 0,
          // EmployeeDocumnetTypeId: 0,
          // officeFieldStaff: 0,
          // officeAuthority: 0,
          // OfficeTrigger: '',
          officeHubId: item?.OfficeHubID || 0,
          // officeFieldExecutiveName: '',

          // documentProperties: [],
          // officeDocumentProperties: [],

          // verificationType: '',
          // officeVerificationType: '',
          // appVerificationTypeID: true, 
          applicantDocumentCheck: item?.ApplicantDocumentCheck ? true : false,
          applicantProfileCheck: item?.ApplicantProfileCheck ? true : false,
          // appOfficeVerificationTypeID: true, 
          officeDocumentCheck: item?.ApplicantOfficeDocumentCheck ? true : false,
          officeProfileCheck: item?.ApplicantOfficeProfileCheck ? true : false,


          // co-applicant
          coAddressType: item?.coApplicantDetails?.coAddressType || '',
          coApplicantName: item?.coApplicantDetails?.coApplicantName || '',
          coAddress: item?.coApplicantDetails?.coAddress || '',
          coAddress_2: item?.coApplicantDetails?.coAddress_2 || '',
          coCity: item?.coApplicantDetails?.coCity || '',
          coPincode: item?.coApplicantDetails?.coPincode || '',
          coState: item?.coApplicantDetails?.coState || '',
          coMobileNo: item?.coApplicantDetails?.coMobileNo || '',
          coEmailAddress: item?.coApplicantDetails?.coEmailAddress || '',
          coAppFieldStaff: item?.coApplicantDetails?.coAppFieldStaffId || 0,

          coofficeName: item?.coApplicantDetails?.coOfficeName || '',
          coofficeAddress1: item?.coApplicantDetails?.coOfficeAddress1 || '',
          coofficeAddress2: item?.coApplicantDetails?.coOfficeAddress2 || '',
          coofficeCity: item?.coApplicantDetails?.coOfficeCity || '',
          coofficeState: item?.coApplicantDetails?.coOfficeState || '',
          coofficePincode: item?.coApplicantDetails?.coOfficePincode || '',
          coofficeLandMark: item?.coApplicantDetails?.coOfficeLandMark || '',
          coofficeLandLine: item?.coApplicantDetails?.coOfficeLandline || '',
          coofficeMobileNumber: item?.coApplicantDetails?.coOfficeMobileNumber || '',
          codesignation: item?.coApplicantDetails?.coDesignation || '',
          coemployeeTypeId: item?.coApplicantDetails?.coEmployeetypeID || 0,
          coAppEmployeeDocumnetTypeId: 0,

          CoAppTrigger: item?.coApplicantDetails?.coAppTrigger || '',
          CoAppOfficeTrigger: item?.coApplicantDetails?.coOfficeTrigger || '',
          coAppHubId: item?.coAppHubID || 0,
          coAppOfficeHubId: item?.coAppOfficeHubID || 0,
          CoAppQueryID: item?.coApplicantDetails?.coResiQueryID || 0,
          CoAppOfficeQueryID: item?.coApplicantDetails?.coOfficeQueryID || 0,

          coAppOfficeFieldStaff: 0,


          appVerificationTypeID: item?.appVerificationTypeID ? true : false,
          appOfficeVerificationTypeID: item?.appOfficeVerificationTypeID ? true : false,

          guarantorAppVerificationTypeID: item?.gaurantorDetails?.guarantorResiVerificationTypeID ? true : false,
          guarantorAppOfficeVerificationTypeID: item?.gaurantorDetails?.guarantorOfficeVerificationTypeID ? true : false,

          coAppVerificationTypeID: item?.coApplicantDetails?.coResiVerificationTypeID ? true : false,
          coAppOfficeVerificationTypeID: item?.coApplicantDetails?.coOfficeVerificationTypeID ? true : false,


          coAppDocumentCheck: item?.CoAppDocumentCheck ? true : false,
          coAppProfileCheck: item?.CoAppProfileCheck ? true : false,
          coAppOfficeDocumentCheck: item?.CoAppOfficeDocumentCheck ? true : false,
          coAppOfficeProfileCheck: item?.CoAppOfficeProfileCheck ? true : false,


          // gaurantorAddressType: item?.'',
          // gaurantorName: '',
          // gaurantorAddress_1: '',
          // gaurantorAddress_2: '',
          // gaurantorCity: '',
          // gaurantorPincode: '',
          // gaurantorState: '',
          // gaurantorMobileNo: '',
          // gaurantorEmail: '',
          GuarantorTrigger: item?.gaurantorDetails?.gaurantorTrigger || '',
          gaurantorHubId: item?.guarantorHubID || 0,

          guarantorofficeName: item?.gaurantorDetails?.gaurantorOfficeName || '',
          guarantorofficeAddress1: item?.gaurantorDetails?.gaurantorOfficeAddress_1 || '',
          guarantorofficeAddress2: item?.gaurantorDetails?.gaurantorOfficeAddress_2 || '',
          guarantorofficeCity: item?.gaurantorDetails?.gaurantorOfficeCity || '',
          guarantorofficeState: item?.gaurantorDetails?.gaurantorOfficeState || '',
          guarantorofficePincode: item?.gaurantorDetails?.gaurantorOfficePincode || '',
          guarantorofficeLandMark: item?.gaurantorDetails?.gaurantorOfficeLandmark || '',
          guarantorofficeLandLine: item?.gaurantorDetails?.gaurantorOfficeLandLine || '',
          guarantorofficeMobileNumber: item?.gaurantorDetails?.gaurantorOfficeMobileNumber || '',
          guarantordesignation: item?.gaurantorDetails?.gaurantorDesignation || '',
          guarantoremployeeTypeId: item?.gaurantorDetails?.guarantorEmployeeTypeID || 0,

          GuarantorOfficeTrigger: item?.gaurantorDetails?.gaurantorOfficeTrigger || '',
          gaurantorOfficeHubId: item?.guarantorOfficeHubID || 0,

          GuaQueryID: item?.gaurantorDetails?.gaurantorResiQueryID || 0,
          GuaOfficeQueryID: item?.gaurantorDetails?.gaurantorOfficeQueryID || 0,
          guarantorDocumentCheck: item?.GuarantorDocumentCheck ? true : false,
          guarantorProfileCheck: item?.GuarantorProfileCheck ? true : false,
          guarantorOfficeDocumentCheck: item?.GuarantorOfficeDocumentCheck ? true : false,
          guarantorOfficeProfileCheck: item?.GuarantorOfficeProfileCheck ? true : false,

          documentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 1) || [],
          officeDocumentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 2) || [],
          coDocumentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 3) || [],
          coOfficeDocumentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 4) || [],
          guarantorDocumentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 5) || [],
          guarantorOfficeDocumentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 6) || [],

          residentialFieldExecutiveName: item?.ResidenceFieldExecutiveName || '',
          officeFieldExecutiveName: item?.OfficeFieldExecutiveName || '',
          coAppFieldStaffName: item?.coApplicantDetails?.coAppFieldStaffName || '',
          coOfficeFieldStaffName: item?.coApplicantDetails?.coOfficeFieldStaffName || '',
          guarantorFieldStaffName: item?.gaurantorDetails?.gaurantorFieldStaffName || '',
          gaurantorOfficeFieldStaffName: item?.gaurantorDetails?.gaurantorOfficeFieldStaffName || '',
        },
      });
    }
  };

  const refreshPage = () => {
    _setChecked1(false)
    _setChecked2(false)
    setCount(1);
    dispatch({
      type: "preCaseData", data: {
        userLoginId: user?.id,
        caseDate: moment()?.format("YYYY-MM-DD"),
        residentialState: 'TamilNadu',
        officeState: 'TamilNadu',
        coState: 'TamilNadu',
        coofficeState: 'TamilNadu',
        gaurantorState: 'TamilNadu',
        guarantorofficeState: 'TamilNadu',
        bankReferenceNo: '',
        documentProperties: [],
        officeDocumentProperties: [],
        coDocumentProperties: [],
        coOfficeDocumentProperties: [],
        guarantorDocumentProperties: [],
        guarantorOfficeDocumentProperties: [],
        appVerificationTypeID: true,
        appOfficeVerificationTypeID: true,
        coAppVerificationTypeID: true,
        coAppOfficeVerificationTypeID: true,
        guarantorAppVerificationTypeID: true,
        guarantorAppOfficeVerificationTypeID: true,
      }
    });
  }

  const refreshBulkUpload: any = () => {
    getTableData();
    refreshPage();
    //MisDownload();
  }

  useEffect(() => {
    getTableData();
    refreshPage();
    // MisDownload();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(()=>{
  //   getTableData();
  // },[page,rowsPerPage])
  const exportPDF = () => {
    var curDate = moment()?.format("YYYY-MM-DD")
    const filename = 'PreCase_' + curDate + '.pdf'
    const headers = [
      { key: 'caseNo', display: 'CaseNo' },
      { key: 'bankReferenceNo', display: 'Reference No' },
      { key: 'BankName', display: 'Bank' },
      { key: 'vendorName', display: 'Vendor' },
      { key: 'customerName', display: 'Customer Name' },
      { key: 'residentialMobileNo', display: 'Mobile No' },
      { key: 'residentialEmail', display: 'Email' },
      { key: 'Status', display: 'Status' },
    ]

    pdf({ data: tableData, headers, filename })
  }

  const MisDownload = () => {
    debugger;
    var PageName = "InputTray";
    var UserID = user.id;
    var caseType = "precase";

    getMISforXcellDownload(caseType, UserID, PageName, user?.CorporateDetailsId).then((response) => {
      if (response.data.status === "Success") {
        debugger;
        _setmisreportExport(response.data.data);

      }
    })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const exportMISEXCEL = () => {
    debugger;
    const header = ["CaseNo", "vendorName", "bankName", "branchName", "LoanAmount", "BankEmployeeName", "DsaDesName", "productName", "BankReferenceNo",
      "TypeOfSampling", "CustomerName", "CustomerCity", "CustomerPincode", "FathersName", "DateOfBirth", "EmployeType", "CompanyName", "OfficeCity",
      "OfficePincode", "Designation", "OfficeTrigger", "ResidencyTrigger", "CoAppTrigger", "CoAppOfficeTrigger", "GuarantorTrigger", "GuarantorOfficeTrigger",
      "QcresiRemarks", "QcofficeRemarks", "QccoAppOfficeRemarks", "QcguaOfficeRemarks", "QcguaResiRemarks", "CaseDate", "QcCloseDate",
      "AppFieldStaffStatusName", "AppOfficeFieldStaffStatusName", "CoAppFieldStaffStatusName", "CoAppOfficeFieldStaffStatusName",
      "GuarantorFieldStaffStatusName",
      "ApplicantFieldStaffName", "ApplicantOfficeFieldStaffName", "CoAppFieldStaffName", "CoAppOfficeFieldStaffName", "GuarantorFieldStaffName", "GuarantorOfficeFieldStaffName",

      "AppBoLocation", "OfficeAppBoLocation", "CoappBoLocation", "CoappOfficeBoLocation", "GuaBoLocation", "GuaBoOfficeLocation",
      "Resikm", "Officekm", "CoAppkm", "CoAppOfficekm", "Guakm", "GuaOfficekm", "ResiBokm", "OfficeBokm", "CoAppBokm", "CoAppOfficeBokm", "GuaBokm", "GuaOfficeBokm",];
    let body: any = _misreportExport.map((e: any) => {
      return [e.CaseNo, e.vendorName, e.bankName, e.branchName, e.LoanAmount, e.BankEmployeeName, e.DsaDesName, e.productName, e.BankReferenceNo,
      e.TypeOfSampling, e.CustomerName, e.CustomerCity, e.CustomerPincode, e.FathersName, e.DateOfBirth, e.EmployeType, e.CompanyName, e.OfficeCity,
      e.OfficePincode, e.Designation, e.OfficeTrigger, e.ResidencyTrigger, e.CoAppTrigger, e.CoAppOfficeTrigger, e.GuarantorTrigger, e.GuarantorOfficeTrigger,
      e.QcresiRemarks, e.QcofficeRemarks, e.QccoAppOfficeRemarks, e.QcguaOfficeRemarks, e.QcguaResiRemarks, e.CaseDate, e.QcCloseDate,
      e.AppFieldStaffStatusName, e.AppOfficeFieldStaffStatusName, e.CoAppFieldStaffStatusName, e.CoAppOfficeFieldStaffStatusName,
      e.GuarantorFieldStaffStatusName,
      e.ApplicantFieldStaffName, e.ApplicantOfficeFieldStaffName, e.CoAppFieldStaffName, e.CoAppOfficeFieldStaffName, e.GuarantorFieldStaffName, e.GuarantorOfficeFieldStaffName,

      e.AppBoLocation, e.OfficeAppBoLocation, e.CoappBoLocation, e.CoappOfficeBoLocation, e.GuaBoLocation, e.GuaBoOfficeLocation,
      e.Resikm, e.Officekm, e.CoAppkm, e.CoAppOfficekm, e.Guakm, e.GuaOfficekm, e.ResiBokm, e.OfficeBokm, e.CoAppBokm, e.CoAppOfficeBokm, e.GuaBokm, e.GuaOfficeBokm,]
    })
    var curDate = moment()?.format("YYYY-MM-DD")
    var filename = 'InputTray_Precase_MIS_Report_' + curDate;
    downloadExcel({
      fileName: filename,
      sheet: "Product_Master",
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = _orderBy === property && _order === 'asc';
    _setOrder(isAsc ? 'desc' : 'asc');
    _setOrderBy(property);
  };

  useEffect(() => {
    const data = sortTableData(tableData, _order, _orderBy);
    setTableData(data)
  }, [_order, _orderBy])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center bg-primary text-white p-3">
        <div>
          <h5 className="mb-0">
            <span className="mx-2">
              <img src={inputdataicon} alt="inputdataicon" />
            </span>
            Input Data-Pre Case
          </h5>
        </div>
      </div>
      <div className="bg-white p-3 content-area">
        {/* coming soon */}

        <div
          className="text-white mb-3 mt-3 px-4 d-flex align-items-center bg-primary"
          style={{ borderRadius: "2px" }}
        >
          <TabContext value={""}>
            <TabList aria-label="lab API tabs example">
              <Tab
                label="Case Details"
                className={clsx(
                  count === 1 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => onChangeTab(1)}
              />
              <Tab
                label="Customer & Office Details"
                className={clsx(
                  count === 2 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => onChangeTab(2)}
              />

              {_checked1 && (
                <Tab
                  label="Co Applicant"
                  className={clsx(
                    count === 4 ? "light-blue" : "bg-primary",
                    "text-white fs-6 rounded-3"
                  )}
                  onClick={() => onChangeTab(4)}
                />
              )}
              {_checked2 && (
                <Tab
                  label="Guarantor"
                  className={clsx(
                    count === 5 ? "light-blue" : "bg-primary",
                    "text-white fs-6 rounded-3"
                  )}
                  onClick={() => onChangeTab(5)}
                />
              )}

              {/* <Tab
                label="Office Details"
                className={clsx(
                  count === 3 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => onChangeTab(3)}
              /> */}
              <Tab
                label="Others Details"
                className={clsx(
                  count === 3 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => onChangeTab(3)}
              />


              <Tab
                label="Bulk Upload"
                className={clsx(
                  count === 6 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => onChangeTab(6)}
              />
            </TabList>
          </TabContext>
        </div>
        <div>
          {count === 1 && <CaseDetails />}
          {count === 2 && <Customerdetails />}
          {/* {count === 3 && <Officedetails />} */}
          {count === 3 && <Otherdetails />}
          {_checked1 && count === 4 && <CoApplicant />}
          {_checked2 && count === 5 && <Guarantor />}
          {count === 6 && <BulkUpload refreshBulkUpload={refreshBulkUpload} />}
          <div className="row">
            {count !== 1 ? (
              ""
            ) : (
              <div className="col-md-3 mb-3" style={{ paddingLeft: "1.7em" }}>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox className={`${preCaseData?.precaseid && 'text-muted'}`} disabled={preCaseData?.precaseid}
                          onChange={(e: any) =>
                            onCheckBoxChange("coApplicant", !_checked1)
                          }
                          checked={_checked1}
                        />
                      }
                      label="Co Applicant"
                    />
                  </FormGroup>
                </div>
              </div>
            )}
            {count !== 1 ? (
              ""
            ) : (
              <div className="col-md-2 mb-3 " style={{ paddingLeft: "1em" }}>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox className={`${preCaseData?.precaseid && 'text-muted'}`} disabled={preCaseData?.precaseid}
                          onChange={(e: any) =>
                            onCheckBoxChange("Guarantor", !_checked2)
                          }
                          checked={_checked2}
                        />
                      }
                      label="Guarantor"
                    />
                  </FormGroup>
                </div>
              </div>
            )}

            <div className=" col-md-6 text-end pe-3 d-flex justify-content-end">
              {count === 1 ? (
                ""
              ) : (
                <Button
                  className="bg-secondary text-white mx-5"
                  sx={{ width: 170, padding: 2 }}
                  onClick={onGoBack}
                >
                  <ArrowBackIosIcon className="h-15" /> Back
                </Button>
              )}

              <Button
                className={clsx(
                  count === 3 ? "d-none" : "bg-success text-white"
                )}
                sx={{ width: 170, p: 2 }}
                onClick={checkValidation}
              >
                Next <ArrowForwardIosIcon className="h-15" />
              </Button>

              {count === 3 && (
                <LoadingButton className={"bg-success text-white"} loadingPosition="end" endIcon={<></>}
                  loading={_loading} fullWidth sx={{ width: 130, p: 2 }}
                  onClick={onSaveClick}>{preCaseData?.precaseid ? 'Update' : 'Save'}</LoadingButton>
              )}

              <Button className="bg-danger text-white ms-2" sx={{ width: 170, p: 2 }}
                onClick={refreshPage}>Clear <DoDisturb className="h-15" /></Button>


            </div>
          </div>
          <hr />

          {/* <Outlet/> */}
          <div className="bg-primary py-2 mt-5 gx-0 text-white fw-bold ps-3 row d-flex align-items-center justify-content-around">
            <div className="col-md-6 fs-4">Pre Case</div>
            <div className="col-md-6 px-2 d-flex justify-content-around">
              <input
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search"
                className="my-2 px-2 rounded-2 w-100 border border-white"
              />
              {/* <Button>
                <img
                  src={PDF}
                  alt=""
                  className="text-end mx-1"
                  role="button"
                  style={{ width: "3rem" }} onClick={exportPDF}
                />
              </Button> */}
              <Button>
                <img
                  src={Excel}
                  // onClick={downloadExcelFile}
                  className="text-end mx-1"
                  alt=""
                  role="button"
                  style={{ width: "3rem" }}
                />
              </Button>
              <AssignmentReturnedIcon onClick={exportMISEXCEL} className='' style={{ fontSize: "3.5rem" }} />
            </div>

          </div>
          <TableContainer component={Paper}>
            <div className="row mt-4 mx-2">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-2 mr-2 d-flex align-items-center">Start Date</div>
                  <div className="col-md-3 p-0" style={{ borderRight: "1px solid black" }}>
                    <TextField
                      size='small'
                      id="date" autoComplete='off'
                      label=" "
                      type="date"
                      //    defaultValue="2017-05-24"
                      value={_fromDate}
                      onChange={(e: any) => _setFromDate(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                    />
                  </div>

                  <div className="col-md-2 d-flex align-items-center mr-2">End Date</div>
                  <div className="col-md-3 p-0">
                    <TextField
                      size='small'
                      id="date" autoComplete='off'
                      label=" "
                      type="date"
                      //   defaultValue="2017-05-24"
                      value={_toDate}
                      onChange={(e: any) => _setToDate(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                    />
                  </div>
                  <div className="col-md-2">
                    <Button className='border rounded bg-primary text-white' onClick={getTableData}>
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <Table
              sx={{ minWidth: 1000, borderRadius: "0px 0px 15px 15px" }}
              aria-label="customized table"
            >
              <SortableTableHead
                order={_order}
                orderBy={_orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  { id: '', label: 'Edit', align: 'center' },
                  { id: 'Status', label: 'Status', align: 'center' },
                  { id: 'caseDate', label: 'Case Date', align: 'center' },
                  { id: 'bankReferenceNo', label: 'Reference No', align: 'center' },
                  { id: 'BankName', label: 'Bank', align: 'center' },
                  { id: 'customerName', label: 'Customer Name', align: 'center' },
                  { id: 'ProductName', label: 'Product Name', align: 'center' },
                  { id: '', label: 'Query', align: 'center' },
                  { id: '', label: 'Field Staff Name', align: 'center' }
                ]}
              />

              <TableBody>
                {tableData?.length > 0
                  ? tableData.filter((content: any) => `${content.caseNo}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.BankReferenceNo}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.BankName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.vendorName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.customerName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.residentialEmail}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.Status}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.ResiQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.OfficeQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.CoAppResiQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.CoAppOfficeQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.GuaResiQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.GuaOfficeQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.ResidenceFieldExecutiveName + ' ' +
                      content?.OfficeFieldExecutiveName + ' ' +
                      content?.coApplicantDetails?.coAppFieldStaffName + ' ' +
                      content?.coApplicantDetails?.coOfficeFieldStaffName + ' ' +
                      content?.gaurantorDetails?.gaurantorFieldStaffName + ' ' +
                      content?.gaurantorDetails?.gaurantorOfficeFieldStaffName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                  )?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                    ?.map((item: any, index: number) => (
                      <TableRow key={index + 1} sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      >
                        <TableCell align="center">
                          <IconButton
                            className="p-0 text-muted"
                            size="large"
                            onClick={() => updateData(item)}
                          >
                            <EditTwoToneIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          {item.StatusName === "Completed" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Completed
                            </Button>
                          )}
                          {item.StatusName === "Completed-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-info text-white px-4  text-nowrap"
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Completed TAT Breached
                            </Button>
                          )}
                          {item.StatusName === "In Progress" && (
                            <Button
                              variant="contained"
                              className="bg-primary px-4  "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              InProgress
                            </Button>
                          )}

                          {item.StatusName === "In Progress-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              In Progress-TAT Breached
                            </Button>
                          )}
                          {item.StatusName === "Cancelled-Reassign" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Cancelled-Reassign
                            </Button>
                          )}
                          {item.Status === "Verification Started" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Verification Started
                            </Button>
                          )}
                          {item.StatusName === "QC Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              QC Tray Pending
                            </Button>
                          )}
                          {item.StatusName === "B.O Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              B.O Tray Pending
                            </Button>
                          )}
                          {item.StatusName === "Sampled" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4  "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Sampled
                            </Button>
                          )}
                        </TableCell>

                        <TableCell className='text-nowrap' component="th" scope="row" align="center">{item?.CaseDate?.split("T")[0]}</TableCell>
                        <TableCell align="center"> {item?.BankReferenceNo}</TableCell>
                        <TableCell align="center">{item?.BankName}</TableCell>
                        <TableCell align="center">{item?.CustomerName}</TableCell>
                        <TableCell align="center">{item?.ProductName}</TableCell>
                        {/* <TableCell align="center">{item?.residentialEmail}</TableCell> */}
                        <TableCell align="center" sx={{ minWidth: 300 }}>
                          {item?.ResiQuery?.length > 0 ? <span>{item?.ResiQuery}, </span> : ''}
                          {item?.OfficeQuery?.length > 0 ? <span>{item?.OfficeQuery}, </span> :
                            <span></span>}
                          {item?.CoAppResiQuery?.length > 0 ? <span>{item?.CoAppResiQuery}, </span> :
                            <span></span>}
                          {item?.CoAppOfficeQuery?.length > 0 ? <span>{item?.CoAppOfficeQuery}, </span> :
                            <span></span>}
                          {item?.GuaResiQuery?.length > 0 ? <span>{item?.GuaResiQuery}, </span> :
                            <span></span>}
                          {item?.GuaOfficeQuery?.length > 0 ? <span>{item?.GuaOfficeQuery}</span> :
                            <span></span>}
                        </TableCell>
                        <TableCell align="center">
                          {item?.ResidenceFieldExecutiveName}
                          {item?.OfficeFieldExecutiveName ? <span> , {item?.OfficeFieldExecutiveName}</span> :
                            <span></span>}
                          {item?.coApplicantDetails?.coAppFieldStaffName ? <span> , {item?.coApplicantDetails?.coAppFieldStaffName}</span> :
                            <span></span>}
                          {item?.coApplicantDetails?.coOfficeFieldStaffName ? <span> , {item?.coApplicantDetails?.coOfficeFieldStaffName}</span> :
                            <span></span>}
                          {item?.gaurantorDetails?.gaurantorFieldStaffName ? <span> , {item?.gaurantorDetails?.gaurantorFieldStaffName}</span> :
                            <span></span>}
                          {item?.gaurantorDetails?.gaurantorOfficeFieldStaffName ? <span> , {item?.gaurantorDetails?.gaurantorOfficeFieldStaffName}</span> :
                            <span></span>}
                        </TableCell>

                        {/* <TableCell align="center">
                          {item.IsActive === 1 && (
                            <Button
                              variant="contained"
                              className="bg-success"
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Active
                            </Button>
                          )}
                          {item.IsActive === 0 && (
                            <Button
                              variant="contained"
                              className="text-white px-4 py-2"
                              style={{
                                color: "yellowgreen",
                                borderRadius: "10px",
                              }}
                              type="submit"
                            >
                              InActive
                            </Button>
                          )}
                        </TableCell> */}
                      </TableRow>
                    ))
                  : !tableData && (
                    <TableRow key={0}>
                      <TableCell align={"center"}>
                        <h3 className="text-muted">Data Not Found</h3>
                      </TableCell>
                    </TableRow>
                  )}
                <SkeletonProviderTables columns={8} visible={_tableLoading} />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component='div'
            count={tableData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />



        </div>
      </div>
    </>
  );
}

export default Index;



