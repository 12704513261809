
import { LoadingButton, TabContext, TabList } from '@mui/lab';
import { Tab, Button, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, TablePagination, TextField, debounce } from '@mui/material';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import inputdataicon from '../../assets/images/InputDataWhite.png';
import PDF from '../../assets/images/ExportPDF.png';
import Excel from '../../assets/images/ExportExcel.png';
import { useStateValue } from '../../providers/stateProvider';
import { getAllPrecaseInspectionData, updateInspectionData, getAllInspectionDataPre, getMISforXcellDownload, getBackOfficeCaseGridData } from '../../Models/configapi';
import Backofficetray from '../../pages/inputData/backofficetray/precase';
import PostbackofficeTray from '../../pages/inputData/backofficetray/precase/inspectionDetails';
import Postcasedocument from '../../pages/inputData/backofficetray/precase/postCaseDocuments';
import Postimages from '../../pages/inputData/backofficetray/precase/Posttimages';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { pdfBackOffice } from './pdf';
import moment from 'moment';
import { downloadExcel } from "react-export-table-to-excel";
import swal from 'sweetalert';
import { Close, DoDisturb } from '@mui/icons-material';
import { SkeletonProviderTables } from '../../providers/SkeletonProvider';
import { getPreCasetable } from '../../Models/model';
import PreInspectionValidation from './preInspectionValidation';
import { routerPath } from '../../config/constents';
import { useNavigate } from 'react-router-dom';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { sortTableData } from '../../services/helperService';
import { SortableTableHead } from '../helpers/tableSortableHeader';

type Order = 'asc' | 'desc';

function Index() {
  const navigate = useNavigate();
  const { handleValidation } = PreInspectionValidation();
  const [count, setCount] = useState(1);
  const [{ user, backOfficeprecaseInspectionData, inspectionImages }, dispatch]: any = useStateValue();
  const [_tableData, _setTableData] = useState<any>([]);
  const [_allData, _setAllData] = useState<any>([]);
  const [_tableLoading, _setTableLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [_save, _setSave] = useState(false);
  const [_submit, _setSubmit] = useState(false);
  const [_loading, _setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [_misreportExport, _setmisreportExport] = useState<any>([]);
  const queryParams = new URLSearchParams(window.location.search);
  const preId: any = queryParams.get('id');
  const [_order, _setOrder] = useState<Order>('asc');
  const [_orderBy, _setOrderBy] = useState<string>('');
  const [_totalRecordCount, _setTotalRecordCount] = useState(0)

  var date = new Date();
  const today = new Date();
  let dateVal = moment(date).format("MM-YYYY-DD");
  let toDate = moment(date).format("MM-YYYY-DD");
  const [_fromDate, _setFromDate] = useState<any>(dateVal);
  const [_toDate, _setToDate] = useState<any>(toDate);


  const dateFilter = () => {
    debugger;
    getBackOfficeCaseGridData(_fromDate, _toDate, user.id, user?.CorporateDetailsId)
      .then(response => {
        if (response.status === 200) {
          console.log('backofficepreCaseTable', response?.data);
          _setTableData(response?.data?.data);
          // _setTableData([...response?.data?.data?.map((item: any) => { return { ...item?.CaseDetails } })]);
          // _setTotalRecordCount(response?.data?.result3?.length)
        }
        else {
          swal(response.data.Data.Message, { icon: 'warning' });
        }
      }).catch(error => {
        swal("Something Went Wrong", { icon: 'error' });
      }).finally(() => _setTableLoading(false));
  }


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    debugger;
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    debugger;
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSaveClick = (_type: any) => {
    debugger;
    _setLoading(true);
    const tempArr = Object.keys(backOfficeprecaseInspectionData?.InspectionData).map((key) => backOfficeprecaseInspectionData?.InspectionData[key])
    debugger;
    let data = {
      caseid: backOfficeprecaseInspectionData?.Id,
      userid: user.id,
      lCorporateId: user.CorporateDetailsId,
      casetype: "precase",
      SaveType: _type || '',

      OverallCaseStatus: backOfficeprecaseInspectionData?.overAllStatus || '',
      BOoverallRemarks: backOfficeprecaseInspectionData?.BOoverallRemarks || '',

      remarksappres: backOfficeprecaseInspectionData?.residentRemarks || "",
      remarksappoff: backOfficeprecaseInspectionData?.resOfficeRemarks || "",
      remarkscoappres: backOfficeprecaseInspectionData?.coAppRemarks || "",
      remarkscoappoff: backOfficeprecaseInspectionData?.coAppOfficeRemarks || "",
      remarksguaoff: backOfficeprecaseInspectionData?.guarantorOfficeRemarks || "",
      remarksguares: backOfficeprecaseInspectionData?.guarantorRemarks || "",
      AuthorityRemarks: backOfficeprecaseInspectionData?.AuthorityRemarks || "",

      backofficestatusappres: Number(backOfficeprecaseInspectionData?.backOfficeResidentStatus) || 0,
      backofficestatusappoff: Number(backOfficeprecaseInspectionData?.backOfficeResidentOffice) || 0,
      backofficestatuscoappres: Number(backOfficeprecaseInspectionData?.backOfficeCoApplicant) || 0,
      backofficestatuscoappoff: Number(backOfficeprecaseInspectionData?.backOfficeCoAppOffice) || 0,
      backofficestatusguares: Number(backOfficeprecaseInspectionData?.guaBackOfficeStatus) || 0,
      backofficestatusguaoff: Number(backOfficeprecaseInspectionData?.guaOfficeBackOfficeStatus) || 0,


      AppLocation: Number(backOfficeprecaseInspectionData?.AppLocation) || 0,
      AppOfficeLocation: Number(backOfficeprecaseInspectionData?.AppOfficeLocation) || 0,
      CoAppLocation: Number(backOfficeprecaseInspectionData?.CoAppLocation) || 0,
      CoAppOfficeboLocation: Number(backOfficeprecaseInspectionData?.CoAppOfficeboLocation) || 0,
      GuaLocation: Number(backOfficeprecaseInspectionData?.GuaLocation) || 0,
      GuaOfficeboLocation: Number(backOfficeprecaseInspectionData?.GuaOfficeboLocation) || 0,

      resiBoKM: Number(backOfficeprecaseInspectionData?.ResiBokm) || 0,
      OfficeBoKM: Number(backOfficeprecaseInspectionData?.OfficeBokm) || 0,
      coAppBoKM: Number(backOfficeprecaseInspectionData?.CoAppBokm) || 0,
      coAppOfficeBoKM: Number(backOfficeprecaseInspectionData?.CoAppOfficeBokm) || 0,
      GuaBoKM: Number(backOfficeprecaseInspectionData?.GuaBokm) || 0,
      GuaOfficeBoKM: Number(backOfficeprecaseInspectionData?.GuaOfficeBokm) || 0,

      querylist: [...tempArr]
    };

    if (!handleValidation(1)) {
      _setLoading(false)
      return;
    }

    console.log(data)
    updateInspectionData(data).then((response) => {
      console.log(response)
      if (response.status === 201) {
        getTableData(page, rowsPerPage);
        refreshPage();
        setCount(1);
        swal(`PreCase ${_type === 'Save' ? 'Saved' : 'Updated'} Successfully`, { icon: "success" });
        // navigate(routerPath.qcTray.precase)
      }
    }).catch((error) => {
      swal("Something went Wrong", { icon: "warning" });
    }).finally(() => _setLoading(false));
  }

  const viewData = (item: any) => {
    refreshPage();
    window.scrollTo(0, 0);
    setCount(1);
    debugger;
    if (item?.StatusName === 'Completed') {

    } else if (item?.StatusName === 'In Progress' || item?.StatusName === 'In Progress-TAT Breached') {
      _setSave(true);
      _setSubmit(false);
    } else if (item?.StatusName === 'B.O Tray Pending' || item?.StatusName === 'B.O. Tray - TAT Breached') {
      _setSave(false);
      _setSubmit(true);
    }
    debugger;
    getAllInspectionDataPre(item?.Id, user?.CorporateDetailsId)
      .then((response) => {
        //console.log(response.data)
        debugger;
        if (response.data.status === 'Success') {

          let arr: any = [];

          if (response?.data?.data?.ResiBOCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.ResiBOCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.ResiCheck]
          }

          if (response?.data?.data?.OfficeBOCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.OfficeBOCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.OfficeCheck]
          }

          if (response?.data?.data?.CoAppBOResiCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.CoAppBOResiCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.CoAppResiCheck]
          }

          if (response?.data?.data?.CoAppBOOfficeCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.CoAppBOOfficeCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.CoAppOfficeCheck]
          }

          if (response?.data?.data?.GuaBOResiCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.GuaBOResiCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.GuaResiCheck]
          }

          if (response?.data?.data?.GuaBOOfficeCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.GuaBOOfficeCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.GuaOfficeCheck]
          }

          let obj: any = {}
          console.log(arr)
          arr.forEach((temp: any) => {
            obj[temp?.InspectionID] = temp;
          })

          debugger;
          dispatch({
            type: "backOfficePreCaseData", data: { ...response?.data?.data?.CaseDetails, _view: true, }
          });
          dispatch({
            type: "backOfficeprecaseInspectionData", data: {
              ...item,
              backOfficeResidentStatus: response?.data?.data?.CaseDetails?.BoresiStatus || 0,
              backOfficeResidentOffice: response?.data?.data?.CaseDetails?.BoofficeStatus || 0,
              backOfficeCoApplicant: response?.data?.data?.CaseDetails?.BocoAppResiStatus || 0,
              backOfficeCoAppOffice: response?.data?.data?.CaseDetails?.BocoAppOfficeStatus || 0,
              guaBackOfficeStatus: response?.data?.data?.CaseDetails?.BoguaResiStatus || 0,
              guaOfficeBackOfficeStatus: response?.data?.data?.CaseDetails?.BoguaOfficeStatus || 0,

              residentLocation: response?.data?.data?.CaseDetails?.ResiLocation || 0,
              residentOfficeLocation: response?.data?.data?.CaseDetails?.OfficeLocation || 0,
              coAppLocation: response?.data?.data?.CaseDetails?.CoAppResiLocation || 0,
              coAppOfficeLocation: response?.data?.data?.CaseDetails?.CoAppOfficeLocation || 0,
              guarantorLocation: response?.data?.data?.CaseDetails?.GuaResiLocation || 0,
              guarantorOfficeLocation: response?.data?.data?.CaseDetails?.GuaOfficeboLocation || 0,

              AppLocation: response?.data?.data?.CaseDetails?.AppBoLocation || 0,
              AppOfficeLocation: response?.data?.data?.CaseDetails?.OfficeAppBoLocation || 0,
              CoAppLocation: response?.data?.data?.CaseDetails?.CoappBoLocation || 0,
              CoAppOfficeboLocation: response?.data?.data?.CaseDetails?.CoappOfficeBoLocation || 0,
              GuaLocation: response?.data?.data?.CaseDetails?.GuaBoLocation || 0,
              GuaOfficeboLocation: response?.data?.data?.CaseDetails?.GuaBoOfficeLocation || 0,

              residentRemarks: response?.data?.data?.CaseDetails?.BoresiRemarks || "",
              resOfficeRemarks: response?.data?.data?.CaseDetails?.BoofficeRemarks || "",
              coAppRemarks: response?.data?.data?.CaseDetails?.BocoAppResiRemarks || "",
              coAppOfficeRemarks: response?.data?.data?.CaseDetails?.BocoAppOfficeRemarks || "",
              guarantorRemarks: response?.data?.data?.CaseDetails?.BoguaResiRemarks || "",
              guarantorOfficeRemarks: response?.data?.data?.CaseDetails?.BoguaOfficeRemarks || "",
              AuthorityRemarks: response?.data?.data?.CaseDetails?.AuthorityRemarks || "",

              remarksappres: response?.data?.data?.CaseDetails?.BoresiRemarks || "",
              remarksappoff: response?.data?.data?.CaseDetails?.BoofficeRemarks || "",
              remarkscoappres: response?.data?.data?.CaseDetails?.BocoAppResiRemarks || "",
              remarkscoappoff: response?.data?.data?.CaseDetails?.BocoAppOfficeRemarks || "",
              remarksguaoff: response?.data?.data?.CaseDetails?.BoguaResiRemarks || "",
              remarksguares: response?.data?.data?.CaseDetails?.BoguaOfficeRemarks || "",

              backofficestatusappres: response?.data?.data?.CaseDetails?.BoresiStatus || 0,
              backofficestatusappoff: response?.data?.data?.CaseDetails?.BoofficeStatus || 0,
              backofficestatuscoappres: response?.data?.data?.CaseDetails?.BocoAppResiStatus || 0,
              backofficestatuscoappoff: response?.data?.data?.CaseDetails?.BocoAppOfficeStatus || 0,
              backofficestatusguares: response?.data?.data?.CaseDetails?.BoguaResiStatus || 0,
              backofficestatusguaoff: response?.data?.data?.CaseDetails?.BoguaOfficeStatus || 0,


              overAllStatus: response?.data?.data?.CaseDetails?.OverallCaseStatus || '',

              ResiCheck: response?.data?.data?.ResiCheck,
              OfficeCheck: response?.data?.data?.OfficeCheck,
              CoAppResiCheck: response?.data?.data?.CoAppResiCheck,
              CoAppOfficeCheck: response?.data?.data?.CoAppOfficeCheck,
              GuaResiCheck: response?.data?.data?.GuaResiCheck,
              GuaOfficeCheck: response?.data?.data?.GuaOfficeCheck,

              ResiBOCheck: response?.data?.data?.ResiBOCheck?.length > 0 ? response?.data?.data?.ResiBOCheck : response?.data?.data?.ResiCheck,
              OfficeBOCheck: response?.data?.data?.OfficeBOCheck?.length > 0 ? response?.data?.data?.OfficeBOCheck : response?.data?.data?.OfficeCheck,
              CoAppBOResiCheck: response?.data?.data?.CoAppBOResiCheck?.length > 0 ? response?.data?.data?.CoAppBOResiCheck : response?.data?.data?.CoAppResiCheck,
              CoAppBOOfficeCheck: response?.data?.data?.CoAppBOOfficeCheck?.length > 0 ? response?.data?.data?.CoAppBOOfficeCheck : response?.data?.data?.CoAppOfficeCheck,
              GuaBOResiCheck: response?.data?.data?.GuaBOResiCheck?.length > 0 ? response?.data?.data?.GuaBOResiCheck : response?.data?.data?.GuaResiCheck,
              GuaBOOfficeCheck: response?.data?.data?.GuaBOOfficeCheck?.length > 0 ? response?.data?.data?.GuaBOOfficeCheck : response?.data?.data?.GuaOfficeCheck,

              InspectionData: { ...obj }, _view: true,
            }
          });

          // const docData = _allData?.filter((aItem:any)=> aItem?.CaseDetails?.precaseid === item?.Id)[0]

          dispatch({
            type: "docProperties", data: { ...item, ...response?.data?.data, _view: true, }
          })
          dispatch({
            type: "inspectionImages", data: { ...item, InspectiondocumentProperties: response?.data?.data?.InspectiondocumentProperties
              , _view: true, }
          })

        }
      })
      .catch((error) => {
        console.log(error.response);
      });

    // const arr = [...item?.ResiCheck, ...item?.OfficeCheck, ...item?.CoAppResiCheck,
    // ...item?.CoAppOfficeCheck, ...item?.GuaResiCheck, ...item?.GuaOfficeCheck]
    // let obj: any = {}
    // arr.forEach((temp: any) => {
    //   obj[temp?.InspectionID] = temp;
    // })

    // // debugger;
    // dispatch({
    //   type: "backOfficePreCaseData", data: { ...item, ...item?.CaseDetails, _view: true }
    // });
    // dispatch({
    //   type: "backOfficeprecaseInspectionData", data: { ...item, ...item, InspectionData: { ...obj }, _view: true }
    // });
    // dispatch({
    //   type: "docProperties", data: { ...item, ...item?.documentProperties, _view: true }
    // })
    // dispatch({
    //   type: "inspectionImages", data: { ...item, ...item?.InspectiondocumentProperties, _view: true }
    // })
  };

  const getTableData = (pageNumber: any, pageSize: any) => {
    debugger;
    //getAllPrecaseInspectionData(user.id,page,rowsPerPage)
    getBackOfficeCaseGridData("", "", user.id, user.CorporateDetailsId)
      .then((response) => {
        debugger;
        console.log(response.data)
        if (response.data.status === 'Success') {
          _setAllData([...response?.data?.data]);
          //   _setTableData([...response?.data?.data?.map((item: any) => { return { ...item?.CaseDetails } })]);
          _setTableData([...response?.data?.data])
          var count = (response.data.data).length;
          debugger;
          _setTotalRecordCount(count);
        }
      })
      .catch((error) => {
        console.log(error.response);
      }).finally(() => _setTableLoading(false));
  }

  const viewDashboardData = (id: any) => {
    getPreCasetable(user.id, page, rowsPerPage, user?.CorporateDetailsId)
      .then(response => {
        if (response.status === 200) {
          console.log(response?.data)
          const caseData = response?.data?.Data?.filter((content: any) => content?.precaseid == id)
          getDashboardData(caseData[0]);
        }
      })
      .catch(error => console.log(error))
  }

  const getDashboardData = (item: any) => {
    getAllInspectionDataPre(item?.precaseid, user?.CorporateDetailsId)
      .then((response) => {
        if (response.data.status === 'Success') {
          let arr: any = [];

          if (response?.data?.data?.ResiBOCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.ResiBOCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.ResiCheck]
          }

          if (response?.data?.data?.OfficeBOCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.OfficeBOCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.OfficeCheck]
          }

          if (response?.data?.data?.CoAppBOResiCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.CoAppBOResiCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.CoAppResiCheck]
          }

          if (response?.data?.data?.CoAppBOOfficeCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.CoAppBOOfficeCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.CoAppOfficeCheck]
          }

          if (response?.data?.data?.GuaBOResiCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.GuaBOResiCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.GuaResiCheck]
          }

          if (response?.data?.data?.GuaBOOfficeCheck?.length > 0) {
            arr = [...arr, ...response?.data?.data?.GuaBOOfficeCheck]
          } else {
            arr = [...arr, ...response?.data?.data?.GuaOfficeCheck]
          }

          let obj: any = {}
          console.log(arr)
          arr.forEach((temp: any) => {
            obj[temp?.InspectionID] = temp;
          })

          dispatch({
            type: "backOfficePreCaseData", data: { ...item, _view: true, }
          });
          dispatch({
            type: "backOfficeprecaseInspectionData", data: {
              ...item,
              backOfficeResidentStatus: item?.BoresiStatus || 0,
              backOfficeResidentOffice: item?.BoofficeStatus || 0,
              backOfficeCoApplicant: item?.BocoAppResiStatus || 0,
              backOfficeCoAppOffice: item?.BocoAppOfficeStatus || 0,
              guaBackOfficeStatus: item?.BoguaResiStatus || 0,
              guaOfficeBackOfficeStatus: item?.BoguaOfficeStatus || 0,

              residentLocation: item?.ResiLocation || 0,
              residentOfficeLocation: item?.OfficeLocation || 0,
              coAppLocation: item?.CoAppResiLocation || 0,
              coAppOfficeLocation: item?.CoAppOfficeLocation || 0,
              guarantorLocation: item?.GuaResiLocation || 0,
              guarantorOfficeLocation: item?.GuaOfficeboLocation || 0,

              AppLocation: item?.AppBoLocation || 0,
              AppOfficeLocation: item?.OfficeAppBoLocation || 0,
              CoAppLocation: item?.CoappBoLocation || 0,
              CoAppOfficeboLocation: item?.CoappOfficeBoLocation || 0,
              GuaLocation: item?.GuaBoLocation || 0,
              GuaOfficeboLocation: item?.GuaBoOfficeLocation || 0,

              residentRemarks: item?.BoresiRemarks || "",
              resOfficeRemarks: item?.BoofficeRemarks || "",
              coAppRemarks: item?.BocoAppResiRemarks || "",
              coAppOfficeRemarks: item?.BocoAppOfficeRemarks || "",
              guarantorRemarks: item?.BoguaResiRemarks || "",
              guarantorOfficeRemarks: item?.BoguaOfficeRemarks || "",
              AuthorityRemarks: item?.AuthorityRemarks || "",

              remarksappres: item?.BoresiRemarks || "",
              remarksappoff: item?.BoofficeRemarks || "",
              remarkscoappres: item?.BocoAppResiRemarks || "",
              remarkscoappoff: item?.BocoAppOfficeRemarks || "",
              remarksguaoff: item?.BoguaResiRemarks || "",
              remarksguares: item?.BoguaOfficeRemarks || "",

              backofficestatusappres: item?.BoresiStatus || 0,
              backofficestatusappoff: item?.BoofficeStatus || 0,
              backofficestatuscoappres: item?.BocoAppResiStatus || 0,
              backofficestatuscoappoff: item?.BocoAppOfficeStatus || 0,
              backofficestatusguares: item?.BoguaResiStatus || 0,
              backofficestatusguaoff: item?.BoguaOfficeStatus || 0,


              overAllStatus: item?.OverallCaseStatus || '',

              ResiCheck: response?.data?.data?.ResiCheck,
              OfficeCheck: response?.data?.data?.OfficeCheck,
              CoAppResiCheck: response?.data?.data?.CoAppResiCheck,
              CoAppOfficeCheck: response?.data?.data?.CoAppOfficeCheck,
              GuaResiCheck: response?.data?.data?.GuaResiCheck,
              GuaOfficeCheck: response?.data?.data?.GuaOfficeCheck,

              ResiBOCheck: response?.data?.data?.ResiBOCheck?.length > 0 ? response?.data?.data?.ResiBOCheck : response?.data?.data?.ResiCheck,
              OfficeBOCheck: response?.data?.data?.OfficeBOCheck?.length > 0 ? response?.data?.data?.OfficeBOCheck : response?.data?.data?.OfficeCheck,
              CoAppBOResiCheck: response?.data?.data?.CoAppBOResiCheck?.length > 0 ? response?.data?.data?.CoAppBOResiCheck : response?.data?.data?.CoAppResiCheck,
              CoAppBOOfficeCheck: response?.data?.data?.CoAppBOOfficeCheck?.length > 0 ? response?.data?.data?.CoAppBOOfficeCheck : response?.data?.data?.CoAppOfficeCheck,
              GuaBOResiCheck: response?.data?.data?.GuaBOResiCheck?.length > 0 ? response?.data?.data?.GuaBOResiCheck : response?.data?.data?.GuaResiCheck,
              GuaBOOfficeCheck: response?.data?.data?.GuaBOOfficeCheck?.length > 0 ? response?.data?.data?.GuaBOOfficeCheck : response?.data?.data?.GuaOfficeCheck,

              InspectionData: { ...obj }, _view: true,
            }
          });
          // dispatch({
          //   type: "backOfficeprecaseInspectionData", data: { ...item, ...item, InspectionData: { ...obj }, _view: true }
          // });
          dispatch({
            type: "docProperties", data: { ...item, ...item, _view: true, }
          })
          dispatch({
            type: "inspectionImages", data: { ...item, InspectiondocumentProperties: response?.data?.data?.InspectiondocumentProperties, _view: true, }
          })

        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  // useEffect(() => {
  //   debugger;
  //   if (preId && user?.BankId != null && user?.BankId != 0) {
  //     viewDashboardData(preId)
  //   }
  // }, [preId])

  const refreshPage = () => {
    dispatch({ type: "backOfficePreCaseData", data: {} });
    dispatch({ type: "backOfficeprecaseInspectionData", data: {} });
    dispatch({ type: "docProperties", data: {} });
    dispatch({ type: "inspectionImages", data: {} });
    dispatch({ type: "preInspectionValidation", data: {} });

    setCount(1);
    _setSave(false);
    _setSubmit(false);
  }

  useEffect(() => {
    debugger;
    refreshPage();
    getTableData(page, rowsPerPage);
    MisDownload();
  }, []);

  // useEffect(()=>{
  //   debugger;
  //   dateFilter();
  // },[_fromDate,_toDate])

  // useEffect(()=>{
  //   getTableData(page,rowsPerPage);
  // },[page,rowsPerPage])

  const exportPDF = () => {
    var curDate = moment()?.format("YYYY-MM-DD")
    const filename = 'BackOffice_PreCase_' + curDate + '.pdf'
    const headers = [
      { key: 'caseNo', display: 'Case No' },
      { key: 'caseDate', display: 'Case Date' },
      { key: 'vendorName', display: 'Vendor Name' },
      { key: 'BankName', display: 'Bank Agency' },
      { key: 'productName', display: 'Product' },
      { key: 'segmentName', display: 'Segment' },
      { key: 'customerName', display: 'Customer Name' },
      { key: 'fieldExecutiveName', display: 'Executive Name' },
    ]

    pdfBackOffice({ data: _tableData, headers, filename })
  }
  const exportEXCEL = () => {
    const header = ["Case No", "Case Date", "Vendor Name", "Bank Agency", "Product", "Segment", "Customer Name", "Executive Name"];
    let body: any = _tableData.map((e: any) => { return [e.caseNo, e.caseDate, e.vendorName, e.BankName, e.productName, e.segmentName, e.customerName, e.fieldExecutiveName] })
    var curDate = moment()?.format("YYYY-MM-DD")
    var filename = 'BackOffice_PreCase_' + curDate;
    downloadExcel({
      fileName: filename,
      sheet: "BackOffice_PreCase",
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  const MisDownload = () => {
    debugger;
    var PageName = "BackOfficeTray";
    var UserID = user.id;
    var caseType = "precase";

    getMISforXcellDownload(caseType, UserID, PageName, user?.CorporateDetailsId).then((response) => {
      if (response.data.status === "Success") {
        debugger;
        _setmisreportExport(response.data.data);

      }
    })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const exportMISEXCEL = () => {
    debugger;
    const header = ["CaseNo", "vendorName", "bankName", "branchName", "LoanAmount", "BankEmployeeName", "DsaDesName", "productName", "BankReferenceNo",
      "TypeOfSampling", "CustomerName", "CustomerCity", "CustomerPincode", "FathersName", "DateOfBirth", "EmployeType", "CompanyName", "OfficeCity",
      "OfficePincode", "Designation", "OfficeTrigger", "ResidencyTrigger", "CoAppTrigger", "CoAppOfficeTrigger", "GuarantorTrigger", "GuarantorOfficeTrigger",
      "QcresiRemarks", "QcofficeRemarks", "QccoAppOfficeRemarks", "QcguaOfficeRemarks", "QcguaResiRemarks", "CaseDate", "QcCloseDate",
      "AppFieldStaffStatusName", "AppOfficeFieldStaffStatusName", "CoAppFieldStaffStatusName", "CoAppOfficeFieldStaffStatusName",
      "GuarantorFieldStaffStatusName",
      "ApplicantFieldStaffName", "ApplicantOfficeFieldStaffName", "CoAppFieldStaffName", "CoAppOfficeFieldStaffName", "GuarantorFieldStaffName", "GuarantorOfficeFieldStaffName",

      "AppBoLocation", "OfficeAppBoLocation", "CoappBoLocation", "CoappOfficeBoLocation", "GuaBoLocation", "GuaBoOfficeLocation",
      "Resikm", "Officekm", "CoAppkm", "CoAppOfficekm", "Guakm", "GuaOfficekm", "ResiBokm", "OfficeBokm", "CoAppBokm", "CoAppOfficeBokm", "GuaBokm", "GuaOfficeBokm",];
    let body: any = _misreportExport.map((e: any) => {
      return [e.CaseNo, e.vendorName, e.bankName, e.branchName, e.LoanAmount, e.BankEmployeeName, e.DsaDesName, e.productName, e.BankReferenceNo,
      e.TypeOfSampling, e.CustomerName, e.CustomerCity, e.CustomerPincode, e.FathersName, e.DateOfBirth, e.EmployeType, e.CompanyName, e.OfficeCity,
      e.OfficePincode, e.Designation, e.OfficeTrigger, e.ResidencyTrigger, e.CoAppTrigger, e.CoAppOfficeTrigger, e.GuarantorTrigger, e.GuarantorOfficeTrigger,
      e.QcresiRemarks, e.QcofficeRemarks, e.QccoAppOfficeRemarks, e.QcguaOfficeRemarks, e.QcguaResiRemarks, e.CaseDate, e.QcCloseDate,
      e.AppFieldStaffStatusName, e.AppOfficeFieldStaffStatusName, e.CoAppFieldStaffStatusName, e.CoAppOfficeFieldStaffStatusName,
      e.GuarantorFieldStaffStatusName,
      e.ApplicantFieldStaffName, e.ApplicantOfficeFieldStaffName, e.CoAppFieldStaffName, e.CoAppOfficeFieldStaffName, e.GuarantorFieldStaffName, e.GuarantorOfficeFieldStaffName,
      e.AppBoLocation, e.OfficeAppBoLocation, e.CoappBoLocation, e.CoappOfficeBoLocation, e.GuaBoLocation, e.GuaBoOfficeLocation,
      e.Resikm, e.Officekm, e.CoAppkm, e.CoAppOfficekm, e.Guakm, e.GuaOfficekm, e.ResiBokm, e.OfficeBokm, e.CoAppBokm, e.CoAppOfficeBokm, e.GuaBokm, e.GuaOfficeBokm,]
    })
    var curDate = moment()?.format("YYYY-MM-DD")
    var filename = 'Backoffice_Precase_MIS_Report_' + curDate;
    downloadExcel({
      fileName: filename,
      sheet: "Product_Master",
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = _orderBy === property && _order === 'asc';
    _setOrder(isAsc ? 'desc' : 'asc');
    _setOrderBy(property);
  };

  useEffect(() => {
    debugger;
    const data = sortTableData(_tableData, _order, _orderBy);
    _setTableData(data)
  }, [_order, _orderBy])


  return (
    <>
      <div className={`d-flex justify-content-between align-items-center bg-primary text-white p-3 ${user?.BankId ? 'roundedTop' : ''}`}>
        <div>
          <h5 className="mb-0">
            <span className="mx-2">
              <img src={inputdataicon} />
            </span>
            Back Office Tray-Pre Case
          </h5>
        </div>
      </div>
      <div className="bg-white p-3 content-area">
        <div
          className={`text-white mb-3 mt-3 px-4 d-flex align-items-center bg-primary ${user?.BankId ? 'rounded--1 py-2' : ''}`}
          style={{ borderRadius: "2px" }}
        >
          <TabContext value={""}>
            <TabList aria-label="lab API tabs example">
              <Tab
                label="Pre Case Details"
                className={clsx(
                  count === 1 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(1)}
              />
              <Tab
                label="Pre Case Documents"
                className={clsx(
                  count === 2 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(2)}
              />
              <Tab
                label="Inspection Details"
                className={clsx(
                  count === 3 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(3)}
              />
              <Tab
                label="Images"
                className={clsx(
                  count === 4 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(4)}
              />
            </TabList>
          </TabContext>
        </div>
        <div>
          {count === 1 && <Backofficetray />}
          {count === 2 && <Postcasedocument />}
          {count === 3 && <PostbackofficeTray />}
          {count === 4 && <Postimages />}

          <div className="row">
            <div className=" col-md-8 text-end pe-3 d-flex justify-content-end">
              {(user?.BankId != null && user?.BankId != 0) && <Button
                className="bg-danger text-white me-2"
                sx={{ width: 140, p: 2 }}
                onClick={() => navigate(routerPath?.dashboard)}
              >Close <Close className="h-15" />
              </Button>}

              {count === 1 ? (
                ""
              ) : (
                <Button
                  className="bg-secondary text-white mx-5"
                  sx={{ width: 170, padding: 2 }}
                  onClick={() => setCount(count - 1)}
                >
                  <ArrowBackIosIcon className="h-15" /> Back
                </Button>
              )}
              <Button
                className={clsx(
                  count === 4 ? "d-none" : "bg-success text-white"
                )}
                sx={{ width: 170, p: 2 }}
                onClick={() => setCount(count + 1)}
              >
                Next <ArrowForwardIosIcon className="h-15" />
              </Button>

              {!(user?.BankId != null && user?.BankId != 0) && count === 3 &&
                <LoadingButton disabled={!_save} className={clsx(`${_save && !_loading ? 'bg-success' : 'bg-light-1 text-muted'} text-white mx-4 px-4`)} loadingPosition="end" endIcon={<></>}
                  loading={_loading} fullWidth sx={{ width: 130, p: 2 }}
                  onClick={() => onSaveClick('Save')}>Save</LoadingButton>
              }

              {!(user?.BankId != null && user?.BankId != 0) && count === 4 && (
                <LoadingButton disabled={!_submit} className={clsx(`${_submit && !_loading ? 'bg-success' : 'bg-light-1 text-muted'} text-white`)} loadingPosition="end" endIcon={<></>}
                  loading={_loading} fullWidth sx={{ width: 170, p: 2 }}
                  onClick={() => onSaveClick('Update')}>Submit</LoadingButton>
              )}
              {!(user?.BankId != null && user?.BankId != 0) && <Button className="bg-danger text-white ms-2" sx={{ width: 170, p: 2 }}
                onClick={refreshPage}>Clear <DoDisturb className="h-15" /></Button>}
            </div>
          </div>
        </div>
        <hr />
        {/* coming soon */}
        {/* <Outlet/> */}
        {!(user?.BankId != null && user?.BankId != 0) && <>
          <div className="bg-primary py-2 mt-5 gx-0 text-white fw-bold ps-3 row d-flex align-items-center justify-content-around">
            <div className="col-md-6 fs-4">Back Office Tray</div>
            <div className="col-md-6 px-2 d-flex justify-content-around">
              <input
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search"
                className="my-2 px-2 rounded-2 w-100 border border-white"
              />
              {/* <Button>
                <img
                  src={PDF}
                  alt=""
                  className="text-end mx-1"
                  role="button"
                  style={{ width: "3rem" }} onClick={exportPDF}
                />
              </Button> */}
              <Button>
                <img
                  src={Excel}
                  onClick={exportEXCEL}
                  className="text-end mx-1"
                  alt=""
                  role="button"
                  style={{ width: "3rem" }}
                />
              </Button>
              <AssignmentReturnedIcon onClick={exportMISEXCEL} className='' style={{ fontSize: "3.5rem" }} />
            </div>
          </div>
          <TableContainer component={Paper}>
            <div className="row mt-4 mx-2">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-2 text-center d-flex align-items-center">Start Date</div>
                  <div className="col-md-3 p-0" style={{ marginLeft: "-1rem", borderRight: "1px solid black" }}>
                    <TextField
                      size='small'
                      id="date" autoComplete='off'
                      label=" "
                      type="date"
                      //  defaultValue="2017-05-24"
                      value={_fromDate}
                      onChange={(e: any) => _setFromDate(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                    />
                  </div>

                  <div className="col-md-2 d-flex align-items-center text-center">End Date</div>
                  <div className="col-md-3 p-0" style={{ marginLeft: "-2rem" }}>
                    <TextField
                      size='small'
                      id="date" autoComplete='off'
                      label=" "
                      type="date"
                      defaultValue={today}
                      // defaultValue="2017-05-24"
                      value={_toDate}
                      onChange={(e: any) => _setToDate(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                    />
                  </div>
                  <div className="col-md-2">
                    <Button className='border rounded bg-primary text-white' onClick={dateFilter}>
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <SortableTableHead
                order={_order}
                orderBy={_orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  { id: '', label: 'View', align: 'center' },
                  { id: 'Status', label: 'Status', align: 'center' },
                  // { id: 'caseNo', label: 'Case No', align: 'left' },
                  { id: 'caseDate', label: 'Case Date', align: 'center' },
                  // { id: 'vendorName', label: 'Vendor Name', align: 'center' },
                  { id: 'BankName', label: 'Bank Agency', align: 'center' },
                  { id: 'productName', label: 'Product Name', align: 'center' },
                  { id: '', label: 'Query', align: 'center' },
                  { id: 'customerName', label: 'Customer Name', align: 'center' },
                  { id: '', label: 'Executive Name', align: 'center' }
                ]}
              />
              <TableBody>
                {_tableData?.length > 0 ?
                  _tableData?.filter((content: any) =>
                    // `${content?.caseNo}`?.toLowerCase().includes(`${query}`?.toLowerCase()) 
                    // || `${content?.vendorName}`?.toLowerCase().includes(`${query}`?.toLowerCase()) 
                    `${content?.CaseDate}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.BankName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.ProductName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.SegmentName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.CustomerName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.fieldExecutiveName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.ResiQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.OfficeQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.CoAppResiQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.CoAppOfficeQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.GuaResiQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.GuaOfficeQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.ResidenceFieldExecutiveName + ' ' +
                      content?.OfficeFieldExecutiveName + ' ' +
                      content?.CoAppFieldStaffName + ' ' +
                      content?.CoOfficeFieldStaffName + ' ' +
                      content?.GaurantorFieldStaffName + ' ' +
                      content?.GaurantorOfficeFieldStaffName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                  )
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item: any, index: any) => (
                      <TableRow
                        key={index + 1}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            className="bg-success"
                            style={{ borderRadius: "10px" }}
                            onClick={() => viewData(item)}
                            type="submit"
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          {/* <Button
                      variant="contained"
                      className="bg-success"
                      style={{ borderRadius: "10px" }}
                      type="submit"
                    >
                      Verification Started
                    </Button> */}
                          {item?.StatusName === "Completed" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Completed
                            </Button>
                          )}
                          {item?.StatusName === "Completed-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-info text-white px-4  text-nowrap"
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Completed TAT Breached
                            </Button>
                          )}
                          {item?.StatusName === "In Progress" && (
                            <Button
                              variant="contained"
                              className="bg-primary px-4  "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              InProgress
                            </Button>
                          )}

                          {item?.StatusName === "In Progress-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              In Progress-TAT Breached
                            </Button>
                          )}
                          {item?.StatusName === "Cancelled-Reassign" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Cancelled-Reassign
                            </Button>
                          )}
                          {item?.StatusName === "Verification Started" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Verification Started
                            </Button>
                          )}
                          {item?.StatusName === "QC Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              QC Tray Pending
                            </Button>
                          )}
                          {item?.StatusName === "B.O Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              B.O Tray Pending
                            </Button>
                          )}
                          {item?.StatusName === "B.O. Tray - TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              B.O. Tray - TAT Breached
                            </Button>
                          )}

                        </TableCell>

                        {/* <TableCell component="th" scope="row">{item?.caseNo}</TableCell> */}
                        <TableCell className="text-nowrap" align="center">{item?.CaseDate?.split("T")[0]}</TableCell>
                        {/* <TableCell align="center">{item.vendorName}</TableCell> */}
                        <TableCell align="center">{item?.BankName}</TableCell>
                        <TableCell align="center">{item?.ProductName}</TableCell>
                        <TableCell align="center" sx={{ minWidth: 200 }}>
                          {item?.ResiQuery?.length > 0 ? <span>{item?.ResiQuery}, </span> : ''}
                          {item?.OfficeQuery?.length > 0 ? <span>{item?.OfficeQuery}, </span> :
                            <span></span>}
                          {item?.CoAppResiQuery?.length > 0 ? <span>{item?.CoAppResiQuery}, </span> :
                            <span></span>}
                          {item?.CoAppOfficeQuery?.length > 0 ? <span>{item?.CoAppOfficeQuery}, </span> :
                            <span></span>}
                          {item?.GuaResiQuery?.length > 0 ? <span>{item?.GuaResiQuery}, </span> :
                            <span></span>}
                          {item?.GuaOfficeQuery?.length > 0 ? <span>{item?.GuaOfficeQuery}</span> :
                            <span></span>}
                        </TableCell>
                        <TableCell align="center">{item?.CustomerName}</TableCell>
                        <TableCell align="center">
                          {`${item?.ResidenceFieldExecutiveName?.length > 0 ? item?.ResidenceFieldExecutiveName + ', ' : ''} 
                            ${item?.OfficeFieldExecutiveName?.length > 0 ? item?.OfficeFieldExecutiveName + ', ' : ''}
                            ${item?.CoAppFieldStaffName?.length > 0 ? item?.CoAppFieldStaffName + ', ' : ''}
                            ${item?.CoOfficeFieldStaffName?.length > 0 ? item?.CoOfficeFieldStaffName + ', ' : ''}
                            ${item?.GaurantorFieldStaffName?.length > 0 ? item?.GaurantorFieldStaffName + ', ' : ''}
                            ${item?.GaurantorOfficeFieldStaffName?.length > 0 ? item?.GaurantorOfficeFieldStaffName + ', ' : ''}`}
                        </TableCell>

                      </TableRow>
                    ))
                  : !_tableData && (
                    <TableRow key={0}>
                      <TableCell align={"center"}>
                        <h3 className="text-muted">Data Not Found</h3>
                      </TableCell>
                    </TableRow>
                  )}
                <SkeletonProviderTables columns={8} visible={_tableLoading} />
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={_tableData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>}
      </div>
    </>
  );
}

export default Index;