import { useEffect, useState } from 'react';
import {
  Checkbox, FormControl, MenuItem, Radio, Select, TextField, Button,
  IconButton, FormHelperText, FormControlLabel
} from '@mui/material';
import { useStateValue } from '../../../providers/stateProvider';
import {
  getEmployeeTypes, getEmployeeDocTypes, getStateDetails, getCustomerDocTypes,
  deleteDocument, getFieldExecutiveName, getAllHubMaster, getQueryList, getFieldStaffList, getAllStaffDetails
} from '../../../Models/configapi';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import swal from 'sweetalert';
import { getVerificationTypeMaster, InsertDocument } from "../../../Models/model";
import { AddCircleOutline, Cancel, CloudUpload, Visibility } from '@mui/icons-material';

function Customerdetails() {
  const [{ user, postCaseData, postCaseValidation }, dispatch]: any = useStateValue();
  const [_formData, _setFormData] = useState<any>({
    gaurantorAddressType: '',
    gaurantorName: '',
    gaurantorAddress_1: '',
    gaurantorAddress_2: '',
    gaurantorCity: '',
    gaurantorPincode: '',
    gaurantorState: '',
    gaurantorMobileNo: '',
    gaurantorEmail: '',
    GuarantorTrigger: '',
    gaurantorHubId: 0,
    guarantorFieldStaffName: '',


    guarantorofficeName: '',
    guarantorofficeAddress1: '',
    guarantorofficeAddress2: '',
    guarantorofficeCity: '',
    guarantorofficeState: '',
    guarantorofficePincode: '',
    guarantorofficeLandMark: '',
    guarantorofficeLandLine: '',
    guarantorofficeMobileNumber: '',
    guarantordesignation: '',
    guarantoremployeeTypeId: 0,
    guarantorEmployeeDocumnetTypeId: 0,

    GuarantorOfficeTrigger: '',
    gaurantorOfficeHubId: 0,
    gaurantorOfficeFieldStaffName: '',

    guarantorDocumentProperties: [],
    guarantorOfficeDocumentProperties: [],

    GuaQueryID: 0,
    GuaOfficeQueryID: 0,
    guarantorVerificationField: false,
    guarantorVerificationAuthority: false,
    guarantorAppVerificationTypeID: true,
    guarantorDocumentCheck: false,
    guarantorProfileCheck: false,

    guarantorOfficeVerificationField: false,
    guarantorOfficeVerificationAuthority: false,
    guarantorAppOfficeVerificationTypeID: true,
    guarantorOfficeDocumentCheck: false,
    guarantorOfficeProfileCheck: false

  });

  const onFormChange = (name: string, value: any) => {
    debugger
    if (name === "gaurantorPincode") {
      _setFormData({ ..._formData, guarantorFieldStaffName: "", gaurantorHubId: 0, guarantorFieldStaff: 0 });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, guarantorFieldStaffName: "", gaurantorHubId: 0, guarantorFieldStaff: 0, [name]: value } });
    }
    else if (name === 'guarantorofficePincode') {
      _setFormData({ ..._formData, gaurantorOfficeFieldStaffName: "", gaurantorOfficeHubId: 0, guarantorOfficeFieldStaff: 0 });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, gaurantorOfficeFieldStaffName: "", gaurantorOfficeHubId: 0, guarantorOfficeFieldStaff: 0, [name]: value } });
    }
    else if (name === 'guarantorVerificationField' || name === 'guarantorProfileCheck' || name === 'guarantorDocumentCheck') {
      _setFormData({ ..._formData, guarantorFieldStaffName: "", gaurantorHubId: 0, guarantorFieldStaff: 0 });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, guarantorFieldStaffName: "", gaurantorHubId: 0, guarantorFieldStaff: 0, [name]: value } });
    }
    else if (name === 'guarantorOfficeVerificationField' || name === 'guarantorOfficeProfileCheck' || name === 'guarantorOfficeDocumentCheck') {
      _setFormData({ ..._formData, gaurantorOfficeFieldStaffName: "", gaurantorOfficeHubId: 0, guarantorOfficeFieldStaff: 0 });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, gaurantorOfficeFieldStaffName: "", gaurantorOfficeHubId: 0, guarantorOfficeFieldStaff: 0, [name]: value } });
    }
    else {
      _setFormData({ ..._formData, [name]: value });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
    }
  };

  useEffect(() => {
    if (Object.entries(postCaseData)?.length) {
      _setFormData({ ...postCaseData });
      if (postCaseData?.guarantorDocumentProperties?.length) {
        _setguarantorResidentialDoc([...postCaseData?.guarantorDocumentProperties?.map((item: any) => { return { ...item, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID } })])
      } else {
        _setguarantorResidentialDoc([{ documentNumber: 0, documentName: '', documentTypeId: 4, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }])
      }
      if (postCaseData?.guarantorOfficeDocumentProperties?.length) {
        _setguarantorOfficeDoc([...postCaseData?.guarantorOfficeDocumentProperties?.map((item: any) => { return { ...item, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID } })])
      } else {
        _setguarantorOfficeDoc([{ documentNumber: 0, documentName: '', documentTypeId: 4, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }])
      }
    }
  }, [postCaseData]);


  // *************************

  const [_StateList, _setStateList] = useState<any>([]);
  const [_EmployeeType, _setEmployeeType] = useState<any>([]);
  const [_EmployeeDocType, _setEmployeeDocType] = useState<any>([]);
  const [_EmployeeDocDisplay, _setEmployeeDocDisplay] = useState(false);

  const [_fieldList, _setFieldList] = useState<any>([]);

  // const [_verificationType, _setVerificationType] = useState('');
  const [_hubList, _setHubList] = useState<any>([]);
  const [_desList, _setDesList] = useState<any>([]);
  const [_queryList, _setQueryList] = useState<any>([]);
  const [_officeQueryList, _setOfficeQueryList] = useState<any>([]);
  // const [_checkMark, _setCheckMark] = useState<any>({
  //     profile: false, document: false, panCard: false,
  //     drivingLicense: false, voterId: false, guarantorDocumentCheck: false, guarantorProfileCheck: true
  // });
  // const [_verificationTypeOffice, _setVerificationTypeOffice] = useState('');
  // const [_checkMarkOffice, _setCheckMarkOffice] = useState<any>({
  //     profile: false, document: false, panCard: false,
  //     drivingLicense: false, voterId: false, guarantorOfficeDocumentCheck: false, guarantorOfficeProfileCheck: true
  // });

  const [_guarantorResidentialDoc, _setguarantorResidentialDoc] = useState([
    { documentNumber: 0, documentName: '', documentTypeId: 4, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }]);
  const [_guarantorOfficeDoc, _setguarantorOfficeDoc] = useState([
    { documentNumber: 0, documentName: '', documentTypeId: 4, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }]);

  const [_profileDocType, _setProfileDocType] = useState<any>([]);
  const [_authorityDocType, _setAuthorityDocType] = useState<any>([]);

  // const onChangeCheckMarkOffice = (key: any, value: any) => {
  //     _setCheckMarkOffice({ ..._checkMarkOffice, [key]: value })
  // }
  // const onChangeCheckMark = (key: any, value: any) => {
  //     _setCheckMark({ ..._checkMark, [key]: value })
  // }

  useEffect(() => {
    // let tempData = JSON.parse(localStorage.getItem('officeDetail') || '{}');
    // _setFormData({ ..._formData, ...tempData });
    getStateList();
    getEmployeeTypeDetail();
    getEmployeeDocumentTypeDetail();
    getHubList();
  }, []);

  const onEmployeeTypeChange = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
    dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
    if (Number(value) === 1) {
      _setEmployeeDocDisplay(true);
    }
    else {
      _setEmployeeDocDisplay(false);
    }
  };


  const getEmployeeTypeDetail = () => {
    getEmployeeTypes(user.CorporateDetailsId)
      .then((response) => {
        debugger;
        //console.log(response.data);
        _setEmployeeType(response.data.employeeTypeDetails);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getEmployeeDocumentTypeDetail = () => {
    getEmployeeDocTypes(user?.CorporateDetailsId)
      .then((response) => {
        debugger;
        _setEmployeeDocType(response.data.data.CustomerDocumentTypeDetails);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getStateList = () => {
    getStateDetails(user?.CorporateDetailsId)
      .then((response) => {
        console.log(response.data);
        _setStateList(response.data.StateDetails
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };


  const [_verificationTypeList, _setVerificationTypeList] = useState<any>([]);

  const getVerificationList = () => {
    getVerificationTypeMaster()
      .then((response) => {
        _setVerificationTypeList([...response?.data?.data?.data]);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getVerificationList()
    getCustomerDocTypes('Field', user?.CorporateDetailsId)
      .then((response) => {
        _setProfileDocType(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    getCustomerDocTypes('Authority', user?.CorporateDetailsId)
      .then((response) => {
        _setAuthorityDocType(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  const getHubList = () => {
    getAllHubMaster(user.CorporateDetailsId)
      .then((response: any) => {
        console.log(response?.data?.data);
        _setHubList([...response?.data?.data?.data]);
      }).catch(error => {
        console.log(error.response.data);
      })
    getQueryList("Resi", user.CorporateDetailsId)
      .then((response: any) => {
        console.log(response?.data?.data);
        _setQueryList([...response?.data?.data]);
      }).catch(error => {
        console.log(error.response.data);
      })
    getQueryList("Office", user.CorporateDetailsId)
      .then((response: any) => {
        console.log(response?.data?.data);
        _setOfficeQueryList([...response?.data?.data]);
      }).catch(error => {
        console.log(error.response.data);
      })
    getAllStaffDetails(user?.CorporateDetailsId)
      .then((response: any) => {
        console.log(response?.data);
        _setFieldList([...response?.data?.Data]);
      }).catch(error => {
        console.log(error.response.data);
      })
  }
  const onChangeGuarantorResDropdownChange = (key: any, value: any, index: any) => {
    let _tempDoc = [..._guarantorResidentialDoc];
    if (key === 'VerificationPerson') {
      _tempDoc[index] = { ..._tempDoc[index], [key]: value, customerDocumentTypeId: 0 };
    } else {
      _tempDoc[index] = { ..._tempDoc[index], [key]: value };
    }
    _setguarantorResidentialDoc(_tempDoc);
    onFormChange('guarantorDocumentProperties', _tempDoc);
  }

  const onChangeResidentialPicture = (e: any, index: any, typeId: any) => {
    let _tempDoc = [..._guarantorResidentialDoc];

    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append('DocumentTypeId', '6');
      formData.append('UserId', user?.id);
      formData.append('image', e.target.files[0]);
      console.log("picture: ", e.target.files);
      console.log("imgUrl: ", URL.createObjectURL(e.target.files[0]));
      InsertDocument(formData)
        .then(response => {
          console.log(response.data);
          if (response.data.status === "Success") {

            _tempDoc[index] = {
              ..._tempDoc[index],
              documentNumber: 5,
              documentName: response.data.data.FileName,
              documentPath: response.data.data.physicalPath,
              documentTypeId: 6,
              customerDocumentTypeId: Number(typeId) || 0
            };
            onFormChange('guarantorDocumentProperties', _tempDoc);
            _setguarantorResidentialDoc(_tempDoc);

          }
        }).catch(error => {
          console.log(error.response.data);
        })

    }
  }
  const removeGuarantorResidentiaDoc = (index: any) => {
    var data; var imageId: any;
    //if (_guarantorResidentialDoc.length > 1) {
    let _tempDoc = [..._guarantorResidentialDoc];
    if (postCaseData?.precaseid !== undefined) {
      data = {
        id: _tempDoc[index].id,
        isActive: 0,
        type: "case",
        typeofcase: "precase",
        caseid: postCaseData?.precaseid,
        documentTypeId: 1
      };
      imageId = _tempDoc[index].id;
    }
    else {
      data = {
        id: _tempDoc[index].id,
        isActive: 0,
        type: "case",
        typeofcase: "precase",
        caseid: 0,
        documentTypeId: 1
      };
      imageId = _tempDoc[index].id;
    }

    deleteDocument(data)
      .then((response) => {
        if (response.data.status === "Success") {
          swal(response.data.data, { icon: "success" });
          const ArrayData = _tempDoc.filter((_tempDoc) => _tempDoc.id !== imageId);
          _setguarantorResidentialDoc(ArrayData);

        } else if (response.data.status === "Error") {
          swal(response.data.data, { icon: "warning" });
        }
      })
      .catch((error) => {
        swal(error.response.data, { icon: "warning" });
      });
    // }
    // else {
    //   swal("Unable to delete.", { icon: "warning" });
    // }
    return;
  }
  const addGuarantorNewImg = (e: any) => {
    let values = [..._guarantorResidentialDoc];
    values.push({
      documentNumber: 5,
      documentName: '',
      documentTypeId: 6,
      documentPath: '',
      reftype: '',
      id: values.length,
      customerDocumentTypeId: 0,
      VerificationPerson: ''
    });
    _setguarantorResidentialDoc(values);
  }

  const onResHubIdChange = (name: string, value: any) => {

    var vPincode = _formData.gaurantorPincode;
    if (vPincode !== undefined && vPincode !== "") {
      getResFieldExecutiveNames(value, vPincode);
    }
    else {
      _setFormData({ ..._formData, [name]: value });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
      swal("Please enter the pincode.", { icon: 'warning' });
    }
  };
  const getResFieldExecutiveNames = (HubId: any, pincode: any) => {

    var data = {
      HubId: HubId,
      pincode: pincode,
      lCorporateId: user?.CorporateDetailsId
    }
    getFieldExecutiveName(data)
      .then((response) => {
        debugger;
        if (response.data.data.length > 0) {
          var exename = response.data.data[0].FirstName + " " + response.data.data[0].LastName;
          _setFormData({ ..._formData, guarantorFieldStaffName: exename, gaurantorHubId: HubId, guarantorFieldStaff: response.data.data[0].Id });
          dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, guarantorFieldStaffName: exename, gaurantorHubId: HubId, guarantorFieldStaff: response.data.data[0].Id } });
        }
        else {
          _setFormData({ ..._formData, guarantorFieldStaffName: "", gaurantorHubId: HubId, guarantorFieldStaff: 0 });
          dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, guarantorFieldStaffName: "", gaurantorHubId: HubId, guarantorFieldStaff: 0 } });
          swal("No field executive assigned.", { icon: 'warning' });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const onOfficeHubIdChange = (name: string, value: any) => {

    var vPincode = _formData.guarantorofficePincode;
    if (vPincode !== undefined && vPincode !== "") {
      getOfficeFieldExecutiveNames(value, vPincode);
    }
    else {
      _setFormData({ ..._formData, [name]: value });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
      swal("Please enter the pincode.", { icon: 'warning' });
    }
  };
  const getOfficeFieldExecutiveNames = (HubId: any, pincode: any) => {

    var data = {
      HubId: HubId,
      pincode: pincode,
      lCorporateId: user?.CorporateDetailsId
    }
    getFieldExecutiveName(data)
      .then((response) => {
        debugger;
        console.log(response.data.data);
        var fieldExeName = response.data.data;
        if (fieldExeName.length > 0) {
          var exename = response.data.data[0].FirstName + " " + response.data.data[0].LastName;
          _setFormData({ ..._formData, gaurantorOfficeFieldStaffName: exename, gaurantorOfficeHubId: HubId, guarantorOfficeFieldStaff: response.data.data[0].Id });
          dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, gaurantorOfficeFieldStaffName: exename, gaurantorOfficeHubId: HubId, guarantorOfficeFieldStaff: response.data.data[0].Id } });
        }
        else {
          _setFormData({ ..._formData, gaurantorOfficeFieldStaffName: "", gaurantorOfficeHubId: HubId, guarantorOfficeFieldStaff: 0 });
          dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, gaurantorOfficeFieldStaffName: "", gaurantorOfficeHubId: HubId, guarantorOfficeFieldStaff: 0 } });
          swal("No field executive assigned.", { icon: 'warning' });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  const onChangeGuarantorOfficeDropdownChange = (key: any, value: any, index: any) => {
    let _tempDoc = [..._guarantorOfficeDoc];
    if (key === 'VerificationPerson') {
      _tempDoc[index] = { ..._tempDoc[index], [key]: value, customerDocumentTypeId: 0 };
    } else {
      _tempDoc[index] = { ..._tempDoc[index], [key]: value };
    }
    _setguarantorOfficeDoc(_tempDoc);
    onFormChange('guarantorOfficeDocumentProperties', _tempDoc);
  }
  const onChangeOfficePicture = (e: any, index: any, typeId: any) => {
    let _tempDoc = [..._guarantorOfficeDoc];

    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append('DocumentTypeId', '8');
      formData.append('UserId', user?.id);
      formData.append('image', e.target.files[0]);
      console.log("picture: ", e.target.files);
      console.log("imgUrl: ", URL.createObjectURL(e.target.files[0]));
      InsertDocument(formData)
        .then(response => {
          console.log(response.data);
          if (response.data.status === "Success") {
            _tempDoc[index] = {
              ..._tempDoc[index],
              documentNumber: 6,
              documentName: response.data.data.FileName,
              documentPath: response.data.data.physicalPath,
              documentTypeId: 8,
              customerDocumentTypeId: Number(typeId) || 0
            };
            onFormChange('guarantorOfficeDocumentProperties', _tempDoc);
            _setguarantorOfficeDoc(_tempDoc);

          }
        }).catch(error => {
          console.log(error.response.data);
        })

    }
  }
  const removeGuarantorOfficeDoc = (index: any) => {
    var data; var imageId: any;
    //if (_guarantorOfficeDoc.length > 1) {
    let _tempDoc = [..._guarantorOfficeDoc];
    if (postCaseData?.precaseid !== undefined) {
      data = {
        id: _tempDoc[index].id,
        isActive: 0,
        type: "case",
        typeofcase: "precase",
        caseid: postCaseData?.precaseid,
        documentTypeId: 1
      };
      imageId = _tempDoc[index].id;
    }
    else {
      data = {
        id: _tempDoc[index].id,
        isActive: 0,
        type: "case",
        typeofcase: "precase",
        caseid: 0,
        documentTypeId: 1
      };
      imageId = _tempDoc[index].id;
    }

    deleteDocument(data)
      .then((response) => {
        if (response.data.status === "Success") {
          swal(response.data.data, { icon: "success" });
          const ArrayData = _tempDoc.filter((_tempDoc) => _tempDoc.id !== imageId);
          _setguarantorOfficeDoc(ArrayData);

        } else if (response.data.status === "Error") {
          swal(response.data.data, { icon: "warning" });
        }
      })
      .catch((error) => {
        swal(error.response.data, { icon: "warning" });
      });
    // }
    // else {
    //   swal("Unable to delete.", { icon: "warning" });
    // }
    //console.log(_fields);
    return;
  }
  const addNewImgOffice = (e: any) => {
    let values = [..._guarantorOfficeDoc];
    values.push({
      documentNumber: 6,
      documentName: '',
      documentTypeId: 8,
      documentPath: '',
      reftype: '',
      id: values.length,
      customerDocumentTypeId: 0,
      VerificationPerson: ''
    });
    _setguarantorOfficeDoc(values);
  }

  return (
    <>
      <div className="bg-white p-3 content-area">
        <div className="row">
          <div className="col-md-6">
            <div className="text-center fw-bold">Guarantor Details</div>
            <div className="row">
              <div className='mb-3'>
                <div>
                  <label className="form-label mb-2">Guarantor Name</label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off' label=" " InputLabelProps={{ shrink: false }} value={_formData?.gaurantorName} onChange={(e: any) => onFormChange('gaurantorName', e.target.value)} variant="outlined" className='w-100'
                    error={postCaseValidation?.gaurantorName?.error} helperText={postCaseValidation?.gaurantorName?.message} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-4">
                <div>
                  <label className="form-label mb-2">Guarantor Residential Address </label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off' label=" " value={_formData?.gaurantorAddress_1} onChange={(e: any) => onFormChange('gaurantorAddress_1', e.target.value)} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                    error={postCaseValidation?.gaurantorAddress_1?.error} helperText={postCaseValidation?.gaurantorAddress_1?.message} multiline />
                </div>
              </div>
            </div>
            {/* <div className="row">
                            <div className="mb-4">
                                <div>
                                    <label className="form-label mb-2">Guarantor Office Address </label>
                                </div>
                                <div>
                                    <TextField size="small" label=" " value={_formData?.gaurantorAddress_2} onChange={(e: any) => onFormChange('gaurantorAddress_2', e.target.value)} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                                        error={postCaseValidation?.gaurantorAddress_2?.error} helperText={postCaseValidation?.gaurantorAddress_2?.message} multiline />
                                </div>
                            </div>
                        </div> */}
            <div className="row">
              <div className='mb-4'>
                <div>
                  <label className="form-label mb-2">Guarantor City</label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off' label=" " value={_formData?.gaurantorCity} onChange={(e: any) => onFormChange('gaurantorCity', e.target.value)} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                    error={postCaseValidation?.gaurantorCity?.error} helperText={postCaseValidation?.gaurantorCity?.message} />
                </div>
              </div>
              <div className='mb-4'>
                <div>
                  <label className='form-label mb-2'>Guarantor Pincode</label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off' label=" " value={_formData?.gaurantorPincode} onChange={(e: any) => onFormChange('gaurantorPincode', (!isNaN(e.target.value) && e.target.value.length < 7) ? e.target.value.trim(' ') : '')} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                    error={postCaseValidation?.gaurantorPincode?.error} helperText={postCaseValidation?.gaurantorPincode?.message}
                    inputProps={{
                      maxLength: 6,
                    }} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className='mb-4'>
                <div>
                  <label className="form-label mb-2">Guarantor State</label>
                </div>
                <div>
                  <FormControl size="small" className="w-100" sx={{ m: 0 }} error={postCaseValidation?.gaurantorState?.error}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={_formData?.gaurantorState}
                      onChange={(e) =>
                        onFormChange("gaurantorState", e.target.value)
                      }
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {_StateList.map((item: any, index: any) => (
                        <MenuItem value={item?.Statename}>{item?.Statename}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{postCaseValidation?.gaurantorState?.message}</FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className='mb-4'>
                <div>
                  <label className="form-label mb-2">Mobile Number</label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off' label=" " value={_formData?.gaurantorMobileNo} onChange={(e: any) => onFormChange('gaurantorMobileNo', (!isNaN(e.target.value) && e.target.value.length < 11) ? e.target.value.trim(' ') : '')} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                    error={postCaseValidation?.gaurantorMobileNo?.error} helperText={postCaseValidation?.gaurantorMobileNo?.message}
                    inputProps={{
                      maxLength: 10,
                    }} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className='mb-4'>
                <div className="form-label mb-2">Email Address</div>
                <div>
                  <TextField size="small" autoComplete='off' label=" " value={_formData?.gaurantorEmail} onChange={(e: any) => onFormChange('gaurantorEmail', e.target.value)} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                    error={postCaseValidation?.gaurantorEmail?.error} helperText={postCaseValidation?.gaurantorEmail?.message} />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-label mb-2">Trigger<b className="text-danger"></b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" " multiline minRows={2}
                  value={_formData?.GuarantorTrigger}
                  onChange={(e: any) =>
                    onFormChange("GuarantorTrigger", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  error={postCaseValidation?.GuarantorTrigger?.error} helperText={postCaseValidation?.GuarantorTrigger?.message}
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="d-flex justify-content-between">
                <label className="form-label mt-2">Verification Type<b className="text-danger"></b></label>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-6">
                  <Checkbox checked={_formData?.guarantorProfileCheck} onChange={() => onFormChange('guarantorProfileCheck', !_formData?.guarantorProfileCheck)} />
                  <span>Profile</span>
                </div>
                <div className="col-6">
                  <Checkbox checked={_formData?.guarantorDocumentCheck} onChange={() => onFormChange('guarantorDocumentCheck', !_formData?.guarantorDocumentCheck)} />
                  <span>Document</span>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="d-flex flex-wrap">
                {(_formData?.guarantorProfileCheck || _formData?.guarantorDocumentCheck) && <div className="col-6">
                  <FormControl error={postCaseValidation?.guarantorVerificationField.error}><FormControlLabel
                    control={<Checkbox checked={_formData?.guarantorVerificationField} onChange={() => onFormChange('guarantorVerificationField', !_formData?.guarantorVerificationField)} />}
                    label='Field' />
                    <FormHelperText>{postCaseValidation?.guarantorVerificationField?.message}</FormHelperText>
                  </FormControl>
                  {/* <Checkbox checked={_formData?.guarantorVerificationField} onChange={() => onFormChange('guarantorVerificationField', !_formData?.guarantorVerificationField)} />
                  <span>Field</span> */}
                </div>}
                {(_formData?.guarantorProfileCheck || _formData?.guarantorDocumentCheck) && <div className="col-6">
                  <Checkbox checked={_formData?.guarantorVerificationAuthority} onChange={() => onFormChange('guarantorVerificationAuthority', !_formData?.guarantorVerificationAuthority)} />
                  <span>Authority</span>
                </div>}
              </div>
            </div>
            {(_formData?.guarantorProfileCheck || _formData?.guarantorDocumentCheck) && _formData?.guarantorVerificationField && <>
              <div className="mb-3">
                <label className="form-label ">Hub Id</label>
                <div>
                  <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={_formData?.gaurantorHubId}
                      onChange={(e) =>
                        onResHubIdChange("gaurantorHubId", e.target.value)
                      }
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {_hubList.map((item: any, index: any) => (
                        <MenuItem key={index} value={item?.Id}>{item?.HubName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label ">Field Staff Name<b className="text-danger"></b></label>
                <div className="row">
                  <div className="col-6">
                    <TextField size="small" autoComplete='off'
                      label=" " disabled
                      value={_formData?.guarantorFieldStaffName}
                      InputLabelProps={{ shrink: false }}
                      variant="outlined"
                      className="w-100"
                    />
                  </div>
                  {_formData?.precaseid && <div className="col-md-6">
                    <FormControl size="small" className='w-100' sx={{ m: 0 }}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper" value={_formData?.guarantorFieldStaff}
                        onChange={(e: any) => onFormChange('guarantorFieldStaff', e.target.value)}
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                      >
                        {_fieldList.map((item: any, index: any) =>
                          <MenuItem key={index} value={item?.Id}>{item?.staffName}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>}
                </div>
              </div>
            </>}
            <div className="mb-3">
              <label className="form-label ">Query</label>
              <div>
                <FormControl size="small" className='w-100' sx={{ m: 0 }} error={postCaseValidation?.GuaQueryID?.error}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper" value={_formData?.GuaQueryID}
                    onChange={(e: any) => onFormChange('GuaQueryID', e.target.value)}
                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                  >
                    {_queryList?.map((item: any, index: any) =>
                      <MenuItem key={index} value={item?.QueryId}>{item?.QueryName}</MenuItem>
                    )}
                  </Select>
                  <FormHelperText>{postCaseValidation?.GuaQueryID?.message}</FormHelperText>
                </FormControl>
              </div>
            </div>

            <div className='row mt-3'>
              <div className=''>
                <div className='row align-items-center my-2'>
                  <label className="form-label col-md-3">Verification Point</label>
                  <label className="form-label col-md-3">Document Type<b className="text-danger"></b></label>
                  <label className="form-label col-md-3">Verification Type<b className="text-danger"></b></label>
                  <div className='col-md-2'></div>
                  <div className='col-md-1'>
                    <div role="button" onClick={(e: any) => addGuarantorNewImg(e)} >
                      <AddCircleOutlineIcon />
                    </div>
                  </div>
                </div>
                {_guarantorResidentialDoc.map((item: any, index: any) => {
                  return (
                    <div className='row align-items-center my-2'>
                      <div className='col-md-3'>
                        <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={item?.VerificationPerson || ''}
                            onChange={(e) =>
                              onChangeGuarantorResDropdownChange('VerificationPerson', e.target.value, index)
                            }
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            <MenuItem value={'Profile'}>Field</MenuItem>
                            <MenuItem value={'Authority'}>Authority</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div className='col-md-3'>
                        <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={item?.customerDocumentTypeId || 0}
                            onChange={(e) =>
                              onChangeGuarantorResDropdownChange('customerDocumentTypeId', e.target.value, index)
                            }
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            <MenuItem value={0}>Select Document Type</MenuItem>
                            {item?.VerificationPerson === "Profile" &&
                              _profileDocType?.map((item: any, index: any) => (
                                <MenuItem value={item?.Id}>{item?.DocumentType}</MenuItem>
                              ))}
                            {item?.VerificationPerson === "Authority" &&
                              _authorityDocType?.map((item: any, index: any) => (
                                <MenuItem value={item?.Id}>{item?.DocumentType}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-md-3'>
                        <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                          <Select
                            value={item?.verificationtypeId || ''}
                            onChange={(e) =>
                              onChangeGuarantorResDropdownChange('verificationtypeId', e.target.value, index)
                            }
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            {_verificationTypeList?.map((item: any, index: any) =>
                              <MenuItem key={index} value={Number(item?.VerificationId || '0')}>{item?.VerificationName}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-md-2'>
                        {item.documentPath &&
                          <span className="d-flex h-100 align-items-center">
                            <div className='d-flex align-items-center justify-content-center'>
                              <i className='position-absolute'>
                                <IconButton>
                                  <VisibilityIcon onClick={() => window.open(item?.documentPath, "_blank")} className='text-muted' />
                                </IconButton>
                              </i>
                              <img onClick={() => window.open(item?.documentPath, "_blank")} src={item?.documentPath} className='imageFit' style={{ height: "3rem", opacity: "50%" }} alt="documentPath" />
                            </div>
                          </span>
                        }
                        {item.documentPath === "" &&
                          <div className='d-flex align-items-center justify-content-center' style={{ border: "1px solid #d8d8d8", height: "3rem", borderRadius: "10px" }}>
                            <label htmlFor="upload-photo">
                              <CloudUploadIcon className='text-muted' />
                            </label>
                            <input onChange={(e: any) => onChangeResidentialPicture(e, index, item?.customerDocumentTypeId)} id="upload-photo" type="file" style={{ height: "3rem" }} />
                          </div>
                        }
                      </div>
                      <div className='col-md-1'>
                        <CancelIcon style={{ color: "rgb(255, 0, 0)" }} onClick={(e: any) => removeGuarantorResidentiaDoc(index)} role="button" />
                      </div>
                      {/* {_guarantorResidentialDoc?.length === index + 1 && <div role="button" className='col-md-1' onClick={(e: any) => addGuarantorNewImg(e)} >
                        <AddCircleOutlineIcon />
                      </div>} */}
                    </div>
                  )
                }
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-center fw-bold">Office Details</div>
            <div className="row ">
              <div className="mb-3">
                <div>
                  <label className="form-label mb-2">
                    Office Name<b className="text-danger">*</b>
                  </label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off'
                    variant="outlined"
                    value={_formData?.guarantorofficeName}
                    onChange={(e: any) =>
                      onFormChange("guarantorofficeName", e.target.value)
                    }
                    label=" "
                    className="w-100"
                    InputLabelProps={{ shrink: false }}

                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="mb-3">
                <div>
                  <label className="form-label mb-2">
                    Office Address 01<b className="text-danger">*</b>
                  </label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off'
                    variant="outlined"
                    value={_formData?.guarantorofficeAddress1}
                    onChange={(e: any) =>
                      onFormChange("guarantorofficeAddress1", e.target.value)
                    }
                    label=" "
                    className="w-100"
                    InputLabelProps={{ shrink: false }}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="mb-3">
                <div>
                  <label className="form-label mb-2">
                    Office Address 02<b className="text-danger"></b>
                  </label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off'
                    variant="outlined"
                    label=" "
                    value={_formData?.guarantorofficeAddress2}
                    onChange={(e: any) =>
                      onFormChange("guarantorofficeAddress2", e.target.value)
                    }
                    className="w-100"
                    InputLabelProps={{ shrink: false }}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="mb-3">
                <div>
                  <label className="form-label mb-2">
                    Office City<b className="text-danger"></b>
                  </label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off'
                    variant="outlined"
                    label=" "
                    value={_formData?.guarantorofficeCity}
                    onChange={(e: any) =>
                      onFormChange("guarantorofficeCity", e.target.value)
                    }
                    className="w-100"
                    InputLabelProps={{ shrink: false }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <div>
                  <label className="form-label mb-2">
                    Office Pincode<b className="text-danger">*</b>
                  </label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off'
                    variant="outlined"
                    label=" "
                    value={_formData?.guarantorofficePincode}
                    onChange={(e: any) => onFormChange("guarantorofficePincode", (!isNaN(e.target.value) && e.target.value.length < 7) ? e.target.value.trim(' ') : '')}
                    className="w-100"
                    InputLabelProps={{ shrink: false }}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="mb-3">
                <div>
                  <label className="form-label mb-2">
                    Office State<b className="text-danger">*</b>
                  </label>
                </div>
                <div>
                  <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={_formData?.guarantorofficeState}
                      onChange={(e) =>
                        onFormChange("guarantorofficeState", e.target.value)
                      }
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {_StateList.map((item: any, index: any) => (
                        <MenuItem value={item?.Statename}>{item?.Statename}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="mb-3">
                <div>
                  <label className="form-label mb-2">
                    Office Land Mark<b className="text-danger"></b>
                  </label>
                </div>
                <div>
                  <TextField size="small" autoComplete='off'
                    variant="outlined"
                    label=" "
                    value={_formData?.guarantorofficeLandMark}
                    onChange={(e: any) =>
                      onFormChange("guarantorofficeLandMark", e.target.value)
                    }
                    className="w-100"
                    InputLabelProps={{ shrink: false }}
                  />
                </div>
              </div>

              <div className="row ">
                <div className="mb-3">
                  <div>
                    <label className="form-label mb-2">
                      Office Landline<b className="text-danger"></b>
                    </label>
                  </div>
                  <div>
                    <TextField size="small" autoComplete='off'
                      variant="outlined"
                      label=" "
                      value={_formData?.guarantorofficeLandLine}
                      onChange={(e: any) => onFormChange("guarantorofficeLandLine", (!isNaN(e.target.value) && e.target.value.length < 12) ? e.target.value.trim(' ') : '')}
                      className="w-100"
                      InputLabelProps={{ shrink: false }}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div>
                    <label className="form-label mb-2">
                      Office MobileNumber<b className="text-danger"></b>
                    </label>
                  </div>
                  <div>
                    <TextField size="small" autoComplete='off'
                      variant="outlined"
                      label=" "
                      value={_formData?.guarantorofficeMobileNumber}
                      onChange={(e: any) => onFormChange("guarantorofficeMobileNumber", (!isNaN(e.target.value) && e.target.value.length < 11) ? e.target.value.trim(' ') : '')}
                      className="w-100"
                      InputLabelProps={{ shrink: false }}
                    />
                  </div>
                </div>
              </div>

              <div className='row '>
                <div className="mb-4">
                  <div className="from-label mb-2">Office Designation<b className="text-danger"></b></div>
                  <div>
                    <TextField size="small" autoComplete='off' variant="outlined" label=" " value={_formData?.guarantordesignation} onChange={(e: any) => onFormChange('guarantordesignation', e.target.value)}
                      className='w-100' InputLabelProps={{ shrink: false }} />
                  </div>
                </div>

                <div className="mb-4">
                  <div className="form-label mb-2">Employee Type<b className="text-danger"></b></div>
                  <div>
                    <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={_formData?.guarantoremployeeTypeId}
                        onChange={(e) =>
                          onEmployeeTypeChange("guarantoremployeeTypeId", e.target.value)
                        }
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                      >
                        {_EmployeeType.map((item: any, index: any) => (
                          <MenuItem value={item?.Id}>{item?.TypeName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {/* <div className="mb-4" style={{ display: _EmployeeDocDisplay ? "block" : "none" }}>
                                    <div className="form-label mb-2">Document Type<b className="text-danger"></b></div>
                                    <div>
                                        <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={_formData?.guarantorEmployeeDocumnetTypeId}
                                                onChange={(e) =>
                                                    onFormChange("guarantorEmployeeDocumnetTypeId", e.target.value)
                                                }
                                                sx={{
                                                    "& legend": { display: "none" },
                                                    "& fieldset": { top: 0 },
                                                }}
                                            >
                                                {_EmployeeDocType.map((item: any, index: any) => (
                                                    <MenuItem value={item?.Id}>{item?.DocumentType}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div> */}
                <div className="mb-3">
                  <div>
                    <label className="form-label mb-2">Office Trigger<b className="text-danger"></b></label>
                  </div>
                  <div>
                    <TextField size="small" autoComplete='off'
                      label=" " multiline minRows={2}
                      value={_formData?.GuarantorOfficeTrigger}
                      onChange={(e: any) =>
                        onFormChange("GuarantorOfficeTrigger", e.target.value)
                      }
                      InputLabelProps={{ shrink: false }}
                      variant="outlined"
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <label className="form-label mt-2">Verification Type<b className="text-danger"></b></label>
                  </div>
                  <div className="d-flex flex-wrap">
                    <div className="col-6">
                      <Checkbox checked={_formData?.guarantorOfficeProfileCheck} onChange={() => onFormChange('guarantorOfficeProfileCheck', !_formData?.guarantorOfficeProfileCheck)} />
                      <span>Profile</span>
                    </div>
                    <div className="col-6">
                      <Checkbox checked={_formData?.guarantorOfficeDocumentCheck} onChange={() => onFormChange('guarantorOfficeDocumentCheck', !_formData?.guarantorOfficeDocumentCheck)} />
                      <span>Document</span>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="d-flex flex-wrap">
                    {(_formData?.guarantorOfficeProfileCheck || _formData?.guarantorOfficeDocumentCheck) && <div className="col-6">
                      <FormControl error={postCaseValidation?.guarantorOfficeVerificationField.error}><FormControlLabel
                        control={<Checkbox checked={_formData?.guarantorOfficeVerificationField} onChange={() => onFormChange('guarantorOfficeVerificationField', !_formData?.guarantorOfficeVerificationField)} />}
                        label='Field' />
                        <FormHelperText>{postCaseValidation?.guarantorOfficeVerificationField?.message}</FormHelperText>
                      </FormControl>
                      {/* <Checkbox checked={_formData?.guarantorOfficeVerificationField} onChange={() => onFormChange('guarantorOfficeVerificationField', !_formData?.guarantorOfficeVerificationField)} />
                      <span>Field</span> */}
                    </div>}
                    {(_formData?.guarantorOfficeProfileCheck || _formData?.guarantorOfficeDocumentCheck) && <div className="col-6">
                      <Checkbox checked={_formData?.guarantorOfficeVerificationAuthority} onChange={() => onFormChange('guarantorOfficeVerificationAuthority', !_formData?.guarantorOfficeVerificationAuthority)} />
                      <span>Authority</span>
                    </div>}
                  </div>
                </div>
                {(_formData?.guarantorOfficeProfileCheck || _formData?.guarantorOfficeDocumentCheck) && _formData?.guarantorOfficeVerificationField && <>
                  <div className="mb-3">
                    <label className="form-label ">Hub Id</label>
                    <div>
                      <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={_formData?.gaurantorOfficeHubId}
                          onChange={(e) =>
                            onOfficeHubIdChange("gaurantorOfficeHubId", e.target.value)
                          }
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                        >
                          {_hubList.map((item: any, index: any) => (
                            <MenuItem key={index} value={item?.Id}>{item?.HubName}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label ">Field Staff Name<b className="text-danger"></b></label>
                    <div className='row'>
                      <div className="col-md-6">
                        <TextField size="small" autoComplete='off'
                          label=" " disabled
                          value={_formData?.gaurantorOfficeFieldStaffName}
                          InputLabelProps={{ shrink: false }}
                          variant="outlined"
                          className="w-100"
                        />
                      </div>
                      {_formData?.precaseid && <div className="col-md-6">
                        <FormControl size="small" className='w-100' sx={{ m: 0 }}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper" value={_formData?.guarantorOfficeFieldStaff}
                            onChange={(e: any) => onFormChange('guarantorOfficeFieldStaff', e.target.value)}
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                          >
                            {_fieldList.map((item: any, index: any) =>
                              <MenuItem key={index} value={item?.Id}>{item?.staffName}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>}
                    </div>
                  </div>
                </>}
                <div className="mb-3">
                  <label className="form-label ">Office Query</label>
                  <div>
                    <FormControl size="small" className='w-100' sx={{ m: 0 }} error={postCaseValidation?.GuaOfficeQueryID?.error}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper" value={_formData?.GuaOfficeQueryID}
                        onChange={(e: any) => onFormChange('GuaOfficeQueryID', e.target.value)}
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                      >
                        {_officeQueryList?.map((item: any, index: any) =>
                          <MenuItem key={index} value={item?.QueryId}>{item?.QueryName}</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{postCaseValidation?.GuaOfficeQueryID?.message}</FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className=''>
                    <div className='row align-items-center my-2'>
                      <label className="form-label col-md-3">Verification Point</label>
                      <label className="form-label col-md-3">Document Type<b className="text-danger"></b></label>
                      <label className="form-label col-md-3">Verification Type<b className="text-danger"></b></label>
                      <div className='col-md-2'></div>
                      <div className='col-md-1'>
                        <div role="button" onClick={(e: any) => addNewImgOffice(e)} >
                          <AddCircleOutlineIcon />
                        </div>
                      </div>
                    </div>
                    {_guarantorOfficeDoc?.map((val: any, index: any) => {
                      return (
                        <div className='row align-items-center my-2'>
                          <div className='col-md-3'>
                            <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={val?.VerificationPerson || 0}
                                onChange={(e) =>
                                  onChangeGuarantorOfficeDropdownChange('VerificationPerson', e.target.value, index)
                                }
                                sx={{
                                  "& legend": { display: "none" },
                                  "& fieldset": { top: 0 },
                                }}
                              >
                                <MenuItem value={'Profile'}>Field</MenuItem>
                                <MenuItem value={'Authority'}>Authority</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className='col-md-3'>
                            <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={val?.customerDocumentTypeId || 0}
                                onChange={(e) =>
                                  onChangeGuarantorOfficeDropdownChange('customerDocumentTypeId', e.target.value, index)
                                }
                                sx={{
                                  "& legend": { display: "none" },
                                  "& fieldset": { top: 0 },
                                }}
                              >
                                <MenuItem value={0}>Select Document Type</MenuItem>
                                {val?.VerificationPerson === "Profile" &&
                                  _profileDocType?.map((item: any, index: any) => (
                                    <MenuItem key={index} value={item?.Id}>{item?.DocumentType}</MenuItem>
                                  ))}
                                {val?.VerificationPerson === "Authority" &&
                                  _authorityDocType?.map((item: any, index: any) => (
                                    <MenuItem key={index} value={item?.Id}>{item?.DocumentType}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className='col-md-3'>
                            <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                              <Select
                                value={val?.verificationtypeId || ''}
                                onChange={(e) =>
                                  onChangeGuarantorOfficeDropdownChange('verificationtypeId', e.target.value, index)
                                }
                                sx={{
                                  "& legend": { display: "none" },
                                  "& fieldset": { top: 0 },
                                }}
                              >
                                {_verificationTypeList?.map((item: any, index: any) =>
                                  <MenuItem key={index} value={Number(item?.VerificationId || '0')}>{item?.VerificationName}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                          <div className='col-md-2'>
                            {val.documentPath &&
                              <span className="d-flex h-100 align-items-center">
                                <div className='d-flex align-items-center justify-content-center'>
                                  <i className='position-absolute'>
                                    <IconButton>
                                      <VisibilityIcon onClick={() => window.open(val?.documentPath, "_blank")} className='text-muted' />
                                    </IconButton>
                                  </i>
                                  <img onClick={() => window.open(val?.documentPath, "_blank")} src={val?.documentPath} className='imageFit' style={{ height: "3rem", opacity: "50%" }} alt="documentPath" />
                                </div>
                              </span>
                            }
                            {val.documentPath === "" &&
                              <div className='d-flex align-items-center justify-content-center' style={{ border: "1px solid #d8d8d8", height: "3rem", borderRadius: "10px" }}>
                                <label htmlFor="upload-office-photo">
                                  <CloudUploadIcon className='text-muted' />
                                </label>
                                <input onChange={(e: any) => onChangeOfficePicture(e, index, val?.customerDocumentTypeId)} id="upload-office-photo" type="file" style={{ height: "3rem" }} />
                              </div>
                            }
                          </div>
                          <div className='col-md-1'>
                            <CancelIcon style={{ color: "rgb(255, 0, 0)" }} onClick={(e: any) => removeGuarantorOfficeDoc(index)} role="button" />
                          </div>
                          {/* {_guarantorOfficeDoc?.length === index + 1 && <div role="button" className='col-md-1' onClick={(e: any) => addNewImgOffice(e)} >
                            <AddCircleOutlineIcon />
                          </div>} */}
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></>



  );
}

export default Customerdetails;
