import {base64} from './helperService';

const authService = {
    setUser() {
        const _tempData = {};
        return _tempData;
    },
    removeUser() {
        localStorage.removeItem('_viveka_temp');
        return null;
    },
    user() {
        const _tempData: string | null = localStorage.getItem('_viveka_temp');
        
        if (!_tempData) return null;

        const user = JSON.parse(base64.decode(_tempData));
        return {
            id           : user?.Id,
            firstName    : user?.FirstName,
            lastName     : user?.LastName,
            email        : user?.Email,
            token        : user?.Token,
            menuNames    : user?.MenuName,
            BankId       : user?.BankId,
            roleName     : user?.RoleName,
            UserRoleID    : user?.UserRoleID,
            CorporateDetailsId:user?.CorporateDetailsId,
            CompanyLogoUrl:user?.CompanyLogoUrl,
            MobileNumber:user?.MobileNumber
        };
    
    }
}

export {authService};