
import { LoadingButton, TabContext, TabList } from '@mui/lab';
import { Tab, Button, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, TablePagination } from '@mui/material';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import inputdataicon from '../../assets/images/InputDataWhite.png';
import PDF from '../../assets/images/ExportPDF.png';
import Excel from '../../assets/images/ExportExcel.png';
import { useStateValue } from '../../providers/stateProvider';
import { getAllPostcaseqcInspectionData, updateQCInspectionData, getallQcInspectionDataPost, getMISforXcellDownload,generatePostCasePDF,generateTotalPostCasePDF } from '../../Models/configapi';
import Backofficetray from '../../pages/inputData/qctray/postcase';
import PostbackofficeTray from '../../pages/inputData/qctray/postcase/inspectionDetails';
import Postcasedocument from '../../pages/inputData/qctray/postcase/postCaseDocuments';
import Postimages from '../../pages/inputData/qctray/postcase/Posttimages';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { pdfBackOffice } from './pdf';
import moment from 'moment';
import { downloadExcel } from "react-export-table-to-excel";
import swal from 'sweetalert';
import { Close, DoDisturb } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { SkeletonProviderTables } from '../../providers/SkeletonProvider';
import { getAllPostCase } from '../../Models/model';
import { routerPath } from '../../config/constents';
import PostInspectionValidation from './postInspectionValidation';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { formatDateTime, sortTableData } from '../../services/helperService';
import { SortableTableHead } from '../helpers/tableSortableHeader';

type Order = 'asc' | 'desc';

function Index() {
  const navigate = useNavigate();
  const { handleValidation } = PostInspectionValidation();
  const [count, setCount] = useState(1);
  const [{ user, QctraypostcaseInspectionData }, dispatch]: any = useStateValue();
  const [tableData, setTableData] = useState<any>([]);
  const [_allData, _setAllData] = useState<any>([]);
  const [_tableLoading, _setTableLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [_loading, _setLoading] = useState(false);
  const [_submit, _setSubmit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [_misreportExport, _setmisreportExport] = useState<any>([]);
  const [_order, _setOrder] = useState<Order>('asc');
  const [_orderBy, _setOrderBy] = useState<string>('');
  const [_totalRecordCount,_setTotalRecordCount]=useState(0)

  const queryParams = new URLSearchParams(window.location.search);
  const postId: any = queryParams.get('id');

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log(QctraypostcaseInspectionData)
  const onSaveClick = () => {
    _setLoading(true);
    const tempArr = Object.keys(QctraypostcaseInspectionData?.InspectionData).map((key) => QctraypostcaseInspectionData?.InspectionData[key])
    let data = {
      caseid: QctraypostcaseInspectionData?.postcaseid,
      userid: user.id,
      casetype: "postcase",

      // OverallCaseStatus: QctraypostcaseInspectionData?.overAllStatus || '',
      OverallCaseStatus: QctraypostcaseInspectionData?.qcOverAllStatus || '',
      QCoverallRemarks: QctraypostcaseInspectionData?.qcRemarks || '',

      remarksappres: QctraypostcaseInspectionData?.residentRemarks || "",
      remarksappoff: QctraypostcaseInspectionData?.resOfficeRemarks || "",
      remarkscoappres: QctraypostcaseInspectionData?.coAppRemarks || "",
      remarkscoappoff: QctraypostcaseInspectionData?.coAppOfficeRemarks || "",
      remarksguaoff: QctraypostcaseInspectionData?.guarantorOfficeRemarks || "",
      remarksguares: QctraypostcaseInspectionData?.guarantorRemarks || "",
      AuthorityRemarks: QctraypostcaseInspectionData?.AuthorityRemarks || "",

      backofficestatusappres: Number(QctraypostcaseInspectionData?.backOfficeResidentStatus) || 0,
      backofficestatusappoff: Number(QctraypostcaseInspectionData?.backOfficeResidentOffice) || 0,
      backofficestatuscoappres: Number(QctraypostcaseInspectionData?.backOfficeCoApplicant) || 0,
      backofficestatuscoappoff: Number(QctraypostcaseInspectionData?.backOfficeCoAppOffice) || 0,
      backofficestatusguares: Number(QctraypostcaseInspectionData?.guaBackOfficeStatus) || 0,
      backofficestatusguaoff: Number(QctraypostcaseInspectionData?.guaOfficeBackOfficeStatus) || 0,

      AppLocation: Number(QctraypostcaseInspectionData?.AppLocation) || 0,
      AppOfficeLocation: Number(QctraypostcaseInspectionData?.AppOfficeLocation) || 0,
      CoAppLocation: Number(QctraypostcaseInspectionData?.CoAppLocation) || 0,
      CoAppOfficeboLocation: Number(QctraypostcaseInspectionData?.CoAppOfficeboLocation) || 0,
      GuaLocation: Number(QctraypostcaseInspectionData?.GuaLocation) || 0,
      GuaOfficeboLocation: Number(QctraypostcaseInspectionData?.GuaOfficeboLocation) || 0,

      resiBoKM: Number(QctraypostcaseInspectionData?.ResiKm) || 0,
      OfficeBoKM: Number(QctraypostcaseInspectionData?.OfficeKm) || 0,
      coAppBoKM: Number(QctraypostcaseInspectionData?.CoAppResiKm) || 0,
      coAppOfficeBoKM: Number(QctraypostcaseInspectionData?.CoAppOfficeKm) || 0,
      GuaBoKM: Number(QctraypostcaseInspectionData?.GuaResiKm) || 0,
      GuaOfficeBoKM: Number(QctraypostcaseInspectionData?.GuaOfficeKm) || 0,

      querylist: [...tempArr]
    };

    if (!handleValidation(2)) {
      setCount(3);
      _setLoading(false)
      return;
    }

    updateQCInspectionData(data).then((response: any) => {
      if (response.status === 201) {
        generatePdf(data.caseid);
        generateTotalPDF(data.caseid);

        getTableData(page,rowsPerPage);
        refreshPage();
        setCount(1);
        swal('PostCase Updated Successfully', { icon: "success" });
      }
    }).catch((error: any) => {
      swal("Something went Wrong", { icon: "warning" });
    }).finally(() => _setLoading(false));
  }

  const generateTotalPDF = (caseID : any) => {
    debugger
    const caseType = "postcase";
    generateTotalPostCasePDF(caseID,caseType, user.CorporateDetailsId).then((response) => {
      debugger;
      if (response.data.status === "success"){
        console.log(response.data.data);
      }
    }).catch((error) => {
      debugger;
      console.log(error.response.data);
    })
  }
  const generatePdf = (caseID : any) => {
    debugger;
    const caseType = "postcase";
    generatePostCasePDF(caseID,caseType, user.CorporateDetailsId).then((response) => {
      debugger;
      if (response.data.status === "success"){
        console.log(response.data.data);
      }
    }).catch((error) => {
      debugger;
      console.log(error.response.data);
    })
  }
  

  const viewData = (item: any) => {
    debugger;
    refreshPage();
    window.scrollTo(0, 0);
    setCount(1);
    if (item?.Status === 'Completed') {
      _setSubmit(false);
    } else if (item?.Status === 'In Progress') {
      _setSubmit(false);
    } else if (item?.Status === 'B.O Tray Pending' ||
      item?.Status === 'QC Pending' ||
      item?.Status === 'QC Tray Pending') {
      _setSubmit(true);
    }
    getallQcInspectionDataPost(item?.postcaseid)
      .then((response) => {
        if (response.data.status === 'Success') {
          const arr = [...response.data.data.ResiCheck, ...response.data.data.OfficeCheck, ...response.data.data.CoAppResiCheck,
          ...response.data.data.CoAppOfficeCheck, ...response.data.data.GuaResiCheck, ...response.data.data.GuaOfficeCheck]
          let obj: any = {}
          arr.forEach((temp: any) => {
            obj[temp?.InspectionID] = temp;
          })


          // debugger;
          dispatch({
            type: "QctrayPostCaseData", data: { ...item, _view: true, }
          });
          dispatch({
            type: "QctraypostcaseInspectionData", data: {
              ...item,
              backOfficeResidentStatus: item?.BoresiStatus || 0,
              backOfficeResidentOffice: item?.BoofficeStatus || 0,
              backOfficeCoApplicant: item?.BocoAppResiStatus || 0,
              backOfficeCoAppOffice: item?.BocoAppOfficeStatus || 0,
              guaBackOfficeStatus: item?.BoguaResiStatus || 0,
              guaOfficeBackOfficeStatus: item?.BoguaOfficeStatus || 0,

              residentLocation: item?.ResiLocation || 0,
              residentOfficeLocation: item?.OfficeLocation || 0,
              coAppLocation: item?.CoAppResiLocation || 0,
              coAppOfficeLocation: item?.CoAppOfficeLocation || 0,
              guarantorLocation: item?.GuaResiLocation || 0,
              guarantorOfficeLocation: item?.GuaOfficeboLocation || 0,

              AppLocation: item?.ResiLocation || 0,
              AppOfficeLocation: item?.OfficeLocation || 0,
              CoAppLocation: item?.CoAppResiLocation || 0,
              CoAppOfficeboLocation: item?.CoAppOfficeLocation || 0,
              GuaLocation: item?.GuaResiLocation || 0,
              GuaOfficeboLocation: item?.GuaOfficeboLocation || 0,

              residentRemarks: item?.BoresiRemarks || "",
              resOfficeRemarks: item?.BoofficeRemarks || "",
              coAppRemarks: item?.BocoAppResiRemarks || "",
              coAppOfficeRemarks: item?.BocoAppOfficeRemarks || "",
              guarantorRemarks: item?.BoguaResiRemarks || "",
              guarantorOfficeRemarks: item?.BoguaOfficeRemarks || "",
              AuthorityRemarks: item?.AuthorityRemarks || "",

              remarksappres: item?.BoresiRemarks || "",
              remarksappoff: item?.BoofficeRemarks || "",
              remarkscoappres: item?.BocoAppResiRemarks || "",
              remarkscoappoff: item?.BocoAppOfficeRemarks || "",
              remarksguaoff: item?.BoguaResiRemarks || "",
              remarksguares: item?.BoguaOfficeRemarks || "",

              backofficestatusappres: item?.BoresiStatus || 0,
              backofficestatusappoff: item?.BoofficeStatus || 0,
              backofficestatuscoappres: item?.BocoAppResiStatus || 0,
              backofficestatuscoappoff: item?.BocoAppOfficeStatus || 0,
              backofficestatusguares: item?.BoguaResiStatus || 0,
              backofficestatusguaoff: item?.BoguaOfficeStatus || 0,


              // overAllStatus: item?.OverallCaseStatus || '',
              qcOverAllStatus: item?.OverallCaseStatus || '',
              qcRemarks: item?.QcoverAllRemarks || '',

              ResiCheck: response.data.data.ResiCheck,
              OfficeCheck: response.data.data.OfficeCheck,
              CoAppResiCheck: response.data.data.CoAppResiCheck,
              CoAppOfficeCheck: response.data.data.CoAppOfficeCheck,
              GuaResiCheck: response.data.data.GuaResiCheck,
              GuaOfficeCheck: response.data.data.GuaOfficeCheck,
              InspectionData: { ...obj }, _view: true,
            }
          });

          const docData = _allData?.filter((aItem:any)=> aItem?.CaseDetails?.postcaseid === item?.postcaseid)[0]

          dispatch({
            type: "QctraypostcasedocProperties", data: { ...item, documentProperties: docData?.documentProperties, _view: true, }
          })
          dispatch({
            type: "QctraypostcaseinspectionImages", data: { ...item, InspectiondocumentProperties: docData?.InspectiondocumentProperties, _view: true, }
          })

        }
      })
      .catch((error) => {
        console.log(error.response);
      })
    // const arr = [...item?.ResiCheck, ...item?.OfficeCheck, ...item?.CoAppResiCheck,
    // ...item?.CoAppOfficeCheck, ...item?.GuaResiCheck, ...item?.GuaOfficeCheck]
    // let obj: any = {}
    // arr.forEach((temp: any) => {
    //   obj[temp?.InspectionID] = temp;
    // })

    // dispatch({
    //   type: "QctrayPostCaseData", data: { ...item, ...item?.CaseDetails, _view: true }
    // });
    // dispatch({
    //   type: "QctraypostcaseInspectionData", data: { ...item, ...item, InspectionData: { ...obj }, _view: true }
    // });
    // dispatch({
    //   type: "QctraypostcasedocProperties", data: { ...item, ...item?.documentProperties, _view: true }
    // })
    // dispatch({
    //   type: "QctraypostcaseinspectionImages", data: { ...item, ...item?.InspectiondocumentProperties, _view: true }
    // })
  };

  const getTableData = (page:any,rowsPerPage:any) => {
    getAllPostcaseqcInspectionData(page,rowsPerPage,user?.CorporateDetailsId)
      .then((response) => {
        _setAllData([...response?.data?.data]);
        setTableData([...response?.data?.data?.map((item: any) => { return { ...item?.CaseDetails } })]);
        _setTotalRecordCount(response.data.data.dataCount)

      })
      .catch((error) => {
        console.log(error.response);
      }).finally(() => _setTableLoading(false));;
  }

  const viewDashboardData = (id: any) => {
    getAllPostCase(user.id,user?.CorporateDetailsId)
      .then(response => {
        if (response.status === 200) {
          console.log(response?.data)
          const caseData = response?.data?.Data?.filter((content: any) => content?.postcaseid == id)
          getDashboardData(caseData[0]);
        }
      })
      .catch(error => console.log(error))
  }

  const getDashboardData = (item: any) => {
    getallQcInspectionDataPost(item.postcaseid)
      .then((response) => {
        if (response.data.status === 'Success') {
          const arr = [...response.data.data.ResiCheck, ...response.data.data.OfficeCheck, ...response.data.data.CoAppResiCheck,
          ...response.data.data.CoAppOfficeCheck, ...response.data.data.GuaResiCheck, ...response.data.data.GuaOfficeCheck]
          let obj: any = {}
          arr.forEach((temp: any) => {
            obj[temp?.InspectionID] = temp;
          })


          // debugger;
          dispatch({
            type: "QctrayPostCaseData", data: { ...item, _view: true, }
          });
          dispatch({
            type: "QctraypostcaseInspectionData", data: {
              ...item,
              backOfficeResidentStatus: item?.BoresiStatus || 0,
              backOfficeResidentOffice: item?.BoofficeStatus || 0,
              backOfficeCoApplicant: item?.BocoAppResiStatus || 0,
              backOfficeCoAppOffice: item?.BocoAppOfficeStatus || 0,
              guaBackOfficeStatus: item?.BoguaResiStatus || 0,
              guaOfficeBackOfficeStatus: item?.BoguaOfficeStatus || 0,

              residentLocation: item?.ResiLocation || 0,
              residentOfficeLocation: item?.OfficeLocation || 0,
              coAppLocation: item?.CoAppResiLocation || 0,
              coAppOfficeLocation: item?.CoAppOfficeLocation || 0,
              guarantorLocation: item?.GuaResiLocation || 0,
              guarantorOfficeLocation: item?.GuaOfficeboLocation || 0,

              AppLocation: item?.ResiLocation || 0,
              AppOfficeLocation: item?.OfficeLocation || 0,
              CoAppLocation: item?.CoAppResiLocation || 0,
              CoAppOfficeboLocation: item?.CoAppOfficeLocation || 0,
              GuaLocation: item?.GuaResiLocation || 0,
              GuaOfficeboLocation: item?.GuaOfficeboLocation || 0,

              residentRemarks: item?.BoresiRemarks || "",
              resOfficeRemarks: item?.BoofficeRemarks || "",
              coAppRemarks: item?.BocoAppResiRemarks || "",
              coAppOfficeRemarks: item?.BocoAppOfficeRemarks || "",
              guarantorRemarks: item?.BoguaResiRemarks || "",
              guarantorOfficeRemarks: item?.BoguaOfficeRemarks || "",
              AuthorityRemarks: item?.AuthorityRemarks || "",

              remarksappres: item?.BoresiRemarks || "",
              remarksappoff: item?.BoofficeRemarks || "",
              remarkscoappres: item?.BocoAppResiRemarks || "",
              remarkscoappoff: item?.BocoAppOfficeRemarks || "",
              remarksguaoff: item?.BoguaResiRemarks || "",
              remarksguares: item?.BoguaOfficeRemarks || "",

              backofficestatusappres: item?.BoresiStatus || 0,
              backofficestatusappoff: item?.BoofficeStatus || 0,
              backofficestatuscoappres: item?.BocoAppResiStatus || 0,
              backofficestatuscoappoff: item?.BocoAppOfficeStatus || 0,
              backofficestatusguares: item?.BoguaResiStatus || 0,
              backofficestatusguaoff: item?.BoguaOfficeStatus || 0,


              // overAllStatus: item?.OverallCaseStatus || '',
              qcOverAllStatus: item?.OverallCaseStatus || '',
              qcRemarks: item?.QcoverAllRemarks || '',

              ResiCheck: response.data.data.ResiCheck,
              OfficeCheck: response.data.data.OfficeCheck,
              CoAppResiCheck: response.data.data.CoAppResiCheck,
              CoAppOfficeCheck: response.data.data.CoAppOfficeCheck,
              GuaResiCheck: response.data.data.GuaResiCheck,
              GuaOfficeCheck: response.data.data.GuaOfficeCheck,
              InspectionData: { ...obj }, _view: true,
            }
          });
          dispatch({
            type: "QctraypostcasedocProperties", data: { ...item, ...item?.documentProperties, _view: true, }
          })
          dispatch({
            type: "QctraypostcaseinspectionImages", data: { ...item, InspectiondocumentProperties: response?.data?.data?.InspectiondocumentProperties, _view: true, }
          })

        }
      })
      .catch((error) => {
        console.log(error.response);
      })
  }

  useEffect(() => {
    if (postId && user?.BankId != null && user?.BankId != 0) {
      viewDashboardData(postId)
    }
  }, [postId])

  const refreshPage = () => {
    dispatch({ type: "QctrayPostCaseData", data: {} });
    dispatch({ type: "QctraypostcaseInspectionData", data: {} });
    dispatch({ type: "QctraypostcasedocProperties", data: {} });
    dispatch({ type: "QctraypostcaseinspectionImages", data: {} });
    dispatch({ type: "postInspectionValidation", data: {} });

    setCount(1);
  }

  useEffect(() => {
    refreshPage();
    getTableData(page,rowsPerPage);
    MisDownload();
  }, []);

  useEffect(()=>{
    getTableData(page,rowsPerPage);
  },[page,rowsPerPage])


  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = _orderBy === property && _order === 'asc';
    _setOrder(isAsc ? 'desc' : 'asc');
    _setOrderBy(property);
  };

  useEffect(() => {
    const data = sortTableData(tableData, _order, _orderBy);
    setTableData(data)
  }, [_order, _orderBy])

  const exportPDF = () => {
    var curDate = moment()?.format("YYYY-MM-DD")
    const filename = 'BackOffice_PreCase_' + curDate + '.pdf'
    const headers = [
      { key: 'caseNo', display: 'Case No' },
      { key: 'caseDate', display: 'Case Date' },
      { key: 'vendorName', display: 'Vendor Name' },
      { key: 'BankName', display: 'Bank Agency' },
      { key: 'productName', display: 'Product' },
      { key: 'segmentName', display: 'Segment' },
      { key: 'customerName', display: 'Customer Name' },
      { key: 'fieldExecutiveName', display: 'Executive Name' },
    ]

    pdfBackOffice({ data: tableData, headers, filename })
  }
  const exportEXCEL = () => {
    const header = ["Case No", "Case Date", "Vendo rName", "BankAgency", "Product", "Segment", "Customer Name", "Executive Name"];
    let body: any = tableData.map((e: any) => { return [e.caseNo, e.caseDate, e.vendorName, e.BankName, e.productName, e.segmentName, e.customerName, e.fieldExecutiveName] })
    var curDate = moment()?.format("YYYY-MM-DD")
    var filename = 'BackOffice_PreCase_' + curDate;
    downloadExcel({
      fileName: filename,
      sheet: "BackOffice_PreCase",
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  const MisDownload = () => {
    debugger;
    var PageName = "QcTray";
    var UserID = user.id;
    var caseType = "postcase";

    getMISforXcellDownload(caseType, UserID, PageName,user?.CorporateDetailsId).then((response) => {
      if (response.data.status === "Success") {
        debugger;
        _setmisreportExport(response.data.data);

      }
    })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const exportMISEXCEL = () => {
    debugger;
    const header = ["CaseNo", "vendorName", "bankName", "branchName", "LoanAmount", "BankEmployeeName", "DsaDesName", "productName", "BankReferenceNo",
      "TypeOfSampling", "CustomerName", "CustomerCity", "CustomerPincode", "FathersName", "DateOfBirth", "EmployeType", "CompanyName", "OfficeCity",
      "OfficePincode", "Designation", "OfficeTrigger", "ResidencyTrigger", "CoAppTrigger", "CoAppOfficeTrigger", "GuarantorTrigger", "GuarantorOfficeTrigger",
      "QcresiRemarks", "QcofficeRemarks", "QccoAppOfficeRemarks", "QcguaOfficeRemarks", "QcguaResiRemarks", "CaseDate", "QcCloseDate",
      "AppFieldStaffStatusName", "AppOfficeFieldStaffStatusName", "CoAppFieldStaffStatusName", "CoAppOfficeFieldStaffStatusName",
      "GuarantorFieldStaffStatusName",
      "ApplicantFieldStaffName","ApplicantOfficeFieldStaffName","CoAppFieldStaffName","CoAppOfficeFieldStaffName","GuarantorFieldStaffName","GuarantorOfficeFieldStaffName",

      "AppBoLocation", "OfficeAppBoLocation", "CoappBoLocation", "CoappOfficeBoLocation", "GuaBoLocation", "GuaBoOfficeLocation",
      "Resikm", "Officekm", "CoAppkm", "CoAppOfficekm", "Guakm", "GuaOfficekm", "ResiBokm", "OfficeBokm", "CoAppBokm", "CoAppOfficeBokm", "GuaBokm", "GuaOfficeBokm",];
    let body: any = _misreportExport.map((e: any) => {
      return [e.CaseNo, e.vendorName, e.bankName, e.branchName, e.LoanAmount, e.BankEmployeeName, e.DsaDesName, e.productName, e.BankReferenceNo,
      e.TypeOfSampling, e.CustomerName, e.CustomerCity, e.CustomerPincode, e.FathersName, e.DateOfBirth, e.EmployeType, e.CompanyName, e.OfficeCity,
      e.OfficePincode, e.Designation, e.OfficeTrigger, e.ResidencyTrigger, e.CoAppTrigger, e.CoAppOfficeTrigger, e.GuarantorTrigger, e.GuarantorOfficeTrigger,
      e.QcresiRemarks, e.QcofficeRemarks, e.QccoAppOfficeRemarks, e.QcguaOfficeRemarks, e.QcguaResiRemarks, e.CaseDate, e.QcCloseDate,
      e.AppFieldStaffStatusName, e.AppOfficeFieldStaffStatusName, e.CoAppFieldStaffStatusName, e.CoAppOfficeFieldStaffStatusName,
      e.GuarantorFieldStaffStatusName,
      e.ApplicantFieldStaffName,e.ApplicantOfficeFieldStaffName,e.CoAppFieldStaffName,e.CoAppOfficeFieldStaffName,e.GuarantorFieldStaffName,e.GuarantorOfficeFieldStaffName,

      e.AppBoLocation, e.OfficeAppBoLocation, e.CoappBoLocation, e.CoappOfficeBoLocation, e.GuaBoLocation, e.GuaBoOfficeLocation,
      e.Resikm, e.Officekm, e.CoAppkm, e.CoAppOfficekm, e.Guakm, e.GuaOfficekm, e.ResiBokm, e.OfficeBokm, e.CoAppBokm, e.CoAppOfficeBokm, e.GuaBokm, e.GuaOfficeBokm,]
    })
    var curDate = moment()?.format("YYYY-MM-DD")
    var filename = 'QC_Postcase_MIS_Report_' + curDate;
    downloadExcel({
      fileName: filename,
      sheet: "Product_Master",
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  return (
    <>
      <div className={`d-flex justify-content-between align-items-center bg-primary text-white p-3 ${user?.BankId ? 'roundedTop' : ''}`}>
        <div>
          <h5 className="mb-0">
            <span className="mx-2">
              <img src={inputdataicon} />
            </span>
            QC Tray-Post Case
          </h5>
        </div>
      </div>
      <div className="bg-white p-3 content-area">
        <div
          className={`text-white mb-3 mt-3 px-4 d-flex align-items-center bg-primary ${user?.BankId ? 'rounded--1 py-2' : ''}`}
          style={{ borderRadius: "2px" }}
        >
          <TabContext value={""}>
            <TabList aria-label="lab API tabs example">
              <Tab
                label="Post Case Details"
                className={clsx(
                  count === 1 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(1)}
              />
              <Tab
                label="Post Case Documents"
                className={clsx(
                  count === 2 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(2)}
              />
              <Tab
                label="Inspection Details"
                className={clsx(
                  count === 3 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(3)}
              />
              <Tab
                label="Images"
                className={clsx(
                  count === 4 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(4)}
              />
            </TabList>
          </TabContext>
        </div>
        <div>
          {count === 1 && <Backofficetray />}
          {count === 2 && <Postcasedocument />}
          {count === 3 && <PostbackofficeTray />}
          {count === 4 && <Postimages />}

          <div className="row">
            <div className=" col-md-8 text-end pe-3 d-flex justify-content-end">
              {(user?.BankId != null && user?.BankId != 0) && <Button
                className="bg-danger text-white me-2"
                sx={{ width: 140, p: 2 }}
                onClick={() => navigate(routerPath?.dashboard)}
              >Close <Close className="h-15" />
              </Button>}

              {count === 1 ? (
                ""
              ) : (
                <Button
                  className="bg-secondary text-white mx-5"
                  sx={{ width: 170, padding: 2 }}
                  onClick={() => setCount(count - 1)}
                >
                  <ArrowBackIosIcon className="h-15" /> Back
                </Button>
              )}
              <Button
                className={clsx(
                  count === 4 ? "d-none" : "bg-success text-white"
                )}
                sx={{ width: 170, p: 2 }}
                onClick={() => setCount(count + 1)}
              >
                Next <ArrowForwardIosIcon className="h-15" />
              </Button>

              {!(user?.BankId != null && user?.BankId != 0) && count === 4 && (
                <LoadingButton disabled={!_submit} className={clsx(`${_submit && !_loading ? 'bg-success' : 'bg-light-1 text-muted'} text-white`)} loadingPosition="end" endIcon={<></>}
                  loading={_loading} fullWidth sx={{ width: 170, p: 2 }}
                  onClick={onSaveClick}>Submit</LoadingButton>
              )}
              {!(user?.BankId != null && user?.BankId != 0) && <Button className="bg-danger text-white ms-2" sx={{ width: 170, p: 2 }}
                onClick={refreshPage}>Clear <DoDisturb className="h-15" /></Button>}
            </div>
          </div>
        </div>
        <hr />
        {/* coming soon */}
        {/* <Outlet/> */}

        {!(user?.BankId != null && user?.BankId != 0) && <>
          <div className="bg-primary py-2 mt-5 gx-0 text-white fw-bold ps-3 row d-flex align-items-center justify-content-around">
            <div className="col-md-6 fs-4">QC Tray</div>
            <div className="col-md-6 px-2 d-flex justify-content-around">
              <input
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search"
                className="my-2 px-2 rounded-2 w-100 border border-white"
              />
              {/* <Button>
              <img
                src={PDF}
                alt=""
                className="text-end mx-1"
                role="button"
                style={{ width: "3rem" }} onClick={exportPDF}
              />
            </Button> */}
              <Button>
                <img
                  src={Excel}
                  onClick={exportEXCEL}
                  className="text-end mx-1"
                  alt=""
                  role="button"
                  style={{ width: "3rem" }}
                />
              </Button>
              <AssignmentReturnedIcon onClick={exportMISEXCEL} className='' style={{ fontSize: "3.5rem" }} />
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <SortableTableHead
                order={_order}
                orderBy={_orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  { id: '', label: 'View', align: 'center' },
                  { id: 'Status', label: 'Status', align: 'center' },
                  // { id: 'caseNo', label: 'Case No', align: 'left' },
                  { id: 'caseDate', label: 'Case Date', align: 'center' },
                  // { id: 'vendorName', label: 'Vendor Name', align: 'center' },
                  { id: 'bankReferenceNo', label: 'Bank Ref No', align: 'center' },
                  { id: 'BankName', label: 'Bank Agency', align: 'center' },
                  { id: 'productName', label: 'Product Name', align: 'center' },
                  { id: '', label: 'Query', align: 'center' },
                  { id: 'customerName', label: 'Customer Name', align: 'center' },
                  { id: '', label: 'Executive Name', align: 'center' },
                  { id: 'AppFieldStaffEndDate', label: 'App Res VerifiedOn', align: 'center' },
                  { id: 'AppOfficeFieldStaffEndDate', label: 'App Office VerifiedOn', align: 'center' },
                  { id: 'CoAppFieldStaffEndDate', label: 'CoApp Res VerifiedOn', align: 'center' },
                  { id: 'CoAppOfficeFieldStaffEndDate', label: 'CoApp Office VerifiedOn', align: 'center' },
                  { id: 'GuarantorFieldStaffEndDate', label: 'Guarantor Res VerifiedOn', align: 'center' },
                  { id: 'GuarantorOfficeFieldEndDate', label: 'Guarantor Office VerifiedOn', align: 'center' },
                  { id: 'BackOfficeCloseDate', label: 'Back Office Close Date', align: 'center' },
                ]}
              />
              <TableBody>
                {tableData?.length > 0
                  ? tableData?.filter((content: any) => 
                  // content?.caseNo?.toLowerCase().includes(query) ||
                    // content?.vendorName?.toLowerCase().includes(query) || 
                    content?.caseDate?.toLowerCase().includes(query)
                    || content?.BankName?.toLowerCase().includes(query) || content?.productName?.toLowerCase().includes(query)
                    || content?.segmentName?.toLowerCase().includes(query) || content?.customerName?.toLowerCase().includes(query)
                    || content?.fieldExecutiveName?.toLowerCase().includes(query))
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item: any, index: any) => (
                      <TableRow
                        key={index + 1}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            className="bg-success"
                            style={{ borderRadius: "10px" }}
                            onClick={() => viewData(item)}
                            type="submit"
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          {item?.Status === "Completed" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Completed
                            </Button>
                          )}
                          {item?.Status === "Completed-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-info text-white px-4  text-nowrap"
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Completed TAT Breached
                            </Button>
                          )}
                          {item?.Status === "In Progress" && (
                            <Button
                              variant="contained"
                              className="bg-primary px-4  "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              InProgress
                            </Button>
                          )}

                          {item?.Status === "In Progress-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              In Progress-TAT Breached
                            </Button>
                          )}
                          {item?.Status === "Cancelled-Reassign" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Cancelled-Reassign
                            </Button>
                          )}
                          {item?.Status === "Verification Started" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Verification Started
                            </Button>
                          )}
                          {item?.Status === "QC Pending" || item?.Status === 'QC Tray Pending' && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              QC Pending
                            </Button>
                          )}
                          {item?.Status === "B.O Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              B.O Tray Pending
                            </Button>
                          )}
                        </TableCell>

                        {/* <TableCell component="th" scope="row">{item?.caseNo}</TableCell> */}
                        <TableCell className="text-nowrap" align="center">{item?.caseDate.split("T")[0]}</TableCell>
                        {/* <TableCell align="center">{item?.vendorName}</TableCell> */}
                        <TableCell align="center">{item?.bankReferenceNo}</TableCell>
                        <TableCell align="center">{item?.BankName}</TableCell>
                        <TableCell align="center">{item?.productName}</TableCell>
                        <TableCell align="center">
                          {`${item?.ApllicantFiledStaffName?.length > 0 ? 'Resident Details, ' : ''} 
                        ${item?.ApllicantOfficeFiledStaffName?.length > 0 ? 'Resident Office Details, ' : ''}
                        ${item?.coApplicantDetails?.coAppFieldStaffName?.length > 0 ? 'Co-Applicant Details, ' : ''}
                        ${item?.coApplicantDetails?.coOfficeFieldStaffName?.length > 0 ? 'Co-Applicant Office Details, ' : ''}
                        ${item?.gaurantorDetails?.gaurantorFieldStaffName?.length > 0 ? 'Guarantor Details, ' : ''}
                        ${item?.gaurantorDetails?.gaurantorOfficeFieldStaffName?.length > 0 ? 'Guarantor Office Details' : ''}`}
                        </TableCell>
                        <TableCell align="center">{item?.customerName}</TableCell>
                        <TableCell align="center">
                          {`${item?.ApllicantFiledStaffName?.length > 0 ? item?.ApllicantFiledStaffName + ', ' : ''} 
                        ${item?.ApllicantOfficeFiledStaffName?.length > 0 ? item?.ApllicantOfficeFiledStaffName + ', ' : ''}
                        ${item?.coApplicantDetails?.coAppFieldStaffName?.length > 0 ? item?.coApplicantDetails?.coAppFieldStaffName + ', ' : ''}
                        ${item?.coApplicantDetails?.coOfficeFieldStaffName?.length > 0 ? item?.coApplicantDetails?.coOfficeFieldStaffName + ', ' : ''}
                        ${item?.gaurantorDetails?.gaurantorFieldStaffName?.length > 0 ? item?.gaurantorDetails?.gaurantorFieldStaffName + ', ' : ''}
                        ${item?.gaurantorDetails?.gaurantorOfficeFieldStaffName?.length > 0 ? item?.gaurantorDetails?.gaurantorOfficeFieldStaffName + ', ' : ''}`}
                        </TableCell>
                        <TableCell align="center">{item?.AppFieldStaffEndDate || "-"}</TableCell>
                        <TableCell align="center">{item?.AppOfficeFieldStaffEndDate || "-"}</TableCell>
                        <TableCell align="center">{item?.CoAppFieldStaffEndDate || "-"}</TableCell>
                        <TableCell align="center">{item?.CoAppOfficeFieldStaffEndDate || "-"}</TableCell>
                        <TableCell align="center">{item?.GuarantorFieldStaffEndDate || "-"}</TableCell>
                        <TableCell align="center">{item?.GuarantorOfficeFieldEndDate || "-"}</TableCell>
                        <TableCell align="center">{formatDateTime(item?.BackOfficeCloseDate) || "-"}</TableCell>
                      </TableRow>
                    ))
                  : !tableData && (
                    <TableRow key={0}>
                      <TableCell align={"center"}>
                        <h3 className="text-muted">Data Not Found</h3>
                      </TableCell>
                    </TableRow>
                  )}
                <SkeletonProviderTables columns={8} visible={_tableLoading} />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={_totalRecordCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>}
      </div>
    </>
  );
}

export default Index;