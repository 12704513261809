import * as React from "react";
import { httpRequest } from "../services/httpService";
import { routerPath } from "../config/constents";
import { useStateValue } from "../providers/stateProvider";



export interface PropRoute {
  path: string;
  element?: React.ReactNode | null;
}
// vendor Details API
export const saveVendorDetails = (formData: any) => {
  
  return httpRequest.post(routerPath.API.config.insertUpdateVendor, formData);
};

export const getVendorDetails = (CorporateDetailsId:any) => {
  return httpRequest.get(
    routerPath.API.config.getVendor+'?lCorporateId='+CorporateDetailsId
  );
};
export const getVendorDetailsForGrid = (CorporateDetailsId:any) => {
  return httpRequest.get(
    routerPath.API.config.getVendorForGrid+'?lCorporateId='+CorporateDetailsId
  );
};

export const getMisTypemasterforDropdown = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.MisMasterForDropdown+'?lCorporateId='+CorporateDetailsId);
};

export const getproductmaster = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.productmaster+'?lCorporateId='+CorporateDetailsId);
};
export const getproductmasterforgrid = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.productmasterForGrid+'?lCorporateId='+CorporateDetailsId);
};

export const getMisFilterDataforcase = (formData: any) => {
  return httpRequest.post(routerPath.API.config.getMisFilterDataforcase, formData);
};

export const getMisFilterDataforcaseForExcel = (formData: any) => {
  return httpRequest.post(routerPath.API.config.getMisFilterDataforcaseForExcel, formData);
};

export const getMisFilterDataforExport = (formData: any) => {
  return httpRequest.post(routerPath.API.config.getMisFilterDataforExport, formData);
};

export const getprecasedataforbindgrid=()=>{
  return httpRequest.get(routerPath.API.config.getAllMisReportData);
}
export const getVendornames = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getvendornames+"?lCorporateId="+CorporateDetailsId);
};
export const getVendornamesInput = (UserID:any,CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getvendornamesInput+"?UserID="+UserID+"&lCorporateId="+CorporateDetailsId);
};
export const getbanknames = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getbanknames+"?lCorporateId="+CorporateDetailsId);
};
export const getsegmentnames = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getsegmentnames+"?lCorporateId="+CorporateDetailsId);
};

export const getEmployeeType = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getEmployeeType+"?lCorporateId="+CorporateDetailsId);
};
export const getAddressTypes = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getAddressType+"?lCorporateId="+CorporateDetailsId);
};
export const getSampling = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getSamplingDetail+"?lCorporateId="+CorporateDetailsId);
};
export const getStaffDetails = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getStaffName+"?lCorporateId="+CorporateDetailsId);
};

export const getProducts = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getProductNames+"?lCorporateId="+CorporateDetailsId);
};
export const getVerificationDet = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getVerificationDetails+"?lCorporateId="+CorporateDetailsId);
};
export const getAuthorityMaster = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getAuthorityMasterDetail+"?lCorporateId="+CorporateDetailsId);
};
export const getEmployeeTypes = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getEmployeeTypeDetails+"?lCorporateId="+CorporateDetailsId);
};
export const getSamplingDetails = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getSamplingDetail+"?lCorporateId="+CorporateDetailsId);
};
export const getAgencyNames = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.getAgencyNames+"?lCorporateId="+CorporateDetailsId);
};
export const insertProductMaster = (formData: any) => {
  return httpRequest.post(routerPath.API.config.inserProductmaster, formData);
};
export const updateProductMaster = (formData: any) => {
  return httpRequest.put(routerPath.API.config.updateProductmaster, formData);
};
export const searchProductMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchProductMaster +
      "?SearchValue=" +
      formData?.search+"&lCorporateId="+formData?.CorporateDetailsId
  );
};
export const pdfProductMaster = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.pdfProductMaster+"?lCorporateId="+CorporateDetailsId);
};
export const excelProductMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelProductmaster +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10+"&lCorporateId="+formData?.CorporateDetailsId
  );
};
// Query Master
export const insertQueryMaster = (formData: any) => {
  return httpRequest.post(routerPath.API.config.insertQueryMaster, formData);
};
export const updateQueryMaster = (formData: any) => {
  return httpRequest.put(routerPath.API.config.updateQueryMaster, formData);
};
export const searchQueryMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchQueryMaster + "?SearchValue=" + formData?.search+"&lCorporateId="+formData?.CorporateDetailsId
  );
};
export const pdfQueryMaster = (CorporateDetailsId:any) => {
  return httpRequest.get(routerPath.API.config.pdfQueryMaster+"?lCorporateId="+CorporateDetailsId);
};
export const excelQueryMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelQueryMaster +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10+"&lCorporateId="+formData?.CorporateDetailsId
  );
};
export const getAllQueryMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.getAllQueryMaster +
      "?PageNumber=" +
      formData?.PageNumber+"&lCorporateId="+formData?.CorporateDetailsId
  );
};
//Hub Master

export const insertHubMaster = (formData: any) => {
  return httpRequest.post(routerPath.API.config.insertHubMaster, formData);
};
export const updateHubMaster = (formData: any) => {
  return httpRequest.put(routerPath.API.config.updateHubMaster, formData);
};
export const searchHubMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchHub + "?SearchValue=" + formData?.search
  );
};
export const getQueryList = (_type:any,lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getquerylist+`?ProfileType=${_type}`+`&lCorporateId=${lCorporateId}`);
};
export const getAllHubMaster = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllHub+"?lCorporateId="+lCorporateId);
};
export const getDesignationList = () => {
  return httpRequest.get(routerPath.API.config.getDesignationList);
};
export const gethubdetails = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.gethubdetails+"?lCorporateId="+lCorporateId);
};
export const getAllHubMasterAll = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllHubAll+"?lCorporateId="+lCorporateId);
};
//User Master Details
export const inserUserMaster = (formData: any) => {
  return httpRequest.post(routerPath.API.config.insertmasterDeatils, formData);
};
export const getUserType = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getUserType+"?lCorporateId="+lCorporateId);
};
export const getUserRole = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getUserRole+"?lCorporateId="+lCorporateId);
};
export const deleteIMEIUser = (id:any) => {
  return httpRequest.post(routerPath.API.config.deleteUserImei+'?ID='+id+'&lCorporateId=0')
}

export const getAllUserMaster = (lCorporateId:any)=>{
  return httpRequest.get(routerPath.API.config.getUserMaster+"?lCorporateId="+lCorporateId);
}

// Bank--- Configuration
export const insertBank = (formData: any)=> {
  return httpRequest.post(routerPath.API.config.insertBankDetails, formData);

};
export const getAllBanks = (lCorporateId:any)=> {
  return httpRequest.get(routerPath.API.config.getAllBanks+"?lCorporateId="+lCorporateId);

};
export const getAllBankForGrid = (lCorporateId:any)=> {
  return httpRequest.get(routerPath.API.config.getAllBanksForGrid+"?lCorporateId="+lCorporateId);

};
export const updateBank = (formData: any)=> {
  return httpRequest.put(routerPath.API.config.updateBankDetails, formData);
};
//Branch--- Configuration
export const insertBranch = (formData: any) => {
  return httpRequest.post(routerPath.API.config.insertBranchDetails, formData);
};
export const updateBranch = (formData: any) => {
  return httpRequest.put(routerPath.API.config.updateBranchDetails, formData);
};
export const searchBranch = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchBranchs + "?SearchValue=" + formData?.search+"&lCorporateId="+formData?.lCorporateId 
  );
};
export const pdfBranch = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.pdfBranch+"?lCorporateId="+lCorporateId);
};
export const excelBranch = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelBranch +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10+"&lCorporateId="+formData?.lCorporateId
  );
};
export const getAllBranch= (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllBranchs+"?lCorporateId="+lCorporateId);
};
export const getAllBranchForGrid= (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllBranchsForGrid+"?lCorporateId="+lCorporateId);
};
export const getAllBranchsByBankId= (bankid:any) => {
  return httpRequest.get(routerPath.API.config.getAllBranchsByBankId+"?bankid="+bankid);
};
//Holiday Master Configuration

export const insertHolidayMaster = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertHolidayMasterDetails,
    formData
  );
};
export const updateHolidayMaster = (formData: any) => {
  return httpRequest.put(
    routerPath.API.config.updateHolidayMasterDetails,
    formData
  );
};
export const searchHolidayMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchHolidayMaster +
      "?SearchValue=" +
      formData?.search
  );
};
export const pdfHolidayMaster = () => {
  return httpRequest.get(routerPath.API.config.pdfHoliday);
};
export const excelHolidayMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelHoliday +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10
  );
};
export const getAllHolidayMaster = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllHolidayMaster+"?lCorporateId="+lCorporateId);
};

//Route Master Configuration

export const insertRouteMaster = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertRouteMasterDetails,
    formData
  );
};
export const updateRouteMaster = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.updateRouteMasterDetails,
    formData
  );
};
export const routeMasterAssignTo = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.routeMasterAssignTo,
    formData
  );
};
export const searchRouteMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchRouteMaster + "?SearchValue=" + formData?.search
  );
};
export const pdfRouteMaster = () => {
  return httpRequest.get(routerPath.API.config.pdfRouteMaster);
};
export const excelRouteMaster = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelRouteMaster +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10
  );
};
export const getAllRouteMaster = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllRouteMasterDetails+"?lCorporateId="+lCorporateId);
};

export const getAllRouteMasterforAssign = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllRouteMasterDetails+"?lCorporateId="+lCorporateId);
};

//Route Assingment Configuration

export const insertRouteAssign = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertRouteAssignDetails,
    formData
  );
};
export const updateRouteAssign = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.updateRouteAssignDetails,
    formData
  );
};
export const searchRouteAssign = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.searchRouteAssign + "?SearchValue=" + formData?.search
  );
};

export const getPincodeList = (FormData : any) => {
  return httpRequest.get(
    routerPath.API.config.getPincodeByRoute + "?routename="+FormData?.routeNme+"&lCorporateId="+FormData?.lCorporateId
  );
};
export const pdfRouteAssign = () => {
  return httpRequest.get(routerPath.API.config.pdfRouteAssign);
};

export const excelRouteAssign = (formData: any) => {
  return httpRequest.get(
    routerPath.API.config.excelRouteAssign +
      "?PageNumber=" +
      formData?.PageNumber +
      "&PageSize=" +
      10
  );
};
export const getAllRouteAssign = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllRouteAssignDetails+"?lCorporateId="+lCorporateId);
};
export const getAllRouteAssignGrid = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllRouteAssignDetailsGrid+"?lCorporateId="+lCorporateId);
};

export const getAllStaffDetails = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.getAllStaffDetails+"?lCorporateId="+lCorporateId);
}

//Product Detail -- Configuration

export const insertProductDetail = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertProductDetails,
    formData
  );
};
export const updateProductDetail = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.updateProductDetails,
    formData
  );
};
export const getAllProductDetail = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllProductDetails+"?lCorporateId="+lCorporateId);
};
export const getAllProductDetailGrid = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllProductDetailsGrig+"?lCorporateId="+lCorporateId);
};


//Tat Matrix Configuration

export const getBankByVendorId = (formData: any) => {
  const url = `${routerPath.API.config.getBankByVendorId}?vendorId=${formData?.VendorId}`+`&lCorporateId=${formData?.lCorporateId}`;
  return httpRequest.get(url);
};

export const getBranchByBankId = (formData: any) => {
  const url = `${routerPath.API.config.getBranchByBankId}?BankId=${formData?.BankId}`+`&lCorporateId=${formData?.lCorporateId}`;
  return httpRequest.get(url);
};
export const getBranchByBranchId = (BranchId: any) => {
  const url = `${routerPath.API.config.getProductNamebyBranch}?BranchId=${BranchId}`;
  return httpRequest.get(url);
};

export const getTypeByProductId = (productID: any,lCorporateId:any) => {
  const url = `${routerPath.API.config.getTypeByProduct}?productID=${productID}`+`&lCorporateId=`+lCorporateId;
  return httpRequest.get(url);
};

export const getProductByBankandBranchId = (formData: any) => {
  
  const url = `${routerPath.API.config.getProductNamebyBankandBranch}?BankId=${formData?.BankId}`+`&lCorporateId=${formData?.lCorporateId}`;
  return httpRequest.get(url);
};

export const insertTatMatrix = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertTatMatrix,
    formData
  );
};
export const updateTatMatrix = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.updateTatMatrix,
    formData
  );
};

export const getAllTatMatrix = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllTatMatrix+"?lCorporateId="+lCorporateId);
};
export const getAllTatMatrixForGrid = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllTatMatrixForGrid+"?lCorporateId="+lCorporateId);
};

//SegmentMaster Configuration

export const insertSegmentDetail = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.insertSegment,
    formData
  );
};
export const updateSegmentDetail = (formData: any) => {
  return httpRequest.post(
    routerPath.API.config.updateSegment,
    formData
  );
};
export const getAllSegment = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getAllSegment+"?lCorporateId="+lCorporateId);
};

export const getStaffDetail = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getStaffDetails+"?lCorporateId="+lCorporateId);
}

export const insertStaffDetails = (formData: any)=> {
  return httpRequest.post(routerPath.API.config.insertStaffDetails, formData);
}

export const updateStaffDetails = (formData: any) => {
  return httpRequest.post(routerPath.API.config.updateStaffDetails, formData);
}

export const getManagerList = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getManagerList+"?lCorporateId="+lCorporateId);
};

export const getAllPrecaseInspectionData = (userid:any,pageNumber:any,pageSize:any,lCorporateId:any) => {
  return httpRequest.get(
    routerPath.API.config.getallPrecaseInspectionData +
      "?caseType=precase&UserID="+userid+"&pageNumber="+pageNumber+"&pageSize="+pageSize+"&lCorporateId="+lCorporateId
  );
};
export const getAllInspectionDataPre = (caseid:any,lCorporateId:any) => {
  return httpRequest.get(
    routerPath.API.config.getallInspectionData +
      "?caseType=precase&caseID="+caseid+"&lCorporateId="+lCorporateId
  );
};
export const getAllInspectionDataPost = (caseid:any,lCorporateId:any) => {
  return httpRequest.get(
    routerPath.API.config.getallInspectionData +
      "?caseType=postcase&caseID="+caseid+"&lCorporateId="+lCorporateId
  );
};
export const updateInspectionData = (formdata:any) => {

  return httpRequest.put(routerPath.API.config.updateInspectionData,formdata);

};

export const updateQCInspectionData = (formdata:any) => {

  return httpRequest.put(routerPath.API.config.updateQCInspectionData,formdata);

};

export const getAllPostcaseInspectionData = (UserId:any,pageNumber:any,pageSize:any,lCorporateId:any) => {
  return httpRequest.get(
    routerPath.API.config.getallPostcaseInspectionData +
      "?caseType=postcase&UserID="+UserId+"&pageNumber="+pageNumber+"&pageSize="+pageSize+"&lCorporateId="+lCorporateId
  );
};

export const getAllPrecaseqcInspectionData = (pageNumber:any,pageSize:any,lCorporateId:any) => {
  return httpRequest.get(
    routerPath.API.config.getallPrecaseQcInspectionData +
      "?caseType=precase&pageNumber="+pageNumber+"&pageSize="+pageSize+"&lCorporateId="+lCorporateId
  );
};
export const getallQcInspectionDataPre = (caseid:any) => {
  return httpRequest.get(
    routerPath.API.config.getallQcInspectionData +
      "?caseType=precase&caseID="+caseid
  );
};

export const getallQcInspectionDataPost = (caseid:any) => {
  return httpRequest.get(
    routerPath.API.config.getallQcInspectionData +
      "?caseType=postcase&caseID="+caseid
  );
};
export const getAllPostcaseqcInspectionData = (pageNumber:any,pageSize:any,lCorporateId:any) => {
  return httpRequest.get(
    routerPath.API.config.getallPostcaseQcInspectionData +
      "?caseType=postcase&pageNumber="+pageNumber+"&pageSize="+pageSize+"&lCorporateId="+lCorporateId
  );
};
export const getAllMisReportData =()=>{
  return httpRequest.get(routerPath.API.config.getAllMisReportData);
}
export const getMISforXcellDownload = (caseType:string,UserID:number,PageName:string,lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getMISforAllTray + "?caseType="+caseType +"&UserID="+ UserID+"&PageName="+PageName+"&lCorporateId="+lCorporateId)
}
export const getallgetbackofficestatus = (lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getallgetbackofficestatus+"?lCorporateId="+lCorporateId);
};
export const deleteDocument =(formdata:any)=>{
return httpRequest.post(routerPath.API.config.deleteDocument, formdata);
}
export const savecaseDoc =(formdata:any)=>{
  return httpRequest.post(routerPath.API.config.saveCaseDocument, formdata);
  }

  export const getStateDetails = (lCorporateId:any) => {
    return httpRequest.get(routerPath.API.config.getStateList+"?lCorporateId="+lCorporateId);
  };

  export const getFieldExecutiveName = (formData: any) => {
    debugger;
    const url = `${routerPath.API.config.getFieldExecutiveName}?HubId=${formData?.HubId}&pincode=${formData?.pincode}&lCorporateId=${formData?.lCorporateId}`;
    return httpRequest.get(url);
  };
  export const getEmployeeDocTypes = (lCorporateId:any) => {
    
    return httpRequest.get(routerPath.API.config.getEmployeeDocDetails+"?lCorporateId="+lCorporateId);
  };
  export const insertRouteMasterWithFile = (formData: any) => {
    return httpRequest.post(routerPath.API.config.insertRouteMasterDetailsWithFile,formData);
  };

  export const bulkUploadPreAndPost = (formData: any) => {
    return httpRequest.post(routerPath.API.config.bulkUploadPreAndPostCase,formData);
  };
  export const bulkUploadPostCase = (formData: any) => {
    return httpRequest.post(routerPath.API.config.bulkUploadPostCase,formData);
  };
  export const insertAccess = (formData: any)=> {
    return httpRequest.post(routerPath.API.config.insertAccessManagement, formData);  
  };
  export const updateAccess = (formData: any)=> {
    return httpRequest.post(routerPath.API.config.updateAccessManagement, formData);  
  };
  export const getAccessDetails = (lCorporateId:any) => {    
    return httpRequest.get(routerPath.API.config.getAccessManagement+"?lCorporateId="+lCorporateId);
  };

  //Case Reassignment
  export const getCaseDetaislforReassign = (formData : any)=> {
    const url = `${routerPath.API.config.getCaseInfoforreassign}?caseType=${formData?.CaseType}`+`&lCorporateId=${formData.lCorporateId}`;
    return httpRequest.get(url);
  }
  export const updateCaseAssign = (formData : any)=> {
    const url = `${routerPath.API.config.updateCaseAssign}?caseType=${formData?.caseType}&caseID=${formData?.caseID}&hubID=${formData?.hubID}&staffID=${formData.staffID}&staffname=${formData.staffname}&lCorporateId=`;
    return httpRequest.get(url);
   // return httpRequest.get(routerPath.API.config.updateCaseAssign+"?caseType=");
  }
  export const getFieldstaffbyHubid = (formData: any)=> {
    const url = `${routerPath.API.config.getfieldstaffbyhubid}?caseType=${formData?.caseType}&caseID=${formData?.caseID}&hubID=${formData?.hubID}&staffID=${formData.staffID}&staffname=${formData.staffname}&lCorporateId=`;
    return httpRequest.get(url);
  }
  export const getFieldStaffList = (formData: any)=> {
    const url = `${routerPath.API.config.getFieldStaffList}?hubID=${formData?.hubId}&lCorporateId=`;
    return httpRequest.get(url);
  }
  export const getCustomerDocTypes = (type:any,lCorporateId:any) => {
    return httpRequest.get(routerPath.API.config.getCustomerDocType + `?personnel=${type}&lCorporateId=${lCorporateId}`);
  };

  export const getAllFieldStaff = (lCorporateId:any) => {
    return httpRequest.get(routerPath.API.config.getAllFieldStaff+"?lCorporateId="+lCorporateId);
  };

  export const deletePincode = (ID:any,lCorporateId:any) => {
    return httpRequest.post(routerPath.API.config.removePincode+"?ID="+ID+"&lCorporateId="+lCorporateId);
  };

  export const generatePreCasePDF = (caseID : any,caseType:any,lCorporateId:any) => {
    return httpRequest.get(routerPath.API.config.getPDF+"?caseID="+caseID+"&caseType="+caseType+"&lCorporateId="+lCorporateId);
  };
  export const generatePostCasePDF = (caseID : any,caseType:any,lCorporateId:any) => {
    return httpRequest.get(routerPath.API.config.getPDF+"?caseID="+caseID+"&caseType="+caseType+"&lCorporateId="+lCorporateId);
  };
  export const generateTotalPostCasePDF = (caseID : any,caseType:any,lCorporateId:any) => {
    return httpRequest.get(routerPath.API.config.getTotalPDF+"?caseID="+caseID+"&caseType="+caseType+"&lCorporateId="+lCorporateId);
  };
  export const generateTotalPreCasePDF = (caseID : any,caseType:any,lCorporateId:any) => {
    return httpRequest.get(routerPath.API.config.getTotalPDF+"?caseID="+caseID+"&caseType="+caseType+"&lCorporateId="+lCorporateId);
  };


  export const getKycDocType = (lCorporateId:any) => {
    return httpRequest.get(routerPath.API.config.getKycDocType+"?lCorporateId="+lCorporateId);
  };

  export const getKycPan = (formParams:any, formData:any) => {
    return httpRequest.post(routerPath.API.config.getKycPan + `?userid=${formParams?.id}&TypeId=${formParams?.typeId}&lCorporateId=${formParams?.lCorporateId}`, formData);
  };

  export const getBackOfficeCaseGridData = (fromDate:any,toDate:any,userID:any,lCorporateId:any) => {
    return httpRequest.get(routerPath.API.config.getallBackOfficeData +"?startDate=" +fromDate + "&endDate=" +toDate+"&UserID="+userID+"&lCorporateId="+lCorporateId);
 }

 export const getQcCaseGridData = (fromDate:any,toDate:any,UserID:any,lCorporateId:any) => {
  return httpRequest.get(routerPath.API.config.getallQcData +"?startDate=" +fromDate + "&endDate=" +toDate+"&UserID="+UserID+"&lCorporateId="+lCorporateId);
}

export const getProductForDropdown = (lCorporateId : any) => {
  return httpRequest.get(routerPath.API.config.getProductForDropdown + "?lCorporateId=" + lCorporateId);
}