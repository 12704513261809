import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import inputdataicon from '../../assets/images/InputDataWhite.png';
import { getAllPostCase, updatePostcase, inserPostCase } from '../../Models/model';
import swal from 'sweetalert';
import PDF from "../../assets/images/ExportPDF.png";
import Excel from '../../assets/images/ExportExcel.png';
import Customerdetails from '../../pages/inputData/postCase/Customerdetails';
import Otherdetails from '../../pages/inputData/postCase/Otherdetails';
import CaseDetails from '../../pages/inputData/postCase/index';
import Guarantor from '../../pages/inputData/postCase/guarantor';
import CoApplicant from '../../pages/inputData/postCase/coapplicant';
import clsx from 'clsx';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useStateValue } from '../../providers/stateProvider';
import { SkeletonProviderTables } from '../../providers/SkeletonProvider';
import PostCaseValidation from './postCaseValidation';
import BulkUpload from '../../pages/inputData/postCase/bulkUpload';
import { pdf } from './pdf';
import moment from 'moment';
import { downloadExcel } from "react-export-table-to-excel";
import { DoDisturb } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {getMISforXcellDownload} from '../../Models/configapi';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { sortTableData } from '../../services/helperService';
import { SortableTableHead } from '../helpers/tableSortableHeader';
//import { downloadExcel } from "react-export-table-to-excel";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 501,
    paddingTop: 24,
    paddingBottom: 24,
  },
}));

type Order = 'asc' | 'desc';

function Index() {
  const { handleValidation } = PostCaseValidation();
  const [count, setCount] = useState(1);
  const [{ user, postCaseData }, dispatch]: any = useStateValue();
  const [tableData, setTableData] = useState<any>([]);
  const [_checked1, _setChecked1] = useState<any>(false);
  const [_checked2, _setChecked2] = useState<any>(false);
  const [_tableLoading, _setTableLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const [_loading, _setLoading] = useState(false);
  const [_misreportExport, _setmisreportExport] = useState<any>([]);
  const [_order, _setOrder] = useState<Order>('asc');
  const [_orderBy, _setOrderBy] = useState<string>('');
  const [_totalRecordCount,_setTotalRecordCount]=useState(0)


  const getTableData = () => {

    getAllPostCase(user.id,user?.CorporateDetailsId)
      .then(response => {
        if (response.status === 200) {
          console.log('getAllPostCase', response.data.Data);
          setTableData(response.data.Data);
          _setTotalRecordCount(response.data.TotalNoOfRecord)

        }
        else {
          swal(response.data.Data.Message, { icon: 'warning' });
        }
      }).catch(error => {
        swal("Something Went Wrong", { icon: 'error' });
      }).finally(() => _setTableLoading(false));
  }

  const onCheckBoxChange = (name: any, value: any) => {
    if (name === "coApplicant") {
      dispatch({ type: "postCaseData", data: { ...postCaseData, coApplicantEnable: value } });
      _setChecked1(value);
    }
    if (name === "Guarantor") {
      dispatch({ type: "postCaseData", data: { ...postCaseData, coGaurantorEnable: value } });
      _setChecked2(value);
    }

  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const onSaveClick = () => {
    _setLoading(true);
    const data = {
      Id: postCaseData?.precaseid || 0,
      userLoginId: user?.id,

      vendorId: postCaseData?.vendorId || 0,
      bankid: postCaseData?.bankid || 0,
      branchid: postCaseData?.branchid || 0,
      productId: postCaseData?.productId || 0,
      caseNo: postCaseData?.caseNo || "",
      caseDate: postCaseData?.caseDate || moment()?.format("YYYY-MM-DD"),
      bankReferenceNo: postCaseData?.bankReferenceNo || "",
      verificationAtId: postCaseData?.verificationAtId || 1,
      TypeOfSampling: postCaseData?.TypeOfSampling || '',

      customerName: postCaseData?.customerName || '',
      residentialCustomerAddress1: postCaseData?.residentialCustomerAddress1 || '',
      residentialCustomerAddress2: postCaseData?.residentialCustomerAddress2 || '',
      residentialCity: postCaseData?.residentialCity || '',
      residentialPincode: parseInt(postCaseData?.residentialPincode) || 0,
      residentialState: postCaseData?.residentialState || '',
      residentialMobileNo: postCaseData?.residentialMobileNo || '',
      residentialCountryCode: Number(postCaseData?.residentialCountryCode) || 0,
      residentialEmail: postCaseData?.residentialEmail || '',
      fatherName: postCaseData?.fatherName || '',
      motherName: '',
      panNo: postCaseData?.panNo || '',
      aadhaarNo: postCaseData?.aadhaarNo || '',
      dob: postCaseData?.dob || '',
      customersamplingDetailsId: postCaseData?.customersamplingDetailsId || '',
      ResidencyTrigger: postCaseData?.ResidencyTrigger || '',
      appHubId: postCaseData?.appHubId || 0,
      AppQueryID: postCaseData?.AppQueryID || 0,
      AppOffice_QueryID: postCaseData?.AppOffice_QueryID || 0,
      residentialFieldExecutiveName: postCaseData?.residentialFieldExecutiveName || '',
      employeeTypeId: postCaseData?.employeeTypeId,
      dealerName: postCaseData?.dealerName || '',
      registrationNo: postCaseData?.registrationNo || '',
      modelVariance: postCaseData?.modelVariance || '',

      officeName: postCaseData?.officeName || '',
      officeAddress1: postCaseData?.officeAddress1 || '',
      officeAddress2: postCaseData?.officeAddress2 || '',
      officeCity: postCaseData?.officeCity || '',
      officeState: postCaseData?.officeState || '',
      officePincode: Number(postCaseData?.officePincode) || 0,
      officeLandMark: postCaseData?.officeLandMark || '',
      officeCountryCode: 0,
      officeLandLine: postCaseData?.officeLandLine || '',
      officeMobileNumber: postCaseData?.officeMobileNumber || '',
      designation: postCaseData?.designation || 0,
      OfficeTrigger: postCaseData?.OfficeTrigger || '',
      officeHubId: postCaseData?.officeHubId || 0,
      officeFieldExecutiveName: postCaseData?.officeFieldExecutiveName || '',
      officeExtensionNumber: parseInt(postCaseData?.officeMobileNumber) || 0,
      othersamplingDetails: postCaseData?.customersamplingDetailsId,

      coApplicantName: postCaseData?.coApplicantName || '',
      coAddress: postCaseData?.coAddress || '',
      coCity: postCaseData?.coCity || '',
      coPincode: Number(postCaseData?.coPincode) || 0,
      coState: postCaseData?.coState || '',
      coMobileNo: Number(postCaseData?.coMobileNo) || 0,
      coEmailAddress: postCaseData?.coEmailAddress || '',
      CoAppTrigger: postCaseData?.CoAppTrigger || '',
      coAppHubId: Number(postCaseData?.coAppHubId) || 0,
      coAppFieldStaffName: postCaseData?.coAppFieldStaffName || '',

      coAddressType: postCaseData?.coAddressType || '',
      coAddress_2: postCaseData?.coAddress_2 || '',

      coofficeName: postCaseData?.coofficeName || '',
      coofficeAddress1: postCaseData?.coofficeAddress1 || '',
      coofficeAddress2: postCaseData?.coofficeAddress2 || '',
      coofficeCity: postCaseData?.coofficeCity || '',
      coofficePincode: postCaseData?.coofficePincode || '',
      coofficeState: postCaseData?.coofficeState || '',
      coofficeLandMark: postCaseData?.coofficeLandMark || '',
      coofficeLandLine: postCaseData?.coofficeLandLine || '',
      coofficeMobileNumber: postCaseData?.coofficeMobileNumber || '',
      codesignation: Number(postCaseData?.codesignation) || 0,
      coemployeeTypeId: Number(postCaseData?.coemployeeTypeId) || 0,
      coOfficeFieldStaffName: postCaseData?.coOfficeFieldStaffName || '',

      CoAppOfficeTrigger: postCaseData?.CoAppOfficeTrigger || '',
      coAppOfficeHubId: Number(postCaseData?.coAppOfficeHubId) || 0,
      CoAppQueryID: Number(postCaseData?.CoAppQueryID) || 0,
      CoAppOfficeQueryID: Number(postCaseData?.CoAppOfficeQueryID) || 0,

      gaurantorName: postCaseData?.gaurantorName || '',
      gaurantorAddress_1: postCaseData?.gaurantorAddress_1 || '',
      gaurantorCity: postCaseData?.gaurantorCity || '',
      gaurantorPincode: Number(postCaseData?.gaurantorPincode) || 0,
      gaurantorState: postCaseData?.gaurantorState || '',
      gaurantorMobileNo: Number(postCaseData?.gaurantorMobileNo) || 0,
      gaurantorEmail: postCaseData?.gaurantorEmail || '',
      GuarantorTrigger: postCaseData?.GuarantorTrigger || '',
      gaurantorHubId: Number(postCaseData?.gaurantorHubId) || 0,
      guarantorFieldStaffName: postCaseData?.guarantorFieldStaffName || '',
      GuaQueryID: Number(postCaseData?.GuaQueryID) || 0,
      GuaOfficeQueryID: Number(postCaseData?.GuaOfficeQueryID) || 0,

      guarantorofficeName: postCaseData?.guarantorofficeName || '',
      guarantorofficeAddress1: postCaseData?.guarantorofficeAddress1 || '',
      guarantorofficeAddress2: postCaseData?.guarantorofficeAddress2 || '',
      guarantorofficeCity: postCaseData?.guarantorofficeCity || '',
      guarantorofficePincode: postCaseData?.guarantorofficePincode || '',
      guarantorofficeState: postCaseData?.guarantorofficeState || '',
      guarantorofficeLandMark: postCaseData?.guarantorofficeLandMark || '',
      guarantorofficeLandLine: postCaseData?.guarantorofficeLandLine || '',
      guarantorofficeMobileNumber: postCaseData?.guarantorofficeMobileNumber || '',
      guarantordesignation: Number(postCaseData?.guarantordesignation) || 0,
      guarantoremployeeTypeId: Number(postCaseData?.guarantoremployeeTypeId) || 0,
      GuarantorOfficeTrigger: postCaseData?.GuarantorOfficeTrigger || '',
      gaurantorOfficeHubId: Number(postCaseData?.gaurantorOfficeHubId) || 0,
      gaurantorOfficeFieldStaffName: postCaseData?.gaurantorOfficeFieldStaffName || '',


      loanAmount: Number(postCaseData?.loanAmount) || 0,
      bankEmployee: postCaseData?.bankEmployee || '',
      dsaOrDesName: postCaseData?.dsaOrDesName || '',
      reqLoggedBy: postCaseData?.reqLoggedBy || '',
      boStaffName: postCaseData?.boStaffName || '',


      fieldExecutiveName: postCaseData?.fieldExecutiveName || '-',

      coApplicantEnable: _checked1,
      coGaurantorEnable: _checked2,

      documentProperties: [
        ...postCaseData?.documentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
        ...postCaseData?.officeDocumentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
        ...postCaseData?.coDocumentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
        ...postCaseData?.coOfficeDocumentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
        ...postCaseData?.guarantorDocumentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
        ...postCaseData?.guarantorOfficeDocumentProperties?.filter((fItem: any) => fItem?.documentPath?.length > 0)?.map((item: any) => { return { documentNumber: item?.documentNumber, documentName: item?.documentName, documentTypeId: item?.documentTypeId, documentPath: item?.documentPath, reftype: item?.reftype, id: item?.id || 0, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID || 0, VerificationPerson: item?.VerificationPerson || 0, verificationtypeId: item?.verificationtypeId || 0 } }),
      ],
      EmployeeDocumnetTypeId: postCaseData?.EmployeeDocumnetTypeId ? 1 : 0,
      coAppEmployeeDocumnetTypeId: postCaseData?.coAppEmployeeDocumnetTypeId ? 1 : 0,
      guarantorEmployeeDocumnetTypeId: postCaseData?.guarantorEmployeeDocumnetTypeId ? 1 : 0,


      applicantDocumentCheck: postCaseData?.applicantDocumentCheck ? 1 : 0,
      applicantProfileCheck: postCaseData?.applicantProfileCheck ? 1 : 0,
      officeDocumentCheck: postCaseData?.officeDocumentCheck ? 1 : 0,
      officeProfileCheck: postCaseData?.officeProfileCheck ? 1 : 0,

      appVerificationTypeID: postCaseData?.verificationTypeField ? 4 : 0,
      appOfficeVerificationTypeID: postCaseData?.officeVerificationTypeField ? 5 : 0,
      coAppVerificationTypeID: postCaseData?.coVerificationField ? 6 : 0,
      coAppOfficeVerificationTypeID: postCaseData?.coOfficeVerificationField ? 7 : 0,
      guarantorAppVerificationTypeID: postCaseData?.guarantorVerificationField ? 9 : 0,
      guarantorAppOfficeVerificationTypeID: postCaseData?.guarantorOfficeVerificationField ? 8 : 0,

      appFieldStaff: postCaseData?.appFieldStaff || 0,
      officeFieldStaff: postCaseData?.officeFieldStaff || 0,
      appAuthority: postCaseData?.verificationTypeAuthority ? 1 : 0,
      officeAuthority: postCaseData?.officeVerificationTypeAuthority ? 1 : 0,

      coAppFieldStaff: postCaseData?.coAppFieldStaff || 0,
      coAppOfficeFieldStaff: postCaseData?.coAppOfficeFieldStaff || 0,
      coAppAuthority: postCaseData?.coVerificationAuthority ? 1 : 0,
      coAppOfficeAuthority: postCaseData?.coOfficeVerificationAuthority ? 1 : 0,

      guarantorFieldStaff: postCaseData?.guarantorFieldStaff || 0,
      guarantorOfficeFieldStaff: postCaseData?.guarantorOfficeFieldStaff || 0,
      guarantorAuthority: postCaseData?.guarantorVerificationAuthority ? 1 : 0,
      guarantorOfficeAuthority: postCaseData?.guarantorOfficeVerificationAuthority ? 1 : 0,

      coAppProfileCheck: postCaseData?.coAppProfileCheck ? 1 : 0,
      coAppOfficeProfileCheck: postCaseData?.coAppOfficeProfileCheck ? 1 : 0,
      coAppDocumentCheck: postCaseData?.coAppDocumentCheck ? 1 : 0,
      coAppOfficeDocumentCheck: postCaseData?.coAppOfficeDocumentCheck ? 1 : 0,

      guarantorProfileCheck: postCaseData?.guarantorProfileCheck ? 1 : 0,
      guarantorOfficeProfileCheck: postCaseData?.guarantorOfficeProfileCheck ? 1 : 0,
      guarantorDocumentCheck: postCaseData?.guarantorDocumentCheck ? 1 : 0,
      guarantorOfficeDocumentCheck: postCaseData?.guarantorOfficeDocumentCheck ? 1 : 0,
      lCorporateId:user?.CorporateDetailsId
    }


    if (!handleValidation(1)) {
      swal("Check required data is filled or not", { icon: 'warning' });
      setCount(1);
      _setLoading(false)
      return;
    }

    if (!handleValidation(2)) {
      swal("Check required data is filled or not", { icon: 'warning' });
      setCount(2);
      _setLoading(false)
      return;
    }

    if (!handleValidation(3)) {
      swal("Check required data is filled or not", { icon: 'warning' });
      setCount(3);
      _setLoading(false)
      return;
    }

    // if(!handleValidation(4)){
    //   swal("Check required data is filled or not", { icon: 'warning' });
    //   setCount(4);
    //   return;
    // }

    if (_checked1 && !handleValidation(4)) {
      swal("Check Co-Applicant data are filled or not", { icon: 'warning' });
      setCount(4);
      _setLoading(false)
      return;
    }

    if (_checked2 && !handleValidation(5)) {
      swal("Check Guarantor data are filled or not", { icon: 'warning' });
      setCount(5);
      _setLoading(false)
      return;
    }
    if (data?.Id) {
      updatePostcase(data)
        .then(response => {
          if (response.data.status === "Success") {
            swal("Data Updated SuccessFully", { icon: 'success' });
            getTableData();
            refreshPage();
            // dispatch({ type: "postCaseData", data: {} });
            dispatch({ type: "postCaseValidation", data: {} });
            setCount(1);
          }
        }).catch(error => {
          swal(error.response.data, { icon: 'warning' });
          console.log(error.response.status);
        }).finally(() => _setLoading(false))

    } else {
      debugger;
      inserPostCase(data)
        .then(response => {
          // debugger;
          if (response.data.status === "Success") {
            swal("Data Inserted SuccessFully", { icon: 'success' });
            getTableData();
            refreshPage();
            // dispatch({ type: "postCaseData", data: {} });
            dispatch({ type: "postCaseValidation", data: {} });
            setCount(1);
          }
        }).catch(error => {
          // debugger;
          swal(error.response.data, { icon: 'warning' });
          console.log(error.response.status);
        }).finally(() => _setLoading(false))
    }
  }

  const onChangeTab = (item: any) => {
    // let formData = JSON.parse(localStorage.getItem(`form${item}`) || '{}');
    // console.log(formData);
    if (item === 4 && _checked1) {
      setCount(item)
      return;
    }
    if (item === 5 && _checked2) {
      setCount(item)
      return;
    }
    setCount(item);
  };

  const checkValidation = () => {
    if (handleValidation(count)) {
      console.log(count)
      if (count === 2 && _checked1) {
        setCount(4)
        return;
      }
      if (count === 2 && !_checked1 && _checked2) {
        setCount(5)
        return;
      }
      if (count === 4 && _checked2) {
        setCount(count + 1)
        return;
      }
      if (count === 4 && !_checked2 && _checked1) {
        setCount(3)
        return;
      }
      setCount(count + 1);
    }
  }

  const onGoBack = () => {
    if (count === 3 && _checked2 && _checked1) {
      setCount(5)
      return;
    }
    if ((count === 3 || count === 5) && _checked1) {
      setCount(4)
      return;
    }
    if ((count === 3 || count === 5) && !_checked1 && _checked2) {
      setCount(2)
      return;
    }
    if (count === 4) {
      setCount(2);
      return;
    }
    setCount(count - 1)
  }

  const updateData = (item: any) => {
    _setChecked1(item?.coApplicantEnable ? true : false);
    _setChecked2(item?.coGaurantorEnable ? true : false);
    setCount(1);
    window.scrollTo(0, 0);
    dispatch({
      type: "postCaseData", data: {
        ...item,
        ...item?.coApplicantDetails,
        ...item?.gaurantorDetails,
        caseDate: item?.caseDate?.slice(0, 10),
        // dob: item?.dob?.slice(0, 10),

        // customer details
        // customerName: item?.dob?.slice(0, 10) || '',
        dob: item?.dob?.slice(0, 10) || '',
        // residentialCustomerAddress1: '',
        // residentialCustomerAddress2: '',
        // residentialCity: '',
        // residentialPincode: '',
        residentialState: item?.residentialState || '',
        // residentialMobileNo: '',
        // residentialCountryCode: '',
        // residentialEmail: '',
        // fatherName: '',
        // motherName: '',
        // panNo: '',
        // aadhaarNo: '',
        // customersamplingDetailsId: '',
        // customeraddressTypeId: 0,
        // appFieldStaff: 0,
        // appAuthority: 0,
        ResidencyTrigger: item?.residentialTrigger || item?.ResidencyTrigger || '',
        OfficeTrigger: item?.officeTrigger || '',
        appHubId: item?.AppHubID || 0,
        AppQueryID: item?.residentialQueryID || 0,
        AppOffice_QueryID: item?.officeQueryID || 0,
        // residentialFieldExecutiveName: '',
        employeeTypeId: item?.employeeTypeId || 0,

        dealerName: item?.DealerName || '',
        registrationNo: item?.RegistrationNo || '',
        modelVariance: item?.ModelVariance || '',


        verificationTypeField: item?.appVerificationTypeID  ? true : false,
        verificationTypeAuthority: item?.ApplicantAuthority ? true : false,
        officeVerificationTypeField: item?.appOfficeVerificationTypeID  ? true : false,
        officeVerificationTypeAuthority: item?.OfficeAuthority ? true : false,
        coVerificationField: item?.coApplicantDetails?.coResiVerificationTypeID  ? true : false,
        coVerificationAuthority: item?.CoAppAuthority ? true : false,
        coOfficeVerificationField: item?.coApplicantDetails?.coOfficeVerificationTypeID  ? true : false,
        coOfficeVerificationAuthority: item?.CoAppOfficeAuthority ? true : false,
        guarantorVerificationField: item?.gaurantorDetails?.guarantorResiVerificationTypeID  ? true : false,
        guarantorVerificationAuthority: item?.GuarantorAuthority ? true : false,
        guarantorOfficeVerificationField: item?.gaurantorDetails?.guarantorOfficeVerificationTypeID  ? true : false,
        guarantorOfficeVerificationAuthority: item?.GuarantorOfficeAuthority ? true : false,


        // officeName: '',
        // officeAddress1: '',
        // officeAddress2: '',
        // officeCity: '',
        // officeState: '',
        // officePincode: '',
        // officeLandMark: '',
        // officeCountryCode: '',
        // officeLandLine: '',
        // officeMobileNumber: '',
        designation: item?.designation || '',
        // officeEmployeeTypeId: 0,
        // EmployeeDocumnetTypeId: 0,
        // officeFieldStaff: 0,
        // officeAuthority: 0,
        // OfficeTrigger: '',
        officeHubId: item?.OfficeHubID || 0,
        // officeFieldExecutiveName: '',

        // documentProperties: [],
        // officeDocumentProperties: [],

        // verificationType: '',
        // officeVerificationType: '',
        // appVerificationTypeID: true, 

        applicantDocumentCheck: item?.ApplicantDocumentCheck ? true : false,
        applicantProfileCheck: item?.ApplicantProfileCheck ? true : false,
        // appOfficeVerificationTypeID: true, 
        officeDocumentCheck: item?.ApplicantOfficeDocumentCheck ? true : false,
        officeProfileCheck: item?.ApplicantOfficeProfileCheck ? true : false,

        // co-applicant
        coAddressType: item?.coApplicantDetails?.coAddressType || '',
        coApplicantName: item?.coApplicantDetails?.coApplicantName || '',
        coAddress: item?.coApplicantDetails?.coAddress || '',
        coAddress_2: item?.coApplicantDetails?.coAddress_2 || '',
        coCity: item?.coApplicantDetails?.coCity || '',
        coPincode: item?.coApplicantDetails?.coPincode || '',
        coState: item?.coApplicantDetails?.coState || '',
        coMobileNo: item?.coApplicantDetails?.coMobileNo || '',
        coEmailAddress: item?.coApplicantDetails?.coEmailAddress || '',
        coAppFieldStaffName: item?.coApplicantDetails?.coAppFieldStaffName || '',
        coAppFieldStaff: item?.coApplicantDetails?.coAppFieldStaffId || 0,

        coofficeName: item?.coApplicantDetails?.coOfficeName || '',
        coofficeAddress1: item?.coApplicantDetails?.coOfficeAddress1 || '',
        coofficeAddress2: item?.coApplicantDetails?.coOfficeAddress2 || '',
        coofficeCity: item?.coApplicantDetails?.coOfficeCity || '',
        coofficeState: item?.coApplicantDetails?.coOfficeState || '',
        coofficePincode: item?.coApplicantDetails?.coOfficePincode || '',
        coofficeLandMark: item?.coApplicantDetails?.coOfficeLandMark || '',
        coofficeLandLine: item?.coApplicantDetails?.coOfficeLandline || '',
        coofficeMobileNumber: item?.coApplicantDetails?.coOfficeMobileNumber || '',
        // codesignation: '',
        codesignation: item?.coApplicantDetails?.coDesignation || '',
        coemployeeTypeId: item?.coApplicantDetails?.coEmployeetypeID || 0,
        coAppEmployeeDocumnetTypeId: 0,

        CoAppTrigger: item?.coApplicantDetails?.coAppTrigger || '',
        CoAppOfficeTrigger: item?.coApplicantDetails?.coOfficeTrigger || '',
        coAppHubId: item?.coAppHubID || 0,
        coAppOfficeHubId: item?.coAppOfficeHubID || 0,
        CoAppQueryID: item?.coApplicantDetails?.coResiQueryID || 0,
        CoAppOfficeQueryID: item?.coApplicantDetails?.coOfficeQueryID || 0,

        coAppOfficeFieldStaff: 0,


        appVerificationTypeID: item?.appVerificationTypeID ? true : false,
        appOfficeVerificationTypeID: item?.appOfficeVerificationTypeID ? true : false,

        guarantorAppVerificationTypeID: item?.gaurantorDetails?.guarantorResiVerificationTypeID ? true : false,
        guarantorAppOfficeVerificationTypeID: item?.gaurantorDetails?.guarantorOfficeVerificationTypeID ? true : false,

        coAppVerificationTypeID: item?.coApplicantDetails?.coResiVerificationTypeID ? true : false,
        coAppOfficeVerificationTypeID: item?.coApplicantDetails?.coOfficeVerificationTypeID ? true : false,

        coAppDocumentCheck: item?.CoAppDocumentCheck ? true : false,
        coAppProfileCheck: item?.CoAppProfileCheck ? true : false,
        coAppOfficeDocumentCheck: item?.CoAppOfficeDocumentCheck ? true : false,
        coAppOfficeProfileCheck: item?.CoAppOfficeProfileCheck ? true : false,


        // gaurantorAddressType: item?.'',
        // gaurantorName: '',
        // gaurantorAddress_1: '',
        // gaurantorAddress_2: '',
        // gaurantorCity: '',
        // gaurantorPincode: '',
        // gaurantorState: '',
        // gaurantorMobileNo: '',
        // gaurantorEmail: '',
        GuarantorTrigger: item?.gaurantorDetails?.gaurantorTrigger || '',
        gaurantorHubId: item?.guarantorHubID || 0,
        // guarantorFieldStaffName: '',
        // guarantorFieldStaff: 0,


        guarantorofficeName: item?.gaurantorDetails?.gaurantorOfficeName || '',
        guarantorofficeAddress1: item?.gaurantorDetails?.gaurantorOfficeAddress_1 || '',
        guarantorofficeAddress2: item?.gaurantorDetails?.gaurantorOfficeAddress_2 || '',
        guarantorofficeCity: item?.gaurantorDetails?.gaurantorOfficeCity || '',
        guarantorofficeState: item?.gaurantorDetails?.gaurantorOfficeState || '',
        guarantorofficePincode: item?.gaurantorDetails?.gaurantorOfficePincode || '',
        guarantorofficeLandMark: item?.gaurantorDetails?.gaurantorOfficeLandmark || '',
        guarantorofficeLandLine: item?.gaurantorDetails?.gaurantorOfficeLandLine || '',
        guarantorofficeMobileNumber: item?.gaurantorDetails?.gaurantorOfficeMobileNumber || '',
        guarantordesignation: item?.gaurantorDetails?.gaurantorDesignation || '',
        guarantoremployeeTypeId: item?.gaurantorDetails?.guarantorEmployeeTypeID || 0,

        GuarantorOfficeTrigger: item?.gaurantorDetails?.gaurantorOfficeTrigger || '',
        gaurantorOfficeHubId: item?.guarantorOfficeHubID || 0,

        GuaQueryID: item?.gaurantorDetails?.gaurantorResiQueryID || 0,
        GuaOfficeQueryID: item?.gaurantorDetails?.gaurantorOfficeQueryID || 0,
        guarantorDocumentCheck: item?.GuarantorDocumentCheck ? true : false,
        guarantorProfileCheck: item?.GuarantorProfileCheck ? true : false,
        guarantorOfficeDocumentCheck: item?.GuarantorOfficeDocumentCheck ? true : false,
        guarantorOfficeProfileCheck: item?.GuarantorOfficeProfileCheck ? true : false,

        documentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 1)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],
        officeDocumentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 2)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],
        coDocumentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 3)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],
        coOfficeDocumentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 4)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],
        guarantorDocumentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 5)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],
        guarantorOfficeDocumentProperties: item?.documentProperties?.filter((item: any) => Number(item?.documentNumber) === 6)?.map((mItem: any) => { mItem.verificationtypeId = mItem?.VerificationTypeId || ''; return mItem; }) || [],

        residentialFieldExecutiveName: item?.ResidenceFieldExecutiveName || '',
        officeFieldExecutiveName: item?.OfficeFieldExecutiveName || '',
        coOfficeFieldStaffName: item?.coApplicantDetails?.coOfficeFieldStaffName || '',
        guarantorFieldStaffName: item?.gaurantorDetails?.gaurantorFieldStaffName || '',
        gaurantorOfficeFieldStaffName: item?.gaurantorDetails?.gaurantorOfficeFieldStaffName || '',
      }

    });
  };

  const refreshPage = () => {
    _setChecked1(false)
    _setChecked2(false)
    setCount(1)
    dispatch({
      type: "postCaseData", data: {
        userLoginId: user?.id,
        caseDate: moment()?.format("YYYY-MM-DD"),
        residentialState: 'TamilNadu',
        officeState: 'TamilNadu',
        coState: 'TamilNadu',
        coofficeState: 'TamilNadu',
        gaurantorState: 'TamilNadu',
        guarantorofficeState: 'TamilNadu',
        bankReferenceNo: '',
        documentProperties: [],
        officeDocumentProperties: [],
        coDocumentProperties: [],
        coOfficeDocumentProperties: [],
        guarantorDocumentProperties: [],
        guarantorOfficeDocumentProperties: [],

        appVerificationTypeID: true,
        appOfficeVerificationTypeID: true,
        coAppVerificationTypeID: true,
        coAppOfficeVerificationTypeID: true,
        guarantorAppVerificationTypeID: true,
        guarantorAppOfficeVerificationTypeID: true,
      }
    });
  }
  const refreshBulkUpload:any = () => {
    console.log("get called")
    getTableData();
    refreshPage();
    MisDownload();
  }

  useEffect(() => {
    getTableData();
    refreshPage();
    MisDownload();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(()=>{
    getTableData();
  },[page,rowsPerPage])
  const exportPDF = () => {
    var curDate = moment()?.format("YYYY-MM-DD")
    const filename = 'PostCase_' + curDate + '.pdf'
    const headers = [
      { key: 'caseNo', display: 'CaseNo' },
      { key: 'bankReferenceNo', display: 'Reference No' },
      { key: 'BankName', display: 'Bank' },
      { key: 'vendorName', display: 'Vendor' },
      { key: 'customerName', display: 'Customer Name' },
      { key: 'residentialMobileNo', display: 'Mobile No' },
      { key: 'residentialEmail', display: 'Email' },
      { key: 'Status', display: 'Status' },
    ]

    pdf({ data: tableData, headers, filename })
  }
  const exportEXCEL = () => {
    const header = ["CaseNo", "Reference No", "Bank", "Vendor", "Customer Name", "Mobile No", "Email", "Status"];
    let body: any = tableData.map((e: any) => { return [e.caseNo, e.bankReferenceNo, e.BankName, e.vendorName, e.customerName, e.residentialMobileNo, e.residentialEmail, e.Status] })
    var curDate = moment()?.format("YYYY-MM-DD")
    var filename = 'PostCase_' + curDate;
    downloadExcel({
      fileName: filename,
      sheet: "PostCase",
      tablePayload: {
        header,
        body: body,
      },
    });
  }
  const MisDownload = () => {
    debugger;
    var PageName = "InputTray";
    var UserID = user.id;
    var caseType = "postcase";

    getMISforXcellDownload(caseType,UserID,PageName,user?.CorporateDetailsId).then((response)=>{
      if (response.data.status === "Success"){
        debugger;
        _setmisreportExport(response.data.data);
        
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  }

  const exportMISEXCEL = () => {
    debugger;
    const header = [ "CaseNo" , "vendorName" , "bankName" ,"branchName" , "LoanAmount" , "BankEmployeeName" , "DsaDesName" , "productName" , "BankReferenceNo",
      "TypeOfSampling","CustomerName","CustomerCity","CustomerPincode","FathersName","DateOfBirth","EmployeType","CompanyName","OfficeCity",
      "OfficePincode","Designation","OfficeTrigger","ResidencyTrigger","CoAppTrigger" ,"CoAppOfficeTrigger","GuarantorTrigger","GuarantorOfficeTrigger",
      "QcresiRemarks","QcofficeRemarks","QccoAppOfficeRemarks","QcguaOfficeRemarks","QcguaResiRemarks","CaseDate","QcCloseDate",
      "AppFieldStaffStatusName","AppOfficeFieldStaffStatusName","CoAppFieldStaffStatusName","CoAppOfficeFieldStaffStatusName",
      "GuarantorFieldStaffStatusName",	
      "ApplicantFieldStaffName","ApplicantOfficeFieldStaffName","CoAppFieldStaffName","CoAppOfficeFieldStaffName","GuarantorFieldStaffName","GuarantorOfficeFieldStaffName",

      "AppBoLocation","OfficeAppBoLocation","CoappBoLocation","CoappOfficeBoLocation","GuaBoLocation","GuaBoOfficeLocation",
      "Resikm","Officekm","CoAppkm","CoAppOfficekm","Guakm","GuaOfficekm","ResiBokm","OfficeBokm","CoAppBokm","CoAppOfficeBokm","GuaBokm","GuaOfficeBokm",];
    let body:any=_misreportExport.map((e:any) => {return [ e.CaseNo , e.vendorName , e.bankName ,e.branchName , e.LoanAmount , e.BankEmployeeName , e.DsaDesName , e.productName , e.BankReferenceNo,
      e.TypeOfSampling,e.CustomerName,e.CustomerCity,e.CustomerPincode,e.FathersName,e.DateOfBirth,e.EmployeType,e.CompanyName,e.OfficeCity,
      e.OfficePincode,e.Designation,e.OfficeTrigger,e.ResidencyTrigger,e.CoAppTrigger ,e.CoAppOfficeTrigger,e.GuarantorTrigger,e.GuarantorOfficeTrigger,
      e.QcresiRemarks,e.QcofficeRemarks,e.QccoAppOfficeRemarks,e.QcguaOfficeRemarks,e.QcguaResiRemarks,e.CaseDate,e.QcCloseDate,
      e.AppFieldStaffStatusName,e.AppOfficeFieldStaffStatusName,e.CoAppFieldStaffStatusName,e.CoAppOfficeFieldStaffStatusName,
      e.GuarantorFieldStaffStatusName,	
      e.ApplicantFieldStaffName,e.ApplicantOfficeFieldStaffName,e.CoAppFieldStaffName,e.CoAppOfficeFieldStaffName,e.GuarantorFieldStaffName,e.GuarantorOfficeFieldStaffName,

      e.AppBoLocation,e.OfficeAppBoLocation,e.CoappBoLocation,e.CoappOfficeBoLocation,e.GuaBoLocation,e.GuaBoOfficeLocation,
      e.Resikm,e.Officekm,e.CoAppkm,e.CoAppOfficekm,e.Guakm,e.GuaOfficekm,e.ResiBokm,e.OfficeBokm,e.CoAppBokm,e.CoAppOfficeBokm,e.GuaBokm,e.GuaOfficeBokm,]})
    var curDate=  moment()?.format("YYYY-MM-DD")
    var filename='InputTray_Postcase_MIS_Report_'+curDate;
    downloadExcel({
      fileName: filename,
      sheet: "Product_Master",
      tablePayload: {
        header,
        body: body,
      },
    });    
  }

  
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = _orderBy === property && _order === 'asc';
    _setOrder(isAsc ? 'desc' : 'asc');
    _setOrderBy(property);
  };

  useEffect(() => {
    const data = sortTableData(tableData, _order, _orderBy);
      setTableData(data)
  }, [_order, _orderBy])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center bg-primary text-white p-3">
        <div>
          <h5 className="mb-0">
            <span className="mx-2">
              <img src={inputdataicon} alt="inputdataicon" />
            </span>
            Input Data-Post Case
          </h5>
        </div>
      </div>
      <div className="bg-white p-3 content-area">
        {/* coming soon */}

        <div
          className="text-white mb-3 mt-3 px-4 d-flex align-items-center bg-primary"
          style={{ borderRadius: "2px" }}
        >
          <TabContext value={""}>
            <TabList aria-label="lab API tabs example">
              <Tab
                label="Case Details"
                className={clsx(
                  count === 1 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => onChangeTab(1)}
              />
              <Tab
                label="Customer & Office Details"
                className={clsx(
                  count === 2 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => onChangeTab(2)}
              />
              {_checked1 && (
                <Tab
                  label="Co Applicant"
                  className={clsx(
                    count === 4 ? "light-blue" : "bg-primary",
                    "text-white fs-6 rounded-3"
                  )}
                  onClick={() => onChangeTab(4)}
                />
              )}
              {_checked2 && (
                <Tab
                  label="Guarantor"
                  className={clsx(
                    count === 5 ? "light-blue" : "bg-primary",
                    "text-white fs-6 rounded-3"
                  )}
                  onClick={() => onChangeTab(5)}
                />
              )}
              {/* <Tab
                label="Office Details"
                className={clsx(
                  count === 3 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => onChangeTab(3)}
              /> */}
              <Tab
                label="Others Details"
                className={clsx(
                  count === 3 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => onChangeTab(3)}
              />
              <Tab
                label="Bulk Upload"
                className={clsx(
                  count === 6 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => onChangeTab(6)}
              />
            </TabList>
          </TabContext>
        </div>
        <div>
          {count === 1 && <CaseDetails />}
          {count === 2 && <Customerdetails />}
          {/* {count === 3 && <Officedetails />} */}
          {count === 3 && <Otherdetails />}
          {_checked1 && count === 4 && <CoApplicant />}
          {_checked2 && count === 5 && <Guarantor />}
          {count === 6 && <BulkUpload refreshBulkUpload={refreshBulkUpload} />}
          <div className="row">
            {count !== 1 ? (
              ""
            ) : (
              <div className="col-md-3 mb-3" style={{ paddingLeft: "1.7em" }}>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox className={`${postCaseData?.precaseid && 'text-muted'}`} disabled={postCaseData?.precaseid}
                          onChange={(e: any) =>
                            onCheckBoxChange("coApplicant", !_checked1)
                          }
                          checked={_checked1}
                        />
                      }
                      label="Co Applicant"
                    />
                  </FormGroup>
                </div>
              </div>
            )}
            {count !== 1 ? (
              ""
            ) : (
              <div className="col-md-2 mb-3 " style={{ paddingLeft: "1em" }}>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox className={`${postCaseData?.precaseid && 'text-muted'}`} disabled={postCaseData?.precaseid}
                          onChange={(e: any) =>
                            onCheckBoxChange("Guarantor", !_checked2)
                          }
                          checked={_checked2}
                        />
                      }
                      label="Guarantor"
                    />
                  </FormGroup>
                </div>
              </div>
            )}

            <div className=" col-md-6 text-end pe-3 d-flex justify-content-end">
              {count === 1 ? (
                ""
              ) : (
                <Button
                  className="bg-secondary text-white mx-5"
                  sx={{ width: 170, padding: 2 }}
                  onClick={onGoBack}
                >
                  <ArrowBackIosIcon className="h-15" /> Back
                </Button>
              )}
              {count !== 5 ? (
                <Button
                  className={clsx(
                    count === 3 || count === 5 ? "d-none" : "bg-success text-white"
                  )}
                  sx={{ width: 170, p: 2 }}
                  onClick={checkValidation}
                >
                  Next <ArrowForwardIosIcon className="h-15" />
                </Button>
              ) : (
                <Button
                  className={clsx("bg-success text-white")}
                  sx={{ width: 170, p: 2 }}
                  onClick={() => { setCount(count - 2); }}
                >
                  Next <ArrowForwardIosIcon className="h-15" />
                </Button>
              )}
              {count === 3 && (
                <LoadingButton className={"bg-success text-white"} loadingPosition="end" endIcon={<></>}
                  loading={_loading} fullWidth sx={{ width: 130, p: 2 }}
                  onClick={onSaveClick}>{postCaseData?.precaseid ? 'Update' : 'Save'}</LoadingButton>
              )}
              <Button className="bg-danger text-white ms-2" sx={{ width: 170, p: 2 }}
                onClick={refreshPage}>Clear <DoDisturb className="h-15" /></Button>
            </div>
          </div>
          <hr />

          {/* <Outlet/> */}
          <div className="bg-primary py-2 mt-5 gx-0 text-white fw-bold ps-3 row d-flex align-items-center justify-content-around">
            <div className="col-md-6 fs-4">Post Case</div>
            <div className="col-md-6 px-2 d-flex justify-content-around">
              <input
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search"
                className="my-2 px-2 rounded-2 w-100 border border-white"
              />
              {/* <Button>
                <img
                  src={PDF}
                  alt=""
                  className="text-end mx-1"
                  role="button"
                  style={{ width: "3rem" }} onClick={exportPDF}
                />
              </Button> */}
              <Button>
                <img
                  src={Excel}
                  onClick={exportEXCEL}
                  className="text-end mx-1"
                  alt=""
                  role="button"
                  style={{ width: "3rem" }}
                />
              </Button>
              <AssignmentReturnedIcon onClick={exportMISEXCEL} className='' style={{fontSize:"3.5rem"}}/>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 1000, borderRadius: "0px 0px 15px 15px" }}
              aria-label="customized table"
            >
              <SortableTableHead
                order={_order}
                orderBy={_orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  { id: '', label: 'Edit', align: 'center' },
                  { id: 'Status', label: 'Status', align: 'center' },
                  { id: 'caseNo', label: 'Case No', align: 'center' },
                  { id: 'caseDate', label: 'Case Date', align: 'center' },
                  { id: 'bankReferenceNo', label: 'Reference No', align: 'center' },
                  { id: 'BankName', label: 'Bank', align: 'center' },
                  { id: 'vendorName', label: 'Vendor', align: 'center' },
                  { id: 'customerName', label: 'Customer Name', align: 'center' },
                  { id: 'ProductName', label: 'Product Name', align: 'center' },
                  { id: '', label: 'Segment', align: 'center' },
                  { id: '', label: 'Field Staff Name', align: 'center' }
                ]}
              />

              <TableBody>
                {tableData?.length > 0
                  ? tableData.filter((content: any) => `${content?.caseNo}`?.toLowerCase().includes(`${query}`?.toLowerCase()) 
                    || `${content?.bankReferenceNo}`?.toLowerCase().includes(`${query}`?.toLowerCase()) 
                    || `${content?.BankName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.vendorName}`?.toLowerCase().includes(`${query}`?.toLowerCase()) 
                    || `${content?.customerName}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${content?.residentialEmail}`?.toLowerCase().includes(`${query}`?.toLowerCase()) 
                    || `${content?.Status}`?.toLowerCase().includes(`${query}`?.toLowerCase())
                    || `${ content?.ResidenceFieldExecutiveName + ' ' +
                        content?.OfficeFieldExecutiveName + ' ' +
                        content?.coApplicantDetails?.coAppFieldStaffName + ' ' +
                        content?.coApplicantDetails?.coOfficeFieldStaffName + ' ' +
                        content?.gaurantorDetails?.gaurantorFieldStaffName + ' ' +
                        content?.gaurantorDetails?.gaurantorOfficeFieldStaffName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    )
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item: any, index: number) => (
                      <TableRow
                        key={index + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          <IconButton
                            className="p-0 text-muted"
                            size="large"
                            onClick={() => updateData(item)}
                          >
                            <EditTwoToneIcon />
                          </IconButton>
                        </TableCell>
                         <TableCell align="center">
                          {item.Status === "Completed" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Completed
                            </Button>
                          )}
                          {item.Status === "Completed TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-info text-white px-4  text-nowrap"
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Completed TAT Breached
                            </Button>
                          )}
                          {item.Status === "In Progress" && (
                            <Button
                              variant="contained"
                              className="bg-primary px-4  "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              InProgress
                            </Button>
                          )}

                          {item.Status === "In Progress-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              In Progress-TAT Breached
                            </Button>
                          )}
                          {item.Status === "Cancelled-Reassign" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Cancelled-Reassign
                            </Button>
                          )}
                          {item.Status === "Verification Started" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Verification Started
                            </Button>
                          )}
                          {item.Status === "QC Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              QC Tray Pending
                            </Button>
                          )}
                          {item.Status === "B.O Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              B.O Tray Pending
                            </Button>
                          )}

                        {item.Status === "Sampled" && (
                            <Button
                              variant="contained"
                              className="bg-primary px-4  "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Sampled
                            </Button>
                          )}
                        </TableCell>
                        
                        <TableCell className='text-nowrap' component="th" scope="row" align="center">{item?.caseNo}</TableCell>
                        <TableCell className='text-nowrap' component="th" scope="row" align="center">{item?.caseDate?.split("T")[0]}</TableCell>
                        <TableCell align="center"> {item?.bankReferenceNo}</TableCell>
                        <TableCell align="center">{item?.BankName}</TableCell>
                        <TableCell align="center">{item?.vendorName}</TableCell>
                        <TableCell align="center">{item?.customerName}</TableCell>
                        <TableCell align="center">{item?.ProductName}</TableCell>
                        {/*<TableCell align="center">{item.residentialEmail}</TableCell> */}
                        <TableCell align="center" sx={{minWidth: 300}}>
                          {item?.ResidenceFieldExecutiveName?.length > 0 ? <span>Applicant Resident Details, </span> : ''}
                          {item?.OfficeFieldExecutiveName?.length > 0 ? <span>Applicant Office Details, </span> :
                            <span></span>}
                          {item?.coApplicantDetails?.coAppFieldStaffName?.length > 0 ? <span>Co-Applicant Resident Details, </span> :
                            <span></span>}
                          {item?.coApplicantDetails?.coOfficeFieldStaffName?.length > 0 ? <span>Co-Applicant Office Details, </span> :
                            <span></span>}
                          {item?.gaurantorDetails?.gaurantorFieldStaffName?.length > 0 ? <span>Guarantor Resident Details, </span> :
                            <span></span>}
                          {item?.gaurantorDetails?.gaurantorFieldStaffName?.length > 0 ? <span>Guarantor Office Details</span> :
                            <span></span>}
                        </TableCell>
                        <TableCell align="center">
                          {item?.ResidenceFieldExecutiveName}
                          {item?.OfficeFieldExecutiveName ? <span> , {item?.OfficeFieldExecutiveName}</span> :
                            <span></span>}
                          {item?.coApplicantDetails?.coAppFieldStaffName ? <span> , {item?.coApplicantDetails?.coAppFieldStaffName}</span> :
                            <span></span>}
                          {item?.coApplicantDetails?.coOfficeFieldStaffName ? <span> , {item?.coApplicantDetails?.coOfficeFieldStaffName}</span> :
                            <span></span>}
                          {item?.gaurantorDetails?.gaurantorFieldStaffName ? <span> , {item?.gaurantorDetails?.gaurantorFieldStaffName}</span> :
                            <span></span>}
                          {item?.gaurantorDetails?.gaurantorFieldStaffName ? <span> , {item?.gaurantorDetails?.gaurantorFieldStaffName}</span> :
                            <span></span>}
                        </TableCell>
                       
                        {/* <TableCell align="center">
                          {item.IsActive === 1 && (
                            <Button
                              variant="contained"
                              className="bg-success"
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Active
                            </Button>
                          )}
                          {item.IsActive === 0 && (
                            <Button
                              variant="contained"
                              className="text-white px-4 py-2"
                              style={{
                                color: "yellowgreen",
                                borderRadius: "10px",
                              }}
                              type="submit"
                            >
                              InActive
                            </Button>
                          )}
                        </TableCell> */}
                      </TableRow>
                    ))
                  : !tableData && (
                    <TableRow key={0}>
                      <TableCell align={"center"}>
                        <h3 className="text-muted">Data Not Found</h3>
                      </TableCell>
                    </TableRow>
                  )}
                <SkeletonProviderTables columns={8} visible={_tableLoading} />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={_totalRecordCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}

export default Index;



