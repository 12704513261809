import { useState, useEffect } from 'react';
import { FormHelperText, TextField } from '@mui/material';
import { FormControl, MenuItem, Select } from '@mui/material';
import { getStaffDetails } from "../../../Models/configapi";
import { useStateValue } from '../../../providers/stateProvider';
import { authService } from '../../../services/authService';

function Otherdetails() {
  const [{ postCaseData, postCaseValidation }, dispatch]: any = useStateValue();
  const [_staffName, _setStaffName] = useState([]);
  const [_formData, _setFormData] = useState<any>({
    loanAmount: '', bankEmployee: '', dsaOrDesName: '',
    reqLoggedBy: '', fieldExecutiveName: '', status: '',
    trigger: '', boStaffName: '', hubId: '', fieldExecutiveId: 0
  });

  const[role, setrole] = useState("");
  const [{ user }]: any = useStateValue();

  // const onFormChange = (name: string, value: any) => {
  //   _setFormData({ ..._formData, [name]: value });
  //   dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
  // };
  const setFormValue = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
    dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });

  };


  const getStaffNames = () => {

    getStaffDetails(user.CorporateDetailsId)
      .then(response => {
        _setStaffName(response.data.StaffDetails);
      }).catch(error => {

        console.log(error.response.data);
      })
  }


  useEffect(() => {
    if (Object.entries(postCaseData)?.length) {
      _setFormData({ ...postCaseData });
    }
  }, [postCaseData]);


  useEffect(() => {
    getStaffNames();
    const user = authService.user();
    const data = user?.UserRoleID;
    setrole(data);
    var loginUser = user?.firstName + " " + user?.lastName;
    _setFormData({ ..._formData, reqLoggedBy: loginUser });
    dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, reqLoggedBy: loginUser } });
  }, []);

  return (<>

    <div className="bg-white p-3 content-area">
      <div className='row col-md-6'>
        <div className='mb-3'>
          <div>
            <label className='form-label mb-2'>Loan Amount</label>
          </div>
          <div>
            <TextField size="small" autoComplete='off' type="number" className='w-100' value={_formData?.loanAmount} onChange={(e: any) => setFormValue('loanAmount', e.target.value)} variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} helperText={postCaseValidation?.loanAmount?.message} />
          </div>
        </div>
      </div>

      <div className="row col-md-6">
        <div className="mb-3">
          <div>
            <label className='form-label mb-2'>Bank Employee<b className="text-danger"></b></label>
          </div>
          <div>
            <TextField size="small" autoComplete='off' variant="outlined" className='w-100' value={_formData?.bankEmployee} onChange={(e: any) => setFormValue('bankEmployee', e.target.value)} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} error={postCaseValidation?.bankEmployee?.error} helperText={postCaseValidation?.bankEmployee?.message} />
          </div>
        </div>

        <div className="mb-3">
          <div>
            <label className='form-label mb-2'>DSA/DES Name</label>
          </div>
          <div>
            <TextField size="small" autoComplete='off' variant="outlined" value={_formData?.dsaOrDesName} onChange={(e: any) => setFormValue('dsaOrDesName', e.target.value)} className='w-100' sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} error={postCaseValidation?.dsaOrDesName?.error} helperText={postCaseValidation?.dsaOrDesName?.message} />
          </div>
        </div>

      </div>
      <div className="row col-md-6">
        <div className="mb-3">
          <div>
            <label className='form-label mb-2'>Req.Lodged by<b className="text-danger">*</b></label>
          </div>
          <div className="border">
            <TextField size="small" autoComplete='off' variant="outlined" disabled value={_formData?.reqLoggedBy} onChange={(e: any) => setFormValue('reqLoggedBy', e.target.value)} className='w-100' sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} error={postCaseValidation?.reqLoggedBy?.error} helperText={postCaseValidation?.reqLoggedBy?.message} />
          </div>
        </div>
      </div>
      <div className='row col-md-6'>
        <div className='mb-3'>
          <div>
            <label className='form-label mb-2'>B.O.Staff Name<b className="text-danger">*</b></label>
          </div>
          <div>
            <FormControl size="small" className='border w-100' sx={{ m: 0 }} error={postCaseValidation?.reqLoggedBy?.error}>
            {(role=='1')?<><TextField size="small" autoComplete='off' variant="outlined" disabled value={_formData?.reqLoggedBy} 
            className='w-100'
            error={postCaseValidation?.reqLoggedBy?.error} 
            helperText={postCaseValidation?.reqLoggedBy?.message} />
            <FormHelperText>{postCaseValidation?.boStaffName?.message}</FormHelperText></>:<Select value={_formData?.boStaffName} onChange={(e: any) => setFormValue('boStaffName', e.target.value)}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
              >
                {_staffName.map((item: any, index: any) =>
                  <MenuItem value={item?.Id}>{item?.Name}</MenuItem>
                )}

              </Select>}
              
            </FormControl>
          </div>
        </div>
      </div>
      {/* <div className='row'>
        <div>
          <label className="form-label mb-2">Documents<b className="text-danger"></b></label>
          {postCaseValidation?.documentProperties?.error && <small className="my-2 text-danger">{postCaseValidation?.documentProperties?.message}</small>}
        </div>
        <div className='col-md-12 d-flex flex-wrap'>
          {_fields.map((item: any, index: any) => {
            return (
              <div className='col-md-2 col-sm-3 mb-3'>
                <div>
                  <div style={{ position: "relative", width: "6rem", height: "6rem" }}>
                    {imgData[index].index &&
                      <span className="d-flex h-100 align-items-center">
                        <span>
                          <i style={{
                            left: "75%",
                            position: "absolute",
                            zIndex: "1"
                          }}>
                            <CancelIcon style={{ color: "rgb(255, 0, 0)" }} onClick={(e: any) => removeImg(index)} />
                          </i>
                          <i style={{
                            top: "35%",
                            left: "25%",
                            position: "absolute",
                            zIndex: "1"
                          }}>
                            <IconButton>
                              <VisibilityIcon onClick={() => window.open(item?.documentPath, "_blank")} className='text-muted' />
                            </IconButton>
                          </i>
                          <img src={item?.documentPath} style={{ width: "6rem", height: "6em", opacity: "50%" }} alt="documentPath" />
                        </span>
                      </span>
                    }
                    {imgData[index].index === null &&
                      <div className='d-flex align-items-center' style={{ border: "1px solid #d8d8d8", width: "6rem", height: "6rem", borderRadius: "10px" }}>
                        <label htmlFor="upload-photo" style={{ paddingLeft: "40%" }}><CloudUploadIcon className='text-muted' /></label>
                        <input onChange={(e: any) => onChangePicture(e, index)} id="upload-photo" multiple type="file" style={{ width: "6rem", height: "6rem" }} />
                      </div>
                    }

                  </div>
                </div>
              </div>
            )
          })}
          <span>
            <Button onClick={(e: any) => addNewImg(e)} className='h-100 minWidth-Auto rounded' >
              <AddCircleOutlineIcon />
            </Button>
          </span>
        </div>
      </div> */}
    </div>
  </>);
}

export default Otherdetails;