
import { LoadingButton, TabContext, TabList } from '@mui/lab';
import { Tab, Button, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, tableCellClasses, TablePagination, TextField } from '@mui/material';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import inputdataicon from '../../assets/images/InputDataWhite.png';
import PDF from '../../assets/images/ExportPDF.png';
import Excel from '../../assets/images/ExportExcel.png';
import { useStateValue } from '../../providers/stateProvider';
import { styled } from '@mui/material/styles';
import { updateQCInspectionData, getAllPrecaseqcInspectionData, getallQcInspectionDataPre, generatePreCasePDF, generateTotalPreCasePDF, getQcCaseGridData } from '../../Models/configapi';
import Backofficetray from '../../pages/inputData/qctray/precase/index';
import PostbackofficeTray from '../../pages/inputData/qctray/precase/inspectionDetails';
import Postcasedocument from '../../pages/inputData/qctray/precase/postCaseDocuments';
import Postimages from '../../pages/inputData/qctray/precase/Posttimages';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { pdfBackOffice } from './pdf';
import moment from 'moment';
import { downloadExcel } from "react-export-table-to-excel";
import swal from 'sweetalert';
import { Close, DoDisturb } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { routerPath } from '../../config/constents';
import { SkeletonProviderTables } from '../../providers/SkeletonProvider';
import { getPreCasetable } from '../../Models/model';
import PreInspectionValidation from './preInspectionValidation';
import { getMISforXcellDownload } from '../../Models/configapi';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { formatDateTime, sortTableData } from '../../services/helperService';
import { SortableTableHead } from '../helpers/tableSortableHeader';

type Order = 'asc' | 'desc';

function Index() {
  const navigate = useNavigate();
  const { handleValidation } = PreInspectionValidation();
  const [count, setCount] = useState(1);
  const [{ user, QctrayprecaseInspectionData, QctrayprecaseinspectionImages }, dispatch]: any = useStateValue();
  const [_allData, _setAllData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [_tableLoading, _setTableLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [_loading, _setLoading] = useState(false);
  const [_submit, _setSubmit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const preId: any = queryParams.get('id');
  const [_misreportExport, _setmisreportExport] = useState<any>([]);
  const [_order, _setOrder] = useState<Order>('asc');
  const [_orderBy, _setOrderBy] = useState<string>('');
  const [_totalRecordCount, _setTotalRecordCount] = useState(0)

  var date = new Date();
  let dateVal = moment(date).format("MM-YYYY-DD");
  let toDate = moment(date).format("MM-YYYY-DD");
  const [_fromDate, _setFromDate] = useState<any>("");
  const [_toDate, _setToDate] = useState<any>("");

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSaveClick = () => {
    _setLoading(true);
    const tempArr = Object.keys(QctrayprecaseInspectionData?.InspectionData).map((key) => QctrayprecaseInspectionData?.InspectionData[key])
    let data = {
      caseid: QctrayprecaseInspectionData?.Id,
      userid: user.id,
      casetype: "precase",

      // OverallCaseStatus: QctrayprecaseInspectionData?.overAllStatus || '',
      OverallCaseStatus: QctrayprecaseInspectionData?.qcOverAllStatus || '',
      QCoverallRemarks: QctrayprecaseInspectionData?.qcRemarks || '',

      remarksappres: QctrayprecaseInspectionData?.residentRemarks || "",
      remarksappoff: QctrayprecaseInspectionData?.resOfficeRemarks || "",
      remarkscoappres: QctrayprecaseInspectionData?.coAppRemarks || "",
      remarkscoappoff: QctrayprecaseInspectionData?.coAppOfficeRemarks || "",
      remarksguaoff: QctrayprecaseInspectionData?.guarantorOfficeRemarks || "",
      remarksguares: QctrayprecaseInspectionData?.guarantorRemarks || "",
      AuthorityRemarks: QctrayprecaseInspectionData?.AuthorityRemarks || "",


      backofficestatusappres: Number(QctrayprecaseInspectionData?.backOfficeResidentStatus) || 0,
      backofficestatusappoff: Number(QctrayprecaseInspectionData?.backOfficeResidentOffice) || 0,
      backofficestatuscoappres: Number(QctrayprecaseInspectionData?.backOfficeCoApplicant) || 0,
      backofficestatuscoappoff: Number(QctrayprecaseInspectionData?.backOfficeCoAppOffice) || 0,
      backofficestatusguares: Number(QctrayprecaseInspectionData?.guaBackOfficeStatus) || 0,
      backofficestatusguaoff: Number(QctrayprecaseInspectionData?.guaOfficeBackOfficeStatus) || 0,

      AppLocation: Number(QctrayprecaseInspectionData?.AppLocation) || 0,
      AppOfficeLocation: Number(QctrayprecaseInspectionData?.AppOfficeLocation) || 0,
      CoAppLocation: Number(QctrayprecaseInspectionData?.CoAppLocation) || 0,
      CoAppOfficeboLocation: Number(QctrayprecaseInspectionData?.CoAppOfficeboLocation) || 0,
      GuaLocation: Number(QctrayprecaseInspectionData?.GuaLocation) || 0,
      GuaOfficeboLocation: Number(QctrayprecaseInspectionData?.GuaOfficeboLocation) || 0,

      resiBoKM: Number(QctrayprecaseInspectionData?.ResiKm) || 0,
      OfficeBoKM: Number(QctrayprecaseInspectionData?.OfficeKm) || 0,
      coAppBoKM: Number(QctrayprecaseInspectionData?.CoAppResiKm) || 0,
      coAppOfficeBoKM: Number(QctrayprecaseInspectionData?.CoAppOfficeKm) || 0,
      GuaBoKM: Number(QctrayprecaseInspectionData?.GuaResiKm) || 0,
      GuaOfficeBoKM: Number(QctrayprecaseInspectionData?.GuaOfficeKm) || 0,

      querylist: [...tempArr]
    };

    if (!handleValidation(2)) {
      _setLoading(false)
      setCount(3);
      return;
    }

    updateQCInspectionData(data).then((response: any) => {
      debugger;
      if (response.status === 200) {
        debugger;
        generatePdf(data.caseid);
        generateTotalPDF(data.caseid);
        getTableData(page, rowsPerPage);
        refreshPage();
        setCount(1);
        swal('PreCase Updated Successfully', { icon: "success" });
      }
    }).catch((error: any) => {
      swal("Something went Wrong", { icon: "warning" });
    }).finally(() => _setLoading(false));
  }

  const generateTotalPDF = (caseID: any) => {
    debugger
    const caseType = "precase";
    generateTotalPreCasePDF(caseID,caseType, user.CorporateDetailsId).then((response) => {
      debugger;
      
      if (response.data.data === "success") {
        console.log(response.data.data);
      }
    }).catch((error) => {
      debugger;
      console.log(error.response.data);
    })
  }
  const generatePdf = (caseID: any) => {
    debugger;
    const caseType = "precase";
    generatePreCasePDF(caseID, caseType, user.CorporateDetailsId).then((response) => {
      debugger;
      if (response.data.data === "success") {
        console.log(response.data.data);
      }
    }).catch((error) => {
      debugger;
      console.log(error.response.data);
    })
  }
  const viewData = (item: any) => {
    // debugger;
    refreshPage();
    window.scrollTo(0, 0);
    setCount(1);
    if (item?.StatusName === 'Completed') {
      _setSubmit(false);
    } else if (item?.StatusName === 'In Progress') {
      _setSubmit(false);
    } else if (item?.StatusName === 'B.O Tray Pending' ||
      item?.StatusName === 'QC Pending' ||
      item?.StatusName === 'QC Tray Pending') {
      _setSubmit(true);
    }
    debugger;
    getallQcInspectionDataPre(item?.Id)
      .then((response) => {
        //console.log(response.data)
        debugger;
        if (response.data.status === 'Success') {

          const arr = [...response.data.data.ResiCheck, ...response.data.data.OfficeCheck, ...response.data.data.CoAppResiCheck,
          ...response.data.data.CoAppOfficeCheck, ...response.data.data.GuaResiCheck, ...response.data.data.GuaOfficeCheck]
          let obj: any = {}
          arr.forEach((temp: any) => {
            obj[temp?.InspectionID] = temp;
          })


          // debugger;
          dispatch({
            type: "QctrayPrecaseData", data: { ...response?.data?.data?.CaseDetails[0]?.CaseDetails, _view: true, }
          });
          dispatch({
            type: "QctrayprecaseInspectionData", data: {
              ...item,

              backOfficeResidentStatus: response.data.data.CaseDetails[0].CaseDetails?.BoresiStatus || 0,
              backOfficeResidentOffice: response.data.data.CaseDetails[0].CaseDetails?.BoofficeStatus || 0,
              backOfficeCoApplicant: response.data.data.CaseDetails[0].CaseDetails?.BocoAppResiStatus || 0,
              backOfficeCoAppOffice: response.data.data.CaseDetails[0].CaseDetails?.BocoAppOfficeStatus || 0,
              guaBackOfficeStatus: response.data.data.CaseDetails[0].CaseDetails?.BoguaResiStatus || 0,
              guaOfficeBackOfficeStatus: response.data.data.CaseDetails[0].CaseDetails?.BoguaOfficeStatus || 0,


              residentLocation: response.data.data.CaseDetails[0].CaseDetails?.ResiLocation || 0,
              residentOfficeLocation: response.data.data.CaseDetails[0].CaseDetails?.OfficeLocation || 0,
              coAppLocation: response.data.data.CaseDetails[0].CaseDetails?.CoAppResiLocation || 0,
              coAppOfficeLocation: response.data.data.CaseDetails[0].CaseDetails?.CoAppOfficeLocation || 0,
              guarantorLocation: response.data.data.CaseDetails[0].CaseDetails?.GuaResiLocation || 0,
              guarantorOfficeLocation: response.data.data.CaseDetails[0].CaseDetails?.GuaOfficeboLocation || 0,

              AppLocation: response.data.data.CaseDetails[0].CaseDetails?.ResiLocation || 0,
              AppOfficeLocation: response.data.data.CaseDetails[0].CaseDetails?.OfficeLocation || 0,
              CoAppLocation: response.data.data.CaseDetails[0].CaseDetails?.CoAppResiLocation || 0,
              CoAppOfficeboLocation: response.data.data.CaseDetails[0].CaseDetails?.CoAppOfficeLocation || 0,
              GuaLocation: response.data.data.CaseDetails[0].CaseDetails?.GuaResiLocation || 0,
              GuaOfficeboLocation: response.data.data.CaseDetails[0].CaseDetails?.GuaOfficeboLocation || 0,

              residentRemarks: response.data.data.CaseDetails[0].CaseDetails?.BoresiRemarks || "",
              resOfficeRemarks: response.data.data.CaseDetails[0].CaseDetails?.BoofficeRemarks || "",
              coAppRemarks: response.data.data.CaseDetails[0].CaseDetails?.BocoAppResiRemarks || "",
              coAppOfficeRemarks: response.data.data.CaseDetails[0].CaseDetails?.BocoAppOfficeRemarks || "",
              guarantorRemarks: response.data.data.CaseDetails[0].CaseDetails?.BoguaResiRemarks || "",
              guarantorOfficeRemarks: response.data.data.CaseDetails[0].CaseDetails?.BoguaOfficeRemarks || "",
              AuthorityRemarks: response.data.data.CaseDetails[0].CaseDetails?.AuthorityRemarks || "",

              remarksappres: response.data.data.CaseDetails[0].CaseDetails?.BoresiRemarks || "",
              remarksappoff: response.data.data.CaseDetails[0].CaseDetails?.BoofficeRemarks || "",
              remarkscoappres: response.data.data.CaseDetails[0].CaseDetails?.BocoAppResiRemarks || "",
              remarkscoappoff: response.data.data.CaseDetails[0].CaseDetails?.BocoAppOfficeRemarks || "",
              remarksguaoff: response.data.data.CaseDetails[0].CaseDetails?.BoguaResiRemarks || "",
              remarksguares: response.data.data.CaseDetails[0].CaseDetails?.BoguaOfficeRemarks || "",

              backofficestatusappres: response.data.data.CaseDetails[0].CaseDetails?.BoresiStatus || 0,
              backofficestatusappoff: response.data.data.CaseDetails[0].CaseDetails?.BoofficeStatus || 0,
              backofficestatuscoappres: response.data.data.CaseDetails[0].CaseDetails?.BocoAppResiStatus || 0,
              backofficestatuscoappoff: response.data.data.CaseDetails[0].CaseDetails?.BocoAppOfficeStatus || 0,
              backofficestatusguares: response.data.data.CaseDetails[0].CaseDetails?.BoguaResiStatus || 0,
              backofficestatusguaoff: response.data.data.CaseDetails[0].CaseDetails?.BoguaOfficeStatus || 0,


              // overAllStatus: item?.OverallCaseStatus || '',
              qcOverAllStatus: response.data.data.CaseDetails[0].CaseDetails?.OverallCaseStatus || '',
              qcRemarks: response.data.data.CaseDetails[0].CaseDetails?.QcoverAllRemarks || '',

              ResiCheck: response.data.data.ResiCheck,
              OfficeCheck: response.data.data.OfficeCheck,
              CoAppResiCheck: response.data.data.CoAppResiCheck,
              CoAppOfficeCheck: response.data.data.CoAppOfficeCheck,
              GuaResiCheck: response.data.data.GuaResiCheck,
              GuaOfficeCheck: response.data.data.GuaOfficeCheck,
              InspectionData: { ...obj }, _view: true,
            }
          });
          const docData = _allData?.filter((aItem: any) => aItem?.CaseDetails?.precaseid === item?.Id)[0]
          debugger;
          dispatch({
            type: "QctrayprecasedocProperties", data: { ...item, documentProperties: response?.data?.data?.CaseDetails[0]?.documentProperties, _view: true, }
          })
          dispatch({
            type: "QctrayprecaseinspectionImages", data: { ...item, InspectiondocumentProperties: response?.data?.data?.CaseDetails[0]?.InspectiondocumentProperties, _view: true, }
          })

        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const viewDashboardData = (id: any) => {
    getPreCasetable(user.id, page, rowsPerPage, user?.CorporateDetailsId)
      .then(response => {
        if (response.status === 200) {
          console.log(response?.data)
          const caseData = response?.data?.Data?.filter((content: any) => content?.precaseid == id)
          getDashboardData(caseData[0]);
        }
      })
      .catch(error => console.log(error))
  }

  const getDashboardData = (item: any) => {
    getallQcInspectionDataPre(item?.precaseid)
      .then((response) => {
        if (response?.data?.status === 'Success') {
          const arr = [...response.data.data.ResiCheck, ...response.data.data.OfficeCheck, ...response.data.data.CoAppResiCheck,
          ...response.data.data.CoAppOfficeCheck, ...response.data.data.GuaResiCheck, ...response.data.data.GuaOfficeCheck]
          let obj: any = {}
          arr.forEach((temp: any) => {
            obj[temp?.InspectionID] = temp;
          })
          debugger;
          dispatch({
            type: "QctrayPrecaseData", data: { ...response?.data?.data?.CaseDetails[0]?.CaseDetails, _view: true, }
          });
          dispatch({
            type: "QctrayprecaseInspectionData", data: {
              ...item,

              backOfficeResidentStatus: item?.BoresiStatus || 0,
              backOfficeResidentOffice: item?.BoofficeStatus || 0,
              backOfficeCoApplicant: item?.BocoAppResiStatus || 0,
              backOfficeCoAppOffice: item?.BocoAppOfficeStatus || 0,
              guaBackOfficeStatus: item?.BoguaResiStatus || 0,
              guaOfficeBackOfficeStatus: item?.BoguaOfficeStatus || 0,


              residentLocation: item?.ResiLocation || 0,
              residentOfficeLocation: item?.OfficeLocation || 0,
              coAppLocation: item?.CoAppResiLocation || 0,
              coAppOfficeLocation: item?.CoAppOfficeLocation || 0,
              guarantorLocation: item?.GuaResiLocation || 0,
              guarantorOfficeLocation: item?.GuaOfficeboLocation || 0,

              AppLocation: item?.ResiLocation || 0,
              AppOfficeLocation: item?.OfficeLocation || 0,
              CoAppLocation: item?.CoAppResiLocation || 0,
              CoAppOfficeboLocation: item?.CoAppOfficeLocation || 0,
              GuaLocation: item?.GuaResiLocation || 0,
              GuaOfficeboLocation: item?.GuaOfficeboLocation || 0,

              residentRemarks: item?.BoresiRemarks || "",
              resOfficeRemarks: item?.BoofficeRemarks || "",
              coAppRemarks: item?.BocoAppResiRemarks || "",
              coAppOfficeRemarks: item?.BocoAppOfficeRemarks || "",
              guarantorRemarks: item?.BoguaResiRemarks || "",
              guarantorOfficeRemarks: item?.BoguaOfficeRemarks || "",
              AuthorityRemarks: item?.AuthorityRemarks || "",

              remarksappres: item?.BoresiRemarks || "",
              remarksappoff: item?.BoofficeRemarks || "",
              remarkscoappres: item?.BocoAppResiRemarks || "",
              remarkscoappoff: item?.BocoAppOfficeRemarks || "",
              remarksguaoff: item?.BoguaResiRemarks || "",
              remarksguares: item?.BoguaOfficeRemarks || "",

              backofficestatusappres: item?.BoresiStatus || 0,
              backofficestatusappoff: item?.BoofficeStatus || 0,
              backofficestatuscoappres: item?.BocoAppResiStatus || 0,
              backofficestatuscoappoff: item?.BocoAppOfficeStatus || 0,
              backofficestatusguares: item?.BoguaResiStatus || 0,
              backofficestatusguaoff: item?.BoguaOfficeStatus || 0,


              // overAllStatus: item?.OverallCaseStatus || '',
              qcOverAllStatus: item?.OverallCaseStatus || '',
              qcRemarks: item?.QcoverAllRemarks || '',

              ResiCheck: response.data.data.ResiCheck,
              OfficeCheck: response.data.data.OfficeCheck,
              CoAppResiCheck: response.data.data.CoAppResiCheck,
              CoAppOfficeCheck: response.data.data.CoAppOfficeCheck,
              GuaResiCheck: response.data.data.GuaResiCheck,
              GuaOfficeCheck: response.data.data.GuaOfficeCheck,
              InspectionData: { ...obj }, _view: true,
            }
          });
          dispatch({
            type: "QctrayprecasedocProperties", data: { ...item, ...item, _view: true, }
          })
          dispatch({
            type: "QctrayprecaseinspectionImages", data: { ...item, InspectiondocumentProperties: response?.data?.data, _view: true, }
          })

        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  useEffect(() => {
    if (preId && user?.BankId != null && user?.BankId != 0) {
      viewDashboardData(preId)
    }
  }, [preId])

  const refreshPage = () => {
    dispatch({ type: "QctrayPrecaseData", data: {} });
    dispatch({ type: "QctrayprecaseInspectionData", data: {} });
    dispatch({ type: "QctrayprecasedocProperties", data: {} });
    dispatch({ type: "QctrayprecaseinspectionImages", data: {} });
    dispatch({ type: "preInspectionValidation", data: {} });

    setCount(1)
  }

  const dateFilter = () => {
    debugger;
    getQcCaseGridData(_fromDate, _toDate, user.id, user.CorporateDetailsId)
      .then(response => {
        if (response.status === 200) {
          console.log('QcPrecasedata', response?.data);
          setTableData(response?.data?.data);
          //setTableData([...response?.data?.data?.map((item: any) => { return { ...item?.CaseDetails } })]);
          // _setTotalRecordCount(response?.data?.result3?.length)
        }
        else {
          swal(response.data.Data.Message, { icon: 'warning' });
        }
      }).catch(error => {
        swal("Something Went Wrong", { icon: 'error' });
      }).finally(() => _setTableLoading(false));
  }

  const getTableData = (pageNumber: any, pageSize: any) => {
    debugger;
    //  getAllPrecaseqcInspectionData(pageNumber, pageSize)
    getQcCaseGridData("", "", user.id, user.CorporateDetailsId)
      .then(response => {
        if (response.status === 200) {
          debugger;
          console.log('QcPrecasedata', response?.data);
          setTableData(response?.data?.data);
          //setTableData([...response?.data?.data?.map((item: any) => { return { ...item?.CaseDetails } })]);
          _setTotalRecordCount(response?.data?.data?.length)
        }
        else {
          swal(response.data.Data.Message, { icon: 'warning' });
        }
      }).catch(error => {
        swal("Something Went Wrong", { icon: 'error' });
      }).finally(() => _setTableLoading(false));
  }


  // useEffect(() => {
  //   dateFilter();
  //   refreshPage();
  //   getTableData(page, rowsPerPage);
  //   MisDownload();
  // }, []);

  // useEffect(() => {
  //   getTableData(page, rowsPerPage);
  // }, [page, rowsPerPage])


  useEffect(() => {
    refreshPage();
    getTableData(page, rowsPerPage);
    MisDownload();
  }, []);

  useEffect(() => {
    dateFilter();
  }, [_fromDate, _toDate])


  const exportPDF = () => {
    var curDate = moment()?.format("YYYY-MM-DD")
    const filename = 'BackOffice_PreCase_' + curDate + '.pdf'
    const headers = [
      { key: 'caseNo', display: 'Case No' },
      { key: 'caseDate', display: 'Case Date' },
      { key: 'vendorName', display: 'Vendor Name' },
      { key: 'BankName', display: 'Bank Agency' },
      { key: 'productName', display: 'Product' },
      { key: 'segmentName', display: 'Segment' },
      { key: 'customerName', display: 'Customer Name' },
      { key: 'fieldExecutiveName', display: 'Executive Name' },
    ]

    pdfBackOffice({ data: tableData, headers, filename })
  }
  const exportEXCEL = () => {
    const header = ["Case No", "Case Date", "Vendor Name", "Bank Agency", "Product", "Segment", "Customer Name", "Executive Name"];
    let body: any = tableData.map((e: any) => { return [e.caseNo, e.caseDate, e.vendorName, e.BankName, e.productName, e.segmentName, e.customerName, e.fieldExecutiveName] })
    var curDate = moment()?.format("YYYY-MM-DD")
    var filename = 'BackOffice_PreCase_' + curDate;
    downloadExcel({
      fileName: filename,
      sheet: "BackOffice_PreCase",
      tablePayload: {
        header,
        body: body,
      },
    });
  }
  const MisDownload = () => {
    debugger;
    var PageName = "QcTray";
    var UserID = user.id;
    var caseType = "precase";

    getMISforXcellDownload(caseType, UserID, PageName, user?.CorporateDetailsId).then((response) => {
      if (response.data.status === "Success") {
        debugger;
        _setmisreportExport(response.data.data);

      }
    })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const exportMISEXCEL = () => {
    debugger;
    const header = ["CaseNo", "vendorName", "bankName", "branchName", "LoanAmount", "BankEmployeeName", "DsaDesName", "productName", "BankReferenceNo",
      "TypeOfSampling", "CustomerName", "CustomerCity", "CustomerPincode", "FathersName", "DateOfBirth", "EmployeType", "CompanyName", "OfficeCity",
      "OfficePincode", "Designation", "OfficeTrigger", "ResidencyTrigger", "CoAppTrigger", "CoAppOfficeTrigger", "GuarantorTrigger", "GuarantorOfficeTrigger",
      "QcresiRemarks", "QcofficeRemarks", "QccoAppOfficeRemarks", "QcguaOfficeRemarks", "QcguaResiRemarks", "CaseDate", "QcCloseDate",
      "AppFieldStaffStatusName", "AppOfficeFieldStaffStatusName", "CoAppFieldStaffStatusName", "CoAppOfficeFieldStaffStatusName",
      "GuarantorFieldStaffStatusName",
      "ApplicantFieldStaffName", "ApplicantOfficeFieldStaffName", "CoAppFieldStaffName", "CoAppOfficeFieldStaffName", "GuarantorFieldStaffName", "GuarantorOfficeFieldStaffName",

      "AppBoLocation", "OfficeAppBoLocation", "CoappBoLocation", "CoappOfficeBoLocation", "GuaBoLocation", "GuaBoOfficeLocation",
      "Resikm", "Officekm", "CoAppkm", "CoAppOfficekm", "Guakm", "GuaOfficekm", "ResiBokm", "OfficeBokm", "CoAppBokm", "CoAppOfficeBokm", "GuaBokm", "GuaOfficeBokm",];
    let body: any = _misreportExport.map((e: any) => {
      return [e.CaseNo, e.vendorName, e.bankName, e.branchName, e.LoanAmount, e.BankEmployeeName, e.DsaDesName, e.productName, e.BankReferenceNo,
      e.TypeOfSampling, e.CustomerName, e.CustomerCity, e.CustomerPincode, e.FathersName, e.DateOfBirth, e.EmployeType, e.CompanyName, e.OfficeCity,
      e.OfficePincode, e.Designation, e.OfficeTrigger, e.ResidencyTrigger, e.CoAppTrigger, e.CoAppOfficeTrigger, e.GuarantorTrigger, e.GuarantorOfficeTrigger,
      e.QcresiRemarks, e.QcofficeRemarks, e.QccoAppOfficeRemarks, e.QcguaOfficeRemarks, e.QcguaResiRemarks, e.CaseDate, e.QcCloseDate,
      e.AppFieldStaffStatusName, e.AppOfficeFieldStaffStatusName, e.CoAppFieldStaffStatusName, e.CoAppOfficeFieldStaffStatusName,
      e.GuarantorFieldStaffStatusName,
      e.ApplicantFieldStaffName, e.ApplicantOfficeFieldStaffName, e.CoAppFieldStaffName, e.CoAppOfficeFieldStaffName, e.GuarantorFieldStaffName, e.GuarantorOfficeFieldStaffName,

      e.AppBoLocation, e.OfficeAppBoLocation, e.CoappBoLocation, e.CoappOfficeBoLocation, e.GuaBoLocation, e.GuaBoOfficeLocation,
      e.Resikm, e.Officekm, e.CoAppkm, e.CoAppOfficekm, e.Guakm, e.GuaOfficekm, e.ResiBokm, e.OfficeBokm, e.CoAppBokm, e.CoAppOfficeBokm, e.GuaBokm, e.GuaOfficeBokm,]
    })
    var curDate = moment()?.format("YYYY-MM-DD")
    var filename = 'QC_Precase_MIS_Report_' + curDate;
    downloadExcel({
      fileName: filename,
      sheet: "Product_Master",
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = _orderBy === property && _order === 'asc';
    _setOrder(isAsc ? 'desc' : 'asc');
    _setOrderBy(property);
  };

  useEffect(() => {
    const data = sortTableData(tableData, _order, _orderBy);
    setTableData(data)
  }, [_order, _orderBy])

  return (
    <>
      <div className={`d-flex justify-content-between align-items-center bg-primary text-white p-3 ${user?.BankId ? 'roundedTop' : ''}`}>
        <div>
          <h5 className="mb-0">
            <span className="mx-2">
              <img src={inputdataicon} />
            </span>
            QC Tray-Pre Case
          </h5>
        </div>
      </div>
      <div className="bg-white p-3 content-area">
        <div
          className={`text-white mb-3 mt-3 px-4 d-flex align-items-center bg-primary ${user?.BankId ? 'rounded--1 py-2' : ''}`}
          style={{ borderRadius: "2px" }}
        >
          <TabContext value={""}>
            <TabList aria-label="lab API tabs example">
              <Tab
                label="Pre Case Details"
                className={clsx(
                  count === 1 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(1)}
              />
              <Tab
                label="Pre Case Documents"
                className={clsx(
                  count === 2 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(2)}
              />
              <Tab
                label="Inspection Details"
                className={clsx(
                  count === 3 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(3)}
              />
              <Tab
                label="Images"
                className={clsx(
                  count === 4 ? "light-blue" : "bg-primary",
                  "text-white fs-6 rounded-3"
                )}
                onClick={() => setCount(4)}
              />
            </TabList>
          </TabContext>
        </div>
        <div>
          {count === 1 && <Backofficetray />}
          {count === 2 && <Postcasedocument />}
          {count === 3 && <PostbackofficeTray />}
          {count === 4 && <Postimages />}

          <div className="row">
            <div className=" col-md-8 text-end pe-3 d-flex justify-content-end">
              {(user?.BankId != null && user?.BankId != 0) && <Button
                className="bg-danger text-white me-2"
                sx={{ width: 140, p: 2 }}
                onClick={() => navigate(routerPath?.dashboard)}
              >Close <Close className="h-15" />
              </Button>}
              {count === 1 ? (
                ""
              ) : (
                <Button
                  className="bg-secondary text-white mx-5"
                  sx={{ width: 170, padding: 2 }}
                  onClick={() => setCount(count - 1)}
                >
                  <ArrowBackIosIcon className="h-15" /> Back
                </Button>
              )}
              <Button
                className={clsx(
                  count === 4 ? "d-none" : "bg-success text-white"
                )}
                sx={{ width: 170, p: 2 }}
                onClick={() => setCount(count + 1)}
              >
                Next <ArrowForwardIosIcon className="h-15" />
              </Button>

              {!(user?.BankId != null && user?.BankId != 0) && count === 4 && (
                <LoadingButton disabled={!_submit} className={clsx(`${_submit && !_loading ? 'bg-success' : 'bg-light-1 text-muted'} text-white`)} loadingPosition="end" endIcon={<></>}
                  loading={_loading} fullWidth sx={{ width: 170, p: 2 }}
                  onClick={onSaveClick}>Submit</LoadingButton>
              )}
              {!(user?.BankId != null && user?.BankId != 0) && <Button className="bg-danger text-white ms-2" sx={{ width: 170, p: 2 }}
                onClick={refreshPage}>Clear <DoDisturb className="h-15" /></Button>}
            </div>
          </div>
        </div>
        <hr />
        {/* coming soon */}
        {/* <Outlet/> */}
        {!(user?.BankId != null && user?.BankId != 0) && <>
          <div className="bg-primary py-2 mt-5 gx-0 text-white fw-bold ps-3 row d-flex align-items-center justify-content-around">
            <div className="col-md-6 fs-4">QC Tray</div>
            <div className="col-md-6 px-2 d-flex justify-content-around">
              <input
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search"
                className="my-2 px-2 rounded-2 w-100 border border-white"
              />
              {/* <Button>
              <img
                src={PDF}
                alt=""
                className="text-end mx-1"
                role="button"
                style={{ width: "3rem" }} onClick={exportPDF}
              />
            </Button> */}
              <Button>
                <img
                  src={Excel}
                  onClick={exportEXCEL}
                  className="text-end mx-1"
                  alt=""
                  role="button"
                  style={{ width: "3rem" }}
                />
              </Button>
              <AssignmentReturnedIcon onClick={exportMISEXCEL} className='' style={{ fontSize: "3.5rem" }} />
            </div>
          </div>
          <TableContainer component={Paper}>


            <div className="row mt-4 mx-2">
              <div className="col-md-10 col-lg-12">
                <div className="row">
                  <div className="col-md-2 text-center d-flex align-items-center">Start Date</div>
                  <div className="col-md-3 p-0" style={{ marginLeft: "-1rem", borderRight: "1px solid black" }}>
                    <TextField
                      size='small'
                      id="date" autoComplete='off'
                      label=" "
                      type="date"
                      //  defaultValue="2017-05-24"
                      value={_fromDate}
                      onChange={(e: any) => _setFromDate(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                    />
                  </div>

                  <div className="col-md-2 d-flex align-items-center text-center">End Date</div>
                  <div className="col-md-3 p-0" style={{ marginLeft: "-2rem" }}>
                    <TextField
                      size='small'
                      id="date" autoComplete='off'
                      label=" "
                      type="date"
                      // defaultValue="2017-05-24"
                      value={_toDate}
                      onChange={(e: any) => _setToDate(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                    />
                  </div>
                  <div className="col-md-2">
                    <Button className='border rounded bg-primary text-white' onClick={dateFilter} >
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <SortableTableHead
                order={_order}
                orderBy={_orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  { id: '', label: 'View', align: 'center' },
                  { id: 'Status', label: 'Status', align: 'center' },
                  // { id: 'caseNo', label: 'Case No', align: 'left' },
                  { id: 'caseDate', label: 'Case Date', align: 'center' },
                  // { id: 'vendorName', label: 'Vendor Name', align: 'center' },
                  { id: 'bankReferenceNo', label: 'Bank Ref No', align: 'center' },
                  { id: 'BankName', label: 'Bank Agency', align: 'center' },
                  { id: 'productName', label: 'Product Name', align: 'center' },
                  { id: '', label: 'Query', align: 'center' },
                  { id: 'customerName', label: 'Customer Name', align: 'center' },
                  { id: '', label: 'Executive Name', align: 'center' },
                  { id: 'AppFieldStaffEndDate', label: 'App Res VerifiedOn', align: 'center' },
                  { id: 'AppOfficeFieldStaffEndDate', label: 'App Office VerifiedOn', align: 'center' },
                  { id: 'CoAppFieldStaffEndDate', label: 'CoApp Res VerifiedOn', align: 'center' },
                  { id: 'CoAppOfficeFieldStaffEndDate', label: 'CoApp Office VerifiedOn', align: 'center' },
                  { id: 'GuarantorFieldStaffEndDate', label: 'Guarantor Res VerifiedOn', align: 'center' },
                  { id: 'GuarantorOfficeFieldEndDate', label: 'Guarantor Office VerifiedOn', align: 'center' },
                  { id: 'BackOfficeCloseDate', label: 'Back Office Close Date', align: 'center' },
                  { id: 'VerifierCompletedDate', label: 'Verifier Completed Date', align: 'center' },
                ]}
              />
              <TableBody>
                {tableData?.length
                  ? tableData?.filter((content: any) =>
                    // `${content?.caseNo}`?.toLowerCase().includes(`${query}`.toLowerCase()) 
                    // || `${content?.vendorName}`?.toLowerCase().includes(`${query}`.toLowerCase()) 
                    `${content?.bankReferenceNo}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.caseDate}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.BankName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.productName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.segmentName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.customerName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.fieldExecutiveName}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.ResiQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.OfficeQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.CoAppResiQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.CoAppOfficeQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.GuaResiQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.GuaOfficeQuery}`?.toLowerCase().includes(`${query}`.toLowerCase())
                    || `${content?.ApllicantFiledStaffName + ' ' +
                      content?.ApllicantOfficeFiledStaffName + ' ' +
                      content?.coApplicantDetails?.coAppFieldStaffName + ' ' +
                      content?.coApplicantDetails?.coOfficeFieldStaffName + ' ' +
                      content?.gaurantorDetails?.gaurantorFieldStaffName + ' ' +
                      content?.gaurantorDetails?.gaurantorOfficeFieldStaffName}`?.toLowerCase().includes(`${query}`.toLowerCase()))
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item: any, index: any) => (
                      <TableRow
                        key={index + 1}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            className="bg-success"
                            style={{ borderRadius: "10px" }}
                            onClick={() => viewData(item)}
                            type="submit"
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          {/* <Button
                      variant="contained"
                      className="bg-success"
                      style={{ borderRadius: "10px" }}
                      type="submit"
                    >
                    </Button> */}
                          {item?.StatusName === "Completed" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Completed
                            </Button>
                          )}
                          {item?.StatusName === "Completed-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-info text-white px-4  text-nowrap"
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Completed TAT Breached
                            </Button>
                          )}
                          {item?.StatusName === "In Progress" && (
                            <Button
                              variant="contained"
                              className="bg-primary px-4  "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              InProgress
                            </Button>
                          )}

                          {item?.StatusName === "In Progress-TAT Breached" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              In Progress-TAT Breached
                            </Button>
                          )}
                          {item?.StatusName === "Cancelled-Reassign" && (
                            <Button
                              variant="contained"
                              className="bg-danger px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              Cancelled-Reassign
                            </Button>
                          )}
                          {item?.StatusName === "Verification Started" && (
                            <Button
                              variant="contained"
                              className="bg-success px-4 "
                              type="submit"
                            >
                              Verification Started
                            </Button>
                          )}
                          {item?.StatusName === "QC Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              QC Tray Pending
                            </Button>
                          )}
                          {item?.StatusName === "B.O Tray Pending" && (
                            <Button
                              variant="contained"
                              className="bg-warning px-4 text-nowrap "
                              style={{ borderRadius: "10px" }}
                              type="submit"
                            >
                              B.O Tray Pending
                            </Button>
                          )}
                        </TableCell>

                        {/* <TableCell component="th" scope="row">{item?.caseNo}</TableCell> */}
                        <TableCell className="text-nowrap" align="center">{item?.CaseDate?.split("T")[0]}</TableCell>
                        {/* <TableCell align="center">{item?.vendorName}</TableCell> */}
                        <TableCell align="center">{item?.BankReferenceNo}</TableCell>
                        <TableCell align="center">{item?.BankName}</TableCell>
                        <TableCell align="center">{item?.ProductName}</TableCell>
                        <TableCell align="center">
                          {item?.ResiQuery?.length > 0 ? <span>{item?.ResiQuery}, </span> : ''}
                          {item?.OfficeQuery?.length > 0 ? <span>{item?.OfficeQuery}, </span> :
                            <span></span>}
                          {item?.CoAppResiQuery?.length > 0 ? <span>{item?.CoAppResiQuery}, </span> :
                            <span></span>}
                          {item?.CoAppOfficeQuery?.length > 0 ? <span>{item?.CoAppOfficeQuery}, </span> :
                            <span></span>}
                          {item?.GuaResiQuery?.length > 0 ? <span>{item?.GuaResiQuery}, </span> :
                            <span></span>}
                          {item?.GuaOfficeQuery?.length > 0 ? <span>{item?.GuaOfficeQuery}</span> :
                            <span></span>}
                        </TableCell>
                        <TableCell align="center">{item?.CustomerName}</TableCell>
                        <TableCell align="center">
                          {`${item?.ResidenceFieldExecutiveName?.length > 0 ? item?.ResidenceFieldExecutiveName + ', ' : ''} 
                        ${item?.OfficeFieldExecutiveName?.length > 0 ? item?.OfficeFieldExecutiveName + ', ' : ''}
                        ${item?.CoAppFieldStaffName?.length > 0 ? item?.CoAppFieldStaffName + ', ' : ''}
                        ${item?.CoOfficeFieldStaffName?.length > 0 ? item?.CoOfficeFieldStaffName + ', ' : ''}
                        ${item?.GaurantorFieldStaffName?.length > 0 ? item?.GaurantorFieldStaffName + ', ' : ''}
                        ${item?.GaurantorOfficeFieldStaffName?.length > 0 ? item?.GaurantorOfficeFieldStaffName + ', ' : ''}`}
                        </TableCell>
                        <TableCell align="center">{item?.AppFieldStaffEndDate || "-"}</TableCell>
                        <TableCell align="center">{item?.AppOfficeFieldStaffEndDate || "-"}</TableCell>
                        <TableCell align="center">{item?.CoAppFieldStaffEndDate || "-"}</TableCell>
                        <TableCell align="center">{item?.CoAppOfficeFieldStaffEndDate || "-"}</TableCell>
                        <TableCell align="center">{item?.GuarantorFieldStaffEndDate || "-"}</TableCell>
                        <TableCell align="center">{item?.GuarantorOfficeFieldEndDate || "-"}</TableCell>
                        <TableCell align="center">{formatDateTime(item?.BackOfficeCloseDate) || "-"}</TableCell>
                        <TableCell align="center">{formatDateTime(item?.VerifierCompletedDate) || "-"}</TableCell>
                      </TableRow>
                    ))
                  : !tableData && (
                    <TableRow key={0}>
                      <TableCell align={"center"}>
                        <h3 className="text-muted">Data Not Found</h3>
                      </TableCell>
                    </TableRow>
                  )}
                <SkeletonProviderTables columns={8} visible={_tableLoading} />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={_totalRecordCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /></>}
      </div>
    </>
  );
}

export default Index;