import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../../../providers/stateProvider';
import { getsegmentnames, getVendornames, getVerificationDet, getBankByVendorId, getBranchByBankId, getProductByBankandBranchId, getAuthorityMaster, getEmployeeTypes, getSamplingDetails, getAddressTypes, getStaffDetails, getAllHubMaster, getStateDetails, getDesignationList } from "../../../../Models/configapi";
import moment from 'moment';

export default function Index() {
  const [{ user, backOfficePostCaseData }, dispatch]: any = useStateValue();

  const [getVendors, setVendors] = useState<any>([]);
  const [_getBanknames, setBanknames] = useState<any>([]);
  const [BranchData, setBranchData] = useState<any>([]);
  const [_product, _setProduct] = useState<any>([]);
  const [_verifiedAt, _setVerifiedAt] = useState<any>([]);
  const [_AuthorityMaster, _setAuthorityMaster] = useState<any>([]);
  const [_snackMessage, _setSnackMessage] = useState("");
  const [_getSegmentnames, setSegmentnames] = useState<any>([]);
  const [_EmployeeType, _setEmployeeType] = useState<any>([]);
  const [_SamplingData, _setSamplingData] = useState<any>([]);
  const [_AddressTypeData, _setAddressTypeData] = useState<any>([]);
  const [_staffName, _setStaffName] = useState<any>([]);
  const [_hubId, _setHubId] = useState<any>([]);

  const [_desList, _setDesList] = useState<any>([]);
  const [_StateList, _setStateList] = useState<any>([]);

  const [_formData, _setFormData] = useState<any>({
    vendorId: 0,
    bankid: 0,
    branchid: 0,
    productId: 0,
    caseNo: "",
    caseDate: moment()?.format("YYYY-MM-DD"),
    bankReferenceNo: "",
    segmentId: 0,
    verificationAtId: 0,
    authorityMasterId: 0,
    customerName: "",
    residentialCustomerAddress1: "",
    residentialCustomerAddress2: "",
    residentialCity: "",
    residentialPincode: "",
    residentialState: "",
    residentialMobileNo: "",
    residentialCountryCode: "",
    residentialEmail: "",
    fatherName: "",
    motherName: "",
    panNo: "",
    aadhaarNo: "",
    dob: "",
    designation: "",
    employeeTypeId: "",
    customersamplingDetailsId: "",
    customeraddressTypeId: "",
    officeName: "",
    officeAddress1: "",
    officeAddress2: "",
    officeCity: "",
    officeState: "",
    officePincode: "",
    officeLandMark: "",
    officeCountryCode: "",
    officeLandLine: "",
    officeMobileNumber: "",
    loanAmount: "",
    bankEmployee: "",
    dsaOrDesName: "",
    reqLoggedBy: "",
    fieldExecutiveName: "",
    status: "",
    trigger: "",
    boStaffName: "",
    hubId: "",
    coAddressType: '',
    coApplicantName: '',
    coAddress: '',
    coAddress_2: '',
    coCity: '',
    coPincode: '',
    coState: '',
    coMobileNo: '',
    coEmailAddress: '',
    gaurantorAddressType: '',
    gaurantorName: '',
    gaurantorAddress_1: '',
    gaurantorAddress_2: '',
    gaurantorCity: '',
    gaurantorPincode: '',
    gaurantorState: '',
    gaurantorMobileNo: '',
    gaurantorEmail: '',
    coApplicantEnable: false,
    coGaurantorEnable: false
  });
  const setFormValue = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
    dispatch({ type: "backOfficePostCaseData", data: { ..._formData, ...backOfficePostCaseData, [name]: value } });
  };

  const getvendordetails = () => {
    getVendornames(user.CorporateDetailsId)
      .then(response => {
        setVendors(response.data.VendorDetails);
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  const getBankDatasbyVendorId = (value: any) => {
    getBankByVendorId({ ..._formData, VendorId: value,lCorporateId:user?.CorporateDetailsId }).then((response) => {
      if (response.data.BankingDetails.length === 0) {
        setFormValue('vendorId', 0)
        return;
      }
      setBanknames(response.data.BankingDetails);
      return;
    }).catch(error => {
      console.log(error.response.data);
      _setSnackMessage("Something Went Wrong Please try again");
    });
  }

  const getBranchDatasbyBankId = (value: any) => {
    getBranchByBankId({ ..._formData, BankId: value,lCorporateId:user?.CorporateDetailsId }).then((response) => {
      if (response.data.BranchDetails.length === 0) {
        setFormValue('bankid', 0);
        return;
      }
      setBranchData(response.data.BranchDetails);
      return;
    }).catch(error => {
      _setSnackMessage("Something Went Wrong Please try again");
    });
  }

  const getProductBybankandBranchId = (value: any) => {
    getProductByBankandBranchId(value).then((response) => {
      if (response?.data?.data?.ProductDetails.length === 0) {
        setFormValue('branchid', 0);
        return;
      }
      _setProduct(response?.data?.data?.ProductDetails);
      return;
    }).catch(error => {
      console.log(error.response.data);
      _setSnackMessage("Something Went Wrong Please try again");
    });
  };

  const getVerifications = () => {
    getVerificationDet(user.CorporateDetailsId)
      .then(response => {
        _setVerifiedAt(response.data.Verificationdetails);
      })
      .catch(error => {
        console.log(error.response);
      })
  }

  const getAuthorityDetail = () => {
    getAuthorityMaster(user.CorporateDetailsId)
      .then(response => {

        _setAuthorityMaster(response.data.authorityDetails);
      })
      .catch(error => {

        console.log(error.response);
      })
  }

  const getsegmantdetail = () => {
    getsegmentnames(user.CorporateDetailsId)
      .then((response) => {
        setSegmentnames(response.data.segmentdetails);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  const getEmployeeTypeDetail = () => {
    getEmployeeTypes(user.CorporateDetailsId)
      .then((response) => {
        _setEmployeeType(response.data.employeeTypeDetails);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getsamplingTypeDetail = () => {
    getSamplingDetails(user.CorporateDetailsId)
      .then((response) => {
        _setSamplingData(response.data.SamplingDetails);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getAddressTypeDetail = () => {
    getAddressTypes(user.CorporateDetailsId)
      .then((response) => {
        _setAddressTypeData(response.data.AddressTypeDetails);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getStaffNames = () => {
    getStaffDetails(user.CorporateDetailsId)
      .then(response => {
        _setStaffName(response.data.StaffDetails);
      }).catch(error => {

        console.log(error.response.data);
      })
  }

  const getHubId = () => {
    getAllHubMaster(user.CorporateDetailsId)
      .then(response => {
        console.log(response?.data?.data);
        _setHubId([...response?.data?.data.data]);
      }).catch(error => {
        console.log(error.response.data);
      })
    getStateDetails(user?.CorporateDetailsId)
      .then((response) => {
        console.log(response.data);
        _setStateList(response.data.StateDetails
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
    getDesignationList()
      .then((response: any) => {
        console.log(response?.data?.data);
        _setDesList([...response?.data?.data]);
      }).catch(error => {
        console.log(error.response.data);
      })
  }

  useEffect(() => {
    getvendordetails();
    getsegmantdetail();
    getVerifications();
    getAuthorityDetail();
    getEmployeeTypeDetail();
    getsamplingTypeDetail();
    getAddressTypeDetail();
    getStaffNames();
    getHubId();
  }, [])

  useEffect(() => {
    if (Object.entries(backOfficePostCaseData)) {
      _setFormData({ ...backOfficePostCaseData });
    }
  }, [backOfficePostCaseData]);

  useEffect(() => {
    if (_formData.vendorId) {
      getBankDatasbyVendorId(_formData.vendorId)
    }
  }, [_formData.vendorId]);

  useEffect(() => {
    if (_formData.bankid) {
      getBranchDatasbyBankId(_formData.bankid)
      getProductBybankandBranchId({BankId:_formData.bankid,lCorporateId:user?.CorporateDetailsId})
    }
  }, [_formData.bankid]);

  return (
    <>
      <div className="bg-white p-3 content-area">
        <div className="col-md-8 row">
          <div className="text-center fs-5 fw-bold">Case</div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Vendor Name :</div>
            <div className="col-md-8 fs14">{getVendors?.filter((item: any) => item?.Id === _formData?.vendorId)[0]?.VendorName || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Bank Name :</div>
            <div className="col-md-8 fs14">{_getBanknames?.filter((item: any) => item?.Id === _formData?.bankid)[0]?.Name || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Branch :</div>
            <div className="col-md-8 fs14">{BranchData?.filter((item: any) => item?.Id === _formData?.branchid)[0]?.BranchName || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Product :</div>
            <div className="col-md-8 fs14">{_product?.filter((item: any) => item?.Id === _formData?.productId)[0]?.ProductName || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Case Date :</div>
            <div className="col-md-8 fs14">{_formData?.caseDate?.slice(0, 10) || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Bank Reference No :</div>
            <div className="col-md-8 fs14">{_formData?.bankReferenceNo || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Types Of Sampling :</div>
            <div className="col-md-8 fs14">{_formData?.TypeofSampling || '-'}</div>
          </div>
        </div>

        <hr />

        <div className="col-md-8 row">
          <div className="text-center fs-5 fw-bold">Residential</div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Customer Name :</div>
            <div className="col-md-8 fs14">{_formData?.customerName || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Address 1 :</div>
            <div className="col-md-8 fs14">{_formData?.residentialCustomerAddress1 || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Address 2 :</div>
            <div className="col-md-8 fs14">{_formData?.residentialCustomerAddress2 || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">City :</div>
            <div className="col-md-8 fs14">{_formData?.residentialCity || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">PinCode :</div>
            <div className="col-md-8 fs14">{_formData?.residentialPincode || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">State :</div>
            <div className="col-md-8 fs14">{_formData?.residentialState || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Mobile Number :</div>
            <div className="col-md-8 fs14">{_formData?.residentialMobileNo || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Email Address :</div>
            <div className="col-md-8 fs14">{_formData?.residentialEmail || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Father Name :</div>
            <div className="col-md-8 fs14">{_formData?.fatherName || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">PAN Number :</div>
            <div className="col-md-8 fs14">{_formData?.panNo || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Proof Id :</div>
            <div className="col-md-8 fs14">{_formData?.aadhaarNo || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">D.O.B :</div>
            <div className="col-md-8 fs14">{_formData?.dob?.slice(0, 10) || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Employee Type :</div>
            <div className="col-md-8 fs14">{_EmployeeType?.filter((item: any) => item?.Id === _formData?.employeeTypeId)[0]?.TypeName || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Trigger :</div>
            <div className="col-md-8 fs14">{_formData?.ResidencyTrigger || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Field Executive Name :</div>
            <div className="col-md-8 fs14">{_formData?.ApllicantFiledStaffName || '-'}</div>
          </div>
        </div>

        <hr />

        <div className="col-md-8 row">
          <div className="text-center fs-5 fw-bold">Residential Office</div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Office Name :</div>
            <div className="col-md-8 fs14">{_formData?.officeName || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Office Address 1 :</div>
            <div className="col-md-8 fs14">{_formData?.officeAddress1 || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Office Address 2 :</div>
            <div className="col-md-8 fs14">{_formData?.officeAddress2 || '-'}</div>
          </div>


          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Office City :</div>
            <div className="col-md-8 fs14">{_formData?.officeCity || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Office Pincode :</div>
            <div className="col-md-8 fs14">{_formData?.officePincode || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Office State :</div>
            <div className="col-md-8 fs14">{_formData?.officeState || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Office Land Mark :</div>
            <div className="col-md-8 fs14">{_formData?.officeLandMark || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Office Mobile Number :</div>
            <div className="col-md-8 fs14">{_formData?.officeMobileNumber || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Office Trigger :</div>
            <div className="col-md-8 fs14">{_formData?.OfficeTrigger || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Field Executive Name :</div>
            <div className="col-md-8 fs14">{_formData?.ApllicantOfficeFiledStaffName || '-'}</div>
          </div>
        </div>

        <hr />

        <div className="col-md-8 row">

          <div className="text-center fs-5 fw-bold">Other Details</div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Loan Amount :</div>
            <div className="col-md-8 fs14">{_formData?.loanAmount || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Bank Employee :</div>
            <div className="col-md-8 fs14">{_formData?.bankEmployee || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">DSA/DES Name :</div>
            <div className="col-md-8 fs14">{_formData?.dsaOrDesName || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">Req.Lodged by :</div>
            <div className="col-md-8 fs14">{_formData?.reqLoggedBy || '-'}</div>
          </div>

          <div className="row my-2">
            <div className="col-md-4 fs14 fw-bold">B.O.Staff Name :</div>
            <div className="col-md-8 fs14">{_staffName?.filter((item: any) => item?.Id === _formData?.boStaffName)[0]?.Name || '-'}</div>
          </div>
        </div>

        {_formData?.coApplicantEnable === true && <>
          <hr />
          <div className="col-md-8 row">
            <div className="fs-5 text-center fw-bold">Co-Applicant</div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Name :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coApplicantName || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Address 1 :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coAddress || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Address 2 :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coAddress_2 || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">City :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coCity || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Pincode :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coPincode || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">State :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coState || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Mobile Number :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coMobileNo || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Email Address :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coEmailAddress || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Trigger :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coAppTrigger || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Field Executive Name :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coAppFieldStaffName || '-'}</div>
            </div>

            <div className="text-center fw-bold">Co-Applicant Office</div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Name :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficeName || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Address 1 :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficeAddress1 || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Address 2 :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficeAddress2 || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office City :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficeCity || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Pincode :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficePincode || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office State :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficeState || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Land Mark :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficeLandMark || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Landline :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficeLandLine || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Mobile Number :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficeMobileNumber || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Trigger :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficeTrigger || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Field Executive Name :</div>
              <div className="col-md-8 fs14">{_formData?.coApplicantDetails?.coOfficeFieldStaffName || '-'}</div>
            </div>
          </div>
        </>}

        {_formData?.coGaurantorEnable === true && <>

          <hr />
          <div className="col-md-8 row">

            <div className="fs-5 text-center fw-bold">Guarantor</div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Name :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorName || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Residential :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorAddress_1 || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">City :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorCity || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Pincode :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorPincode || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">State :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorState || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Mobile Number :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorMobileNo || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Email Address :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorEmail || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Field Executive Name :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorFieldStaffName || '-'}</div>
            </div>

            <div className="text-center fw-bold">Guarantor Office</div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Name :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficeName || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Address 1 :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficeAddress_1 || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Address 2 :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficeAddress_2 || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office City :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficeCity || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Pincode :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficePincode || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office State :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficeState || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Land Mark :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficeLandmark || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Landline :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficeLandLine || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Mobile Number :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficeMobileNumber || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Designation :</div>
              <div className="col-md-8 fs14">{_desList?.filter((item: any) => item?.DesignationID === _formData?.gaurantorDetails?.gaurantorDesignation)[0]?.DesignationName || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Office Trigger :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficeTrigger || '-'}</div>
            </div>

            <div className="row my-2">
              <div className="col-md-4 fs14 fw-bold">Field Executive Name :</div>
              <div className="col-md-8 fs14">{_formData?.gaurantorDetails?.gaurantorOfficeFieldStaffName || '-'}</div>
            </div>
          </div>
        </>}
      </div>
    </>
  );
}