import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useStateValue } from '../../../../providers/stateProvider';
import { Visibility } from '@mui/icons-material';
import { IconButton } from '@mui/material';


function Postcasedocument() {
    const [{ user, QctrayprecasedocProperties, QctrayPrecaseData }, dispatch]: any = useStateValue();
    const [imgData, setImgData] = useState<any>([{ index: null }]);
    const [_fields, _setFields] = useState([]);

    useEffect(() => {
        if (Object.entries(QctrayprecasedocProperties)?.length) {
            var DocData = QctrayprecasedocProperties?.documentProperties;
            let _tempChief1 = [...imgData];
            console.log('DocData', DocData);
            if (DocData?.length !== undefined && DocData?.length !== 0) {
                let arr = DocData.map((i: any, index: number) => {
                    _tempChief1[index] = { ..._tempChief1[index], index: i?.documentName }
                })
                setImgData(_tempChief1);
                _setFields(DocData);
            }
        }
    }, [QctrayprecasedocProperties]);
    return (<>

        <div className="bg-white p-3 content-area">
                <TableContainer component={Paper} >
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>S.No</TableCell>
                                <TableCell className="mb-3" align="center">Document Type</TableCell>
                                <TableCell className="mb-3" align="center">Upload document</TableCell>
                                <TableCell className="mb-3" align="center">Verification Point</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_fields?.map((row: any, index: any) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell className="mb-3" align="center">{row?.documentTypeName}</TableCell>
                                   
                                    <TableCell className="mb-3" align="center">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <i className='position-absolute'>
                                                <IconButton>
                                                    <Visibility onClick={() => window.open(row?.documentPath, "_blank")} className='text-muted' />
                                                </IconButton>
                                            </i>
                                            <img loading="lazy" onClick={() => window.open(row?.documentPath, "_blank")} src={row?.documentPath} style={{ width: "6rem", height: "6em", opacity: "50%" }} alt="document" />
                                        </div>
                                    </TableCell>
                                    {/* <TableCell align="center"><Button variant="contained" component="label">Upload File<input type="file" hidden />
                                            </Button>
                                            </TableCell> */}
                                 <TableCell className="mb-3" align="center">{row?.VerificationPerson}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        </div>
    </>);
}

export default Postcasedocument;
