import React, { useEffect, useState } from 'react';
import {
  MenuItem, TextField, FormControl, Select, FormHelperText,
  Checkbox, Radio, Button, IconButton, FormControlLabel
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  getEmployeeTypes,
  getStateDetails,
  getAllHubMaster, getCustomerDocTypes, deleteDocument, getFieldExecutiveName, getQueryList, getFieldStaffList, getAllStaffDetails
} from "../../../Models/configapi";
import { getVerificationTypeMaster, InsertDocument } from "../../../Models/model";
import { useStateValue } from '../../../providers/stateProvider';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import swal from 'sweetalert';

function Customerdetails() {

  const [{ user, postCaseData, postCaseValidation }, dispatch]: any = useStateValue();
  //const [_EmployeeType, _setEmployeeType] = useState<any>([]);
  const [_formData, _setFormData] = useState<any>({
    customerName: '',
    residentialCustomerAddress1: '',
    residentialCustomerAddress2: '',
    residentialCity: '',
    residentialPincode: '',
    residentialState: '',
    residentialMobileNo: '',
    residentialCountryCode: '',
    residentialEmail: '',
    fatherName: '',
    motherName: '',
    panNo: '',
    aadhaarNo: '',
    dob: '',
    customersamplingDetailsId: '',
    ResidencyTrigger: '',
    appHubId: 0,
    AppQueryID: 0,
    AppOffice_QueryID: 0,
    residentialFieldExecutiveName: '',
    employeeTypeId: 0,

    officeName: '',
    officeAddress1: '',
    officeAddress2: '',
    officeCity: '',
    officeState: '',
    officePincode: '',
    officeLandMark: '',
    officeCountryCode: '',
    officeLandLine: '',
    officeMobileNumber: '',
    designation: '',
    EmployeeDocumnetTypeId: 0,
    OfficeTrigger: '',
    officeHubId: 0,
    officeFieldExecutiveName: '',

    documentProperties: [],
    officeDocumentProperties: [],

    verificationTypeField: false,
    verificationTypeAuthority: false,
    appVerificationTypeID: true,
    applicantDocumentCheck: false,
    applicantProfileCheck: false,

    officeVerificationTypeField: false,
    officeVerificationTypeAuthority: false,
    appOfficeVerificationTypeID: true,
    officeDocumentCheck: false,
    officeProfileCheck: false,

  });
  const [_StateList, _setStateList] = useState<any>([]);
  const [_customerResidentialDoc, _setcustomerResidentialDoc] = useState([
    { documentNumber: 0, documentName: '', documentTypeId: 0, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }]);

  const [_customerOfficeDoc, _setcustomerOfficeDoc] = useState([
    { documentNumber: 0, documentName: '', documentTypeId: 0, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }]);

  const onFormChange = (name: string, value: any) => {
    if (name === "residentialPincode") {
      _setFormData({ ..._formData, residentialFieldExecutiveName: "", appHubId: 0, appFieldStaff: 0 });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, residentialFieldExecutiveName: "", appHubId: 0, appFieldStaff: 0, [name]: value } });
    }
    else if (name === 'officePincode') {
      _setFormData({ ..._formData, officeFieldExecutiveName: "", officeHubId: 0, officeFieldStaff: 0 });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, officeFieldExecutiveName: "", officeHubId: 0, officeFieldStaff: 0, [name]: value } });
    } else {
      _setFormData({ ..._formData, [name]: value });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
    }
  };

  const getStateList = () => {
    getStateDetails(user?.CorporateDetailsId)
      .then((response) => {
        console.log(response.data);
        _setStateList(response.data.StateDetails
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    if (Object.entries(postCaseData)?.length) {
      _setFormData({ ...postCaseData });
      if (postCaseData?.documentProperties?.length) {
        _setcustomerResidentialDoc([...postCaseData?.documentProperties?.map((item: any) => { return { ...item, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID } })])
      } else {
        _setcustomerResidentialDoc([{ documentNumber: 0, documentName: '', documentTypeId: 4, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }])
      }
      if (postCaseData?.officeDocumentProperties?.length) {
        _setcustomerOfficeDoc([...postCaseData?.officeDocumentProperties?.map((item: any) => { return { ...item, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID } })])
      } else {
        _setcustomerOfficeDoc([{ documentNumber: 0, documentName: '', documentTypeId: 4, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }])
      }
    }
  }, [postCaseData]);


  // ***************************************************

  const [_EmployeeType, _setEmployeeType] = useState<any>([]);
  const [_EmployeeDocDisplay, _setEmployeeDocDisplay] = useState(false);
  // const [_verificationType, _setVerificationType] = useState('');
  const [_hubId, _setHubId] = useState<any>([]);
  const [_fieldList, _setFieldList] = useState<any>([]);

  const [_desList, _setDesList] = useState<any>([]);
  const [_queryList, _setQueryList] = useState<any>([]);
  const [_officeQueryList, _setOfficeQueryList] = useState<any>([]);
  // const [_checkMark, _setCheckMark] = useState<any>({ profile: true, applicantDocumentCheck: false, applicantProfileCheck: false });
  // const [_verificationTypeOffice, _setVerificationTypeOffice] = useState('');
  // const [_checkMarkOffice, _setCheckMarkOffice] = useState<any>({ officeProfile: false, officeDocumentCheck: false, officeProfileCheck: true, });
  const [_profileDocType, _setProfileDocType] = useState<any>([]);
  const [_authorityDocType, _setAuthorityDocType] = useState<any>([]);


  // const onChangeCheckMarkOffice = (key: any, value: any) => {
  //   onFormChange(key, value);
  //   _setCheckMarkOffice({ ..._checkMarkOffice, [key]: value })
  // }
  // const onChangeCheckMark = (key: any, value: any) => {
  //   onFormChange(key, value);
  //   //_setCheckMark({ ..._checkMark, [key]: value })
  //   _setCheckMark({ ..._checkMark, [key]: value })
  // }

  const getHubList = () => {
    getAllHubMaster(user.CorporateDetailsId)
      .then((response: any) => {
        _setHubId([...response?.data?.data?.data]);
      }).catch(error => {
        console.log(error.response.data);
      })
    getQueryList("Resi", user.CorporateDetailsId)
      .then((response: any) => {
        _setQueryList([...response?.data?.data]);
      }).catch(error => {
        console.log(error.response.data);
      })
    getQueryList("Office", user.CorporateDetailsId)
      .then((response: any) => {
        _setOfficeQueryList([...response?.data?.data]);
      }).catch(error => {
        console.log(error.response.data);
      })
    getAllStaffDetails(user?.CorporateDetailsId)
      .then((response: any) => {
        _setFieldList([...response?.data?.Data]);
      }).catch(error => {
        console.log(error.response.data);
      })
  }

  const [_verificationTypeList, _setVerificationTypeList] = useState<any>([]);

  const getVerificationList = () => {
    getVerificationTypeMaster()
      .then((response) => {
        _setVerificationTypeList([...response?.data?.data?.data]);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getVerificationList()
    getEmployeeTypeDetail();
    getHubList();
  }, []);

  const onEmployeeTypeChange = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
    dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
    if (Number(value) === 1) {
      _setEmployeeDocDisplay(true);
    }
    else {
      _setEmployeeDocDisplay(false);
    }
  };

  const getEmployeeTypeDetail = () => {
    getEmployeeTypes(user.CorporateDetailsId)
      .then((response) => {
        _setEmployeeType(response.data.employeeTypeDetails);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getCustomerDocTypes('Field', user?.CorporateDetailsId)
      .then((response) => {
        _setProfileDocType(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    getCustomerDocTypes('Authority', user?.CorporateDetailsId)
      .then((response) => {
        _setAuthorityDocType(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);


  const onChangeCustDropdownChange = (key: any, value: any, index: any) => {
    let _tempDoc = [..._customerResidentialDoc];
    if (key === 'VerificationPerson') {
      _tempDoc[index] = { ..._tempDoc[index], [key]: value, customerDocumentTypeId: 0 };
    } else {
      _tempDoc[index] = { ..._tempDoc[index], [key]: value };
    }
    _setcustomerResidentialDoc(_tempDoc);
    onFormChange('documentProperties', _tempDoc);
  }
  const onChangeCustOfficeDropdownChange = (key: any, value: any, index: any) => {
    let _tempDoc = [..._customerOfficeDoc];
    if (key === 'VerificationPerson') {
      _tempDoc[index] = { ..._tempDoc[index], [key]: value, customerDocumentTypeId: 0 };
    } else {
      _tempDoc[index] = { ..._tempDoc[index], [key]: value };
    }
    _setcustomerOfficeDoc(_tempDoc);
    onFormChange('officeDocumentProperties', _tempDoc);
  }


  const onChangeResidentialPicture = (e: any, index: any, typeId: any) => {
    let _tempDoc = [..._customerResidentialDoc];
    debugger;
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append('DocumentTypeId', '4');
      formData.append('UserId', user?.id);
      formData.append('image', e.target.files[0]);
      console.log("picture: ", e.target.files);
      console.log("imgUrl: ", URL.createObjectURL(e.target.files[0]));
      InsertDocument(formData)
        .then(response => {
          console.log(response.data);
          if (response.data.status === "Success") {
            debugger;
            _tempDoc[index] = {
              ..._tempDoc[index],
              documentNumber: 1,
              documentName: response.data.data.FileName,
              documentPath: response.data.data.physicalPath,
              documentTypeId: 4,
              customerDocumentTypeId: Number(typeId) || 0,
            };

            _setcustomerResidentialDoc(_tempDoc);
            console.log(_tempDoc)
            onFormChange('documentProperties', _tempDoc);
          }
        }).catch(error => {
          console.log(error.response.data);
        })

    }
  }

  const removeCustomerResidentiaDoc = (index: any) => {
    var data; var imageId: any;
    //if (_customerResidentialDoc.length > 1) {
    let _tempDoc = [..._customerResidentialDoc];
    if (postCaseData?.precaseid !== undefined) {
      data = {
        id: _tempDoc[index].id,
        isActive: 0,
        type: "case",
        typeofcase: "precase",
        caseid: postCaseData?.precaseid,
        documentTypeId: 1
      };
      imageId = _tempDoc[index].id;
    }
    else {
      data = {
        id: _tempDoc[index].id,
        isActive: 0,
        type: "case",
        typeofcase: "precase",
        caseid: 0,
        documentTypeId: 1
      };
      imageId = _tempDoc[index].id;
    }

    deleteDocument(data)
      .then((response) => {
        if (response.data.status === "Success") {
          swal(response.data.data, { icon: "success" });
          const ArrayData = _tempDoc.filter((_tempDoc) => _tempDoc.id !== imageId);
          _setcustomerResidentialDoc(ArrayData);
        } else if (response.data.status === "Error") {
          swal(response.data.data, { icon: "warning" });
        }
      })
      .catch((error) => {
        swal(error.response.data, { icon: "warning" });
      });
    // }
    // else {
    //   swal("Unable to delete.", { icon: "warning" });
    // }
    //console.log(_fields);
    return;
  }
  const addNewImg = (e: any) => {
    let values = [..._customerResidentialDoc];
    values.push({
      documentNumber: 1,
      documentName: '',
      documentTypeId: 4,
      documentPath: '',
      reftype: '',
      id: values.length,
      customerDocumentTypeId: 0,
      VerificationPerson: ''
    });
    _setcustomerResidentialDoc(values);
  }
  const onResidentialHubIdChange = (name: string, value: any) => {

    var vPincode = _formData.residentialPincode;
    if (vPincode !== undefined && vPincode !== "") {
      getResidentialFieldExecutiveNames(value, vPincode);
    }
    else {
      _setFormData({ ..._formData, [name]: value });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
      // swal("Please enter the pincode.", { icon: 'warning' });
    }
    //
  };
  const getResidentialFieldExecutiveNames = (HubId: any, pincode: any) => {

    var data = {
      HubId: HubId,
      pincode: pincode,
      lCorporateId: user?.CorporateDetailsId
    }
    getFieldExecutiveName(data)
      .then((response) => {
        debugger;
        console.log(response.data);
        if (response.data.data.length > 0) {
          const exename = response.data.data[0].FirstName + " " + response.data.data[0].LastName;
          console.log(exename)
          _setFormData({ ..._formData, residentialFieldExecutiveName: exename, appHubId: HubId, appFieldStaff: response.data.data[0].Id });
          dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, residentialFieldExecutiveName: exename, appHubId: HubId, appFieldStaff: response.data.data[0].Id } });
        }
        else {
          _setFormData({ ..._formData, residentialFieldExecutiveName: "", appHubId: HubId, appFieldStaff: 0 });
          dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, residentialFieldExecutiveName: "", appHubId: HubId, appFieldStaff: 0 } });
          swal("No field executive assigned.", { icon: 'warning' });
        }
        //fieldExecutiveName
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const removeCustomerOfficeDoc = (index: any) => {
    var data; var imageId: any;
    //if (_customerOfficeDoc.length > 1) {
    let _tempDoc = [..._customerOfficeDoc];
    if (postCaseData?.precaseid !== undefined) {
      data = {
        id: _tempDoc[index].id,
        isActive: 0,
        type: "case",
        typeofcase: "precase",
        caseid: postCaseData?.precaseid,
        documentTypeId: 1
      };
      imageId = _tempDoc[index].id;
    }
    else {
      data = {
        id: _tempDoc[index].id,
        isActive: 0,
        type: "case",
        typeofcase: "precase",
        caseid: 0,
        documentTypeId: 1
      };
      imageId = _tempDoc[index].id;
    }

    deleteDocument(data)
      .then((response) => {
        if (response.data.status === "Success") {
          swal(response.data.data, { icon: "success" });
          const ArrayData = _tempDoc.filter((_tempDoc) => _tempDoc.id !== imageId);
          _setcustomerOfficeDoc(ArrayData);

        } else if (response.data.status === "Error") {
          swal(response.data.data, { icon: "warning" });
        }
      })
      .catch((error) => {
        swal(error.response.data, { icon: "warning" });
      });
    // }
    // else {
    //   swal("Unable to delete.", { icon: "warning" });
    // }
    //console.log(_fields);
    return;
  }
  const addNewImgOffice = (e: any) => {
    let values = [..._customerOfficeDoc];
    values.push({
      documentNumber: 2,
      documentName: '',
      documentTypeId: 5,
      documentPath: '',
      reftype: '',
      id: values.length,
      customerDocumentTypeId: 0,
      VerificationPerson: ''
    });
    _setcustomerOfficeDoc(values);

  }
  const onChangeOfficePicture = (e: any, index: any, typeId: any) => {
    let _tempDoc = [..._customerOfficeDoc];
    debugger;
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append('DocumentTypeId', '9');
      formData.append('UserId', user?.id);
      formData.append('image', e.target.files[0]);
      console.log("picture: ", e.target.files);
      console.log("imgUrl: ", URL.createObjectURL(e.target.files[0]));
      InsertDocument(formData)
        .then(response => {
          console.log(response.data);
          if (response.data.status === "Success") {

            _tempDoc[index] = {
              ..._tempDoc[index],
              documentNumber: 2,
              documentName: response.data.data.FileName,
              documentPath: response.data.data.physicalPath,
              documentTypeId: 9,
              customerDocumentTypeId: Number(typeId) || 0
            };
            debugger;
            _setcustomerOfficeDoc(_tempDoc);
            onFormChange('officeDocumentProperties', _tempDoc);
          }
        }).catch(error => {
          console.log(error.response.data);
        })

    }
  }
  const onOfficeHubIdChange = (name: string, value: any) => {

    var vPincode = _formData.officePincode;
    if (vPincode !== undefined && vPincode !== "") {
      getOfficeFieldExecutiveNames(value, vPincode);
    }
    else {
      _setFormData({ ..._formData, [name]: value });
      dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
      swal("Please enter the pincode.", { icon: 'warning' });
    }
  };
  const getOfficeFieldExecutiveNames = (HubId: any, pincode: any) => {

    var data = {
      HubId: HubId,
      pincode: pincode,
      lCorporateId: user?.CorporateDetailsId
    }
    getFieldExecutiveName(data)
      .then((response) => {
        debugger;
        if (response.data.data.length > 0) {
          var exename = response.data.data[0].FirstName + " " + response.data.data[0].LastName;
          _setFormData({ ..._formData, officeFieldExecutiveName: exename, officeHubId: HubId, officeFieldStaff: response.data.data[0].Id });
          dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, officeFieldExecutiveName: exename, officeHubId: HubId, officeFieldStaff: response.data.data[0].Id } });
        }
        else {
          _setFormData({ ..._formData, officeFieldExecutiveName: "", officeHubId: HubId, officeFieldStaff: 0 });
          dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, officeFieldExecutiveName: "", officeHubId: HubId, officeFieldStaff: 0 } });
          swal("No field executive assigned.", { icon: 'warning' });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  return (
    <>
      <div className="bg-white p-3 content-area">
        {/* coming soon */}
        <div className="row">
          <div className="col-md-6">
            <div className="text-center fw-bold">Residential Details</div>
            <div className="mb-3">
              <div>
                <label className="form-label mb-2">Customer Name<b className="text-danger">*</b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  InputLabelProps={{ shrink: false }}
                  value={_formData?.customerName}
                  onChange={(e: any) =>
                    onFormChange("customerName", e.target.value)
                  }
                  variant="outlined"
                  className="w-100"
                  error={postCaseValidation?.customerName?.error} helperText={postCaseValidation?.customerName?.message}
                />
              </div>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-label mb-2">
                  Residential Address 01<b className="text-danger">*</b>
                </label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  value={_formData?.residentialCustomerAddress1}
                  onChange={(e: any) =>
                    onFormChange("residentialCustomerAddress1", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  error={postCaseValidation?.residentialCustomerAddress1?.error} helperText={postCaseValidation?.residentialCustomerAddress1?.message}
                />
              </div>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-label mb-2">
                  Residential Address 02<b className="text-danger"></b>
                </label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  value={_formData?.residentialCustomerAddress2}
                  onChange={(e: any) =>
                    onFormChange("residentialCustomerAddress2", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  error={postCaseValidation?.residentialCustomerAddress2?.error} helperText={postCaseValidation?.residentialCustomerAddress2?.message}
                />
              </div>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-label mb-2">Residential City<b className="text-danger">*</b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  value={_formData?.residentialCity}
                  onChange={(e: any) =>
                    onFormChange("residentialCity", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  error={postCaseValidation?.residentialCity?.error} helperText={postCaseValidation?.residentialCity?.message}
                />
              </div>
              <div>
                <label className="form-label mb-2">Residential Pincode<b className="text-danger">*</b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  // type="number"
                  value={_formData?.residentialPincode}
                  onChange={(e: any) => onFormChange("residentialPincode", (!isNaN(e.target.value) && e.target.value.length < 7) ? e.target.value.trim(' ') : '')}
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  inputProps={{
                    maxLength: 6,
                  }}
                  error={postCaseValidation?.residentialPincode?.error} helperText={postCaseValidation?.residentialPincode?.message}
                />
              </div>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-label mb-2">Residential State<b className="text-danger">*</b></label>
              </div>
              <div>
                <FormControl size="small" className="w-100" sx={{ m: 0 }} error={postCaseValidation?.residentialState?.error}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={_formData?.residentialState}
                    onChange={(e) =>
                      onFormChange("residentialState", e.target.value)
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    {_StateList.map((item: any, index: any) => (
                      <MenuItem value={item?.Statename}>{item?.Statename}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{postCaseValidation?.residentialState?.message}</FormHelperText>
                </FormControl>

                {/* <TextField size="small"
                  label=" "
                  value={_formData?.residentialState}
                  onChange={(e: any) =>
                    onFormChange("residentialState", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  error={postCaseValidation?.residentialState?.error} helperText={postCaseValidation?.residentialState?.message}
                /> */}
              </div>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-label mb-2">Mobile Number<b className="text-danger"></b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  // type="number"
                  value={_formData?.residentialMobileNo}
                  onChange={(e: any) => onFormChange("residentialMobileNo", (!isNaN(e.target.value) && e.target.value.length < 11) ? e.target.value.trim(' ') : '')}
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  inputProps={{
                    maxLength: 10,
                  }}
                  error={postCaseValidation?.residentialMobileNo?.error} helperText={postCaseValidation?.residentialMobileNo?.message}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="form-label mb-2">Email Address<b className="text-danger"></b></div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  type="email"
                  value={_formData?.residentialEmail}
                  onChange={(e: any) =>
                    onFormChange("residentialEmail", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  error={postCaseValidation?.residentialEmail?.error} helperText={postCaseValidation?.residentialEmail?.message}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="form-label mb-2">Father Name<b className="text-danger"></b></div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  value={_formData?.fatherName}
                  onChange={(e: any) =>
                    onFormChange("fatherName", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  error={postCaseValidation?.fatherName?.error} helperText={postCaseValidation?.fatherName?.message}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="from-label mb-2">PAN Number<b className="text-danger"></b></div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  value={_formData?.panNo}
                  onChange={(e: any) => onFormChange("panNo", e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  inputProps={{
                    maxLength: 10,
                  }}
                  error={postCaseValidation?.panNo?.error} helperText={postCaseValidation?.panNo?.message}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="form-label mb-2">Proof Id<b className="text-danger"></b></div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  // type="number"
                  value={_formData?.aadhaarNo}
                  onChange={(e: any) => onFormChange("aadhaarNo", e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  inputProps={{
                    maxLength: 30,
                  }}
                  error={postCaseValidation?.aadhaarNo?.error} helperText={postCaseValidation?.aadhaarNo?.message}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="form-label mb-2">D.O.B<b className="text-danger"></b></div>
              <div>
                <TextField size="small" autoComplete='off'
                  type="date"
                  label=" "
                  value={_formData?.dob}
                  onChange={(e: any) => onFormChange("dob", e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  error={postCaseValidation?.dob?.error} helperText={postCaseValidation?.dob?.message}
                />
              </div>
            </div>
            {/* <div className="mb-4">
              <div>
                <label className="form-label mb-2">Address Type<b className="text-danger"></b></label>
              </div>
              <div>

                <FormControl size="small" className="w-100" sx={{ m: 0 }} error={postCaseValidation?.customeraddressTypeId?.error}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={_formData?.customeraddressTypeId}
                    onChange={(e) =>
                      onFormChange("customeraddressTypeId", e.target.value)
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    {_AddressTypeData.map((item: any, index: any) => (
                      <MenuItem value={item?.Id}>{item?.TypeName}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{postCaseValidation?.customeraddressTypeId?.message}</FormHelperText>
                </FormControl>
              </div>
            </div> */}

            <div className="mb-4">
              <div className="form-label mb-2">Employee Type<b className="text-danger">*</b></div>
              <div>
                <FormControl size="small" className="w-100" sx={{ m: 0 }} error={postCaseValidation?.employeeTypeId?.error}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={_formData?.employeeTypeId}
                    onChange={(e) =>
                      onFormChange("employeeTypeId", e.target.value)
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    {_EmployeeType.map((item: any, index: any) => (
                      <MenuItem value={item?.Id}>{item?.TypeName}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{postCaseValidation?.employeeTypeId?.message}</FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-label mb-2">Trigger<b className="text-danger"></b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" " multiline minRows={2}
                  value={_formData?.ResidencyTrigger}
                  onChange={(e: any) =>
                    onFormChange("ResidencyTrigger", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                  error={postCaseValidation?.ResidencyTrigger?.error} helperText={postCaseValidation?.ResidencyTrigger?.message}
                />
              </div>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-label mb-2">Dealer Name<b className="text-danger"></b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  value={_formData?.dealerName}
                  onChange={(e: any) =>
                    onFormChange("dealerName", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                />
              </div>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-label mb-2">Registration No<b className="text-danger"></b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  value={_formData?.registrationNo}
                  onChange={(e: any) =>
                    onFormChange("registrationNo", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                />
              </div>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-label mb-2">Model Variance<b className="text-danger"></b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off'
                  label=" "
                  value={_formData?.modelVariance}
                  onChange={(e: any) =>
                    onFormChange("modelVariance", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  className="w-100"
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="d-flex justify-content-between">
                <label className="form-label mt-2">Verification Type<b className="text-danger"></b></label>
              </div>
              <div className="d-flex flex-wrap ">
                <div className="col-6">
                  <Checkbox checked={_formData?.applicantProfileCheck} onChange={() => onFormChange('applicantProfileCheck', !_formData?.applicantProfileCheck)} />
                  <span>Profile</span>
                </div>
                <div className="col-6">
                  <Checkbox checked={_formData?.applicantDocumentCheck} onChange={() => onFormChange('applicantDocumentCheck', !_formData?.applicantDocumentCheck)} />
                  <span>Document</span>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="d-flex flex-wrap">
                {(_formData?.applicantProfileCheck || _formData?.applicantDocumentCheck) &&
                  <div className="col-6">
                    <FormControl error={postCaseValidation?.verificationTypeField.error}><FormControlLabel
                      control={<Checkbox checked={_formData?.verificationTypeField} onChange={() => onFormChange('verificationTypeField', !_formData?.verificationTypeField)} />}
                      label='Field' />
                      <FormHelperText>{postCaseValidation?.verificationTypeField?.message}</FormHelperText>
                    </FormControl>
                    {/* <Checkbox checked={_formData?.verificationTypeField} onChange={() => onFormChange('verificationTypeField', !_formData?.verificationTypeField)} />
                    <span>Field</span> */}
                  </div>}
                {(_formData?.applicantProfileCheck || _formData?.applicantDocumentCheck) && <div className="col-6">
                  <Checkbox checked={_formData?.verificationTypeAuthority} onChange={() => onFormChange('verificationTypeAuthority', !_formData?.verificationTypeAuthority)} />
                  <span>Authority</span>
                </div>}
              </div>
            </div>
            {(_formData?.applicantProfileCheck || _formData?.applicantDocumentCheck) && _formData?.verificationTypeField && <>
              <div className="mb-3">
                <label className="form-label ">Hub Id</label>
                <div>
                  <FormControl size="small" className='w-100' sx={{ m: 0 }} error={postCaseValidation?.appHubId?.error}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper" value={_formData?.appHubId}
                      onChange={(e: any) => onResidentialHubIdChange('appHubId', e.target.value)}
                      sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                    >
                      {_hubId.map((item: any, index: any) =>
                        <MenuItem key={index} value={item?.Id}>{item?.HubName}</MenuItem>
                      )}
                    </Select>
                    <FormHelperText>{postCaseValidation?.appHubId?.message}</FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label ">Field Staff Name<b className="text-danger"></b></label>
                <div className="row">
                  <div className="col-6">
                    <TextField size="small" autoComplete='off'
                      label=" " disabled
                      value={_formData?.residentialFieldExecutiveName}
                      InputLabelProps={{ shrink: false }}
                      variant="outlined"
                      className="w-100"
                    />
                  </div>
                  {_formData?.precaseid && <div className="col-md-6">
                    <FormControl size="small" className='w-100' sx={{ m: 0 }}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper" value={_formData?.appFieldStaff}
                        onChange={(e: any) => onFormChange('appFieldStaff', e.target.value)}
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                      >
                        {_fieldList.map((item: any, index: any) =>
                          <MenuItem key={index} value={item?.Id}>{item?.staffName}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>}
                </div>
              </div>
            </>}
            <div className="mb-3">
              <label className="form-label ">Query Id</label>
              <div>
                <FormControl size="small" className='w-100' sx={{ m: 0 }} error={postCaseValidation?.AppQueryID?.error}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper" value={_formData?.AppQueryID}
                    onChange={(e: any) => onFormChange('AppQueryID', e.target.value)}
                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                  >
                    {_queryList?.map((item: any, index: any) =>
                      <MenuItem key={index} value={item?.QueryId}>{item?.QueryName}</MenuItem>
                    )}
                  </Select>
                  <FormHelperText>{postCaseValidation?.AppQueryID?.message}</FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className='row mt-3'>
              <div className=''>
                <div className='row align-items-center my-2'>
                  <label className="form-label col-md-3">Verification Point</label>
                  <label className="form-label col-md-3">Document Type<b className="text-danger"></b></label>
                  <label className="form-label col-md-3">Verification Type<b className="text-danger"></b></label>
                  <div className='col-md-2'></div>
                  <div className='col-md-1'>
                    <div role="button" onClick={(e: any) => addNewImg(e)} >
                      <AddCircleOutlineIcon />
                    </div>
                  </div>
                </div>
                {_customerResidentialDoc.map((item: any, index: any) => {
                  return (
                    <div className='row align-items-center my-2'>
                      <div className='col-md-3'>
                        <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={item?.VerificationPerson || ''}
                            onChange={(e) =>
                              onChangeCustDropdownChange('VerificationPerson', e.target.value, index)
                            }
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            <MenuItem value={'Profile'}>Field</MenuItem>
                            <MenuItem value={'Authority'}>Authority</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div className='col-md-3'>
                        <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={item?.customerDocumentTypeId || 0}
                            onChange={(e) =>
                              onChangeCustDropdownChange('customerDocumentTypeId', e.target.value, index)
                            }
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            <MenuItem value={0}>Select Document Type</MenuItem>
                            {item?.VerificationPerson === "Profile" &&
                              _profileDocType?.map((item: any, index: any) => (
                                <MenuItem value={item?.Id}>{item?.DocumentType}</MenuItem>
                              ))}
                            {item?.VerificationPerson === "Authority" &&
                              _authorityDocType?.map((item: any, index: any) => (
                                <MenuItem value={item?.Id}>{item?.DocumentType}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-md-3'>
                        <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                          <Select
                            value={item?.verificationtypeId || ''}
                            onChange={(e) =>
                              onChangeCustDropdownChange('verificationtypeId', e.target.value, index)
                            }
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            {_verificationTypeList?.map((item: any, index: any) =>
                              <MenuItem key={index} value={Number(item?.VerificationId || '0')}>{item?.VerificationName}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>

                      <div className='col-md-2'>
                        {item.documentPath &&
                          <span className="d-flex h-100 align-items-center">
                            <div className='d-flex align-items-center justify-content-center'>
                              <i className='position-absolute'>
                                <IconButton>
                                  <VisibilityIcon onClick={() => window.open(item?.documentPath, "_blank")} className='text-muted' />
                                </IconButton>
                              </i>
                              <img onClick={() => window.open(item?.documentPath, "_blank")} src={item?.documentPath} className='imageFit' style={{  height: "3rem", opacity: "50%" }} alt="documentPath" />
                            </div>
                          </span>
                        }
                        {item.documentPath === "" &&
                          <div className='d-flex align-items-center justify-content-center' style={{ border: "1px solid #d8d8d8",  height: "3rem", borderRadius: "10px" }}>
                            <label htmlFor="upload-photo">
                              <CloudUploadIcon className='text-muted' />
                            </label>
                            <input onChange={(e: any) => onChangeResidentialPicture(e, index, item?.customerDocumentTypeId)} id="upload-photo" type="file" style={{  height: "3rem" }} />
                          </div>
                        }
                      </div>
                      <div className='col-md-1'>
                        <CancelIcon style={{ color: "rgb(255, 0, 0)" }} onClick={(e: any) => removeCustomerResidentiaDoc(index)} role="button" />
                      </div>
                      {/* {_customerResidentialDoc?.length === index + 1 && <div role="button" className='col-md-1' onClick={(e: any) => addNewImg(e)} >
                        <AddCircleOutlineIcon />
                      </div>} */}
                    </div>
                  )
                }
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-center fw-bold">Office Details</div>
            <div className='mb-3'>
              <div>
                <label className='form-label mb-2'>Office Name<b className="text-danger">*</b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off' variant="outlined" value={_formData?.officeName} onChange={(e: any) => onFormChange('officeName', e.target.value)} label=" " className='w-100' InputLabelProps={{ shrink: false }} error={postCaseValidation?.officeName?.error} helperText={postCaseValidation?.officeName?.message} />
              </div>
            </div>
            <div className='mb-3'>
              <div>
                <label className='form-label mb-2'>Office Address 01<b className="text-danger">*</b></label>

              </div>
              <div>
                <TextField size="small" autoComplete='off' variant="outlined" value={_formData?.officeAddress1} onChange={(e: any) => onFormChange('officeAddress1', e.target.value)} label=" " className='w-100' InputLabelProps={{ shrink: false }}
                  error={postCaseValidation?.officeAddress1?.error} helperText={postCaseValidation?.officeAddress1?.message} />
              </div>
            </div>
            <div className='mb-3'>
              <div>
                <label className='form-label mb-2'>Office Address 02<b className="text-danger"></b></label>

              </div>
              <div>
                <TextField size="small" autoComplete='off' variant="outlined" label=" " value={_formData?.officeAddress2} onChange={(e: any) => onFormChange('officeAddress2', e.target.value)} className='w-100' InputLabelProps={{ shrink: false }}
                  error={postCaseValidation?.officeAddress2?.error} helperText={postCaseValidation?.officeAddress2?.message} />
              </div>
            </div>
            <div className='mb-3'>
              <div>
                <label className='form-label mb-2'>Office City<b className="text-danger">*</b></label>

              </div>
              <div>
                <TextField size="small" autoComplete='off' variant="outlined" label=" " value={_formData?.officeCity} onChange={(e: any) => onFormChange('officeCity', e.target.value)} className='w-100' InputLabelProps={{ shrink: false }} error={postCaseValidation?.officeCity?.error} helperText={postCaseValidation?.officeCity?.message} />
              </div>
            </div>
            <div className='mb-3'>
              <div>
                <label className='form-label mb-2'>Office Pincode<b className="text-danger">*</b></label>

              </div>
              <div>
                <TextField size="small" autoComplete='off' variant="outlined" label=" " value={_formData?.officePincode} onChange={(e: any) => onFormChange('officePincode', (!isNaN(e.target.value) && e.target.value.length < 7) ? e.target.value.trim(' ') : '')} className='w-100' InputLabelProps={{ shrink: false }} error={postCaseValidation?.officePincode?.error} helperText={postCaseValidation?.officePincode?.message} />
              </div>
            </div>
            <div className='mb-3'>
              <div>
                <label className='form-label mb-2'>Office State<b className="text-danger">*</b></label>

              </div>
              <div>
                <FormControl size="small" className="w-100" sx={{ m: 0 }} error={postCaseValidation?.officeState?.error}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={_formData?.officeState}
                    onChange={(e) =>
                      onFormChange("officeState", e.target.value)
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    {_StateList.map((item: any, index: any) => (
                      <MenuItem value={item?.Statename}>{item?.Statename}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{postCaseValidation?.officeState?.message}</FormHelperText>
                </FormControl>
                {/* <TextField size="small" variant="outlined" label=" " value={_formData?.officeState} onChange={(e: any) => onFormChange('officeState', e.target.value)} className='w-100' InputLabelProps={{ shrink: false }} error={postCaseValidation?.officeState?.error} helperText={postCaseValidation?.officeState?.message} /> */}
              </div>
            </div>
            <div className='mb-3'>
              <div>
                <label className='form-label mb-2'>Office Land Mark<b className="text-danger"></b></label>

              </div>
              <div>
                <TextField size="small" autoComplete='off' variant="outlined" label=" " value={_formData?.officeLandMark} onChange={(e: any) => onFormChange('officeLandMark', e.target.value)} className='w-100' InputLabelProps={{ shrink: false }}
                  error={postCaseValidation?.officeLandMark?.error} helperText={postCaseValidation?.officeLandMark?.message} />
              </div>
            </div>
            <div className='mb-3'>
              <div>
                <label className='form-label mb-2'>Office Landline<b className="text-danger"></b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off' variant="outlined" label=" " value={_formData?.officeLandLine} onChange={(e: any) => onFormChange('officeLandLine', (!isNaN(e.target.value) && e.target.value.length < 12) ? e.target.value.trim(' ') : '')} className='w-100' InputLabelProps={{ shrink: false }}
                  error={postCaseValidation?.officeLandLine?.error} helperText={postCaseValidation?.officeLandLine?.message} />
              </div>
            </div>
            <div className='mb-3'>
              <div>
                <label className='form-label mb-2'>Office Mobile Number<b className="text-danger"></b></label>
              </div>
              <div>
                <TextField size="small" autoComplete='off' variant="outlined" label=" " value={_formData?.officeMobileNumber} onChange={(e: any) => onFormChange('officeMobileNumber', (!isNaN(e.target.value) && e.target.value.length < 11) ? e.target.value.trim(' ') : '')} className='w-100' InputLabelProps={{ shrink: false }} error={postCaseValidation?.officeMobileNumber?.error} helperText={postCaseValidation?.officeMobileNumber?.message} />
              </div>
            </div>
            <div className="mb-4">
              <div className="from-label mb-2">Designation<b className="text-danger"></b></div>
              <div>
                <TextField size="small" autoComplete='off' variant="outlined" label=" " value={_formData?.designation} onChange={(e: any) => onFormChange('designation', e.target.value)}
                  className='w-100' InputLabelProps={{ shrink: false }} />
              </div>
            </div>

            {/* <div className="mb-4" style={{ display: _EmployeeDocDisplay ? "block" : "none" }}>
              <div className="form-label mb-2">Document Type<b className="text-danger"></b></div>
              <div>
                <FormControl size="small" className="w-100" sx={{ m: 0 }} error={postCaseValidation?.EmployeeDocumnetTypeId?.error}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={_formData?.EmployeeDocumnetTypeId}
                    onChange={(e) =>
                      onFormChange("EmployeeDocumnetTypeId", e.target.value)
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    {_EmployeeDocType.map((item: any, index: any) => (
                      <MenuItem value={item?.Id}>{item?.DocumentType}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{postCaseValidation?.EmployeeDocumnetTypeId?.message}</FormHelperText>
                </FormControl>
              </div>
            </div> */}
            <div className="mb-3">
              <div><label className="form-label mb-2">Office Trigger<b className="text-danger"></b></label></div>
              <div>
                <TextField size="small" autoComplete='off' label=" " multiline minRows={2}
                  value={_formData?.OfficeTrigger}
                  onChange={(e: any) =>
                    onFormChange("OfficeTrigger", e.target.value)
                  }
                  InputLabelProps={{ shrink: false }} variant="outlined" className="w-100"
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="d-flex justify-content-between">
                <label className="form-label mt-2">Verification Type<b className="text-danger"></b></label>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-6">
                  <Checkbox checked={_formData?.officeProfileCheck} onChange={() => onFormChange('officeProfileCheck', !_formData?.officeProfileCheck)} />
                  <span>Profile</span>
                </div>
                <div className="">
                  <Checkbox checked={_formData?.officeDocumentCheck} onChange={() => onFormChange('officeDocumentCheck', !_formData?.officeDocumentCheck)} />
                  <span>Document</span>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="d-flex flex-wrap">
                {(_formData?.officeProfileCheck || _formData?.officeDocumentCheck) && <div className="col-6">
                  <FormControl error={postCaseValidation?.officeVerificationTypeField.error}><FormControlLabel
                    control={<Checkbox checked={_formData?.officeVerificationTypeField} onChange={() => onFormChange('officeVerificationTypeField', !_formData?.officeVerificationTypeField)} />}
                    label='Field' />
                    <FormHelperText>{postCaseValidation?.officeVerificationTypeField?.message}</FormHelperText>
                  </FormControl>
                  {/* <Checkbox checked={_formData?.officeVerificationTypeField} onChange={() => onFormChange('officeVerificationTypeField', !_formData?.officeVerificationTypeField)} />
                  <span>Field</span> */}
                </div>}
                {(_formData?.officeProfileCheck || _formData?.officeDocumentCheck) && <div className="col-6">
                  <Checkbox checked={_formData?.officeVerificationTypeAuthority} onChange={() => onFormChange('officeVerificationTypeAuthority', !_formData?.officeVerificationTypeAuthority)} />
                  <span>Authority</span>
                </div>}
              </div>
            </div>
            {(_formData?.officeProfileCheck || _formData?.officeDocumentCheck) && _formData?.officeVerificationTypeField && <>
              <div className="mb-3">
                <label className="form-label ">Hub Id</label>
                <div>
                  <FormControl size="small" className="w-100" sx={{ m: 0 }} error={postCaseValidation?.officeHubId?.error} >
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={_formData?.officeHubId}
                      onChange={(e: any) => onOfficeHubIdChange('officeHubId', e.target.value)}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {_hubId.map((item: any, index: any) => (
                        <MenuItem key={index} value={item?.Id}>{item?.HubName}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{postCaseValidation?.officeHubId?.message}</FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label ">Field Staff Name<b className="text-danger"></b></label>
                <div className="row">
                  <div className='col-md-6'>
                    <TextField size="small" autoComplete='off'
                      label=" " disabled
                      value={_formData?.officeFieldExecutiveName}
                      InputLabelProps={{ shrink: false }}
                      variant="outlined"
                      className="w-100"
                    />
                  </div>
                  {_formData?.precaseid && <div className="col-md-6">
                    <FormControl size="small" className='w-100' sx={{ m: 0 }}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper" value={_formData?.officeFieldStaff}
                        onChange={(e: any) => onFormChange('officeFieldStaff', e.target.value)}
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                      >
                        {_fieldList.map((item: any, index: any) =>
                          <MenuItem key={index} value={item?.Id}>{item?.staffName}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>}
                </div>
              </div>
            </>}
            <div className="mb-3">
              <label className="form-label ">Query</label>
              <div>
                <FormControl size="small" className='w-100' sx={{ m: 0 }} error={postCaseValidation?.AppOffice_QueryID?.error}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper" value={_formData?.AppOffice_QueryID}
                    onChange={(e: any) => onFormChange('AppOffice_QueryID', e.target.value)}
                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                  >
                    {_officeQueryList?.map((item: any, index: any) =>
                      <MenuItem key={index} value={item?.QueryId}>{item?.QueryName}</MenuItem>
                    )}
                  </Select>
                  <FormHelperText>{postCaseValidation?.AppOffice_QueryID?.message}</FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className='row mt-3'>
              <div className=''>
                <div className='row align-items-center my-2'>
                  <label className="form-label col-md-3">Verification Point</label>
                  <label className="form-label col-md-3">Document Type<b className="text-danger"></b></label>
                  <label className="form-label col-md-3">Verification Type<b className="text-danger"></b></label>
                  <div className='col-md-2'></div>
                  <div className='col-md-1'>
                    <div role="button" onClick={(e: any) => addNewImgOffice(e)} >
                      <AddCircleOutlineIcon />
                    </div>
                  </div>
                </div>
                {_customerOfficeDoc?.map((val: any, index: any) => {
                  return (
                    <div className='row align-items-center my-2'>
                      <div className='col-md-3'>
                        <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={val?.VerificationPerson || 0}
                            onChange={(e) =>
                              onChangeCustOfficeDropdownChange('VerificationPerson', e.target.value, index)
                            }
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            <MenuItem value={'Profile'}>Field</MenuItem>
                            <MenuItem value={'Authority'}>Authority</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-md-3'>
                        <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={val?.customerDocumentTypeId || 0}
                            onChange={(e) =>
                              onChangeCustOfficeDropdownChange('customerDocumentTypeId', e.target.value, index)
                            }
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            <MenuItem value={0}>Select Document Type</MenuItem>
                            {val?.VerificationPerson === "Profile" &&
                              _profileDocType?.map((item: any, index: any) => (
                                <MenuItem key={index} value={item?.Id}>{item?.DocumentType}</MenuItem>
                              ))}
                            {val?.VerificationPerson === "Authority" &&
                              _authorityDocType?.map((item: any, index: any) => (
                                <MenuItem key={index} value={item?.Id}>{item?.DocumentType}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-md-3'>
                        <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                          <Select
                            value={val?.verificationtypeId || ''}
                            onChange={(e) =>
                              onChangeCustOfficeDropdownChange('verificationtypeId', e.target.value, index)
                            }
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            {_verificationTypeList?.map((item: any, index: any) =>
                              <MenuItem key={index} value={Number(item?.VerificationId || '0')}>{item?.VerificationName}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-md-2'>
                        {val.documentPath &&
                          <span className="d-flex h-100 align-items-center">
                            <div className='d-flex align-items-center justify-content-center'>
                              <i className='position-absolute'>
                                <IconButton>
                                  <VisibilityIcon onClick={() => window.open(val?.documentPath, "_blank")} className='text-muted' />
                                </IconButton>
                              </i>
                              <img onClick={() => window.open(val?.documentPath, "_blank")} src={val?.documentPath} className='imageFit' style={{  height: "3rem", opacity: "50%" }} alt="documentPath" />
                            </div>
                          </span>
                        }
                        {val.documentPath === "" &&
                          <div className='d-flex align-items-center justify-content-center' style={{ border: "1px solid #d8d8d8",  height: "3rem", borderRadius: "10px" }}>
                            <label htmlFor="upload-office-photo">
                              <CloudUploadIcon className='text-muted' />
                            </label>
                            <input onChange={(e: any) => onChangeOfficePicture(e, index, val?.customerDocumentTypeId)} id="upload-office-photo" type="file" style={{  height: "3rem" }} />
                          </div>
                        }
                      </div>
                      <div className='col-md-1'>
                        <CancelIcon style={{ color: "rgb(255, 0, 0)" }} onClick={(e: any) => removeCustomerOfficeDoc(index)} role="button" />
                      </div>
                      {/* {_customerOfficeDoc?.length === index + 1 && <div role="button" className='col-md-1' onClick={(e: any) => addNewImgOffice(e)} >
                        <AddCircleOutlineIcon />
                      </div>} */}
                    </div>
                  )
                }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customerdetails;
