import { useEffect, useState } from 'react';
import {
    Checkbox, FormControl, MenuItem, Radio, Select, TextField, FormHelperText, Button,
    IconButton,
    FormControlLabel
} from '@mui/material';
import { useStateValue } from '../../../providers/stateProvider';
import {
    getEmployeeDocTypes, getEmployeeTypes, getStateDetails, getCustomerDocTypes,
    deleteDocument, getFieldExecutiveName, getAllHubMaster, getQueryList, getFieldStaffList, getAllStaffDetails
} from '../../../Models/configapi';
import { getVerificationTypeMaster, InsertDocument } from "../../../Models/model";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import swal from 'sweetalert';
import { AddCircleOutline, Cancel, CloudUpload, Visibility } from '@mui/icons-material';


function Customerdetails() {

    const [{ user, postCaseData, postCaseValidation }, dispatch]: any = useStateValue();
    const [_formData, _setFormData] = useState<any>({
        coAddressType: '',
        coApplicantName: '',
        coAddress: '',
        coAddress_2: '',
        coCity: '',
        coPincode: '',
        coState: '',
        coMobileNo: '',
        coEmailAddress: '',
        coAppHubId: 0,
        coAppFieldStaffName: '',

        coofficeName: '',
        coofficeAddress1: '',
        coofficeAddress2: '',
        coofficeCity: '',
        coofficeState: '',
        coofficePincode: '',
        coofficeLandMark: '',
        coofficeLandLine: '',
        coofficeMobileNumber: '',
        codesignation: '',
        coemployeeTypeId: 0,
        coAppEmployeeDocumnetTypeId: 0,

        CoAppOfficeTrigger: '',
        coAppOfficeHubId: 0,
        CoAppQueryID: 0,
        CoAppOfficeQueryID: 0,

        residentialFieldExecutiveName: '',

        coDocumentProperties: [],
        coOfficeDocumentProperties: [],

        coVerificationField: false,
        coVerificationAuthority: false,
        coAppVerificationTypeID: true,
        coAppDocumentCheck: false,
        coAppProfileCheck: false,

        coOfficeVerificationField: false,
        coOfficeVerificationAuthority: false,
        coAppOfficeVerificationTypeID: true,
        coAppOfficeDocumentCheck: false,
        coAppOfficeProfileCheck: false

    });
    const [_coApplicantResidentialDoc, _setcoApplicantResidentialDoc] = useState([
        { documentNumber: 0, documentName: '', documentTypeId: 4, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }]);
    const [_coApplicantOfficeDoc, _setcoApplicantOfficeDoc] = useState([
        { documentNumber: 0, documentName: '', documentTypeId: 4, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }]);

    const [_profileDocType, _setProfileDocType] = useState<any>([]);
    const [_authorityDocType, _setAuthorityDocType] = useState<any>([]);
    const [_verificationTypeList, _setVerificationTypeList] = useState<any>([]);


    const [_fieldList, _setFieldList] = useState<any>([]);

    const onFormChange = (name: string, value: any) => {
        debugger;
        if (name === "coPincode") {
            _setFormData({ ..._formData, coAppFieldStaffName: "", coAppHubId: 0, coAppFieldStaff: 0 });
            dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, coAppFieldStaffName: "", coAppHubId: 0, coAppFieldStaff: 0, [name]: value } });
        }
        else if (name === 'coofficePincode') {
            _setFormData({ ..._formData, coOfficeFieldStaffName: "", coAppOfficeHubId: 0, coAppOfficeFieldStaff: 0 });
            dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, coOfficeFieldStaffName: "", coAppOfficeHubId: 0, coAppOfficeFieldStaff: 0, [name]: value } });
        }
        else if (name === 'coVerificationField' || name === 'coAppProfileCheck' || name === 'coAppDocumentCheck') {
            _setFormData({ ..._formData, coAppFieldStaffName: "", coAppHubId: 0, coAppFieldStaff: 0 });
            dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, coAppFieldStaffName: "", coAppHubId: 0, coAppFieldStaff: 0, [name]: value } });
        }
        else if (name === 'coOfficeVerificationField' || name === 'coAppOfficeProfileCheck' || name === 'coAppOfficeDocumentCheck') {
            _setFormData({ ..._formData, coOfficeFieldStaffName: "", coAppOfficeHubId: 0, coAppOfficeFieldStaff: 0 });
            dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, coOfficeFieldStaffName: "", coAppOfficeHubId: 0, coAppOfficeFieldStaff: 0, [name]: value } });
        }
        else {
            _setFormData({ ..._formData, [name]: value });
            dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
        }
    };

    useEffect(() => {
        if (Object.entries(postCaseData)?.length) {
            _setFormData({ ...postCaseData });
            if (postCaseData?.coDocumentProperties?.length) {
                _setcoApplicantResidentialDoc([...postCaseData?.coDocumentProperties?.map((item: any) => { return { ...item, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID } })])
            } else {
                _setcoApplicantResidentialDoc([{ documentNumber: 0, documentName: '', documentTypeId: 4, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }])
            }
            if (postCaseData?.coOfficeDocumentProperties?.length) {
                _setcoApplicantOfficeDoc([...postCaseData?.coOfficeDocumentProperties?.map((item: any) => { return { ...item, customerDocumentTypeId: item?.customerDocumentTypeId || item?.customerDocumentTypeID } })])
            } else {
                _setcoApplicantOfficeDoc([{ documentNumber: 0, documentName: '', documentTypeId: 4, documentPath: '', reftype: '', id: 0, customerDocumentTypeId: 0, VerificationPerson: '' }])
            }
        }
    }, [postCaseData]);

    // *************************

    const [_StateList, _setStateList] = useState<any>([]);
    const [_EmployeeType, _setEmployeeType] = useState<any>([]);
    const [_EmployeeDocType, _setEmployeeDocType] = useState<any>([]);
    const [_EmployeeDocDisplay, _setEmployeeDocDisplay] = useState(false);
    // const [_verificationType, _setVerificationType] = useState('');
    const [_hubList, _setHubList] = useState<any>([]);
    const [_desList, _setDesList] = useState<any>([]);
    const [_queryList, _setQueryList] = useState<any>([]);
    const [_officeQueryList, _setOfficeQueryList] = useState<any>([]);
    // const [_checkMark, _setCheckMark] = useState<any>({
    //     profile: true, document: false, panCard: false,
    //     drivingLicense: false, voterId: false, coAppDocumentCheck: false, coAppProfileCheck: false
    // });
    // const [_verificationTypeOffice, _setVerificationTypeOffice] = useState('');
    // const [_checkMarkOffice, _setCheckMarkOffice] = useState<any>({
    //     profile: true, document: false, panCard: false,
    //     drivingLicense: false, voterId: false, coAppOfficeDocumentCheck: false, coAppOfficeProfileCheck: false
    // });

    // const onChangeCheckMarkOffice = (key: any, value: any) => {
    //     _setCheckMarkOffice({ ..._checkMarkOffice, [key]: value })
    // }
    // const onChangeCheckMark = (key: any, value: any) => {
    //     _setCheckMark({ ..._checkMark, [key]: value })
    // }

    const getVerificationList = () => {
        getVerificationTypeMaster()
            .then((response) => {
                _setVerificationTypeList([...response?.data?.data?.data]);
            })
            .catch((error) => {
                console.log(error.response);
            });
    };

    useEffect(() => {
        // let tempData = JSON.parse(localStorage.getItem('officeDetail') || '{}');
        // _setFormData({ ..._formData, ...tempData });
        getStateList();
        getEmployeeTypeDetail();
        getEmployeeDocumentTypeDetail();
        getHubList()
        getVerificationList()
    }, []);

    const onEmployeeTypeChange = (name: string, value: any) => {
        _setFormData({ ..._formData, [name]: value });
        dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
        if (Number(value) === 1) {
            _setEmployeeDocDisplay(true);
        }
        else {
            _setEmployeeDocDisplay(false);
        }
    };

    const getEmployeeTypeDetail = () => {
        getEmployeeTypes(user.CorporateDetailsId)
            .then((response) => {
                debugger;
                //console.log(response.data);
                _setEmployeeType(response.data.employeeTypeDetails);
            })
            .catch((error) => {
                console.log(error.response);
            });
    };
    const getEmployeeDocumentTypeDetail = () => {
        getEmployeeDocTypes(user?.CorporateDetailsId)
            .then((response) => {
                debugger;
                _setEmployeeDocType(response.data.data.CustomerDocumentTypeDetails);
            })
            .catch((error) => {
                console.log(error.response);
            });
    };
    const getStateList = () => {
        getStateDetails(user?.CorporateDetailsId)
            .then((response) => {
                console.log(response.data);
                _setStateList(response.data.StateDetails
                );
            })
            .catch((error) => {
                console.log(error.response);
            });
    };

    useEffect(() => {
        getCustomerDocTypes('Field', user?.CorporateDetailsId)
            .then((response) => {
                _setProfileDocType(response.data.data);
            })
            .catch((error) => {
                console.log(error.response);
            });
        getCustomerDocTypes('Authority', user?.CorporateDetailsId)
            .then((response) => {
                _setAuthorityDocType(response.data.data);
            })
            .catch((error) => {
                console.log(error.response);
            });
    }, []);

    const getHubList = () => {
        getAllHubMaster(user.CorporateDetailsId)
            .then((response: any) => {
                console.log(response?.data?.data);
                _setHubList([...response?.data?.data?.data]);
            }).catch(error => {
                console.log(error.response.data);
            })
        getQueryList("Resi", user.CorporateDetailsId)
            .then((response: any) => {
                console.log(response?.data?.data);
                _setQueryList([...response?.data?.data]);
            }).catch(error => {
                console.log(error.response.data);
            })
        getQueryList("Office", user.CorporateDetailsId)
            .then((response: any) => {
                console.log(response?.data?.data);
                _setOfficeQueryList([...response?.data?.data]);
            }).catch(error => {
                console.log(error.response.data);
            })
        getAllStaffDetails(user?.CorporateDetailsId)
            .then((response: any) => {
                console.log(response?.data);
                _setFieldList([...response?.data?.Data]);
            }).catch(error => {
                console.log(error.response.data);
            })
    }
    const onChangeCoApplicantResDropdownChange = (key: any, value: any, index: any) => {
        let _tempDoc = [..._coApplicantResidentialDoc];
        if (key === 'VerificationPerson') {
            _tempDoc[index] = { ..._tempDoc[index], [key]: value, customerDocumentTypeId: 0 };
        } else {
            _tempDoc[index] = { ..._tempDoc[index], [key]: value };
        }
        _setcoApplicantResidentialDoc(_tempDoc);
        onFormChange('coDocumentProperties', _tempDoc);
    }

    const onChangeResidentialPicture = (e: any, index: any, typeId: any) => {
        let _tempDoc = [..._coApplicantResidentialDoc];

        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append('DocumentTypeId', '5');
            formData.append('UserId', user?.id);
            formData.append('image', e.target.files[0]);
            console.log("picture: ", e.target.files);
            console.log("imgUrl: ", URL.createObjectURL(e.target.files[0]));
            InsertDocument(formData)
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === "Success") {

                        _tempDoc[index] = {
                            ..._tempDoc[index],
                            documentNumber: 3,
                            documentName: response.data.data.FileName,
                            documentPath: response.data.data.physicalPath,
                            documentTypeId: 5,
                            customerDocumentTypeId: Number(typeId) || 0
                        };
                        onFormChange('coDocumentProperties', _tempDoc);
                        _setcoApplicantResidentialDoc(_tempDoc);

                    }
                }).catch(error => {
                    console.log(error.response.data);
                })

        }
    }
    const removeCoApplicantResidentiaDoc = (index: any) => {
        var data; var imageId: any;
        //if (_coApplicantResidentialDoc.length > 1) {
        let _tempDoc = [..._coApplicantResidentialDoc];
        if (postCaseData?.precaseid !== undefined) {
            data = {
                id: _tempDoc[index].id,
                isActive: 0,
                type: "case",
                typeofcase: "precase",
                caseid: postCaseData?.precaseid,
                documentTypeId: 1
            };
            imageId = _tempDoc[index].id;
        }
        else {
            data = {
                id: _tempDoc[index].id,
                isActive: 0,
                type: "case",
                typeofcase: "precase",
                caseid: 0,
                documentTypeId: 1
            };
            imageId = _tempDoc[index].id;
        }

        deleteDocument(data)
            .then((response) => {
                if (response.data.status === "Success") {
                    swal(response.data.data, { icon: "success" });
                    const ArrayData = _tempDoc.filter((_tempDoc) => _tempDoc.id !== imageId);
                    _setcoApplicantResidentialDoc(ArrayData);

                } else if (response.data.status === "Error") {
                    swal(response.data.data, { icon: "warning" });
                }
            })
            .catch((error) => {
                swal(error.response.data, { icon: "warning" });
            });
        // }
        // else {
        //     swal("Unable to delete.", { icon: "warning" });
        // }
        return;
    }
    const addCoApplicantNewImg = (e: any) => {
        let values = [..._coApplicantResidentialDoc];
        values.push({
            documentNumber: 3,
            documentName: '',
            documentTypeId: 5,
            documentPath: '',
            reftype: '',
            id: values.length,
            customerDocumentTypeId: 0,
            VerificationPerson: ''
        });
        _setcoApplicantResidentialDoc(values);
    }

    const onResHubIdChange = (name: string, value: any) => {

        var vPincode = _formData.coPincode;
        if (vPincode !== undefined && vPincode !== "") {
            getResFieldExecutiveNames(value, vPincode);
        }
        else {
            _setFormData({ ..._formData, [name]: value });
            dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
            swal("Please enter the pincode.", { icon: 'warning' });
        }
    };
    const getResFieldExecutiveNames = (HubId: any, pincode: any) => {

        var data = {
            HubId: HubId,
            pincode: pincode,
            lCorporateId: user?.CorporateDetailsId
        }
        getFieldExecutiveName(data)
            .then((response) => {
                debugger;
                if (response.data.data.length > 0) {
                    var exename = response.data.data[0].FirstName + " " + response.data.data[0].LastName;
                    _setFormData({ ..._formData, coAppFieldStaffName: exename, coAppHubId: HubId, coAppFieldStaff: response.data.data[0].Id });
                    dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, coAppFieldStaffName: exename, coAppHubId: HubId, coAppFieldStaff: response.data.data[0].Id } });
                }
                else {
                    _setFormData({ ..._formData, coAppFieldStaffName: "", coAppHubId: HubId, coAppFieldStaff: 0 });
                    dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, coAppFieldStaffName: "", coAppHubId: HubId, coAppFieldStaff: 0 } });
                    swal("No field executive assigned.", { icon: 'warning' });
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
    }
    const onOfficeHubIdChange = (name: string, value: any) => {

        var vPincode = _formData.coofficePincode;
        if (vPincode !== undefined && vPincode !== "") {
            getOfficeFieldExecutiveNames(value, vPincode);
        }
        else {
            _setFormData({ ..._formData, [name]: value });
            dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, [name]: value } });
            swal("Please enter the pincode.", { icon: 'warning' });
        }
    };
    const getOfficeFieldExecutiveNames = (HubId: any, pincode: any) => {

        var data = {
            HubId: HubId,
            pincode: pincode,
            lCorporateId: user?.CorporateDetailsId
        }
        getFieldExecutiveName(data)
            .then((response) => {
                debugger;
                if (response.data.data.length > 0) {
                    var exename = response.data.data[0].FirstName + " " + response.data.data[0].LastName;
                    _setFormData({ ..._formData, coOfficeFieldStaffName: exename, coAppOfficeHubId: HubId, coAppOfficeFieldStaff: response.data.data[0].Id });
                    dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, coOfficeFieldStaffName: exename, coAppOfficeHubId: HubId, coAppOfficeFieldStaff: response.data.data[0].Id } });
                }
                else {
                    _setFormData({ ..._formData, coOfficeFieldStaffName: "", coAppOfficeHubId: HubId, coAppOfficeFieldStaff: 0 });
                    dispatch({ type: "postCaseData", data: { ..._formData, ...postCaseData, coOfficeFieldStaffName: "", coAppOfficeHubId: HubId, coAppOfficeFieldStaff: 0 } });
                    swal("No field executive assigned.", { icon: 'warning' });
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
    }

    const onChangeCoApplicantOfficeDropdownChange = (key: any, value: any, index: any) => {
        let _tempDoc = [..._coApplicantOfficeDoc];
        if (key === 'VerificationPerson') {
            _tempDoc[index] = { ..._tempDoc[index], [key]: value, customerDocumentTypeId: 0 };
        } else {
            _tempDoc[index] = { ..._tempDoc[index], [key]: value };
        }
        _setcoApplicantOfficeDoc(_tempDoc);
        onFormChange('coOfficeDocumentProperties', _tempDoc);
    }
    const onChangeOfficePicture = (e: any, index: any, typeId: any) => {
        let _tempDoc = [..._coApplicantOfficeDoc];

        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append('DocumentTypeId', '7');
            formData.append('UserId', user?.id);
            formData.append('image', e.target.files[0]);
            console.log("picture: ", e.target.files);
            console.log("imgUrl: ", URL.createObjectURL(e.target.files[0]));
            InsertDocument(formData)
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === "Success") {

                        _tempDoc[index] = {
                            ..._tempDoc[index],
                            documentNumber: 4,
                            documentName: response.data.data.FileName,
                            documentPath: response.data.data.physicalPath,
                            documentTypeId: 7,
                            customerDocumentTypeId: Number(typeId) || 0
                        };
                        onFormChange('coOfficeDocumentProperties', _tempDoc);
                        _setcoApplicantOfficeDoc(_tempDoc);

                    }
                }).catch(error => {
                    console.log(error.response.data);
                })

        }
    }
    const removeCoApplicantOfficeDoc = (index: any) => {
        var data; var imageId: any;
        //if (_coApplicantOfficeDoc.length > 1) {
        let _tempDoc = [..._coApplicantOfficeDoc];
        if (postCaseData?.precaseid !== undefined) {
            data = {
                id: _tempDoc[index].id,
                isActive: 0,
                type: "case",
                typeofcase: "precase",
                caseid: postCaseData?.precaseid,
                documentTypeId: 1
            };
            imageId = _tempDoc[index].id;
        }
        else {
            data = {
                id: _tempDoc[index].id,
                isActive: 0,
                type: "case",
                typeofcase: "precase",
                caseid: 0,
                documentTypeId: 1
            };
            imageId = _tempDoc[index].id;
        }

        deleteDocument(data)
            .then((response) => {
                if (response.data.status === "Success") {
                    swal(response.data.data, { icon: "success" });
                    const ArrayData = _tempDoc.filter((_tempDoc) => _tempDoc.id !== imageId);
                    _setcoApplicantOfficeDoc(ArrayData);

                } else if (response.data.status === "Error") {
                    swal(response.data.data, { icon: "warning" });
                }
            })
            .catch((error) => {
                swal(error.response.data, { icon: "warning" });
            });
        // }
        // else {
        //     swal("Unable to delete.", { icon: "warning" });
        // }
        //console.log(_fields);
        return;
    }
    const addNewImgOffice = (e: any) => {
        let values = [..._coApplicantOfficeDoc];
        values.push({
            documentNumber: 4,
            documentName: '',
            documentTypeId: 7,
            documentPath: '',
            reftype: '',
            id: values.length,
            customerDocumentTypeId: 0,
            VerificationPerson: ''
        });
        _setcoApplicantOfficeDoc(values);
    }

    return (
        <>
            <div className="bg-white p-3 content-area">
                {/* coming soon */}
                <div className="row">
                    <div className="col-md-6">
                        <div className="text-center fw-bold">Co Applicant Details</div>
                        <div className="row">
                            <div className='mb-3'>
                                <div>
                                    <label className="form-label mb-2">Co Applicant Name<b className="text-danger">*</b></label>
                                </div>
                                <div>
                                    <TextField size='small' autoComplete='off' label=" " InputLabelProps={{ shrink: false }} value={_formData?.coApplicantName} onChange={(e: any) => onFormChange('coApplicantName', e.target.value)} variant="outlined" className='w-100'
                                        error={postCaseValidation?.coApplicantName?.error} helperText={postCaseValidation?.coApplicantName?.message} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-4">
                                <div>
                                    <label className="form-label mb-2">Co Applicant Residential Address <b className="text-danger">*</b></label>
                                </div>
                                <div>
                                    <TextField size='small' autoComplete='off' label=" " value={_formData?.coAddress} onChange={(e: any) => onFormChange('coAddress', e.target.value)} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                                        error={postCaseValidation?.coAddress?.error} helperText={postCaseValidation?.coAddress?.message} multiline />
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="mb-4">
                                <div>
                                    <label className="form-label mb-2">Co Applicant Office Address <b className="text-danger">*</b></label>
                                </div>
                                <div>
                                    <TextField size='small' label=" " value={_formData?.coAddress_2} onChange={(e: any) => onFormChange('coAddress_2', e.target.value)} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                                        error={postCaseValidation?.coAddress_2?.error} helperText={postCaseValidation?.coAddress_2?.message} multiline />
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className='mb-4'>
                                <div>
                                    <label className="form-label mb-2">Co Applicant City<b className="text-danger">*</b></label>
                                </div>
                                <div>
                                    <TextField size='small' autoComplete='off' label=" " value={_formData?.coCity} onChange={(e: any) => onFormChange('coCity', e.target.value)} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                                        error={postCaseValidation?.coCity?.error} helperText={postCaseValidation?.coCity?.message} />
                                </div>
                            </div>
                            <div className='mb-4'>
                                <div>
                                    <label className='form-label mb-2'>Co Applicant Pincode<b className="text-danger">*</b></label>
                                </div>
                                <div>
                                    <TextField size='small' autoComplete='off' label=" " value={_formData?.coPincode} onChange={(e: any) => onFormChange('coPincode', (!isNaN(e.target.value) && e.target.value.length < 7) ? e.target.value.trim(' ') : '')} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                                        error={postCaseValidation?.coPincode?.error} helperText={postCaseValidation?.coPincode?.message}
                                        inputProps={{
                                            maxLength: 6,
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='mb-4'>
                                <div>
                                    <label className="form-label mb-2">Co Applicant State<b className="text-danger">*</b></label>
                                </div>
                                <div>
                                    <FormControl size="small" className="w-100" sx={{ m: 0 }} error={postCaseValidation?.coState?.error}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={_formData?.coState}
                                            onChange={(e) =>
                                                onFormChange("coState", e.target.value)
                                            }
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                        >
                                            {_StateList.map((item: any, index: any) => (
                                                <MenuItem value={item?.Statename}>{item?.Statename}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{postCaseValidation?.coState?.message}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <div>
                                    <label className="form-label mb-2">Mobile Number</label>
                                </div>
                                <div>
                                    <TextField size='small' autoComplete='off' label=" " value={_formData?.coMobileNo} onChange={(e: any) => onFormChange('coMobileNo', (!isNaN(e.target.value) && e.target.value.length < 11) ? e.target.value.trim(' ') : '')} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                                        error={postCaseValidation?.coMobileNo?.error} helperText={postCaseValidation?.coMobileNo?.message}
                                        inputProps={{
                                            maxLength: 10,
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='mb-4'>
                                <div className="form-label mb-2">Email Address</div>
                                <div>
                                    <TextField size='small' autoComplete='off' label=" " value={_formData?.coEmailAddress} onChange={(e: any) => onFormChange('coEmailAddress', e.target.value)} InputLabelProps={{ shrink: false }} variant="outlined" className='w-100'
                                        error={postCaseValidation?.coEmailAddress?.error} helperText={postCaseValidation?.coEmailAddress?.message} />
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div>
                                <label className="form-label mb-2">Trigger<b className="text-danger"></b></label>
                            </div>
                            <div>
                                <TextField size="small" autoComplete='off'
                                    label=" " multiline minRows={2}
                                    value={_formData?.CoAppTrigger}
                                    onChange={(e: any) =>
                                        onFormChange("CoAppTrigger", e.target.value)
                                    }
                                    InputLabelProps={{ shrink: false }}
                                    variant="outlined"
                                    className="w-100"
                                    error={postCaseValidation?.CoAppTrigger?.error} helperText={postCaseValidation?.CoAppTrigger?.message}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="d-flex justify-content-between">
                                <label className="form-label mt-2">Verification Type<b className="text-danger"></b></label>
                            </div>
                            <div className="d-flex flex-wrap">
                                <div className="col-6">
                                    <Checkbox checked={_formData?.coAppProfileCheck} onChange={() => onFormChange('coAppProfileCheck', !_formData?.coAppProfileCheck)} />
                                    <span>Profile</span>
                                </div>
                                <div className="col-6">
                                    <Checkbox checked={_formData?.coAppDocumentCheck} onChange={() => onFormChange('coAppDocumentCheck', !_formData?.coAppDocumentCheck)} />
                                    <span>Document</span>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="d-flex flex-wrap">
                                {(_formData?.coAppProfileCheck || _formData?.coAppDocumentCheck) && <div className="col-6">
                                    <FormControl error={postCaseValidation?.coVerificationField.error}><FormControlLabel
                                        control={<Checkbox checked={_formData?.coVerificationField} onChange={() => onFormChange('coVerificationField', !_formData?.coVerificationField)} />}
                                        label='Field' />
                                        <FormHelperText>{postCaseValidation?.coVerificationField?.message}</FormHelperText>
                                    </FormControl>
                                    {/* <Checkbox checked={_formData?.coVerificationField} onChange={() => onFormChange('coVerificationField', !_formData?.coVerificationField)} />
                                    <span>Field</span> */}
                                </div>}
                                {(_formData?.coAppProfileCheck || _formData?.coAppDocumentCheck) && <div className="col-6">
                                    <Checkbox checked={_formData?.coVerificationAuthority} onChange={() => onFormChange('coVerificationAuthority', !_formData?.coVerificationAuthority)} />
                                    <span>Authority</span>
                                </div>}
                            </div>
                        </div>
                        {(_formData?.coAppProfileCheck || _formData?.coAppDocumentCheck) && _formData?.coVerificationField && <>
                            <div className="mb-3">
                                <label className="form-label ">Hub Id</label>
                                <div>
                                    <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={_formData?.coAppHubId}
                                            onChange={(e) =>
                                                onResHubIdChange("coAppHubId", e.target.value)
                                            }
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                        >
                                            {_hubList.map((item: any, index: any) =>
                                                <MenuItem key={index} value={item?.Id}>{item?.HubName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label ">Field Staff Name<b className="text-danger"></b></label>
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField size='small' autoComplete='off'
                                            label=" " disabled
                                            value={_formData?.coAppFieldStaffName}
                                            InputLabelProps={{ shrink: false }}
                                            variant="outlined"
                                            className="w-100"
                                        />
                                    </div>
                                    {_formData?.precaseid && <div className="col-md-6">
                                        <FormControl size="small" className='w-100' sx={{ m: 0 }}>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper" value={_formData?.coAppFieldStaff}
                                                onChange={(e: any) => onFormChange('coAppFieldStaff', e.target.value)}
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            >
                                                {_fieldList.map((item: any, index: any) =>
                                                    <MenuItem key={index} value={item?.Id}>{item?.staffName}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>}
                                </div>
                            </div>
                        </>}
                        <div className="mb-3">
                            <label className="form-label ">Query</label>
                            <div>
                                <FormControl size="small" className='w-100' sx={{ m: 0 }} error={postCaseValidation?.CoAppQueryID?.error}>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper" value={_formData?.CoAppQueryID}
                                        onChange={(e: any) => onFormChange('CoAppQueryID', e.target.value)}
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    >
                                        {_queryList?.map((item: any, index: any) =>
                                            <MenuItem key={index} value={item?.QueryId}>{item?.QueryName}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText>{postCaseValidation?.CoAppQueryID?.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className=''>
                                <div className='row align-items-center my-2'>
                                    <label className="form-label col-md-3">Verification Point</label>
                                    <label className="form-label col-md-3">Document Type<b className="text-danger"></b></label>
                                    <label className="form-label col-md-3">Verification Type<b className="text-danger"></b></label>
                                    <div className='col-md-2'></div>
                                    <div className='col-md-1'>
                                        <div role="button" onClick={(e: any) => addCoApplicantNewImg(e)} >
                                            <AddCircleOutlineIcon />
                                        </div>
                                    </div>
                                </div>
                                {_coApplicantResidentialDoc.map((item: any, index: any) => {
                                    return (
                                        <div className='row align-items-center my-2'>
                                            <div className='col-md-3'>
                                                <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={item?.VerificationPerson || ''}
                                                        onChange={(e) =>
                                                            onChangeCoApplicantResDropdownChange('VerificationPerson', e.target.value, index)
                                                        }
                                                        sx={{
                                                            "& legend": { display: "none" },
                                                            "& fieldset": { top: 0 },
                                                        }}
                                                    >
                                                        <MenuItem value={'Profile'}>Field</MenuItem>
                                                        <MenuItem value={'Authority'}>Authority</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className='col-md-3'>
                                                <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={item?.customerDocumentTypeId || 0}
                                                        onChange={(e) =>
                                                            onChangeCoApplicantResDropdownChange('customerDocumentTypeId', e.target.value, index)
                                                        }
                                                        sx={{
                                                            "& legend": { display: "none" },
                                                            "& fieldset": { top: 0 },
                                                        }}
                                                    >
                                                        <MenuItem value={0}>Select Document Type</MenuItem>
                                                        {item?.VerificationPerson === "Profile" &&
                                                            _profileDocType?.map((item: any, index: any) => (
                                                                <MenuItem value={item?.Id}>{item?.DocumentType}</MenuItem>
                                                            ))}
                                                        {item?.VerificationPerson === "Authority" &&
                                                            _authorityDocType?.map((item: any, index: any) => (
                                                                <MenuItem value={item?.Id}>{item?.DocumentType}</MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='col-md-3'>
                                                <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                                                    <Select
                                                        value={item?.verificationtypeId || ''}
                                                        onChange={(e) =>
                                                            onChangeCoApplicantResDropdownChange('verificationtypeId', e.target.value, index)
                                                        }
                                                        sx={{
                                                            "& legend": { display: "none" },
                                                            "& fieldset": { top: 0 },
                                                        }}
                                                    >
                                                        {_verificationTypeList?.map((item: any, index: any) =>
                                                            <MenuItem key={index} value={Number(item?.VerificationId || '0')}>{item?.VerificationName}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='col-md-2'>
                                                {item.documentPath &&
                                                    <span className="d-flex h-100 align-items-center">
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            <i className='position-absolute'>
                                                                <IconButton>
                                                                    <VisibilityIcon onClick={() => window.open(item?.documentPath, "_blank")} className='text-muted' />
                                                                </IconButton>
                                                            </i>
                                                            <img onClick={() => window.open(item?.documentPath, "_blank")} src={item?.documentPath} className='imageFit' style={{  height: "3rem", opacity: "50%" }} alt="documentPath" />
                                                        </div>
                                                    </span>
                                                }
                                                {item.documentPath === "" &&
                                                    <div className='d-flex align-items-center justify-content-center' style={{ border: "1px solid #d8d8d8",  height: "3rem", borderRadius: "10px" }}>
                                                        <label htmlFor="upload-photo">
                                                            <CloudUploadIcon className='text-muted' />
                                                        </label>
                                                        <input onChange={(e: any) => onChangeResidentialPicture(e, index, item?.customerDocumentTypeId)} id="upload-photo" type="file" style={{  height: "3rem" }} />
                                                    </div>
                                                }
                                            </div>
                                            <div className='col-md-1'>
                                                <CancelIcon style={{ color: "rgb(255, 0, 0)" }} onClick={(e: any) => removeCoApplicantResidentiaDoc(index)} role="button" />
                                            </div>
                                            {/* {_coApplicantResidentialDoc?.length === index + 1 && <div role="button" className='col-md-1' onClick={(e: any) => addCoApplicantNewImg(e)} >
                                                <AddCircleOutlineIcon />
                                            </div>} */}
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="text-center fw-bold">Office Details</div>
                        <div className="row ">
                            <div className="mb-3">
                                <div>
                                    <label className="form-label mb-2">
                                        Office Name<b className="text-danger">*</b>
                                    </label>
                                </div>
                                <div>
                                    <TextField size="small" autoComplete='off'
                                        variant="outlined"
                                        value={_formData?.coofficeName}
                                        onChange={(e: any) =>
                                            onFormChange("coofficeName", e.target.value)
                                        }
                                        label=" "
                                        className="w-100"
                                        InputLabelProps={{ shrink: false }}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="mb-3">
                                <div>
                                    <label className="form-label mb-2">
                                        Office Address 01<b className="text-danger">*</b>
                                    </label>
                                </div>
                                <div>
                                    <TextField size="small" autoComplete='off'
                                        variant="outlined"
                                        value={_formData?.coofficeAddress1}
                                        onChange={(e: any) =>
                                            onFormChange("coofficeAddress1", e.target.value)
                                        }
                                        label=" "
                                        className="w-100"
                                        InputLabelProps={{ shrink: false }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="mb-3">
                                <div>
                                    <label className="form-label mb-2">
                                        Office Address 02<b className="text-danger"></b>
                                    </label>
                                </div>
                                <div>
                                    <TextField size="small" autoComplete='off'
                                        variant="outlined"
                                        label=" "
                                        value={_formData?.coofficeAddress2}
                                        onChange={(e: any) =>
                                            onFormChange("coofficeAddress2", e.target.value)
                                        }
                                        className="w-100"
                                        InputLabelProps={{ shrink: false }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="mb-3">
                                <div>
                                    <label className="form-label mb-2">
                                        Office City<b className="text-danger"></b>
                                    </label>
                                </div>
                                <div>
                                    <TextField size="small" autoComplete='off'
                                        variant="outlined"
                                        label=" "
                                        value={_formData?.coofficeCity}
                                        onChange={(e: any) =>
                                            onFormChange("coofficeCity", e.target.value)
                                        }
                                        className="w-100"
                                        InputLabelProps={{ shrink: false }}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <div>
                                    <label className="form-label mb-2">
                                        Office Pincode<b className="text-danger">*</b>
                                    </label>
                                </div>
                                <div>
                                    <TextField size="small" autoComplete='off'
                                        variant="outlined"
                                        label=" "
                                        value={_formData?.coofficePincode}
                                        onChange={(e: any) => onFormChange("coofficePincode", (!isNaN(e.target.value) && e.target.value.length < 7) ? e.target.value.trim(' ') : '')}
                                        className="w-100"
                                        InputLabelProps={{ shrink: false }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="mb-3">
                                <div>
                                    <label className="form-label mb-2">
                                        Office State<b className="text-danger">*</b>
                                    </label>
                                </div>
                                <div>
                                    <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={_formData?.coofficeState}
                                            onChange={(e) =>
                                                onFormChange("coofficeState", e.target.value)
                                            }
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                        >
                                            {_StateList.map((item: any, index: any) => (
                                                <MenuItem value={item?.Statename}>{item?.Statename}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div>
                                    <label className="form-label mb-2">
                                        Office Land Mark<b className="text-danger"></b>
                                    </label>
                                </div>
                                <div>
                                    <TextField size="small" autoComplete='off'
                                        variant="outlined"
                                        label=" "
                                        value={_formData?.coofficeLandMark}
                                        onChange={(e: any) =>
                                            onFormChange("coofficeLandMark", e.target.value)
                                        }
                                        className="w-100"
                                        InputLabelProps={{ shrink: false }}
                                    />
                                </div>
                            </div>

                            <div className="row ">
                                <div className="mb-3">
                                    <div>
                                        <label className="form-label mb-2">
                                            Office Landline<b className="text-danger"></b>
                                        </label>
                                    </div>
                                    <div>
                                        <TextField size="small" autoComplete='off'
                                            variant="outlined"
                                            label=" "
                                            value={_formData?.coofficeLandLine}
                                            onChange={(e: any) => onFormChange("coofficeLandLine", (!isNaN(e.target.value) && e.target.value.length < 12) ? e.target.value.trim(' ') : '')}
                                            className="w-100"
                                            InputLabelProps={{ shrink: false }}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div>
                                        <label className="form-label mb-2">
                                            Office Mobile Number
                                        </label>
                                    </div>
                                    <div>
                                        <TextField size="small" autoComplete='off'
                                            variant="outlined"
                                            label=" "
                                            value={_formData?.coofficeMobileNumber}
                                            onChange={(e: any) => onFormChange("coofficeMobileNumber", (!isNaN(e.target.value) && e.target.value.length < 11) ? e.target.value.trim(' ') : '')}
                                            className="w-100"
                                            InputLabelProps={{ shrink: false }}
                                            error={postCaseValidation?.coofficeMobileNumber?.error} helperText={postCaseValidation?.coofficeMobileNumber?.message}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row '>
                                <div className="mb-4">
                                    <div className="from-label mb-2">Designation<b className="text-danger"></b></div>
                                    <div>
                                        <TextField size="small" autoComplete='off' variant="outlined" label=" " value={_formData?.codesignation} onChange={(e: any) => onFormChange('codesignation', e.target.value)}
                                            className='w-100' InputLabelProps={{ shrink: false }} />
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <div className="form-label mb-2">Employee Type<b className="text-danger"></b></div>
                                    <div>
                                        <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={_formData?.coemployeeTypeId}
                                                onChange={(e) =>
                                                    onEmployeeTypeChange("coemployeeTypeId", e.target.value)
                                                }
                                                sx={{
                                                    "& legend": { display: "none" },
                                                    "& fieldset": { top: 0 },
                                                }}
                                            >
                                                {_EmployeeType.map((item: any, index: any) => (
                                                    <MenuItem value={item?.Id}>{item?.TypeName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {/* <div className="mb-4" style={{ display: _EmployeeDocDisplay ? "block" : "none" }}>
                                    <div className="form-label mb-2">Document Type<b className="text-danger"></b></div>
                                    <div>
                                        <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={_formData?.coAppEmployeeDocumnetTypeId}
                                                onChange={(e) =>
                                                    onFormChange("coAppEmployeeDocumnetTypeId", e.target.value)
                                                }
                                                sx={{
                                                    "& legend": { display: "none" },
                                                    "& fieldset": { top: 0 },
                                                }}
                                            >
                                                {_EmployeeDocType.map((item: any, index: any) => (
                                                    <MenuItem value={item?.Id}>{item?.DocumentType}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div> */}
                                <div className="mb-4">
                                    <div>
                                        <label className="form-label mb-2">Office Trigger<b className="text-danger"></b></label>
                                    </div>
                                    <div>
                                        <TextField size="small" autoComplete='off'
                                            label=" " multiline minRows={2}
                                            value={_formData?.CoAppOfficeTrigger}
                                            onChange={(e: any) =>
                                                onFormChange("CoAppOfficeTrigger", e.target.value)
                                            }
                                            InputLabelProps={{ shrink: false }}
                                            variant="outlined"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label mt-2">Verification Type<b className="text-danger"></b></label>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        <div className="col-6">
                                            <Checkbox checked={_formData?.coAppOfficeProfileCheck} onChange={() => onFormChange('coAppOfficeProfileCheck', !_formData?.coAppOfficeProfileCheck)} />
                                            <span>Profile</span>
                                        </div>
                                        <div className="col-6">
                                            <Checkbox checked={_formData?.coAppOfficeDocumentCheck} onChange={() => onFormChange('coAppOfficeDocumentCheck', !_formData?.coAppOfficeDocumentCheck)} />
                                            <span>Document</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex flex-wrap">
                                        {(_formData?.coAppOfficeProfileCheck || _formData?.coAppOfficeDocumentCheck) && <div className="col-6">
                                            <FormControl error={postCaseValidation?.coOfficeVerificationField.error}><FormControlLabel
                                                control={<Checkbox checked={_formData?.coOfficeVerificationField} onChange={() => onFormChange('coOfficeVerificationField', !_formData?.coOfficeVerificationField)} />}
                                                label='Field' />
                                                <FormHelperText>{postCaseValidation?.coOfficeVerificationField?.message}</FormHelperText>
                                            </FormControl>
                                            {/* <Checkbox checked={_formData?.coOfficeVerificationField} onChange={() => onFormChange('coOfficeVerificationField', !_formData?.coOfficeVerificationField)} />
                                            <span>Field</span> */}
                                        </div>}
                                        {(_formData?.coAppOfficeProfileCheck || _formData?.coAppOfficeDocumentCheck) && <div className="col-6">
                                            <Checkbox checked={_formData?.coOfficeVerificationAuthority} onChange={() => onFormChange('coOfficeVerificationAuthority', !_formData?.coOfficeVerificationAuthority)} />
                                            <span>Authority</span>
                                        </div>}
                                    </div>
                                </div>
                                {(_formData?.coAppOfficeProfileCheck || _formData?.coAppOfficeDocumentCheck) && _formData?.coOfficeVerificationField && <>
                                    <div className="mb-3">
                                        <label className="form-label ">Hub Id</label>
                                        <div>
                                            <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={_formData?.coAppOfficeHubId}
                                                    onChange={(e) =>
                                                        onOfficeHubIdChange("coAppOfficeHubId", e.target.value)
                                                    }
                                                    sx={{
                                                        "& legend": { display: "none" },
                                                        "& fieldset": { top: 0 },
                                                    }}
                                                >
                                                    {_hubList.map((item: any, index: any) => (
                                                        <MenuItem key={index} value={item?.Id}>{item?.HubName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label ">Field Staff Name<b className="text-danger"></b></label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextField size="small" autoComplete='off'
                                                    label=" " disabled
                                                    value={_formData?.coOfficeFieldStaffName}
                                                    InputLabelProps={{ shrink: false }}
                                                    variant="outlined"
                                                    className="w-100"
                                                />
                                            </div>
                                            {_formData?.precaseid && <div className="col-md-6">
                                                <FormControl size="small" className='w-100' sx={{ m: 0 }}>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper" value={_formData?.coAppOfficeFieldStaff}
                                                        onChange={(e: any) => onFormChange('coAppOfficeFieldStaff', e.target.value)}
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    >
                                                        {_fieldList.map((item: any, index: any) =>
                                                            <MenuItem key={index} value={item?.Id}>{item?.staffName}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>}
                                        </div>
                                    </div>
                                </>}
                                <div className="mb-3">
                                    <label className="form-label ">Office Query</label>
                                    <div>
                                        <FormControl size="small" className='w-100' sx={{ m: 0 }} error={postCaseValidation?.CoAppOfficeQueryID?.error}>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper" value={_formData?.CoAppOfficeQueryID}
                                                onChange={(e: any) => onFormChange('CoAppOfficeQueryID', e.target.value)}
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            >
                                                {_officeQueryList?.map((item: any, index: any) =>
                                                    <MenuItem key={index} value={item?.QueryId}>{item?.QueryName}</MenuItem>
                                                )}
                                            </Select>
                                            <FormHelperText>{postCaseValidation?.CoAppOfficeQueryID?.message}</FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className=''>
                                        <div className='row align-items-center my-2'>
                                            <label className="form-label col-md-3">Verification Point</label>
                                            <label className="form-label col-md-3">Document Type<b className="text-danger"></b></label>
                                            <label className="form-label col-md-3">Verification Type<b className="text-danger"></b></label>
                                            <div className='col-md-2'></div>
                                            <div className='col-md-1'>
                                                <div role="button" onClick={(e: any) => addNewImgOffice(e)} >
                                                    <AddCircleOutlineIcon />
                                                </div>
                                            </div>
                                        </div>
                                        {_coApplicantOfficeDoc?.map((val: any, index: any) => {
                                            return (
                                                <div className='row align-items-center my-2'>
                                                    <div className='col-md-3'>
                                                        <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                                                            <Select
                                                                labelId="demo-simple-select-helper-label"
                                                                id="demo-simple-select-helper"
                                                                value={val?.VerificationPerson || 0}
                                                                onChange={(e) =>
                                                                    onChangeCoApplicantOfficeDropdownChange('VerificationPerson', e.target.value, index)
                                                                }
                                                                sx={{
                                                                    "& legend": { display: "none" },
                                                                    "& fieldset": { top: 0 },
                                                                }}
                                                            >
                                                                <MenuItem value={'Profile'}>Field</MenuItem>
                                                                <MenuItem value={'Authority'}>Authority</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <FormControl size="small" className="w-100" sx={{ m: 0 }} >
                                                            <Select
                                                                labelId="demo-simple-select-helper-label"
                                                                id="demo-simple-select-helper"
                                                                value={val?.customerDocumentTypeId || 0}
                                                                onChange={(e) =>
                                                                    onChangeCoApplicantOfficeDropdownChange('customerDocumentTypeId', e.target.value, index)
                                                                }
                                                                sx={{
                                                                    "& legend": { display: "none" },
                                                                    "& fieldset": { top: 0 },
                                                                }}
                                                            >
                                                                <MenuItem value={0}>Select Document Type</MenuItem>
                                                                {val?.VerificationPerson === "Profile" &&
                                                                    _profileDocType?.map((item: any, index: any) => (
                                                                        <MenuItem key={index} value={item?.Id}>{item?.DocumentType}</MenuItem>
                                                                    ))}
                                                                {val?.VerificationPerson === "Authority" &&
                                                                    _authorityDocType?.map((item: any, index: any) => (
                                                                        <MenuItem key={index} value={item?.Id}>{item?.DocumentType}</MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <FormControl size="small" className="w-100" sx={{ m: 0 }}>
                                                            <Select
                                                                value={val?.verificationtypeId || ''}
                                                                onChange={(e) =>
                                                                    onChangeCoApplicantOfficeDropdownChange('verificationtypeId', e.target.value, index)
                                                                }
                                                                sx={{
                                                                    "& legend": { display: "none" },
                                                                    "& fieldset": { top: 0 },
                                                                }}
                                                            >
                                                                {_verificationTypeList?.map((item: any, index: any) =>
                                                                    <MenuItem key={index} value={Number(item?.VerificationId || '0')}>{item?.VerificationName}</MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        {val.documentPath &&
                                                            <span className="d-flex h-100 align-items-center">
                                                                <div className='d-flex align-items-center justify-content-center'>
                                                                    <i className='position-absolute'>
                                                                        <IconButton>
                                                                            <VisibilityIcon onClick={() => window.open(val?.documentPath, "_blank")} className='text-muted' />
                                                                        </IconButton>
                                                                    </i>
                                                                    <img onClick={() => window.open(val?.documentPath, "_blank")} src={val?.documentPath} className='imageFit' style={{ height: "3rem", opacity: "50%" }} alt="documentPath" />
                                                                </div>
                                                            </span>
                                                        }
                                                        {val.documentPath === "" &&
                                                            <div className='d-flex align-items-center justify-content-center' style={{ border: "1px solid #d8d8d8", height: "3rem", borderRadius: "10px" }}>
                                                                <label htmlFor="upload-office-photo">
                                                                    <CloudUploadIcon className='text-muted' />
                                                                </label>
                                                                <input onChange={(e: any) => onChangeOfficePicture(e, index, val?.customerDocumentTypeId)} id="upload-office-photo" type="file" style={{ height: "3rem" }} />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='col-md-1'>
                                                        <CancelIcon style={{ color: "rgb(255, 0, 0)" }} onClick={(e: any) => removeCoApplicantOfficeDoc(index)} role="button" />
                                                    </div>
                                                    {/* {_coApplicantOfficeDoc?.length === index + 1 && <div role="button" className='col-md-1' onClick={(e: any) => addNewImgOffice(e)} >
                                                        <AddCircleOutlineIcon />
                                                    </div>} */}
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}

export default Customerdetails;
